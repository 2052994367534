import React, { Component } from 'react'
import { Kushki } from '@kushki/js'
import { withRouter } from 'react-router-dom'
import FormError from '../common/FormError'
import { connect } from 'react-redux'
import 'react-credit-cards/es/styles-compiled.css';
import { tokenRequetError } from '../../funciones/checkout'
import { cleverCheckoutFail } from '../../funciones/clevertap'
import { formatNumber } from '../../funciones/general'

class PseForm extends Component {

    constructor(props){
        super(props)
        this.state={
            load:true,
            error_message:'',
            mensaje:'Cargando lista de bancos',
            list_bank:[],
            bank:'',
            doc:'',
            email:'',
            doc_type:'CC',
            user_type:"0",
            kushki: new Kushki({
                merchantId: '20000000106403330000', //produccion
                //merchantId: '20000000102909980000', //pruebas
                inTestEnvironment: false,
                regional:false
            })
        }
    }

    changeText = () => {

        this.setState({
            mensaje:"Pedido en proceso"
        })
        
    }


    requestPseToken = () => {

        const {kushki,bank,user_type,doc,doc_type,email}= this.state

        let callback = (response) =>{
            if(!response.code){
              this.setState({
                  mensaje:"Pedido en proceso"
              })
              this.props.pay(response.token,this.state.bank)
            } else {
                tokenRequetError(response.error,response.code,response.message)
                cleverCheckoutFail("Error en solicitud de token PSE")
            }
        }

        kushki.requestTransferToken({
        bankId: bank, // Only required for Colombian merchants
        callbackUrl: 'https://www.fluio.com.co/pse-confirmation', // prod
        //callbackUrl: 'http://localhost:3000/pse-confirmation', // test
        userType: user_type,
        documentType: doc_type,
        documentNumber: doc,
        paymentDesc: 'Compra de domicilio saludable en fluio',
        email: email,
        currency: 'COP',
        amount: { 
            subtotalIva: 0,
            subtotalIva0: this.props.amount,
            iva: 0,
            extraTaxes: {
                propina: 0,
                tasaAeroportuaria: 0,
                agenciaDeViajes: 0,
                iac: 0
                }
            }
        },
        callback); // Also you can set the function directly
        

         console.log(this.state,this.props.amount)
        
    }
    




    handleSubmit = (e) => {

        e.preventDefault()

        this.setState({
            load:true,
            mensaje:'Pedido en proceso'
        },()=>{
            this.requestPseToken()
        })

    }


    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    getBankkList = () => {

        const {kushki}=this.state

        let callback = (response)=> {
            if(!response.code){
                response.shift()
                this.setState({
                    list_bank:response,
                    load: false,
                    bank:response[0].code
                })
            } else {
                tokenRequetError(response.error,response.code,response.message)
            }
        }
        
        kushki.requestPseBankList(callback); // Also you can set the function directly
        
    }
    
    componentDidMount(){
        this.getBankkList()
    }



    render() {

        const {list_bank,load,mensaje}= this.state

        const bank_options=list_bank.map((value,index)=>{

            return(
                <option  value={value.code} key={index}>{value.name}</option>
            )

        })

        return(
            <div>
                {
                    load?

                        <div className="row h-196 margin-zero">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                <h3>{mensaje}</h3>
                            </div>
                        </div>

                    :
                    <>
                        <h3 className="tac">Pago PSE</h3>
                        <form onSubmit={this.handleSubmit}>

                            <div className="form-group">

                                <label htmlFor="radio" >Tipode persona</label>
                                <br/>

                                <label className="radio-inline">
                                    <input type="radio" name="user_type"  value={"0"} onChange={this.handleChange} checked={this.state.user_type == "0"} /> Natural
                                </label>
                
                                <label className="ml-2 radio-inline">
                                    <input type="radio" name="user_type"  value={"1"} onChange={this.handleChange} checked={this.state.user_type == "1"} />Jurídica
                                </label>

                            </div>


                            <div className="form-group">
                                <label htmlFor="doc_type" className="col-form-label" >Tipo de documento</label>
                                <select name="doc_type" onChange={this.handleChange} value={this.state.doc_type || ''} className="form-control" required>
                                    <option value="CC">Cédula colombiana</option>
                                    <option value="NIT">NIT</option>
                                    <option value="CE">Cédula extranjera</option>
                                    <option value="TI">Tarjeta de identidad</option>
                                    <option value="PP">Pasaporte</option>                    
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="doc" >Correo</label>
                                <input type="email" className="form-control" name="email" value={this.state.email || ''} onChange={this.handleChange} required/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="doc" >Número de documento</label>
                                <input type="text" className="form-control" name="doc" value={this.state.doc || ''} onChange={this.handleChange} required/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="bank"  >Banco</label>
                                <select name="bank" onChange={this.handleChange} value={this.state.bank || ''} className="form-control" required>
                                    {bank_options}
                                </select>
                            </div>

                            <button type="submit" className="btn w-100 btn-pay">
                                Pagar ${formatNumber(this.props.amount)}
                            </button>
                            
                        </form>
                    </>
                }
                
            </div>
        )


    }
}

const mapStateToProps = (state) => ({
    user:state.customer.data
})

export default connect(
    mapStateToProps,
    {}
)(withRouter(PseForm))

