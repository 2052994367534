import React, { Component } from 'react'
import { connect } from 'react-redux'




class DetalleAdicional extends Component {

    constructor(props){
        super(props)
        this.state={
            product: props.product
        }
    }

    getPostre(id_add){
        const {add_postre}=this.props

        return add_postre.find((value)=>
            value.id==id_add
        )

    }

    getBebida(id_add){
        const {add_bebida}=this.props

        return add_bebida.find((value)=>
            value.id==id_add
        )

    }

    render() {

        const {product}= this.state
        const {symbol}= this.props
        
        return (
            <div className="modal-dialog  modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <strong className="font-20" >Comentarios</strong>
                    </div>
                    <br/>

                    <div className="modal-body">
                        <div className="row">

                            <div className="col-md-12">
                                <form onSubmit={this.submit}>

                                    <div>
                                        <strong>Producto:</strong>
                                        {product.name}
                                    </div>
        
                                    <div>
                                        <strong>Costo unitario:</strong>
                                        {symbol}{product.price}
                                    </div>


                                    <div>
                                        <strong>Comentario:</strong>
                                        {product.comment?product.comment:'Sin comentario'}
                                    </div>

                                    

                                </form>

                            </div>

                            
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps
)(DetalleAdicional)