import React, { Component } from 'react'

export class Facebook extends Component {

    click = (e) => {

        e.preventDefault()
        this.props.click()
        
    }

    render() {
        return (
            <a href="#" class="fb social-btn" onClick={this.click}>
                <i class="fa fa-facebook"></i> Login con facebook
            </a>
        )
    }
}

export default Facebook
