import React, { Component } from 'react'
import { connect } from 'react-redux'

class ProgramableNormal extends Component {

    constructor(props){
        super(props)
        this.state={
            hora: props.hora,
            fecha: props.fecha
        }
    }

    submit = (e) => {
        e.preventDefault()
        this.props.close()
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }


    render() {
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h4 className="modal-title" >Programar Envío</h4>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submit}>


                            <div className="form-group row">
                                <label htmlFor="fecha" className="col-2 col-form-label">Fecha:</label>
                                <div className="col-10">
                                    <input className="form-control" name="fecha" type="date" value={this.state.fecha || ''} onChange={this.handleChange} required  />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="hora" className="col-form-label" >Hora :</label>
                                <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                    <option  value="">N/A</option>
                                    <option  value={1}>12:00</option>
                                    <option  value={2}>12:15</option>
                                    <option  value={3}>12:30</option>
                                    <option  value={4}>12:45</option> 
                                    <option  value={4}>13:00</option> 
                                    <option  value={4}>13:15</option>
                                    <option  value={4}>13:30</option>
                                    <option  value={4}>13:45</option>
                                    <option  value={4}>14:00</option>
                                    <option  value={4}>14:15</option>
                                    <option  value={4}>14:30</option> 
                                </select>
                            </div>

                            <button className="btn btn-solid"> Guardar</button>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps
)(ProgramableNormal)
