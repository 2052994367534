import React, { Component } from 'react'
import { formatNumber } from '../../funciones/general'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toastSuccess } from '../../alertas';

export class PriceListingPage extends Component {

    onCopy = () => {

        toastSuccess('Enlace copiado')
        
    }
    
    render() {
        const {product}=this.props
        return (
            <>
            {
                product.if_offer?
                <h4>
                    ${formatNumber(product.price_offer)}
                    <del className="old-price-space">
                    ${formatNumber(product.price)}
                    </del>
                    <CopyToClipboard text={`https://fluio.com.co${window.location.pathname}`} onCopy={this.onCopy}>
                        <i className="fa fa-share share-icon pointer"></i>
                    </CopyToClipboard>
                </h4>
                :
                <h4>
                    ${formatNumber(product.price)}
                    <CopyToClipboard text={`https://fluio.com.co${window.location.pathname}`} onCopy={this.onCopy}>
                        <i className="fa fa-share share-icon pointer"></i>
                    </CopyToClipboard>
                </h4>
            }
            </>
        )
    }
}

export default PriceListingPage