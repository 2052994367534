import React, { Component } from 'react';
import empty from '../../assets/images/portfolio/empty.jpg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { filterCategory, updateHalls, updateLoadHome } from '../../actions';
import ProductListingPasillosAllAliado from './common/product-listing-pasillos-all-aliado';
import { cargarStorage } from '../../funciones/cargarDatos';
import Banner from '../common/Banner';
import AllySlider from '../common/AllySlider';
import { getLocationHalls } from '../../funciones/cities';

class CollectionPasillosAllAliado extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: 3,
            data: [],
            categoria: '',
        };
    }

    async componentDidMount() {
        cargarStorage();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.filterCategory([]);
        this.props.history.push('/productos/' + this.state.text);
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    goToPasillo = (e, pasillo) => {
        e.preventDefault();
        const { ally } = this.props.match.params;
        window.location.href = process.env.PUBLIC_URL + '/' + ally + '/' + pasillo;
    };

    render() {
        const { halls, categories, aliado, loading_products } = this.props;
        const ally_id = this.props.match.params.ally;
        let categoria = [];

        let data_aliado = aliado.find((value) => value.seo == ally_id);

        let categorias_lista = categories.filter((value) => {
            return value.id == this.props.match.params.category;
        });

        if (categorias_lista.length > 0) {
            categoria = categorias_lista[0];
        }

        const lista_pasillo = halls.filter((value) =>
            value.aliados.includes(parseInt(data_aliado ? data_aliado.id : ''))
        );

        const array_pasillo = lista_pasillo.map((value, index) => {
            return (
                <div className={'row max-h-250 ' + (index > 0 ? 'mt-5 ' : '')} key={index} id={'hall' + value.id}>
                    <div className="col-4 col-sm-3 media-pasillo mb-none">
                        {value.image ? (
                            <>
                                <img
                                    src={value.image}
                                    alt="Snow"
                                    className="pasillo-img3 w-respo mb-max-img-pas pas-min-h "
                                />
                                <p className="pasillo-label text-bc w-respo80 mb-font9">{value.name}</p>
                            </>
                        ) : (
                            <>
                                <img src={empty} alt="Snow" className="pasillo-img3 w-respo mb-max-184" />
                                <p className="pasillo-label text-bc w-respo80 mb-font9 ">{value.name}</p>
                            </>
                        )}
                    </div>

                    <div className="col-12 mt-1  mb-display">
                        <p className="tal pasillo-label-style">{value.name}</p>
                    </div>
                    <span
                        className="mb-display text-deco mb-display"
                        onClick={(e) => {
                            this.goToPasillo(e, value.seo);
                        }}
                    >
                        Ver más
                    </span>

                    {/*Caso desktop*/}
                    <div className="collection-content col-8 col-sm-7 mt-150px mb-mt-130 mb-no-padding mb-none">
                        <ProductListingPasillosAllAliado
                            categoria={categoria.id}
                            colSize={this.state.columns}
                            aliado={data_aliado.id}
                            aliado_seo={data_aliado.seo}
                            pasillo={value.id}
                            pasillo_seo={value.seo}
                            hallValue={value}
                        />
                    </div>

                    {/*Caso movil*/}
                    <div className="container mb-display">
                        <div className="collection-content col-12 col-sm-7 mt-150px mb-mt-130 mb-no-padding ">
                            <ProductListingPasillosAllAliado
                                categoria={categoria.id}
                                colSize={this.state.columns}
                                aliado={data_aliado.id}
                                aliado_seo={data_aliado.seo}
                                pasillo={value.id}
                                pasillo_seo={value.seo}
                                hallValue={value}
                            />
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <Banner />

                <div className="sticky-hall-s">
                    <div>
                        <h3 className="center mb-center90 ttn">{data_aliado ? data_aliado.name : ''}</h3>
                    </div>

                    <AllySlider halls={lista_pasillo} />
                </div>

                <section className="section-b-space no-pt no-p-bt">
                    <div className="collection-wrapper">
                        <div className="container">
                            {/*posible inicio del algoritmo*/}
                            {array_pasillo.length > 0 && !loading_products ? (
                                <>{array_pasillo}</>
                            ) : !loading_products ? (
                                <div className="row">
                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                            className="img-fluid mb-4"
                                        />
                                        <h3 className="ttn">Parece que no hay pasillos en este aliado</h3>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`}
                                            className="img-fluid mb-4"
                                        />
                                        <h3>Cargando</h3>
                                    </div>
                                </div>
                            )}

                            {/*


                            <div className="row mt-5">

                                <div className="col-sm-3 collection-filter">


                                    <img className="pasillo-img2" src="/assets/images/vege.jpg" alt="Avatar"></img>
                                    <p className="pasillo-label w-respo80 ml-3 mt-3" >Comida Sana</p>

                                </div>

                                <div className="collection-content col mt-5">


                                    <ProductListingPasillos colSize={this.state.columns} />


                                </div>

                            </div>

                            <div className="row mt-5">

                                <div className="col-sm-3 collection-filter">


                                    <img className="circular--portrait" src="/assets/images/square.jpg" alt="Avatar"></img>
                                    <p className="pasillo-label2 text-c w-respo80 ml-3 mt-3" >Comida Sana</p>

                                </div>

                                <div className="collection-content col mt-5">


                                    <ProductListingPasillos colSize={this.state.columns} />


                                </div>

                            </div>

                             */}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    halls: getLocationHalls(),
    categories: state.categories.categories,
    load_home: state.customer.load_home,
    products: state.data.products,
    aliado: state.aliado.data,
    loading_products: state.data.loading_products,
});

export default connect(
    mapStateToProps,
    { filterCategory, updateHalls, updateLoadHome }
)(withRouter(CollectionPasillosAllAliado));
