import React, { Component } from 'react';
import { getData } from '../../servicios';
import empty from '../../assets/images/portfolio/empty.jpg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { filterCategory, updateHalls, updateLoadHome } from '../../actions';
import ProductListingPasillos2 from './common/product-listing-pasillos2';
import { cargarDatos } from '../../services';
import { getArrayCategoriesSlider } from '../../funciones/pasillo';
import CategoriesSlider from '../common/CategoriesSlider';
import { cargarStorage } from '../../funciones/cargarDatos';
import Banner from '../common/Banner';
import { cleverVisitedCategory } from '../../funciones/clevertap';
import HallSlider from '../common/HallSlider';
import { getLocationCategories, getLocationHalls } from '../../funciones/cities';

class CollectionPasillos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: 3,
            data: [],
            categoria: '',
            load: false,
        };
    }

    async componentDidMount() {
        const { categories } = this.props;

        let categoria = categories.find((val) => val.id == this.props.match.params.category);

        if (categoria) {
            cleverVisitedCategory(categoria.name);
        }

        cargarStorage();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.filterCategory([]);
        this.props.history.push('/productos/' + this.state.text);
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    goToPasillo = (e, pasillo) => {
        e.preventDefault();
        //this.props.history.push("/pasillo/"+pasillo)
        window.location.href = process.env.PUBLIC_URL + '/pasillo/' + pasillo;
    };

    render() {
        const { halls, categories, products, loading_products } = this.props;
        const categoria_id = this.props.match.params.category;
        let categoria = [];

        let categorias_lista = categories.filter((value) => {
            return value.seo == this.props.match.params.category;
        });

        if (categorias_lista.length > 0) {
            categoria = categorias_lista[0];
        }

        const lista_pasillo = halls.filter((value) => value.category_id == categoria.id);

        const array_pasillo = lista_pasillo.map((value, index) => {
            return (
                <div className={'row max-h-250 ' + (index > 0 ? 'mt-5 ' : '')} key={index} id={'hall' + value.id}>
                    <div className="col-4 col-sm-3 media-pasillo mb-none">
                        {value.image ? (
                            <>
                                <img
                                    src={value.image}
                                    alt="Snow"
                                    className="pasillo-img3 w-respo mb-max-img-pas pas-min-h "
                                />
                                <p className="pasillo-label text-bc w-respo80 mb-font9">{value.name}</p>
                            </>
                        ) : (
                            <>
                                <img src={empty} alt="Snow" className="pasillo-img3 w-respo mb-max-184" />
                                <p className="pasillo-label text-bc w-respo80 mb-font9 ">{value.name}</p>
                            </>
                        )}
                    </div>

                    <div className="col-12 mt-1  mb-display">
                        <p className="tal pasillo-label-style">{value.name}</p>
                    </div>
                    <span
                        className="mb-display text-deco mb-display"
                        onClick={(e) => {
                            this.goToPasillo(e, value.seo);
                        }}
                    >
                        Ver más
                    </span>

                    {/*Caso desktop*/}
                    <div className="collection-content col-8 col-sm-7 mt-150px mb-mt-130 mb-no-padding mb-none">
                        <ProductListingPasillos2
                            categoria={categoria.tipo}
                            colSize={this.state.columns}
                            productos={value.productos}
                            pasillo={value.id}
                            pasillo_seo={value.seo}
                            hallValue={value}
                        />
                    </div>

                    {/*Caso movil*/}
                    <div className="container mb-display">
                        <div className="collection-content col-12 col-sm-7 mt-150px mb-mt-130 mb-no-padding ">
                            <ProductListingPasillos2
                                categoria={categoria.tipo}
                                colSize={this.state.columns}
                                productos={value.productos}
                                pasillo={value.id}
                                pasillo_seo={value.seo}
                                hallValue={value}
                            />
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <Banner />

                {/*
                <CategoriesSlider categoria_id={categoria.id} seo={categoria.seo}/>
                */}

                <div className="sticky-hall-s">
                    <div>
                        <h3 className="center mb-center90 ttn">{categoria.name}</h3>
                    </div>

                    <HallSlider categoria_id={categoria.id} />
                </div>

                <section className="section-b-space no-pt no-p-bt">
                    <div className="collection-wrapper">
                        <div className="container">
                            {/*posible inicio del algoritmo*/}
                            {array_pasillo.length > 0 || products.length > 0 ? (
                                <>{array_pasillo}</>
                            ) : loading_products == false ? (
                                <div className="row">
                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                            className="img-fluid mb-4"
                                        />
                                        <h3 className="ttn">Parece que no hay pasillos en esta categoria</h3>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                        <img
                                            src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`}
                                            className="img-fluid mb-4"
                                        />
                                        <h3>Cargando</h3>
                                    </div>
                                </div>
                            )}

                            {/*


                            <div className="row mt-5">

                                <div className="col-sm-3 collection-filter">


                                    <img className="pasillo-img2" src="/assets/images/vege.jpg" alt="Avatar"></img>
                                    <p className="pasillo-label w-respo80 ml-3 mt-3" >Comida Sana</p>

                                </div>

                                <div className="collection-content col mt-5">


                                    <ProductListingPasillos colSize={this.state.columns} />


                                </div>

                            </div>

                            <div className="row mt-5">

                                <div className="col-sm-3 collection-filter">


                                    <img className="circular--portrait" src="/assets/images/square.jpg" alt="Avatar"></img>
                                    <p className="pasillo-label2 text-c w-respo80 ml-3 mt-3" >Comida Sana</p>

                                </div>

                                <div className="collection-content col mt-5">


                                    <ProductListingPasillos colSize={this.state.columns} />


                                </div>

                            </div>

                             */}
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    halls: getLocationHalls(),
    categories: getLocationCategories(),
    load_home: state.customer.load_home,
    products: state.data.products,
    loading_products: state.data.loading_products,
});

export default connect(
    mapStateToProps,
    { filterCategory, updateHalls, updateLoadHome }
)(withRouter(CollectionPasillos));
