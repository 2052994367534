import React, { Component } from 'react'
import { isUnaHoraUntil, isAfterHora } from '../../funciones/general'
import { getListaHorasRepeticionCore, getArrayDateCore, getDateOrdenadoHuertaCongelado, detFechaPatronSnack, detRadioPatronSnack, initialHourSnack, getArrayPatronSnack, detFechaPatronCore, detRadioPatronCore, initialHourCore, getArrayPatronCore, getNextMartes, getNextJueves, getNextSabado } from '../../funciones/patrones'
import horasCore from '../../api/horarios/tipoCore.json'
import horasSnack from '../../api/horarios/tipoSnack.json'
import horasHuerta from '../../api/horarios/tipoHuerta.json'
import {updateCore,updateHuerta,updateSnack} from '../../actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getDiffHoraria } from '../../servicios'
import * as moment from 'moment';
import { getDiaMesEspanol, getDiaMesEspanolHuertaCongelado } from '../../funciones/momentEspañol'

export class CartPrograming extends Component {

    constructor(props){
        super(props)
        this.state={

            radioCore:detRadioPatronCore(horasCore),
            listadoCore:getArrayPatronCore(horasCore),
            fechaCore:detFechaPatronCore(horasCore),
            horaCore:'',

            radioSnack:detRadioPatronSnack(horasSnack),
            listadoSnack:getArrayPatronSnack(horasSnack),
            fechaSnack:detFechaPatronSnack(horasSnack),
            horaSnack:'',

            horaHuerta:props.huerta.programacion?props.huerta.programacion.hora:horasHuerta[0].hora,
            radioHuerta:props.huerta.programacion?props.huerta.programacion.dia:this.getRadioValue(getDateOrdenadoHuertaCongelado()[0].day)
        }
    }

    componentDidMount(){

        this.setState({
            horaSnack:initialHourSnack(horasSnack,this.state.fechaSnack),
            horaCore:initialHourCore(horasCore,this.state.fechaCore),
        })

        this.props.onRef(this)
        

    }

    getRadioValue = (day) => {

        switch (day) {
            case "martes":
                return "mar"
            case "jueves":
                return "jue"
            case "sabado":
                return "sab"
            default:
                return "sab"
        }
        
    }

    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    handleHoraSnack = (e) => {

        const {value}= e.target
        const today=moment().format('YYYY-MM-DD')

        //caso mismo dia
        if(today==value){

            const list_horas=horasSnack.filter((value,index)=>{
                return (getDiffHoraria(value.hora)>0)
            })

            //para llenar las horas al escoger una fecha
            if(list_horas.length>0){
                this.setState({
                    listadoSnack:list_horas,
                    horaSnack:list_horas[0].hora
                })
            //caso que deja la lista vacio si no hay horas disponibles
            }else{
                this.setState({
                    listadoSnack:list_horas
                })
            }

        //caso un dia programado en el futuro
        }else{
            this.setState({
                listadoSnack:horasSnack,
                horaSnack:horasSnack[0].hora
            })

        }
        
    }

    handleHora = (e) => {

        const {value}= e.target
        const today=moment().format('YYYY-MM-DD')

        //caso mismo dia
        if(today==value){

            const list_horas=horasCore.filter((value,index)=>{
                return (getDiffHoraria(value.hora)>0)
            })

            //para llenar las horas al escoger una fecha
            if(list_horas.length>0){
                this.setState({
                    listadoCore:list_horas,
                    horaCore:list_horas[0].hora
                })
            //caso que deja la lista vacio si no hay horas disponibles
            }else{
                this.setState({
                    listadoCore:list_horas
                })
            }

        //caso un dia programado en el futuro
        }else{
            this.setState({
                listadoCore:horasCore,
                horaCore:horasCore[0].hora
            })

        }
        
    }


    submit = (e) => {
        e.preventDefault()

        const {
            radioSnack,
            fechaSnack,
            horaSnack,

            radioCore,
            fechaCore,
            horaCore,

            radioHuerta,
            horaHuerta,
        }=this.state

        const {
            updateCore,
            updateSnack,
            updateHuerta
        }=this.props


        let programacionSnack

        if(radioSnack==1){
            programacionSnack=1
        }else{
            programacionSnack={
                hora:horaSnack,
                fecha:fechaSnack
            }
        }

        updateSnack(programacionSnack)

        let programacionCore

        if(radioCore==1){
            programacionCore=1
        }else{
            programacionCore={
                hora:horaCore,
                fecha:fechaCore
            }
        }

        updateCore(programacionCore)


        let programacionHuerta

        programacionHuerta={
            hora:horaHuerta?horaHuerta:horasHuerta[0].hora,
            dia:radioHuerta
        }

        updateHuerta(programacionHuerta)

        this.props.close()
        this.props.history.push("/checkout")
        
    }
    
    handleRadioSnack= (event) => {
        const {value}= event.target
        this.setState({
            radioSnack: value
        })

    }

    handleRadioCore = (event) => {
        const {value}= event.target
        this.setState({
            radioCore: value
        })

    }

    getRadioCheck = (day) => {

        const {radioHuerta}=this.state

        switch (day) {
            case "martes":
                return radioHuerta=="mar"
            case "jueves":
                return radioHuerta=="jue"
            case "sabado":
                return radioHuerta=="sab"
            default:
                return radioHuerta=="sab"
        }

    }

    getRadioDate = (day) => {

        switch (day) {
            case "martes":
                return getDiaMesEspanolHuertaCongelado(getNextMartes())
            case "jueves":
                return getDiaMesEspanolHuertaCongelado(getNextJueves())
            case "sabado":
                return getDiaMesEspanolHuertaCongelado(getNextSabado())
            default:
                return getDiaMesEspanolHuertaCongelado(getNextSabado())
        }
        
    }

    clickOverlay(){

        console.log("click fuera")
        
    }
    

    render() {

        const {snack,core,huerta} = this.props
        const {listadoSnack,listadoCore} = this.state

        const list_horas_core=listadoCore.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_fechas_core=getArrayDateCore(horasCore).map((value,index)=>{

            return(
                <option  value={value} key={index}>{getDiaMesEspanol(value)}</option>
            )

        })

        const list_horas_snack=listadoSnack.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_fechas_snack=getArrayDateCore(horasSnack).map((value,index)=>{

            return(
                <option  value={value} key={index}>{getDiaMesEspanol(value)}</option>
            )

        })

        const list_horas_huerta=horasHuerta.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_radio_huerta=getDateOrdenadoHuertaCongelado().map((value,i)=>{
            return(

                <label className="form-check p-0 radio-style" key={i}>
                    <input 
                        type="radio" 
                        name="radioHuerta"  
                        value={this.getRadioValue(value.day)} 
                        onChange={this.handleChange} 
                        checked={this.getRadioCheck(value.day)}
                    />

                    {this.getRadioDate(value.day)}

                </label>

            )
        })

        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal fluio-font">

                    <div>
                        <div className="header-title tac-desk" >Programa tu pedido</div>
                    </div>

                    <form onSubmit={(e)=>e.preventDefault()}>

                        {
                            core.length>0?
                            <>
                                <div>
                                    <div className="body-title tac-desk">Todas las categorias</div>
                                </div>
                                <div className="form-group tac-desk margin-zero">

                                    {
                                        isUnaHoraUntil("18:00") && isAfterHora("9:30") ? 

                                        <label className="form-check p-0 margin-radio radio-style">
                                            <input type="radio"  value={1} onChange={this.handleRadioCore} checked={this.state.radioCore == 1} />Entrega en menos de 90 minutos
                                        </label>
                                        
                                        :
                                        ''
                                    }

                                    <label className="form-check p-0 radio-style">
                                        <input type="radio"  value={2} onChange={this.handleRadioCore} checked={this.state.radioCore == 2} />Envío programado
                                    </label>

                                </div>

                                {this.state.radioCore==2 ?

                                    <div className="form-row margin-zero">
                                        
                                        <div className="form-group col-8 pl-0">
                                            <select name="fechaCore" onChange={(e)=>{
                                                    this.handleChange(e)
                                                    this.handleHora(e)
                                            }} value={this.state.fechaCore || ''} className="form-control input-style" required>
                                                {list_fechas_core}
                                            </select>
                                        </div>

                                        <div className="form-group col-4 padding-0">
                                            <select name="horaCore" onChange={this.handleChange} value={this.state.horaCore || ''} className="form-control input-style" required>
                                                {list_horas_core}
                                            </select>
                                        </div>
                                      
                                    </div>
                                : '' }
                            </>
                            :''
                        }

                        {
                            snack.length>0?
                            <>
                                {
                                    core.length>0?
                                    <div className="modal-separator">
                                        <hr/>
                                    </div>
                                    :<></>
                                }
                                
                                <div>
                                    <div className="body-title tac-desk">Snacks</div>
                                </div>
                                <div className="form-group tac-desk margin-zero">

                                    {
                                        isUnaHoraUntil("18:00") && isAfterHora("9:30") ? 

                                        <label className="p-0 form-check margin-radio radio-style">
                                            <input type="radio"  value={1} onChange={this.handleRadioSnack} checked={this.state.radioSnack == 1} />Entrega en menos de 90 minutos
                                        </label>
                                        
                                        :
                                        ''
                                    }

                                    <label className="p-0 form-check radio-style">
                                        <input type="radio"  value={2} onChange={this.handleRadioSnack} checked={this.state.radioSnack == 2} />Envío programado
                                    </label>

                                </div>

                                {this.state.radioSnack==2 ?

                                    <div className="form-row margin-zero">
                                        
                                        <div className="form-group col-8 pl-0">
                                            <select name="fechaSnack" onChange={(e)=>{
                                                    this.handleChange(e)
                                                    this.handleHoraSnack(e)
                                            }} value={this.state.fechaSnack || ''} className="form-control input-style" required>
                                                {list_fechas_snack}
                                            </select>
                                        </div>

                                        <div className="form-group col-4 padding-0">
                                            <select name="horaSnack" onChange={this.handleChange} value={this.state.horaSnack || ''} className="form-control input-style" required>
                                                {list_horas_snack}
                                            </select>
                                        </div>
                                      
                                    </div>
                                : '' }
                            </>
                            :''
                        }

                        {
                            huerta.cart.length>0?
                            <>
                                {
                                    (core.length>0 || snack.length>0) ?
                                    <div className="modal-separator">
                                        <hr/>
                                    </div>
                                    :<></>
                                }
                                <div>
                                    <div className="body-title tac-desk">Huerta orgánica programada</div>
                                </div>
                                <div className="form-group tac-desk">
                                    {list_radio_huerta}
                                </div>
                                <div className="form-group">
                                    <select name="horaHuerta" onChange={this.handleChange} value={this.state.horaHuerta || ''} className="form-control input-style" required>
                                        {list_horas_huerta}
                                    </select>
                                </div>
                            </>
                            :''
                        }




                        <div className="tac mt-5">
                            <button  className="btn btn-solid ttn" onClick={this.submit} style={{width: '100%'}} >Hacer pedido</button>                                                  
                        </div>


                    </form>

                </div>            
            </div>
        )
    }
}

const mapStateToProps = state => ({
    snack: state.snack.cart,
    core: state.core.cart,
    huerta: state.huerta,
})

export default connect(
    mapStateToProps, {updateCore,updateSnack,updateHuerta}
)(withRouter(CartPrograming))
