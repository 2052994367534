import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import Breadcrumb from '../common/breadcrumb';
import { getCartIndividual, getTotalGeneral, updateSaldo } from '../../services';
import { removeFromCart, incrementQty, decrementQty } from '../../actions';
import CartHuerta from './secciones/CartHuerta';
import CartCongelado from './secciones/CartCongelado';
import CartCore from './secciones/CartCore';
import CartSnack from './secciones/CartSnack';
import { getCartLength } from '../../servicios';
import CartRestaurante from './secciones/CartRestaurante';
import CartPlan from './secciones/CartPlan';
import CartCena from './secciones/CartCena';
import { validateActiveInCart } from '../../funciones/validateCart';
import { getDescuento, CoreHuertaMatch } from '../../funciones/checkout';
import ContactIcons from '../common/ContactIcons';
import { CartPrograming } from '../common/CartPrograming';
import Banner from '../common/Banner';
import { formatNumber } from '../../funciones/general';
import ProgramingValidation from '../pages/ProgramingValidation';
import BonoForm from '../pages/BonoForm';
import CheckoutSugeridos from '../CheckoutSugeridos';
import { isActualDirectionLocal } from '../../funciones/cities';
import CartExternal from './secciones/CartExternal';
import DSugeridos from '../Modals/DSugeridos';

class cartComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPrograming: false,
            openDelivery: false,
            openBono: false,
            openProductos: false,
            openDSugeridos: false,
        };
    }

    componentDidMount() {
        validateActiveInCart();
        updateSaldo();
    }

    openDelivery = (e) => {
        e.preventDefault();
        this.setState({ openDelivery: true });
    };

    closeDelivery = () => {
        this.setState({ openDelivery: false });
    };

    openDSugeridos = (e) => {
        e.preventDefault();
        this.setState({ openDSugeridos: true });
    };

    closeDSugeridos = () => {
        this.setState({ openDSugeridos: false });
        window.location.href = '/checkout';
    };

    openPrograming = (e) => {
        e.preventDefault();
        this.setState({ openPrograming: true });
    };

    closePrograming = () => {
        this.setState({ openPrograming: false });
    };

    handleOverlay = (e) => {
        this.child.submit(e);
    };

    openModalBono = (e) => {
        e.preventDefault();
        this.setState({ openBono: true });
    };

    closeModalBono = () => {
        this.setState({ openBono: false });
    };

    openProductos = (e) => {
        e.preventDefault();
        this.setState({ openProductos: true });
    };

    openDSugeridos = (e) => {
        e.preventDefault();
        this.setState({ openDSugeridos: true });
    };

    closeProductos = () => {
        this.setState({ openProductos: false });
    };

    render() {
        const { symbol, general_total, tamaño, descuento, delivery, user } = this.props;
        const { openBono } = this.state;

        return (
            <div>
                <Banner />

                <Breadcrumb title={'Carrito'} />

                {tamaño > 0 ? (
                    <>
                        <div className="row">
                            <div className="col-sm-12 col-12 mb-2">
                                <h5 className="mb-0 tac">
                                    <button className="btn btn-link theme-color wrap-buttom mt-1 cart-section-btn">
                                        Productos
                                    </button>
                                </h5>
                            </div>
                        </div>

                        <hr className="hr-cart" />

                        {user || descuento > 0 ? (
                            <div className="tac">
                                <button className="btn btn-link theme-color wrap-buttom mt-1 cart-section-btn2">
                                    Bono de descuento: ${formatNumber(descuento)}
                                </button>
                            </div>
                        ) : (
                            ''
                        )}

                        {user ? (
                            <button
                                onClick={this.openModalBono}
                                type="button"
                                className="btn btn-bono btn-center mt-2 ttn"
                            >
                                Canjear bono
                            </button>
                        ) : (
                            ''
                        )}

                        {isActualDirectionLocal() ? (
                            <>
                                <CartCore />

                                <CartRestaurante />

                                <CartPlan />

                                <CartHuerta />

                                <CartCongelado />

                                <CartSnack />

                                <CartCena />
                            </>
                        ) : (
                            <CartExternal />
                        )}

                        <div className="row no-plr">
                            <div className="col-md-6" />
                            <div className="col-md-6">
                                <table className="table cart-table table-responsive-md center mb-center90">
                                    <tfoot>
                                        {CoreHuertaMatch() ? (
                                            <tr>
                                                <td>
                                                    Delivery
                                                    <span onClick={this.openDelivery}>
                                                        <i className="fa fa-question-circle match-delivery-icon" />:
                                                    </span>
                                                </td>
                                                <td>
                                                    <h2>${delivery.core_huerta}</h2>
                                                </td>
                                            </tr>
                                        ) : (
                                            <></>
                                        )}
                                        {descuento > 0 ? (
                                            <>
                                                <tr>
                                                    <td>Total en carrito:</td>
                                                    <td>
                                                        <h4>{symbol + formatNumber(general_total)} </h4>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Descuento por saldo:</td>
                                                    <td>
                                                        <h4>-{symbol + formatNumber(descuento)}</h4>
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <tr />
                                        )}
                                        <tr>
                                            <td>Total A Cancelar:</td>
                                            <td>
                                                <h2>{symbol + formatNumber(general_total - descuento)} </h2>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div className="row mb-3 no-plr mb-none">
                            <div className="col-6 center">
                                <a
                                    href={`${process.env.PUBLIC_URL}/checkout`}
                                    className="btn btn-solid btn-round"
                                    onClick={this.openDSugeridos}
                                >
                                    Ir a pagar
                                </a>
                            </div>
                        </div>

                        <div className="row mb-3 no-plr mb-display">
                            <div className="col-6 center">
                                <a
                                    onClick={this.openProductos}
                                    href={`${process.env.PUBLIC_URL}/checkout`}
                                    className="btn btn-solid btn-round"
                                >
                                    Ir a pagar
                                </a>
                            </div>
                        </div>

                        <Modal
                            open={this.state.openDelivery}
                            onClose={this.closeDelivery}
                            center
                            classNames={{ modal: 'mb-modal', closeButton: 'close-forgotten' }}
                        >
                            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                                <div className="modal-content quick-view-modal">
                                    <div className="modal-body">
                                        La fecha y hora de entrega de tus productos de categorias mercado, huerta y
                                        congelados coincide, por lo que te cobraremos un único delivery de $
                                        {formatNumber(delivery.core_huerta)} por la entrega de ellos
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal
                            open={this.state.openPrograming}
                            onClose={this.closePrograming}
                            center
                            classNames={{
                                modal: 'modal-mobile-style',
                                closeButton: 'close-forgotten',
                            }}
                            showCloseIcon={false}
                            closeOnOverlayClick={false}
                            onOverlayClick={this.handleOverlay}
                        >
                            <CartPrograming onRef={(ref) => (this.child = ref)} close={this.closePrograming} />
                        </Modal>

                        <Modal
                            open={openBono}
                            onClose={this.closeModalBono}
                            classNames={{ closeButton: 'close-forgotten', modal: 'mb-modal' }}
                            center
                        >
                            <BonoForm close={this.closeModalBono} />
                        </Modal>
                    </>
                ) : (
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div>
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                                                className="img-fluid mb-4"
                                                alt=""
                                            />
                                            <h3>
                                                <strong>Tu carrito de compras está vacío</strong>
                                            </h3>
                                            <h4>Revisa nuestros productos y agrega algo!</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <ContactIcons />

                {this.state.openProductos ? <CheckoutSugeridos close={this.closeProductos} /> : <></>}

                <Modal
                    open={this.state.openDSugeridos}
                    onClose={this.closeDSugeridos}
                    center
                    classNames={{
                        modal: 'modal-mobile-style',
                        closeButton: 'close-forgotten',
                    }}
                    closeOnOverlayClick={true}
                >
                    <DSugeridos close={this.closeDSugeridos} />
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartIndividual(state.cartList.cart),
    general_total: getTotalGeneral(),
    tamaño: getCartLength(),
    delivery: state.delivery,
    user: state.customer.data,
    descuento: getDescuento(),
});

export default connect(
    mapStateToProps,
    { removeFromCart, incrementQty, decrementQty }
)(withRouter(cartComponent));
