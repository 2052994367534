import React, { Component } from "react";
import { withTranslate } from "react-redux-multilingual";

// Custom Components
import HeaderOne from "./common/headers/header-one";
import HomeHeader from "./common/headers/HomeHeader";
import HeaderTwo from "./common/headers/header-two";
import HeaderThree from "./common/headers/header-three";

import FooterOne from "./common/footers/footer-one";
import FooterTwo from "./common/footers/footer-two";
import FooterThree from "./common/footers/footer-three";

// ThemeSettings
import ThemeSettings from "./common/theme-settings";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
import { inHollyDayRange } from "../funciones/checkout";
import { HollyDayPage } from "./HollyDayPage";
import Modal from "react-responsive-modal";
import LocationForm from "./Modals/LocationForm";
import { isLocation } from "../funciones/cities";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLocation: !isLocation(),
    };
  }
  starAnalitics = () => {
    ReactGA.initialize("UA-160391093-1");
  };

  closeLocation = () => {
    this.setState({
      openLocation: false,
    });
  };

  render() {
    this.starAnalitics();
    return (
      <div>
        <HeaderOne />
        <HomeHeader />

        <div style={{ height: "100px" }} className="mb-display" />

        {this.props.children}

        <FooterOne logoName={"logo-fluio.png"} />

        {inHollyDayRange() ? <HollyDayPage /> : <></>}

        <Modal
          open={this.state.openLocation}
          onClose={this.closeLocation}
          center
          classNames={{
            modal: "modal-mobile-style",
            closeButton: "close-forgotten",
          }}
          showCloseIcon={false}
          closeOnOverlayClick={false}
        >
          <LocationForm close={this.closeLocation} />
        </Modal>
      </div>
    );
  }
}

export default withRouter(App);
