import * as types from '../constants/ActionTypes'

const hallsReducerDefaultState = {
    halls:[]
};

const hallsReducer = (state = hallsReducerDefaultState, action) => {
    switch (action.type) {
        case types.UPDATE_HALLS:
            return {
                ...state,
                halls: action.halls
            };
        default:
            return state;
    }
}

export default hallsReducer;