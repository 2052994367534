import React, {Component} from 'react';
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component';
import { addToCart, addToWishlist, addToCompare } from '../../../actions'
import AllAliadoListItem from './all-aliado-list-item';

class AllAliadoListing extends Component {

    constructor (props) {
        super (props)

        this.state = { limit: 12, hasMoreItems: true };

    }

    componentWillMount(){
        this.fetchMoreItems();
    }

    fetchMoreItems = () => {
        if (this.state.limit >= this.props.total.length) {
            this.setState({ hasMoreItems: false });
            return;
        }
        // a fake async api call
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 12
            });
        }, 3000);
    }

    render (){
        const {aliado, symbol,categoria} = this.props;

        let filtered_allies=aliado

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {filtered_allies.length > 0 ?
                            <InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={this.fetchMoreItems}
                                hasMore={this.state.hasMoreItems}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Ya no hay mas Aliados!</b>
                                    </p>
                                }
                            >

                                <div className="row">
                                    { filtered_allies.map((value, index) =>
                                        <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-6':'col-lg-'+this.props.colSize}`} key={index}>

                                        <AllAliadoListItem aliado={value} symbol={symbol} />
                                        </div>)
                                    }
                                </div>
                                
                            </InfiniteScroll>
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                    <h3>No hay resultados en tu busqueda</h3>
                                    <p>Intenta colocando otras opciones de busqueda</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    symbol: state.data.symbol,
    aliado: state.aliado.data,
    total: state.data.products
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare}
)(AllAliadoListing)