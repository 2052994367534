import React, { Component } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { getData } from '../../../servicios'
import Modal from 'react-responsive-modal';
import ModalOrder from './Modales/ModalOrder';
import json from '../../../api/ordenes.json'
import { connect } from 'react-redux';
import { cargarOrders } from '../../../services';
import ContactIcons from '../../common/ContactIcons'
import BotonPse from '../../../assets/images/BotonPSE.png'
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import RepetirPedido from './Modales/RepetirPedido';
import { OrdenesAscByDate } from '../../../funciones/Ordenes';
import { formatNumber } from '../../../funciones/general';

class OrdenRepeat extends Component {

    constructor(props){
        super(props)
        this.state={
            ordenes:[],
            open: false,
            orden: null,
            openRepetir:false
        }

    }

    openModal = (e,value) => {
        e.preventDefault()
        this.setState({
            open:true,
            orden:value
        })

    }

    openRepetir = (e,value) => {
        e.preventDefault()
        this.setState({
            openRepetir:true,
            orden:value
        })

    }

    closeModal = () => {
        this.setState({
            open:false
        })
    }

    closeRepetir = () => {
        this.setState({
            openRepetir:false
        })
    }



    async componentDidMount(){

        const {user}=this.props

        try {
            const req=await getData("usuario/ordenes-completadas/?id="+user.id)
            this.setState({
                ordenes:req.data
            })

        } catch (error) {
            console.log(error)
        }

        cargarOrders()

    }



    render() {
        const {ordenes,orden,open,openRepetir}=this.state

        let array_ordenes

        if(ordenes.length>=3){
            array_ordenes=OrdenesAscByDate(ordenes).slice(0,3)
        }else{
            array_ordenes=OrdenesAscByDate(ordenes)
        }


        const list=array_ordenes.map((value)=>{

            return(
                <tr key={value.id}>
                    <td>
                        {value.fecha}
                    </td>
                    <td>
                        {formatNumber(value.amount)}
                    </td>
                    <td>
                        <span onClick={(e)=> this.openModal(e,value)}>
                            <i className="fa fa-eye pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                        <span onClick={(e)=> this.openRepetir(e,value)}>
                            <i className="fa fa-shopping-cart pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                    </td>

                </tr>
            )

        })

      

        return (

            <>
                <table className="table table-cart table-responsive-xs">

                    <thead>
                        <tr>
                            <th scope="col">
                                Fecha
                            </th>
                            <th scope="col">
                                Monto
                            </th>
                            <th scope="col">
                                Detalles
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {list}
                    </tbody>

                </table>

                <Modal classNames={{'modal': 'mb-modal'}} open={open} onClose={this.closeModal}>
                    <ModalOrder orden={orden} />
                </Modal>
                <Modal classNames={{'modal': 'modal-mobile-style',closeButton: "close-forgotten"}} open={openRepetir} onClose={this.closeRepetir}>
                    <RepetirPedido orden={orden} />
                </Modal>
            </>

        )
    }

}

const mapStateToProps = (state) => ({
    user: state.customer.data
})

export default connect(
    mapStateToProps, { }
)(OrdenRepeat)