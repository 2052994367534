import React, { Component } from 'react'
import { Kushki } from '@kushki/js'
import { withRouter } from 'react-router-dom'
import FormError from '../common/FormError'
import { connect } from 'react-redux'
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { detectCardType } from '../../servicios'
import { tokenRequetError } from '../../funciones/checkout'
import { cleverCheckoutFail } from '../../funciones/clevertap'
import { formatNumber } from '../../funciones/general'

class PayForm extends Component {

    constructor(props){
        super(props)
        if(false){
            const card=props.user.cards[0]
            this.state={
                nombre: card.name,
                mes: card.month,
                anno:card.year,
                cvc:card.cvc,
                card:card.number,
                bool:false,
                mensaje:'Pedido en proceso',
                load:false,
                radio:'old',
                bool:true,
            }
        }else{
            this.state={
                nombre: '',
                mes: '',
                anno:'',
                cvc: '',
                card:'',
                bool:false,
                mensaje:'Pedido en proceso',
                load:false,
                radio:'new',
                bool:false,
            }
        }
    }

    setData = (mensaje) => {

        this.setState({
            bool:true,
            mensaje: "mensaje"
        })

    }


    changeText = () => {

        this.setState({
            mensaje:"Pedido en proceso"
        })
        
    }
    

    checkCard = (params) => {

        let kushki = new Kushki({
            merchantId: '20000000106403330000', //produccion
            //merchantId: '20000000102909980000', //pruebas
            inTestEnvironment: false,
            regional:false
        });

        let callback = (response)=> {
            if(!response.code){
                console.log(response);
                this.changeText()
                this.props.pay(response.token)

                //this.props.history.push("/confirm")

            } else {
                tokenRequetError(response.error,response.code,response.message)
                cleverCheckoutFail("Error en solicitud de token TDC")
            }
        }

        kushki.requestToken({
            amount: this.props.amount,
            currency: "COP",
            card: {
              name: this.state.nombre,
              number: String(this.state.card),
              cvc: String(this.state.cvc),
              expiryMonth: String(this.state.mes),
              expiryYear: String(this.state.anno)
          },
          }, callback);

    }




    handleSubmit = (e) => {

        e.preventDefault()

        this.setState({
            load:true
        },()=>{
            this.checkCard()
        })

    }


    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    handleRadio = (event) => {
        const { value}= event.target

        if(value=="new"){

            this.setState({
                radio: value,
                nombre: '',
                mes: '',
                anno:'',
                cvc: '',
                card:'',
            })

        }else{
            const card=this.props.user.cards[0]

            this.setState({
                radio: value,
                nombre: card.name,
                mes: card.month,
                anno:card.year,
                cvc:card.cvc,
                card:card.number,
            })

        }

    }




    render() {

        const {load,bool,mensaje}=this.state

        if(load){

            return(
                <div className="row h-196">
                    <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                        <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                        <h3>{mensaje}</h3>
                    </div>
                </div>
            )

        }else{

            return (
                <div id="PaymentForm">

                    

                    <form onSubmit={this.handleSubmit}>

                        <div className="form-row">

                            {
                                bool ? 

                                <div className="form-group col-md-4">
                                    <label className="radio-inline">
                                        <input type="radio"  value={'old'} onChange={this.handleRadio} checked={this.state.radio == 'old'} />Tarjeta registrada
                                    </label>
                                </div>
                                
                                
                                :
                                ''
                            }

                            <div className="form-group col-md-4">
                                <label className="radio-inline">
                                    <input type="radio"  value={'new'} onChange={this.handleRadio} checked={this.state.radio == 'new'} />Ingresar tarjeta
                                </label>
                            </div>                      

                        </div>


                        <div className="row">

                            {
                                this.state.radio=='new' ?

                                <>

                                    <div className="form-group col-12">
                                        <input type="text" name="nombre" className="form-control" value={this.state.nombre} onChange={this.handleChange} required placeholder="Nombre"/>
                                    </div>
                                    <div className="form-group col-12">
                                        <input type="text" name="card" className="form-control"  value={this.state.card} onChange={this.handleChange} placeholder="Numero de tarjeta" required/>
                                    </div>
                                    <div className="form-group col-md-3 col-sm-3 col-xs-12">
                                        <input type="number" name="mes" className="form-control" min={1} max={12}  value={this.state.mes} onChange={this.handleChange} placeholder="MM" required/>
                                    </div>
                                    <div className="form-group col-md-3 col-sm-3 col-xs-12">
                                        <input type="number" name="anno" className="form-control" min={0} max={99}  value={this.state.anno} onChange={this.handleChange} placeholder="AA" required/>
                                    </div>
                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                        <input type="number" name="cvc" className="form-control" min={0} value={this.state.cvc} onChange={this.handleChange} placeholder="CVC" required/>
                                    </div>

                                    <Cards
                                        preview={true}
                                        cvc={this.state.cvc}
                                        expiry={this.state.mes+this.state.anno}
                                        focused=""
                                        name={this.state.nombre}
                                        number={this.state.card}
                                    />
                                    <p className="tac w100">
                                    El preview de la tarjeta solo es un apróximado
                                    </p>                              

                                </>
                          
                                :

                                    <>

                                        <Cards
                                            preview={true}
                                            cvc={this.state.cvc}
                                            expiry={"****"}
                                            focused=""
                                            name={this.state.nombre}
                                            number={this.state.card.replace(/.(?=.{4})/g, '*')}
                                            issuer={detectCardType(String(this.state.card))}
                                        />
                                        <p className="tac w100">
                                        El preview de la tarjeta solo es un apróximado
                                        </p>  

                                    </>

                                                               
                                
                            }
                            
                        
                            <button type="submit" className="btn  w-100 sandwich-14 btn-pay">
                                Pagar ${formatNumber(this.props.amount)}
                            </button>

                        </div>
                    </form>

                </div>
                

            )

        }


    }
}

const mapStateToProps = (state) => ({
    user:state.customer.data
})

export default connect(
    mapStateToProps,
    {}
)(withRouter(PayForm))
