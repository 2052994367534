import React, { Component } from 'react'

export class NotAvailableProd extends Component {
    render() {
        return (
            <>

            <div className="col-12 mt-5 tac mb-none">
                Producto sin stock
            </div>

            <div className="col-12 mt-3 tac mb-display">
                Producto sin stock
            </div>   
                
            </>
        )
    }
}

export default NotAvailableProd
