import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { removeFromWishlist, updateEnvio, updateHeaders } from '../../actions';
import {
    ValidatePago,
    getTotalGeneral,
    limpiarCarrito,
    getCartIndividual,
    cargarOrders,
    getMatchDelivery,
} from '../../services';
import PayForm from './PayForm';
import CheckRestaurante from './secciones/CheckRestaurante';
import CheckSnack from './secciones/CheckSnack';
import CheckCore from './secciones/CheckCore';
import CheckHuerta from './secciones/CheckHuerta';
import CheckCongelado from './secciones/CheckCongelado';
import { withRouter } from 'react-router-dom';
import { postData } from '../../servicios';
import CheckCena from './secciones/CheckCena';
import { validateActiveInCart } from '../../funciones/validateCart';
import {
    getDescuento,
    getArrayProductosOrder,
    tokenRequetError,
    getPaybuttonText,
    CoreHuertaMatch,
    isCoreHollyDayPrograming,
    isSnackHollyDayPrograming,
    isHuertaHollyDayPrograming,
    isHollyDayPrograming,
    getDeliveryAddress,
    getOrderType,
} from '../../funciones/checkout';
import CheckPlan from './secciones/CheckPlan';
import PseForm from './PseForm';
import ContactIcons from '../common/ContactIcons';
import MapTool from '../common/MapTool';
import Swal from 'sweetalert2';
import { formatNumber, sweetQuestion, sweetErrorMsg } from '../../funciones/general';
import ProgramingValidation from '../pages/ProgramingValidation';
import BotonPse from '../../assets/images/BotonPSE.png';
import { cleverChargued, cleverCheckoutFail } from '../../funciones/clevertap';
import ProgramingPage from '../common/ProgramingPage';
import { Kushki } from '@kushki/js';
import { sendSchedule } from '../../funciones/patrones';
import ProductosSugeridos from '../ProductosSugeridos';
import CheckoutSugeridos from '../CheckoutSugeridos';
import { isActualDirectionLocal } from '../../funciones/cities';
import CheckExternal from './secciones/CheckExternal';

class checkOut extends Component {
    constructor(props) {
        super(props);

        //let dir
        //let lon
        //let lat

        /** 
        if(props.usuario.addresses[0]){
            dir=props.usuario.addresses[0].dir
            lon=props.usuario.addresses[0].lon
            lat=props.usuario.addresses[0].lat
        }
        */

        this.state = {
            first_name: props.usuario.name,
            phone: props.usuario.tel,
            email: props.usuario.email,
            address: props.usuario.directions.length > 0 ? props.usuario.directions[0] : '',
            create_account: '',
            ci: props.usuario.ci,
            radio: props.usuario.cards.length > 0 ? 'register-tdc' : 'tdc',
            open: false,
            openRestaurante: false,
            openSemanal: false,
            openQuincenal: false,
            openMensual: false,
            product: null,
            pago: false,
            load: false,
            openPse: false,
            openDelivery: false,
            openMap: false,
            openProductos: true,
            radioDir: 'list',
            desc: '',
            //lon:lon,
            //lat:lat
        };
    }

    setStateFromInput = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
        });
    };

    openProductos = (e) => {
        e.preventDefault();
        this.setState({ openProductos: true });
    };

    closeProductos = () => {
        this.setState({ openProductos: false });
    };

    handleRadio = (event) => {
        const { value } = event.target;
        this.setState({
            radio: value,
        });
    };

    handleRadioDir = (event) => {
        const { value } = event.target;

        this.setState({
            radioDir: value,
            address: value === 'list' ? this.props.usuario.directions[0] : '',
        });
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        cleverCheckoutFail('Pop up TDC cerrado');
        this.setState({ open: false });
    };

    onOpenPse = () => {
        this.setState({ openPse: true });
    };

    onClosePse = () => {
        cleverCheckoutFail('Pop up PSE cerrado');
        this.setState({ openPse: false });
    };

    doSubmit = () => {
        const { general_total, descuento } = this.props;
        sendSchedule();
        this.setState({
            load: false,
        });

        if (general_total - descuento == 0) {
            this.postOrderNoCost();
            this.setState({
                pago: true,
                load: true,
            });
            return;
        }

        if (this.state.radio == 'register-tdc') {
            this.checkCard();
            this.setState({
                pago: true,
                load: true,
            });
        } else if (this.state.radio == 'tdc') {
            //this.postOrderCash()
            this.onOpenModal();
        } else if (this.state.radio == 'pse') {
            this.onOpenPse();
        } else {
            this.postOrderCash();
            this.setState({
                pago: true,
                load: true,
            });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        if (isHollyDayPrograming()) {
            sweetErrorMsg(`Fluio estara de vacaciones del 24 de diciembre al 4 de enero,
            ajusta tu carrito para que tu pedido se pueda entregar en una fecha disponible`);
            return;
        }

        this.doSubmit();

        /** 

        this.setState({
            load:true
        })

        if(this.state.lat){

            try {

                const req=await getData('is-bog?lat='+this.state.lat+"&lon="+this.state.lon)
                if(req.data.isBogota=="true"){
                    this.doSubmit()
                }else{

                    const question=await sweetQuestion(
                        `Advertencia`,
                        `Parece que la ubicacion que ingresaste no se encuentra en bogota,continua con 
                        tu pedido solo si puedes recibir el domicilio en alguna zona dentro de bogota
                        <br/>
                        <b>¿Deseas continuar?<b/>`
                    )
            
                    if(question.value){
                        this.doSubmit()
                    }else{
                        this.setState({
                            load:false
                        })
                    }

                }

                
            } catch (error) {
                console.log(error)
                const question=await sweetQuestion(
                    `Advertencia`,
                    `No pudimos determinar si tu ubicacion se encuentra en bogota,continua con 
                    tu pedido solo si puedes recibir el domicilio en alguna zona dentro de bogota
                    <br/>
                    <b>¿Deseas continuar?<b/>`
                )
        
                if(question.value){
                    this.doSubmit()
                }else{
                    this.setState({
                        load:false
                    })
                }
            }
            
        }else{

            const question=await sweetQuestion(
                `Advertencia`,
                `Parece que no ingresaste ninguna ubicacion,continua con 
                tu pedido solo si puedes recibir el domicilio en alguna zona dentro de bogota
                <br/>
                <b>¿Deseas continuar?<b/>`
            )
    
            if(question.value){
                this.doSubmit()
            }else{
                this.setState({
                    load:false
                })
            }

        }

        */
    };

    openDelivery = (e) => {
        e.preventDefault();
        this.setState({ openDelivery: true });
    };

    closeDelivery = () => {
        this.setState({ openDelivery: false });
    };

    openMap = (e) => {
        e.preventDefault();
        this.setState({ openMap: true });
    };

    closeMap = () => {
        this.setState({ openMap: false });
    };

    setCordenates = (lon, lat) => {
        this.setState({
            lon: lon,
            lat: lat,
            openMap: false,
        });
    };

    postOrderTDC = async (token) => {
        const { usuario, general_total, descuento } = this.props;

        let json = {
            order: {
                customer_id: usuario.id,
                pay_cod: token,
                status: 0,
                dir: getDeliveryAddress(this.state.address),
                amount: general_total - descuento,
                cname: this.state.first_name,
                pay_method: 1,
                tel_c: this.state.phone,
                email: this.state.email,
                ci: this.state.ci,
                details_attributes: getArrayProductosOrder(),
                amount_t: general_total,
                discount: descuento,
                city: this.props.city,
                ext: getOrderType(),
                desc: this.state.desc,
            },
        };

        console.log(json);

        try {
            const config = {
                headers: this.props.headers,
            };
            const response = await postData('orders', json, config);
            this.props.updateHeaders(response.headers);
            if (response.data.codigo == '201') {
                limpiarCarrito();
            }
            cleverChargued(general_total - descuento);
            this.props.history.push('/confirm/' + response.data.codigo + '/' + response.data.mensaje);
        } catch (error) {
            console.log(error.response);
            cleverCheckoutFail('Error en endpoint de pago TDC');
            if (error.response) {
                tokenRequetError('Estatus:' + error.response.status);
            } else {
                tokenRequetError(error.message);
            }
        }
    };

    postOrderPSE = async (token, bank) => {
        const { usuario, general_total, descuento } = this.props;

        let json = {
            order: {
                customer_id: usuario.id,
                bankId: bank,
                pay_cod: token,
                status: 0,
                dir: getDeliveryAddress(this.state.address),
                amount: general_total - descuento,
                cname: this.state.first_name,
                pay_method: 3,
                tel_c: this.state.phone,
                email: this.state.email,
                ci: this.state.ci,
                details_attributes: getArrayProductosOrder(),
                amount_t: general_total,
                discount: descuento,
                url: 'https://www.fluio.com.co/pse-confirmation',
                city: this.props.city,
                ext: getOrderType(),
                desc: this.state.desc,
            },
        };

        try {
            const config = {
                headers: this.props.headers,
            };
            const response = await postData('orders', json, config);
            this.props.updateHeaders(response.headers);
            if (response.data.codigo == '201') {
                limpiarCarrito();
                window.location.href = response.data.url_r;
                //window.open(response.data.url_r, "_blank");
                cleverChargued(general_total - descuento);
                this.props.history.push('/');
            } else {
                tokenRequetError('Ocurrio un problema:' + response.data.mensaje);
            }
            //this.props.history.push("/confirm/"+response.data.codigo+"/"+response.data.mensaje)
        } catch (error) {
            console.log(error.response);
            cleverCheckoutFail('Error en endpoint de pago PSE');
            if (error.response) {
                tokenRequetError('Estatus:' + error.response.status);
            } else {
                tokenRequetError(error.message);
            }
        }
    };

    postOrderCash = async () => {
        const { usuario, general_total, descuento } = this.props;

        let json = {
            order: {
                customer_id: usuario.id,
                status: 0,
                dir: getDeliveryAddress(this.state.address),
                amount: general_total - descuento,
                cname: this.state.first_name,
                pay_method: 0,
                tel_c: this.state.phone,
                email: this.state.email,
                ci: this.state.ci,
                details_attributes: getArrayProductosOrder(),
                amount_t: general_total,
                discount: descuento,
                city: this.props.city,
                ext: getOrderType(),
                desc: this.state.desc,
            },
        };

        try {
            const config = {
                headers: this.props.headers,
            };
            const response = await postData('orders', json, config);
            this.props.updateHeaders(response.headers);
            limpiarCarrito();
            cleverChargued(general_total - descuento);
            this.props.history.push('/confirm/201/Pedido Realizado');
        } catch (error) {
            cleverCheckoutFail('Error en endpoint de pago efectivo');

            if (error.response) {
                tokenRequetError('Estatus:' + error.response.status);
            } else {
                tokenRequetError(error.message);
            }
        }
    };

    postOrderNoCost = async () => {
        const { usuario, general_total, descuento } = this.props;

        let json = {
            order: {
                customer_id: usuario.id,
                status: 0,
                dir: getDeliveryAddress(this.state.address),
                amount: general_total - descuento,
                cname: this.state.first_name,
                pay_method: 2,
                tel_c: this.state.phone,
                email: this.state.email,
                ci: this.state.ci,
                details_attributes: getArrayProductosOrder(),
                amount_t: general_total,
                discount: descuento,
                city: this.props.city,
                ext: getOrderType(),
                desc: this.state.desc,
            },
        };

        try {
            const config = {
                headers: this.props.headers,
            };
            const response = await postData('orders', json, config);
            this.props.updateHeaders(response.headers);
            limpiarCarrito();
            this.props.history.push('/confirm/201/Pedido Realizado');
        } catch (error) {
            cleverCheckoutFail('Error en endpoint de pago sin costo');

            if (error.response) {
                tokenRequetError('Estatus:' + error.response.status);
            } else {
                tokenRequetError(error.message);
            }
        }
    };

    checkCard = () => {
        const { general_total, descuento, usuario } = this.props;

        const card = usuario.cards[0];

        let kushki = new Kushki({
            //merchantId: '20000000106403330000', //produccion
            merchantId: '20000000102909980000', //pruebas
            inTestEnvironment: true,
            regional: false,
        });

        let callback = (response) => {
            if (!response.code) {
                console.log(response);
                this.postOrderTDC(response.token);

                //this.props.history.push("/confirm")
            } else {
                tokenRequetError(response.error, response.code, response.message);
                cleverCheckoutFail('Error en solicitud de token TDC');
            }
        };

        kushki.requestToken(
            {
                amount: (general_total - descuento).toString(),
                currency: 'COP',
                card: {
                    name: card.name,
                    number: String(card.number),
                    cvc: String(card.cvc),
                    expiryMonth: String(card.month),
                    expiryYear: String(card.year),
                },
            },
            callback
        );
    };

    componentDidMount() {
        validateActiveInCart();
        cargarOrders();
    }

    render() {
        const { symbol, general_total, descuento, delivery, actualDirectionLocal } = this.props;
        const { pago } = this.state;

        const list_dir = this.props.usuario.directions.map((value) => {
            return (
                <option value={value} key={value}>
                    {value}
                </option>
            );
        });

        return (
            <div>
                <section className="section-b-space">
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="checkout row">
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            {/*
                                            <div className="checkout-title">
                                                <h3>Detalles de envío</h3>
                                            </div>
                                            <div className="row check-out">
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Nombre</div>
                                                    <input type="text" name="first_name" value={this.state.first_name} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Cédula</div>
                                                    <input type="text" name="ci" value={this.state.ci} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Celular</div>
                                                    <input type="text" name="phone"  value={this.state.phone} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Correo electrónico</div>
                                                    <input type="email" name="email" value={this.state.email} onChange={this.setStateFromInput} required />
                                                </div>                               
                                                
                                 
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label map-form">
                                                        <span  >
                                                            Ubicación: 
                                                            {
                                                                this.state.lat?
                                                                "Latitud:"+this.state.lat+" , "+"Longitud:"+this.state.lon
                                                                :'No se ha seleccionado una ubicación'
                                                            }
                                                        </span>
                                                        <br/>
                                                        <button className="btn btn-cart margin-zero" onClick={this.openMap}>
                                                            Ver mapa
                                                            <i className="fa fa-map-marker map-icon"/>
                                                        </button>                                                       
                                                    </div>                                             
                                                </div>
 
                                            </div>
                                            */}
                                            <ProgramingPage />
                                            <hr className="hr-check my-5" />
                                            <div className="checkout-title">
                                                <h3 className="check-title">Dirección</h3>
                                            </div>

                                            <div className="form-check form-check-inline dir-radio-l ">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    onChange={this.handleRadioDir}
                                                    value="list"
                                                    checked={this.state.radioDir === 'list'}
                                                />
                                                <label className="form-check-label">Direcciones registradas</label>
                                            </div>
                                            <div className="form-check form-check-inline dir-radio-l mb-4">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    onChange={this.handleRadioDir}
                                                    value="new"
                                                    checked={this.state.radioDir === 'new'}
                                                />
                                                <label className="form-check-label">Otra direccion</label>
                                            </div>

                                            <div className="form-row margin-zero">
                                                {this.state.radioDir === 'list' ? (
                                                    <div className="form-group col-12 pl-0">
                                                        <select
                                                            name="address"
                                                            className="form-control input-style"
                                                            onChange={this.setStateFromInput}
                                                            value={this.state.address}
                                                            required
                                                        >
                                                            {list_dir}
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className="form-group col-12">
                                                        <input
                                                            type="text"
                                                            name="address"
                                                            value={this.state.address}
                                                            onChange={this.setStateFromInput}
                                                            required
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            {/*

                                            <hr className="hr-check my-5"/>

                                            <div className="checkout-title">
                                                <h3 className="check-title">Detalles</h3>
                                            </div>

                                            <div className="form-row margin-zero">

                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Nombre</div>
                                                    <input type="text" name="first_name" value={this.state.first_name} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Cédula</div>
                                                    <input type="text" name="ci" value={this.state.ci} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Celular</div>
                                                    <input type="text" name="phone"  value={this.state.phone} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Correo electrónico</div>
                                                    <input type="email" name="email" value={this.state.email} onChange={this.setStateFromInput} required />
                                                </div>

                                            </div>

                                            */}

                                            <hr className="hr-check my-5" />
                                            <div className="checkout-title">
                                                <h3 className="check-title">Comentarios adicionales</h3>
                                            </div>

                                            <div className="form-group col-12">
                                                <textarea
                                                    rows={5}
                                                    name="desc"
                                                    value={this.state.desc}
                                                    onChange={this.setStateFromInput}
                                                    placeholder="Ejm: el timbre se encuentra a la derecha del portón"
                                                    className="form-control"
                                                    style={{ height: '120px' }}
                                                />
                                            </div>

                                            <hr className="hr-check my-5" />

                                            <div className="checkout-title">
                                                <h3 className="check-title mb-4">Formas de pago</h3>
                                                <div className="form-group margin-zero">
                                                    {this.props.usuario.cards.length > 0 ? (
                                                        <label className="form-check p-0 margin-radio programing-radio">
                                                            <input
                                                                type="radio"
                                                                value="register-tdc"
                                                                onChange={this.handleRadio}
                                                                checked={this.state.radio === 'register-tdc'}
                                                                className="radio-space"
                                                            />
                                                            Tarjeta de crédito registrada
                                                        </label>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <label className="form-check p-0 margin-radio programing-radio">
                                                        <input
                                                            type="radio"
                                                            value="tdc"
                                                            onChange={this.handleRadio}
                                                            checked={this.state.radio === 'tdc'}
                                                            className="radio-space"
                                                        />
                                                        Otra tarjeta
                                                    </label>

                                                    <label className="form-check p-0 margin-radio programing-radio">
                                                        <input
                                                            type="radio"
                                                            value="pse"
                                                            onChange={this.handleRadio}
                                                            checked={this.state.radio === 'pse'}
                                                            className="radio-space"
                                                        />
                                                        Débito PSE
                                                        <img
                                                            src={BotonPse}
                                                            className="pse-button-order"
                                                            alt={'boton pse'}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12 mt-3">
                                            <div className="checkout-details">
                                                <div className="order-box">
                                                    {actualDirectionLocal ? (
                                                        <>
                                                            <CheckPlan />

                                                            <CheckSnack />

                                                            <CheckCore />

                                                            <CheckRestaurante />

                                                            <CheckHuerta />

                                                            <CheckCongelado />

                                                            <CheckCena />
                                                        </>
                                                    ) : (
                                                        <CheckExternal />
                                                    )}

                                                    <ul className="sub-total mobile-tac">
                                                        {general_total - descuento > 0 && false ? (
                                                            <li>
                                                                Forma De Pago
                                                                <div className="shipping">
                                                                    <div className="mb-display mt-3" />

                                                                    {this.props.usuario.cards.length > 0 ? (
                                                                        <div className="radio">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="register-tdc"
                                                                                    onChange={this.handleRadio}
                                                                                    checked={
                                                                                        this.state.radio ===
                                                                                        'register-tdc'
                                                                                    }
                                                                                />
                                                                                Tarjeta de crédito registrada
                                                                            </label>
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}

                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                value="tdc"
                                                                                onChange={this.handleRadio}
                                                                                checked={this.state.radio === 'tdc'}
                                                                            />
                                                                            Otra tarjeta
                                                                        </label>
                                                                    </div>

                                                                    {/**/}
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                value="pse"
                                                                                onChange={this.handleRadio}
                                                                                checked={this.state.radio === 'pse'}
                                                                            />
                                                                            Débito PSE
                                                                            <img
                                                                                src={BotonPse}
                                                                                className="pse-button-order"
                                                                                alt={'boton pse'}
                                                                            />
                                                                        </label>
                                                                    </div>

                                                                    {ValidatePago() ? (
                                                                        <div className="radio">
                                                                            <label>
                                                                                <input
                                                                                    type="radio"
                                                                                    value="cash"
                                                                                    onChange={this.handleRadio}
                                                                                    checked={
                                                                                        this.state.radio === 'cash'
                                                                                    }
                                                                                />
                                                                                Efectivo
                                                                            </label>
                                                                        </div>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </div>
                                                            </li>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {CoreHuertaMatch() ? (
                                                            <li>
                                                                Delivery
                                                                <i
                                                                    onClick={this.openDelivery}
                                                                    className="fa fa-question-circle match-delivery-icon"
                                                                />
                                                                :{' '}
                                                                <span className="count">
                                                                    {symbol}
                                                                    {formatNumber(getMatchDelivery())}
                                                                </span>
                                                            </li>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        {descuento > 0 ? (
                                                            <>
                                                                <li>
                                                                    Total en carrito;{' '}
                                                                    <span className="count">
                                                                        {symbol}
                                                                        {formatNumber(general_total)}
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    Descuento por saldo;{' '}
                                                                    <span className="count">
                                                                        -{symbol}
                                                                        {formatNumber(descuento)}
                                                                    </span>
                                                                </li>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}

                                                        <li>
                                                            Total a pagar:{' '}
                                                            <span className="count">
                                                                {symbol}
                                                                {formatNumber(general_total - descuento)}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="payment-box">
                                                    {general_total !== 0 ? (
                                                        <div className="text-right">
                                                            <div className="d-flex flex-row justify-content-center">
                                                                {this.state.load ? (
                                                                    <div className="col-md-6 text-center section-b-space mt-5 no-found">
                                                                        <img
                                                                            src={`${
                                                                                process.env.PUBLIC_URL
                                                                            }/assets/images/loading.gif`}
                                                                            className="img-fluid mb-4"
                                                                        />
                                                                        <h3>Pedido en proceso</h3>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="p-4">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn-solid btn"
                                                                                disabled={pago}
                                                                            >
                                                                                {getPaybuttonText(
                                                                                    this.state.radio,
                                                                                    general_total - descuento
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactIcons />

                <Modal open={this.state.open} onClose={this.onCloseModal} center classNames={{ modal: 'mb-modal' }}>
                    <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <PayForm amount={general_total - descuento} pay={this.postOrderTDC} />
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.openPse}
                    onClose={this.onClosePse}
                    center
                    classNames={{ modal: 'mb-modal', closeButton: 'close-forgotten' }}
                >
                    <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <PseForm amount={general_total - descuento} pay={this.postOrderPSE} />
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.openDelivery}
                    onClose={this.closeDelivery}
                    center
                    classNames={{ modal: 'mb-modal', closeButton: 'close-forgotten' }}
                >
                    <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                La fecha y hora de entrega de tus productos de mercado y huerta orgánica programada
                                coinciden, por lo que te cobraremos un único delivery de {getMatchDelivery()}
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    open={this.state.openMap}
                    onClose={this.closeMap}
                    center
                    classNames={{ modal: 'map-mb', closeButton: 'close-forgotten' }}
                >
                    <div className="modal-dialog modal-dialog-centered w-vista-r map-dialog" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <MapTool
                                    submit={this.setCordenates}
                                    lat={this.state.lat}
                                    lon={this.state.lon}
                                    profile={false}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>

                <ProgramingValidation />
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    delivery: state.delivery,
    general_total: getTotalGeneral(),
    usuario: state.customer.data,
    envio: state.customer.envio,
    huerta: state.huerta,
    congelado: state.congelado,
    restaurante: state.restaurante,
    core: state.core,
    snack: state.snack,
    cena: state.cena,
    total: getCartIndividual(state.cartList.cart),
    headers: state.customer.headers,
    descuento: getDescuento(),
    actualDirectionLocal: isActualDirectionLocal(),
    city: state.data.city,
});

export default connect(
    mapStateToProps,
    { removeFromWishlist, updateEnvio, updateHeaders }
)(withRouter(checkOut));
