import React, { Component } from "react";
import { connect } from "react-redux";
import { getDeliveryCost, getTotalNormal } from "../../../services";
import {
  getFechaPatronHuertaYCongelado,
  getNextSabado,
  getNextMartes,
  getNextJueves,
  getDiaEntregaHuerta,
} from "../../../funciones/patrones";
import { getDiaMesEspanolHuertaCongelado } from "../../../funciones/momentEspañol";
import { CoreHuertaMatch } from "../../../funciones/checkout";
import { formatNumber, getPrice } from "../../../funciones/general";

class CheckHuerta extends Component {
  render() {
    const { cartItems, total, symbol, delivery, programacion } = this.props;

    if (cartItems.length > 0) {
      return (
        <>
          <strong className="center70 font-25">Huerta orgánica</strong>
          <br />
          <div className="title-box">
            <div className="row">
              <div className="col-7">Producto</div>
              <div className="col-5">Total</div>
            </div>
          </div>
          <ul className="qty">
            {cartItems.map((item, index) => {
              return (
                <li key={index}>
                  <div className="row">
                    <div className="col-7">
                      {item.name} (×{item.qty})
                    </div>
                    <div className="col-5">
                      {symbol}
                      {formatNumber(getPrice(item) * item.qty)}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>

          <ul className="sub-total">
            <li>
              Entrega
              <span className="count">
                {getDiaEntregaHuerta(programacion.dia) + " "}a las{" "}
                {programacion.hora}
              </span>
            </li>
            {!CoreHuertaMatch() ? (
              <li>
                Delivery{" "}
                <span className="count">
                  {symbol}
                  {formatNumber(delivery)}
                </span>
              </li>
            ) : (
              <></>
            )}
            <li>
              Subtotal{" "}
              <span className="count">
                {symbol}
                {formatNumber(total)}
              </span>
            </li>
          </ul>
        </>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.huerta.cart,
  symbol: state.data.symbol,
  total: getTotalNormal(state.huerta.cart, 4),
  delivery: getDeliveryCost(4),
  programacion: state.huerta.programacion,
});

export default connect(
  mapStateToProps,
  {}
)(CheckHuerta);
