import React, { Component } from 'react';
import { Link, NavLink, withRouter} from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";
import {changeCurrency, filterCategory} from '../../../actions'
import {connect} from "react-redux";
import HomeNav from './common/HomeNav';

class HeaderOne extends Component {

    constructor(props) {
        super(props);

		this.state = {
			isLoading:false,
			text:''
		}
    }
    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
		
        setTimeout(function() {
            document.querySelector(".fluio-wrapper").style = "display: none";
		}, 2000);
		

		this.setState({ open: true });
    }

    componentWillMount(){
        window.addEventListener('scroll', this.handleScroll);
	}
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky").classList.remove('fixed');
            }else
            	document.getElementById("sticky").classList.add('fixed');
        } else {
            document.getElementById("sticky").classList.remove('fixed');
        }
    }

    changeLanguage(lang) {
        store.dispatch(IntlActions.setLocale(lang))
	}

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if(openmyslide){
            openmyslide.classList.add('open-side')
		}
    }
    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

	load = ()=>{
		this.setState({isLoading: true});
		fetch().then(()=>{
			// deal with data fetched
			this.setState({isLoading: false})
		})
	};

	handleSubmit = (e) => {
		e.preventDefault()
		this.props.filterCategory([]);
		this.props.history.push("/productos/"+this.state.text)
		window.location.reload()
		
	}

	handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

	
	
	render() {

		const {ordenes} = this.props

		return (
			<div className='mb-display'>

				<header id="sticky" className="sticky mb-header">
					{this.state.isLoading ? <Pace color="#27ae60"/> : null}
					<div className="mobile-fix-option mb-none"></div>
					{/*Top Header Component*/}
					<TopBar/>

					<div className="container h-100">
						<div className="row">
							<div className="col-sm-12">

								<div className="main-menu fixed-main">
									<div className="menu-left">
										<div className="navbar">
											{/*
											<a href="javascript:void(0)" onClick={this.openNav}>
												<div className="bar-style"> <i className="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
											</a>
											*/}
											{/*SideBar Navigation Component
											<SideBar/>
											*/}
										</div>
										
										<div className="brand-logo mb-l25">
											<LogoImage />										
										</div>

									</div>



									<div className="contact-container mb-display">
										
										{/*
										<div className="d-flex justify-content-center mb-1">
											<div>
												<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/FLUIO-101854164543495/">
													<img src={`/assets/images/facebook.png`} alt="fb" className="contact-home-icon mr-1"/>
												</a>											
											</div>
											<div>
											</div>
											<div>
												<a href="instagram://user?username=fluio.co">
													<img src={`/assets/images/instagram.png`} alt="fb" className="contact-home-icon ml-1"/>
												</a>												
											</div>
										</div>
										*/}

										{/*
										<div className="d-flex justify-content-around">
											<div>
												<a href="mailto:info@fluio.com.co" data-toggle="tooltip" title="info@fluio.com.co">
													<i class="fa fa-envelope contact-home-icon2"/>
												</a>																
											</div>
											<div>
												<a href="tel:+573012535984" data-toggle="tooltip" title="(+57)3012535984">
													<i class="fa fa-phone contact-home-icon2"/>
												</a>
											</div>
											<div>
												<a href="https://api.whatsapp.com/send?phone=+57 350 2343959">
													<img src={`/assets/images/whatsapp.png`} alt="fb" className="contact-home-icon"/>
												</a>											
											</div>
										</div>
										*/}

									</div>
									
									<div className="menu-right pull-right">
										{/*Top Navigation Bar Component*/}
										<NavBar/>

										<div>
											<div className="icon-nav">
												<ul>
													<li className="onhover-div mobile-search">
														<div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`} onClick={this.openSearch} className="img-fluid" alt="" />
															<i className="fa fa-search mb-none" onClick={this.openSearch}></i></div>
													</li>
													{/*
													<li className="onhover-div mobile-setting mb-display">

														<a href={`${process.env.PUBLIC_URL}/orders`}>
															<div className="cart-qty-cls">{ordenes}
															</div>
														</a>
														<div>
															<a href={`${process.env.PUBLIC_URL}/orders`}>
															<i className="fa fa-truck"></i>
															</a>
														</div>
													</li>													
													<li className="onhover-div mobile-setting">
														<div><img src={`${process.env.PUBLIC_URL}/assets/images/icon/setting.png`} className="img-fluid" alt="" />
															<i className="fa fa-cog"></i></div>
														<div className="show-div setting">
															<h6>language</h6>
															<ul>
																<li><a href={null} onClick={() => this.changeLanguage('en')}>English</a> </li>
																<li><a href={null} onClick={() => this.changeLanguage('fn')}>French</a> </li>
															</ul>
															<h6>currency</h6>
															<ul className="list-inline">
																<li><a href={null} onClick={() => this.props.changeCurrency('€')}>euro</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('₹')}>rupees</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('£')}>pound</a> </li>
																<li><a href={null} onClick={() => this.props.changeCurrency('$')}>doller</a> </li>
															</ul>
														</div>
													</li>
													*/}
													{/*Header Cart Component */}
													<CartContainer/>
		
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

                <div id="search-overlay" className="search-overlay">
                    <div>
                        <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">×</span>
                        <div className="overlay-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <input type="text" onChange={this.handleChange} name="text" className="form-control" id="exampleInputPassword1" placeholder="Buscar productos" />
                                            </div>
                                            <button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

			</div>
			)
	}
}

const mapStateToProps = state => ({
	estado: state,
	ordenes: state.orden.pendientes.length
})

export default connect(mapStateToProps,
    { changeCurrency,filterCategory }
)(withRouter(HeaderOne));