import React, { Component } from 'react'
import { toastSuccess,toastError } from '../../../../alertas'
import { connect } from 'react-redux'
import { addToCongelado } from '../../../../actions'
import horas from '../../../../api/horarios/tipoHuerta.json'
import store from '../../../../store'
import { validateCongelado } from '../../../../services'
import { getFechaPatronHuertaYCongelado, getNextMartes, getNextSabado, getDateOrdenadoHuertaCongelado } from '../../../../funciones/patrones'
import { getDiaMesEspanolHuertaCongelado } from '../../../../funciones/momentEspañol'

class ItemTipoCongelado extends Component {

    constructor(props){
        super(props)
        if(props.congelado.programacion){
            this.state={
                hora:props.congelado.programacion.hora,
                cantidad: 1,
                radio:getDateOrdenadoHuertaCongelado()[0].day=="martes"? "mar": "sab"
            }
        }else{
            this.state={
                hora: horas[0].hora,
                cantidad: 1,
                radio:getDateOrdenadoHuertaCongelado()[0].day=="martes"? "mar": "sab"
            }
        }
    }


    agregarCarrito = (product) => {

        const {carrito,addToCongelado}= this.props
        const {cantidad,hora,radio}= this.state
        const numero_plato=parseInt(cantidad)

        //determina si el item esta en el carrito para saber si agregarlo o no
        const bool=carrito.some((item) => {
            return item.id==product.id
        })

        let programacion

        programacion={
            hora:hora,
            dia:radio
        }

        if(bool){
            toastError("El producto ya esta en el carrito")
        }else{
            if(validateCongelado(numero_plato)){
                addToCongelado(product,programacion,numero_plato)
                toastSuccess("Se agrego al carrito")
            }else{
                toastError("Limite de articulos congelados alcanzado")
            }
        }
        this.props.close()
    }

    handleSubmit = async (e,product) => {
        e.preventDefault()
        this.agregarCarrito(product)
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    componentDidMount(){
    }

    restar = (e) => {

        e.preventDefault()
        this.setState({
            cantidad: this.state.cantidad-1
        })
        
    }

    sumar = (e) => {

        e.preventDefault()
        this.setState({
            cantidad: this.state.cantidad+1
        })
        
    }
    
    render() {

        const {product,symbol}=this.props
        const {cantidad,radio}=this.state


        const list_horas=horas.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_radio=getDateOrdenadoHuertaCongelado().map((value,i)=>{
            return(

                <label className="radio-inline" key={i}>
                    <input 
                        type="radio" 
                        name="radio"  
                        value={value.day=="martes"?'mar':"sab"} 
                        onChange={this.handleChange} 
                        checked={value.day=="martes"? radio == 'mar': radio=="sab"} />

                    {value.day=="martes"?
                        getDiaMesEspanolHuertaCongelado(getNextMartes())
                        :
                        getDiaMesEspanolHuertaCongelado(getNextSabado())}
                </label>


            )
        })


        return (
            <div className="modal-dialog modal-lg modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-6  col-xs-12">
                                <div className="quick-view-img">
                                    <img src={product.variants?
                                        this.state.image?this.state.image:product.variants[0].images
                                        :product.pictures[0]} alt="" className="modal-image-style" />
                                </div>
                            </div>
                            <div className="col-lg-6 rtl-text">
                                <div className="product-right">
                                    <h2 className="ttn"> {product.name} </h2>
                                    <div className="border-product">
                                        <p>{product.description}</p>
                                    </div>
                                    <h3>{symbol}{product.price}
                                        {/*
                                            <del><span className="money">{symbol}{product.price}</span></del>
                                        */}
                                    </h3>
                                    {product.variants?
                                    <ul className="color-variant">
                                        {product.variants.map((vari, i) =>
                                            <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                        }
                                    </ul>:''}
                                    <form onSubmit={(e)=>this.handleSubmit(e,product)}>

                                        <div className="form-group">

                                            {list_radio}

                                        </div>

                                        <div className="form-group">
                                            <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                                {list_horas}
                                            </select>
                                        </div>

                                        <div className="form-row">

                                            <div className="col-6">

                                                <label htmlFor="cantidad" className="col-form-label cantidad-modal-label" >Cantidad:</label>
                                                <div className="input-group">

                                                    <span className="input-group-prepend">
                                                        <button type="button" className="btn quantity-left-minus" onClick={(e) => this.restar(e,"cantidad")} data-type="minus" data-field="" disabled={(cantidad <= 1)? true : false}>
                                                            <i className="fa fa-angle-left"></i>
                                                        </button>
                                                    </span>
                                                    <input type="text" name="cantidad" value={cantidad} readOnly={true} className="form-control input-number tac" />
                                                    <span className="input-group-prepend">
                                                        <button className="btn quantity-right-plus" onClick={(e) => this.sumar(e,"cantidad")}  data-type="plus" disabled={(cantidad >= 15)? true : false}>
                                                            <i className="fa fa-angle-right"></i>
                                                        </button>
                                                    </span>
                                                </div>

                                            </div>

                                            <div className="col-6">

                                                <div className="product-buttons">
                                                    <button  className="btn btn-solid ttn btn-modal-product" >Añadir al carrito</button>                                                  
                                                </div>
                                                
                                            </div>


                                        </div>

                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    total: state.data.products,
    carrito: state.congelado.cart,
    symbol: state.data.symbol,
    congelado: state.congelado,
})

export default connect(
    mapStateToProps, {addToCongelado}
)(ItemTipoCongelado)
