import * as types from '../constants/ActionTypes'

const categoriesReducerDefaultState = {
    categories:[]
};

const categoriesReducer = (state = categoriesReducerDefaultState, action) => {
    switch (action.type) {
        case types.UPDATE_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };
        default:
            return state;
    }
}

export default categoriesReducer;