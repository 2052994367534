import React from 'react';
import {Link} from 'react-router-dom'

function goHome(e) {
    e.preventDefault()
    window.location.replace(process.env.PUBLIC_URL+"/")
}

function LogoImage(props) {

    return (
        <>
            <Link onClick={goHome} to={`${process.env.PUBLIC_URL}/`} className="mb-none" >
                <img src={`${process.env.PUBLIC_URL}/assets/images/icon/fluio-logo.JPG`} alt="" className="img-fluid i-footer mb-fix-logo" />
            </Link>
        </>
    )
    
}

export default LogoImage;