import React from 'react'
import { Route,Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

export const NotAuthRoute = ({component:Component,...rest}) => {

    const customer=JSON.parse(localStorage.getItem("state")).customer.data

    return (
        <Route {...rest} render={
            (props)=>{
                if(!customer){    

                    return <Component {...props} />

                }else{
                    return <Redirect to="/" />
                }
            }
        }/>
    )
    
}

const mapStateToProps = (state) => ({
    customer: state.customer.data
})

export default connect(mapStateToProps)(NotAuthRoute)