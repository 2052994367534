import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux'
import { LazyImage } from "react-lazy-images";


class AllAliadoListItem extends Component {

    constructor(props){
        super(props)

        this.state = {
        }
    }

    goAllyHalls = (e) => {
        e.preventDefault()
        const {aliado} = this.props;
        this.props.history.push("/"+aliado.seo)
        
    }
    

    render() {
        const {aliado} = this.props;

        return (
                <>

                    <div className={"product-box pro-style "}>
                        <div className="">
                            <div className="front" onClick={(e)=>this.goAllyHalls(e)}>
                                <LazyImage
                                    src={aliado.image}
                                    alt={aliado.name}
                                    placeholder={({ imageProps, ref }) => (
                                        <img
                                            className="max-h mb-center90 green-loader"
                                            ref={ref} src={`${process.env.PUBLIC_URL}/assets/images/loading4.gif`}
                                            alt={imageProps.alt}
                                        />
                                    )}
                                    actual={({ imageProps }) => 
                                        <img {...imageProps} className="img-fluid max-h mb-pro-img"/>
                                    }
                                    observerProps={{
                                        rootMargin: "200px 500px 200px 500px",
                                        threshold: 0
                                    }}
                                />
                            </div>

                        </div>
                        <div className="product-detail ">
                            <div>
                                <Link to={"/#"} onClick={(e)=>this.goAllyHalls(e)}>
                                    <h6 className="lbl-name-aliado tac">{aliado.name}</h6>
                                </Link>
                            </div>
                        </div>


                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(withRouter(AllAliadoListItem))