import React, { Component } from 'react'
import { getTotalOrden } from '../../../../../servicios'

class OrdenCena extends Component {

    render() {

        const {productos}=this.props

        if(productos.length>0){

            return (
                <div>
                    
                    <div className="pedidos-title">Restaurantes</div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Producto</strong>
                        </div>                       
                        <div className="col-4">
                            <strong>Extras</strong>                       
                        </div>
                        <div className="col-4">
                            <strong>Costo</strong>                       
                        </div>
                    </div>
                    <br/>

                    {
                        productos.map((item, index) => {
                            return (

                                <div key={index}>

                                    <div className="row" >
                                        <div className="col-4">
                                            {item.producto} (×{item.amount})  
                                        </div>
                                        <div className="col-4">
                                            {
                                                item.adname ?
                                                <>
                                                    {(item.adname)} (Bebidas:{item.bebidas_n},Postres:{item.postres_n})
                                                </>: ''                                           
                                            }
                                        </div>
                                        <div className="col-4">
                                            ${(item.total)}
                                        </div>   
                                        {
                                            item.com ?
                                                <div className="col-12">
                                                    <strong>Comentario:</strong>
                                                    {item.com}
                                                </div>
                                            :''
                                        }                                    
                                    </div>
                                    <hr/>

                                </div>
                            
                            )
                        })
                    }

                    <div className="row">
                        <div className="col-4">
                            <strong>Entrega:</strong>
                        </div>
                        <div className="col-4">
                            {productos[0].fecha_i} a las {productos[0].hora}                 
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Delivery:</strong>
                        </div>
                        <div className="col-4">
                            ${productos[0].delivery}              
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">
                            ${getTotalOrden(productos,2)}             
                        </div>
                    </div>

                    <br/>
                 
                </div>
            )

        }else{
            return ''
        }
        
    }
}

export default OrdenCena
