import shop from "../api/shop";
import * as types from "../constants/ActionTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const getAllProducts = () => (dispatch) => {
  //dispatch(fetchProductsBegin());
  shop.getProducts((products) => {
    dispatch(receiveProducts(products));
    return products;
  });
};
export const fetchSingleProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});

//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, programacion, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, programacion, qty));
};
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
  dispatch(removeFromWishlist(product));
};
export const addToCartUnsafe = (product, programacion, qty) => ({
  type: types.ADD_TO_CART,
  product,
  programacion,
  qty,
});

export const updateCart = (product) => (dispatch) => {
  dispatch({
    type: types.UPDATE_CART,
    product,
  });
};

export const removeFromCart = (product_id) => (dispatch) => {
  toast.error("Item Removed from Cart");
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id,
  });
};
export const incrementQty = (product, qty) => (dispatch) => {
  toast.success("Item Added to Cart");
  dispatch(addToCartUnsafe(product, qty));
};
export const decrementQty = (productId) => (dispatch) => {
  toast.warn("Item Decrement Qty to Cart");

  dispatch({
    type: types.DECREMENT_QTY,
    productId,
  });
};

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
  toast.success("Item Added to Wishlist");
  dispatch(addToWishlistUnsafe(product));
};
export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product,
});
export const removeFromWishlist = (product_id) => (dispatch) => {
  toast.error("Item Removed from Wishlist");
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    product_id,
  });
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
  toast.success("Item Added to Compare");
  dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product,
});
export const removeFromCompare = (product_id) => ({
  type: types.REMOVE_FROM_COMPARE,
  product_id,
});

// Filters
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});
export const filterCategory = (category) => ({
  type: types.FILTER_CATEGORY,
  category,
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});
export const filterPrice = (value) => ({
  type: types.FILTER_PRICE,
  value,
});
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol,
});

//user stuff

export const dataLogin = (user, headers) => ({
  type: types.LOG_IN,
  user,
  headers,
});

export const dataLogout = () => ({
  type: types.LOG_OUT,
});

//cosas del carrito de huerta
export const addToHuerta = (product, programacion, qty) => ({
  type: types.ADD_TO_HUERTA,
  product,
  programacion,
  qty,
});

export const incrementHuerta = (product, qty) => (dispatch) => {
  dispatch(addToHuerta(product, qty));
};

export const removeFromHuerta = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_HUERTA,
    product_id,
  });
};

export const removeFromExternal = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_EXTERNAL,
    product_id,
  });
};

export const updateHuerta = (programacion) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HORA_HUERTA,
    programacion,
  });
};

export const decrementHuerta = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_HUERTA,
    productId,
  });
};

//cosas del carrito de congelados
export const addToCongelado = (product, programacion, qty) => ({
  type: types.ADD_TO_CONGELADO,
  product,
  programacion,
  qty,
});

export const incrementCongelado = (product, qty) => (dispatch) => {
  dispatch(addToCongelado(product, qty));
};

export const removeFromCongelado = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_CONGELADO,
    product_id,
  });
};

export const updateCongelado = (programacion) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HORA_CONGELADO,
    programacion,
  });
};

export const decrementCongelado = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_CONGELADO,
    productId,
  });
};

//cosas del carrito de core
export const addToCore = (product, programacion, qty) => ({
  type: types.ADD_TO_CORE,
  product,
  programacion,
  qty,
});

export const addToExternal = (product, programacion, qty) => ({
  type: types.ADD_TO_EXTERNAL,
  product,
  programacion,
  qty,
});

export const incrementCore = (product, qty) => (dispatch) => {
  dispatch(addToCore(product, qty));
};

export const incrementExternal = (product, qty) => (dispatch) => {
  dispatch(addToExternal(product, qty));
};

export const removeFromCore = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_CORE,
    product_id,
  });
};

export const updateCore = (programacion) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HORA_CORE,
    programacion,
  });
};

export const updateExternal = (programacion) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HORA_EXTERNAL,
    programacion,
  });
};

export const decrementCore = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_CORE,
    productId,
  });
};

export const decrementExternal = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_EXTERNAL,
    productId,
  });
};

//cosas del carrito de SNACK
export const addToSnack = (product, programacion, qty) => ({
  type: types.ADD_TO_SNACK,
  product,
  programacion,
  qty,
});

export const incrementSnack = (product, qty) => (dispatch) => {
  dispatch(addToSnack(product, qty));
};

export const removeFromSnack = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_SNACK,
    product_id,
  });
};

export const updateSnack = (programacion) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HORA_SNACK,
    programacion,
  });
};

export const decrementSnack = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_SNACK,
    productId,
  });
};

//cosas del delivery

export const updateDelivery = (costo) => ({
  type: types.UPDATE_DELIVERY,
  costo,
});

//cosas del carrito de restaurante
export const addToRestaurante = (product, hora, qty) => ({
  type: types.ADD_TO_RESTAURANTE,
  product,
  hora,
  qty,
});

export const incrementRestaurante = (product, qty) => (dispatch) => {
  dispatch(addToRestaurante(product, qty));
};

export const removeFromRestaurante = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_RESTAURANTE,
    product_id,
  });
};

export const updateRestaurante = (hora) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HORA_RESTAURANTE,
    hora,
  });
};

export const decrementRestaurante = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_RESTAURANTE,
    productId,
  });
};

//cosas de LAS CATEGORIAS

export const updateCategories = (categories) => ({
  type: types.UPDATE_CATEGORIES,
  categories,
});

//cosas de los aliados
export const updateAliado = (aliado) => ({
  type: types.UPDATE_ALIADO,
  aliado,
});

//cosas de los halls

export const updateHalls = (halls) => ({
  type: types.UPDATE_HALLS,
  halls,
});

//cosas para limpiar carrito

export const clearPlan = () => ({
  type: types.CLEAR_PLAN,
});
export const clearRestaurante = () => ({
  type: types.CLEAR_RESTAURANTE,
});
export const clearCena = () => ({
  type: types.CLEAR_CENA,
});
export const clearHuerta = () => ({
  type: types.CLEAR_HUERTA,
});
export const clearCongelado = () => ({
  type: types.CLEAR_CONGELADO,
});
export const clearCore = () => ({
  type: types.CLEAR_CORE,
});

export const clearExternal = () => ({
  type: types.CLEAR_EXTERNAL,
});
export const clearSnack = () => ({
  type: types.CLEAR_SNACK,
});

//cosas del envio
export const updateEnvio = (envio) => ({
  type: types.UPDATE_ENVIO,
  envio,
});

//cosas de headers
export const updateHeaders = (headers) => ({
  type: types.UPDATE_HEADERS,
  headers,
});

//cosas del user
export const updateUser = (user) => ({
  type: types.UPDATE_USER,
  user,
});

//cosas de rest
export const actualizarRestaurante = (product) => ({
  type: types.ACTUALIZAR_RESTAURANTE,
  product,
});

//cosas de core
export const actualizarCore = (product) => ({
  type: types.ACTUALIZAR_RESTAURANTE,
  product,
});

export const actualizarExternal = (product) => ({
  type: types.ACTUALIZAR_EXTERNAL,
  product,
});

//cosas de cena
export const actualizarCena = (product) => ({
  type: types.ACTUALIZAR_CENA,
  product,
});

//cosas de los adicionales
export const updatePostre = (postres) => ({
  type: types.UPDATE_POSTRE,
  postres,
});

export const updateBebida = (bebidas) => ({
  type: types.UPDATE_BEBIDA,
  bebidas,
});

//cosas del carrito de cena
export const addToCena = (product, hora, qty) => ({
  type: types.ADD_TO_CENA,
  product,
  hora,
  qty,
});

export const incrementCena = (product, qty) => (dispatch) => {
  dispatch(addToCena(product, qty));
};

export const removeFromCena = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_CENA,
    product_id,
  });
};

export const updateCena = (hora) => (dispatch) => {
  dispatch({
    type: types.UPDATE_HORA_CENA,
    hora,
  });
};

export const decrementCena = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_CENA,
    productId,
  });
};

export const updateOrden = (ordenes) => (dispatch) => {
  dispatch({
    type: types.UPDATE_ORDEN,
    ordenes,
  });
};

export const clearOrden = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_ORDEN,
  });
};

export const updateLoadHome = (bool) => ({
  type: types.UPDATE_LOAD_HOME,
  bool,
});

//cosas de survey
export const completeSurvey = () => ({
  type: types.COMPLETE_SURVEY,
});

export const updateLocation = (city, country) => ({
  type: types.UPDATE_LOCATION,
  city,
  country,
});
