import React, { Component } from 'react'
import { formatNumber } from '../../../funciones/general'

export class PriceListing extends Component {
    render() {
        const {product}=this.props
        return (
            <>
            {
                product.if_offer?
                <h4>
                    ${formatNumber(product.price_offer)}
                    <del className="old-price-space">
                    ${formatNumber(product.price)}
                    </del>
                </h4>
                :
                <h4>
                    ${formatNumber(product.price)}
                </h4>
            }
            </>
        )
    }
}

export default PriceListing
