import React, {Component} from 'react';
import {filterCategory} from '../../actions/index'
import { connect } from 'react-redux';
import AllAliadoListing from './common/all-aliado-listing';
import { cargarStorage } from '../../funciones/cargarDatos';
import Banner from '../common/Banner';


class CollectionAllAliado extends Component {

    state = {
        layoutColumns:3
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = (e) => {
		e.preventDefault()
		this.props.filterCategory([]);
		this.props.history.push("/productos/"+this.state.text)
    }

    componentDidMount(){
        cargarStorage()
    }

    goAllyHalls = (e,aliado) => {
        this.props.history.push("/"+aliado.seo)
        
    }

    render (){
        const {loading_products,aliado}=this.props

        const aliado_list=aliado.map((val,i)=>{
            return(
                <div className='mb-padding col-sm-6 col-lg-3 col-xl-3 col-4 big-cate-img center-cate-styles'
                onClick={(e) => {this.goAllyHalls(e,val)}} key={i}>

                    <div className='cate-banner2 box400 bg-cate mb-5 mb-cate-box border-category-home no-border'>

                        <img src={val.image} alt={val.name} className='img-fluid bg-img up-image'/>

                    </div>

                    <p class="center-cate-label mt-1">{val.name}</p>

                </div>
            )
        })

        return (
            <div>

                <Banner/>

                <div>
                    <h3 className="center mb-center90 ttn">{'Aliados'}</h3>
                </div>

                <div className="mb-display">

                    <hr/>

                    <section className="p-0 ratio2_1 bg-white sandwitch8">

                        <div className="row">
                            {aliado_list}
                        </div>

                    </section>

                </div>

                <section className="section-b-space mb-none">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">

                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-xl-12">

                                                                    </div>
                                                                </div>
                                                                <div className="row">

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            loading_products==false?

                                                            <AllAliadoListing 
                                                                colSize={this.state.layoutColumns} 
                                                            />

                                                            :

                                                                <div className="row">
                                                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                                                        <h3>Cargando</h3>
                                                                    </div>
                                                                </div>
                                                            
                                                        }


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categories: state.categories.categories,
    loading_products:state.data.loading_products,
    aliado: state.aliado.data,
})

export default connect(
    mapStateToProps,
    {filterCategory}
)(CollectionAllAliado)