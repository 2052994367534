import store from '../store';

import {
    clearCongelado,
    clearCore,
    clearHuerta,
    clearRestaurante,
    clearPlan,
    clearSnack,
    updatePostre,
    updateBebida,
    clearCena,
    updateHalls,
    updateCategories,
    getAllProducts,
    updateOrden,
    updateHeaders,
    clearOrden,
    dataLogout,
    updateUser,
    updateAliado,
    clearExternal,
} from '../actions';

import { getData, postData } from '../servicios';
import axios from 'axios';
import { CoreHuertaMatch } from '../funciones/checkout';
import { getPrice } from '../funciones/general';
import { getLocationProducts, isActualDirectionLocal } from '../funciones/cities';

// Get Unique Brands from Json Data
export const getBrands = (products) => {
    var uniqueBrands = [];
    products.map((product, index) => {
        if (product.tags) {
            product.tags.map((tag) => {
                if (uniqueBrands.indexOf(tag) === -1) {
                    uniqueBrands.push(tag);
                }
            });
        }
    });
    //console.log(uniqueBrands)
    return uniqueBrands;
};

// Get Unique Colors from Json Data
export const getColors = (products) => {
    var uniqueColors = [];
    products.map((product, index) => {
        if (product.colors) {
            product.colors.map((color) => {
                if (uniqueColors.indexOf(color) === -1) {
                    uniqueColors.push(color);
                }
            });
        }
    });
    //console.log(uniqueBrands)
    return uniqueColors;
};

// Get Minimum and Maximum Prices from Json Data
export const getMinMaxPrice = (products) => {
    let min = 100,
        max = 1000;

    products.map((product, index) => {
        let v = product.price;
        min = v < min ? v : min;
        max = v > max ? v : max;
    });

    return { min: min - 100, max: max + 100 };
};

export const getVisibleproducts = (data, { brand, color, value, sortBy }) => {
    return data.products
        .filter((product) => {
            let brandMatch;
            if (product.tags) brandMatch = product.tags.some((tag) => brand.includes(tag));
            else brandMatch = true;

            let colorMatch;
            if (color && product.colors) {
                colorMatch = product.colors.includes(color);
            } else {
                colorMatch = true;
            }

            const startPriceMatch = typeof value.min !== 'number' || value.min <= product.price;
            const endPriceMatch = typeof value.max !== 'number' || product.price <= value.max;

            return brandMatch && colorMatch && startPriceMatch && endPriceMatch;
        })
        .sort((product1, product2) => {
            if (sortBy === 'HighToLow') {
                return product2.price < product1.price ? -1 : 1;
            } else if (sortBy === 'LowToHigh') {
                return product2.price > product1.price ? -1 : 1;
            } else if (sortBy === 'Newest') {
                return product2.id < product1.id ? -1 : 1;
            } else if (sortBy === 'AscOrder') {
                return product1.name.localeCompare(product2.name);
            } else if (sortBy === 'DescOrder') {
                return product2.name.localeCompare(product1.name);
            } else {
                return product2.id > product1.id ? -1 : 1;
            }
        });
};

export const getProductosVisibles = (data, { category, value, sortBy }) => {
    const dataProduct = getLocationProducts();
    return dataProduct
        .filter((product) => {
            //verifico que exista algun filtrado, de no haber regresa true
            if (category.length == 0) {
                return true;
            } else {
                //variable para seber si el item aprueba el filtro

                let match = false;

                //condicion para categorias
                if (category.includes(product.category)) {
                    match = true;
                }
                return match;
            }

            //metodo para ordenar los productos
        })
        .sort((product1, product2) => {
            if (sortBy === 'HighToLow') {
                return product2.price < product1.price ? -1 : 1;
            } else if (sortBy === 'LowToHigh') {
                return product2.price > product1.price ? -1 : 1;
            } else if (sortBy === 'Newest') {
                return product2.id < product1.id ? -1 : 1;
            } else if (sortBy === 'AscOrder') {
                return product1.name.localeCompare(product2.name);
            } else if (sortBy === 'DescOrder') {
                return product2.name.localeCompare(product1.name);
            } else {
                return product2.pri > product1.pri ? -1 : 1;
            }
        });
};

export const calcularPlan = (item) => {
    return getCostoPlan(item) * item.qty + getNumberDeliveryPlan(item) * store.getState().delivery.plan;
};

export const calcularCosto = (item) => {
    return getPrice(item) * item.qty;
};

export const getCostoDeliveryPlan = (product) => {
    const { delivery } = this.props;

    switch (product.tipo_cate) {
        case 2:
            return delivery.restaurante;
        case 3:
            return delivery.plan;
        default:
            return 0;
    }
};

export const getCostoPlan = (product) => {
    if (product.tipo_hall == 1) {
        return product.price * product.programacion.semana1.length;
    }
    if (product.tipo_hall == 2) {
        return product.price * (product.programacion.semana1.length + product.programacion.semana2.length);
    }

    if (product.tipo_hall == 4) {
        const dias_semanas =
            product.programacion.semana1.length +
            product.programacion.semana2.length +
            product.programacion.semana3.length +
            product.programacion.semana4.length;

        return product.price * dias_semanas;
    }
};

export const getNumberDeliveryPlan = (product) => {
    switch (product.tipo_cate) {
        case 2:
            return 1;
        case 3:
            if (product.tipo_hall == 1) {
                return product.programacion.semana1.length;
            }
            if (product.tipo_hall == 2) {
                return product.programacion.semana1.length + product.programacion.semana2.length;
            }

            if (product.tipo_hall == 4) {
                const dias_semanas =
                    product.programacion.semana1.length +
                    product.programacion.semana2.length +
                    product.programacion.semana3.length +
                    product.programacion.semana4.length;

                return dias_semanas;
            }

        default:
            return 0;
    }
};

export const getDeliveryCost = (tipo) => {
    const delivery = store.getState().delivery;

    if (isActualDirectionLocal()) {
        if (isChia()) {
            switch (tipo) {
                case 0:
                    if (store.getState().snack.cart.length > 0) {
                        return delivery.chia;
                    }
                    break;
                case 1:
                    if (store.getState().core.cart.length > 0 && !CoreHuertaMatch()) {
                        return delivery.chia;
                    }
                    break;
                case 2:
                    if (store.getState().restaurante.cart.length > 0) {
                        return delivery.chia;
                    }
                    break;
                case 4:
                    if (store.getState().huerta.cart.length > 0 && !CoreHuertaMatch()) {
                        return delivery.chia;
                    }
                    break;
                case 5:
                    if (store.getState().congelado.cart.length > 0) {
                        return delivery.chia;
                    }
                    break;
                case 6:
                    if (store.getState().cena.cart.length > 0) {
                        return delivery.chia;
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (tipo) {
                case 0:
                    if (store.getState().snack.cart.length > 0) {
                        return delivery.snack;
                    }
                    break;
                case 1:
                    if (store.getState().core.cart.length > 0 && !CoreHuertaMatch()) {
                        return delivery.core;
                    }
                    break;
                case 2:
                    if (store.getState().restaurante.cart.length > 0) {
                        return delivery.restaurante;
                    }
                    break;
                case 4:
                    if (store.getState().huerta.cart.length > 0 && !CoreHuertaMatch()) {
                        return delivery.huerta;
                    }
                    break;
                case 5:
                    if (store.getState().congelado.cart.length > 0) {
                        return delivery.congelado;
                    }
                    break;
                case 6:
                    if (store.getState().cena.cart.length > 0) {
                        return delivery.restaurante;
                    }
                    break;
                default:
                    break;
            }
        }
    } else {
        return 0;
    }
};

//determina el total de carrito de programacion individual
export const getCartIndividual = (cartItems) => {
    var total = 0;
    const delivery = store.getState().delivery;

    for (var i = 0; i < cartItems.length; i++) {
        //caso restaurante
        if (cartItems[i].tipo_cate !== 3) {
            total += parseInt(cartItems[i].qty, 10) * parseInt(cartItems[i].price, 10) + delivery.restaurante;

            //caso plan alimenticio
        } else {
            if (cartItems[i].tipo_hall === 1) {
                const dias1 = cartItems[i].programacion.semana1.length;
                const delivery_p = dias1 * delivery.plan;

                total += parseInt(cartItems[i].qty, 10) * parseInt(cartItems[i].price * dias1, 10) + delivery_p;
            }

            if (cartItems[i].tipo_hall === 2) {
                const dias1 = cartItems[i].programacion.semana1.length;
                const dias2 = cartItems[i].programacion.semana2.length;

                const delivery_p = (dias1 + dias2) * delivery.plan;

                total +=
                    parseInt(cartItems[i].qty, 10) * parseInt(cartItems[i].price * (dias1 + dias2), 10) + delivery_p;
            }

            if (cartItems[i].tipo_hall === 4) {
                const dias1 = cartItems[i].programacion.semana1.length;
                const dias2 = cartItems[i].programacion.semana2.length;
                const dias3 = cartItems[i].programacion.semana3.length;
                const dias4 = cartItems[i].programacion.semana4.length;

                const total_dias = dias1 + dias2 + dias3 + dias4;

                const delivery_p = total_dias * delivery.plan;

                total += parseInt(cartItems[i].qty, 10) * parseInt(cartItems[i].price * total_dias, 10) + delivery_p;
            }
        }
    }

    return total;
};

export const isChia = () => {
    const city = store.getState().data.city;

    return city === 'Chía';
};

//calcula el total de los item que se entregan los martes de momento huerta y congelado
export const getTotalNormal = (cartItems, tipo) => {
    var total = 0;
    const delivery = store.getState().delivery;

    for (var i = 0; i < cartItems.length; i++) {
        total += parseInt(cartItems[i].qty, 10) * parseInt(getPrice(cartItems[i]), 10);
    }

    if (isActualDirectionLocal()) {
        if (isChia()) {
            switch (tipo) {
                case 0:
                    if (store.getState().snack.cart.length > 0) {
                        total += delivery.chia;
                    }
                    break;
                case 1:
                    if (store.getState().core.cart.length > 0 && !CoreHuertaMatch()) {
                        total += delivery.chia;
                    }
                    break;
                case 2:
                    if (store.getState().restaurante.cart.length > 0) {
                        total += delivery.chia;
                    }
                    break;
                case 4:
                    if (store.getState().huerta.cart.length > 0 && !CoreHuertaMatch()) {
                        total += delivery.chia;
                    }
                    break;
                case 5:
                    if (store.getState().congelado.cart.length > 0) {
                        total += delivery.chia;
                    }
                    break;
                case 6:
                    if (store.getState().cena.cart.length > 0) {
                        total += delivery.chia;
                    }
                    break;
                default:
                    break;
            }
        } else {
            switch (tipo) {
                case 0:
                    if (store.getState().snack.cart.length > 0) {
                        total += delivery.snack;
                    }
                    break;
                case 1:
                    if (store.getState().core.cart.length > 0 && !CoreHuertaMatch()) {
                        total += delivery.core;
                    }
                    break;
                case 2:
                    if (store.getState().restaurante.cart.length > 0) {
                        total += delivery.restaurante;
                    }
                    break;
                case 4:
                    if (store.getState().huerta.cart.length > 0 && !CoreHuertaMatch()) {
                        total += delivery.huerta;
                    }
                    break;
                case 5:
                    if (store.getState().congelado.cart.length > 0) {
                        total += delivery.congelado;
                    }
                    break;
                case 6:
                    if (store.getState().cena.cart.length > 0) {
                        total += delivery.restaurante;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    return total;
};

export const getTotalRestaurante = (cart) => {
    let total = 0;
    const delivery = store.getState().delivery;

    for (var i = 0; i < cart.length; i++) {
        total += calcularRestaurante(cart[i]);
    }

    if (store.getState().restaurante.cart.length > 0) {
        total += delivery.restaurante;
    }

    return total;
};

export const getTotalCena = (cart) => {
    let total = 0;
    const delivery = store.getState().delivery;

    for (var i = 0; i < cart.length; i++) {
        total += calcularRestaurante(cart[i]);
    }

    if (isActualDirectionLocal()) {
        if (isChia()) {
            if (store.getState().cena.cart.length > 0) {
                total += delivery.chia;
            }
        } else {
            if (store.getState().cena.cart.length > 0) {
                total += delivery.restaurante;
            }
        }
    }

    return total;
};

export const getTotalGeneral = () => {
    const individual = getCartIndividual(store.getState().cartList.cart);
    const huerta = getTotalNormal(store.getState().huerta.cart, 4);
    const congelado = getTotalNormal(store.getState().congelado.cart, 5);
    const core = getTotalNormal(store.getState().core.cart, 1);
    const snack = getTotalNormal(store.getState().snack.cart, 0);
    const restaurante = getTotalRestaurante(store.getState().restaurante.cart);
    const cena = getTotalCena(store.getState().cena.cart);
    const external = getTotalNormal(store.getState().external.cart);

    let core_huerta_delivery = 0;

    if (CoreHuertaMatch()) {
        if (isChia()) {
            core_huerta_delivery += store.getState().delivery.chia;
        } else {
            core_huerta_delivery += store.getState().delivery.core_huerta;
        }
    }

    return individual + huerta + congelado + core + snack + restaurante + cena + external + core_huerta_delivery;
};

//metodo para validar la forma de pago
export const ValidatePago = () => {
    let cant = 0;

    const individual = store.getState().cartList.cart;
    const huerta = store.getState().huerta.cart;
    const congelado = store.getState().congelado.cart;
    const core = store.getState().core.cart;
    const snack = store.getState().snack.cart;
    const restaurante = store.getState().restaurante.cart;
    const cena = store.getState().cena.cart;

    if (individual.length > 0) {
        return false;
    }
    if (huerta.length > 0) {
        return false;
    }
    if (congelado.length > 0) {
        return false;
    }
    if (core.length > 0) {
        return false;
    }
    if (snack.length > 0 && restaurante.length > 0) {
        return false;
    }

    if (cena.length > 0 && restaurante.length > 0) {
        return false;
    }

    if (snack.length > 0 && cena.length > 0) {
        return false;
    }

    if (snack.length > 0 && restaurante.length > 0 && cena.length > 0) {
        return false;
    }

    return true;
};

// Get Trending Tag wise Collection
export const getTrendingTagCollection = (products, type, tag) => {
    const items = products.filter((product) => {
        return product.category === type && product.tags.includes(tag);
    });
    return items.slice(0, 8);
};

// Get Trending Collection
export const getTrendingCollection = (products, type) => {
    const items = products.filter((product) => {
        return product.category === type;
    });
    return items.slice(0, 8);
};

// Get Special 5 Collection
export const getSpecialCollection = (products, type) => {
    const items = products.filter((product) => {
        return product.category === type;
    });
    return items.slice(0, 5);
};

// Get TOP Collection
export const getTopCollection = (products) => {
    const items = products.filter((product) => {
        return product.rating > 4;
    });
    return items.slice(0, 8);
};

// Get New Products
export const getNewProducts = (products, type) => {
    const items = products.filter((product) => {
        return product.new === true && product.category === type;
    });

    return items.slice(0, 8);
};

// Get Related Items
export const getRelatedItems = (products, type) => {
    const items = products.filter((product) => {
        return product.category === type;
    });

    return items.slice(0, 4);
};

// Get Best Seller Furniture
export const getBestSellerProducts = (products, type) => {
    const items = products.filter((product) => {
        return product.sale === true && product.category === type;
    });

    return items.slice(0, 8);
};

// Get Best Seller
export const getBestSeller = (products) => {
    const items = products.filter((product) => {
        return product.sale === true;
    });

    return items.slice(0, 8);
};

// Get Mens Wear
export const getMensWear = (products) => {
    const items = products.filter((product) => {
        return product.category === 'men';
    });

    return items.slice(0, 8);
};

// Get Womens Wear
export const getWomensWear = (products) => {
    const items = products.filter((product) => {
        return product.category === 'women';
    });

    return items.slice(0, 8);
};

// Get Single Product
export const getSingleItem = (products, id) => {
    const items = products.find((element) => {
        return element.id === id;
    });
    return items;
};

// Get Feature Products
export const getFeatureImages = (products, type) => {
    const items = products.filter((product) => {
        return product.type === type;
    });
    return items;
};

export const getTipoCategoria = (tipo) => {
    const categorias = store.getState().categories.categories;

    const tipo_cate = categorias.filter((value) => {
        return value.tipo == tipo;
    });

    return tipo_cate;
};

export const limpiarCarrito = () => {
    store.dispatch(clearPlan());
    store.dispatch(clearRestaurante());
    store.dispatch(clearHuerta());
    store.dispatch(clearCongelado());
    store.dispatch(clearCore());
    store.dispatch(clearSnack());
    store.dispatch(clearCena());
    store.dispatch(clearExternal());
};

export const validateRestaurante = (cantidad) => {
    const cart = store.getState().restaurante.cart;
    let cant = cantidad;

    cart.forEach((item) => {
        cant += item.qty;
    });

    if (cant <= 100) {
        return true;
    } else {
        return false;
    }
};

export const validateCena = (cantidad) => {
    const cart = store.getState().cena.cart;
    let cant = cantidad;

    cart.forEach((item) => {
        cant += item.qty;
    });

    if (cant <= 100) {
        return true;
    } else {
        return false;
    }
};

export const validatePlan = (cantidad) => {
    const cart = store.getState().cartList.cart;
    let cant = cantidad;

    cart.forEach((item) => {
        cant += item.qty;
    });

    if (cant <= 100) {
        return true;
    } else {
        return false;
    }
};

export const validateHuerta = (cantidad) => {
    const cart = store.getState().huerta.cart;
    let cant = cantidad;

    cart.forEach((item) => {
        cant += item.qty;
    });

    if (cant <= 100) {
        return true;
    } else {
        return false;
    }
};

export const validateCongelado = (cantidad) => {
    const cart = store.getState().congelado.cart;
    let cant = cantidad;

    cart.forEach((item) => {
        cant += item.qty;
    });

    if (cant <= 100) {
        return true;
    } else {
        return false;
    }
};

export const validateCore = (cantidad) => {
    const cart = store.getState().core.cart;
    let cant = cantidad;

    cart.forEach((item) => {
        cant += item.qty;
    });

    if (cant <= 100) {
        return true;
    } else {
        return false;
    }
};

export const validateSnack = (cantidad) => {
    const cart = store.getState().snack.cart;
    let cant = cantidad;

    cart.forEach((item) => {
        cant += item.qty;
    });

    if (cant <= 100) {
        return true;
    } else {
        return false;
    }
};

export const getPostre = (id_add) => {
    const add_postre = store.getState().data.postres;

    return add_postre.find((value) => value.id == id_add);
};

export const getBebida = (id_add) => {
    const add_bebida = store.getState().data.bebidas;

    return add_bebida.find((value) => value.id == id_add);
};

export const calcularRestaurante = (item) => {
    let price = 0;

    price += item.price * item.qty;

    if (item.postre) {
        price += getPostre(item.postre).price * item.num_postre;
    }
    if (item.bebida) {
        price += getBebida(item.bebida).price * item.num_bebida;
    }

    return price;
};

export const getArrayAdicional = (postre, bebida) => {
    let array = '';

    if (postre && !bebida) {
        array = '[' + postre + ']';
    }

    if (bebida && !postre) {
        array = '[' + bebida + ']';
    }

    if (postre && bebida) {
        array = '[' + postre + ',' + bebida + ']';
    }

    if (array.length > 0) {
        return array;
    } else {
        return null;
    }
};

export const cargarPostres = async () => {
    try {
        const response = await getData('postres');
        store.dispatch(updatePostre(response.data));
    } catch (error) {
        console.log(error);
    }
};

export const cargarBebidas = async () => {
    try {
        const response = await getData('bebidas');
        store.dispatch(updateBebida(response.data));
    } catch (error) {
        console.log(error);
    }
};

export const cargarHalls = async () => {
    try {
        const response = await getData('halls');
        store.dispatch(updateHalls(response.data));
    } catch (error) {
        console.log(error);
    }
};

export const cargarCategories = async () => {
    try {
        const response = await getData('categories');
        store.dispatch(updateCategories(response.data));
    } catch (error) {
        console.log(error);
    }
};

export const cargarOrders = async () => {
    const user = store.getState().customer.data;

    if (user) {
        getData('usuario/ordenes/?id=' + user.id).then((req) => {
            store.dispatch(updateOrden(req.data));
        });
    }
};

export const cargarDatos = () => {
    const user = store.getState().customer.data;

    axios
        .all([getData('categories'), getData('postres'), getData('bebidas'), getData('halls'), getData('allies')])
        .then(
            axios.spread((res1, res2, res3, res4, res5) => {
                store.dispatch(getAllProducts());
                store.dispatch(updateCategories(res1.data));
                store.dispatch(updatePostre(res2.data));
                store.dispatch(updateBebida(res3.data));
                store.dispatch(updateHalls(res4.data));
                store.dispatch(updateAliado(res5.data));
                console.log('data paso');
            })
        )
        .catch(() => {
            if (store.getState().categories.categories.length == 0) {
                setTimeout(() => {
                    window.location.reload();
                }, 1900);
            }
        });

    if (user) {
        let newData = user;

        getData('usuario/ordenes/?id=' + user.id).then((req) => {
            store.dispatch(updateOrden(req.data));
        });

        getData('saldo/?id=' + user.id).then((req) => {
            newData.saldo = parseFloat(req.data.saldo);
            store.dispatch(updateUser(newData));
        });
    }
};

export const updateSaldo = () => {
    const user = store.getState().customer.data;

    if (user) {
        let newData = user;

        getData('saldo/?id=' + user.id).then((req) => {
            newData.saldo = parseFloat(req.data.saldo);
            store.dispatch(updateUser(newData));
        });
    }
};

export const refreshToken = () => {
    if (store.getState().customer.data) {
        const config = {
            headers: store.getState().customer.headers,
        };

        console.log('header inicial', config);
        postData('re-token', {}, config)
            .then((response) => {
                store.dispatch(updateHeaders(response.headers));
                console.log('header refrescado:', response.headers);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.errors.includes('You need to sign in or sign up before continuing.')) {
                        console.log('token murio');
                        store.dispatch(clearOrden());
                        store.dispatch(dataLogout());
                    } else {
                        console.log(error);
                    }
                } else {
                    console.log(error);
                }
            });
    }
};

export const getNumberPostre = (product) => {
    if (product.postre) {
        return product.num_postre;
    } else {
        return 0;
    }
};

export const getNumberBebida = (product) => {
    if (product.bebida) {
        return product.num_bebida;
    } else {
        return 0;
    }
};

export const postreByHall = (id) => {
    const array_postre = store.getState().data.postres;

    return array_postre.filter((value) => {
        return value.hall_id == id;
    });
};

export const bebidaByHall = (id) => {
    const array_bebida = store.getState().data.bebidas;

    return array_bebida.filter((value) => {
        return value.hall_id == id;
    });
};

export const getMatchDelivery = () => {
    const delivery = store.getState().delivery;

    if (isChia()) {
        return delivery.chia;
    } else {
        return delivery.core_huerta;
    }
};
