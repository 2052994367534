import React, { Component } from 'react'
import { getTotalOrden } from '../../../../../servicios'
import {getDiaMesEspanolHuertaCongelado} from '../../../../../funciones/momentEspañol'

export class OrdenCongelado extends Component {
    render() {

        const {productos}=this.props

        if(productos.length>0){

            return (
                <div>
                    
                    <h5 className="f-w-600 bold center">Ultra Congelados</h5>

                    <div className="row">
                        <div className="col-4">
                            <strong>Producto</strong>
                        </div>
                        <div className="col-8">
                            <strong>Costo</strong>                       
                        </div>
                    </div>
                    <br/>

                    {
                        productos.map((item, index) => {
                            return (

                                <div key={index}>

                                    <div className="row" >
                                        <div className="col-4">
                                            {item.producto} (×{item.amount})  
                                        </div>
                                        <div className="col-8">
                                            ${(item.total)}
                                        </div>
                                    </div>

                                </div>
                            
                            )
                        })
                    }

                    <div className="row">
                        <div className="col-4">
                            <strong>Entrega:</strong>
                        </div>
                        <div className="col-4">
                            {getDiaMesEspanolHuertaCongelado(productos[0].fecha_i)} a las {productos[0].hora}                 
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Delivery:</strong>
                        </div>
                        <div className="col-4">
                            ${productos[0].delivery}    
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">
                            ${getTotalOrden(productos,5)}             
                        </div>
                    </div>

                    <br/>
                 
                </div>
            )

        }else{
            return ''
        }
        
    }
}

export default OrdenCongelado
