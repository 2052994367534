import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cities, Countries } from '../../constants/data';
import {
    getInitialCity,
    getInitialCountry,
    getLocationProducts,
    isActualDirectionLocal,
    isDirectionLocal,
} from '../../funciones/cities';
import { updateLocation } from '../../actions/index';
import { limpiarCarrito } from '../../services';
import { getData } from '../../servicios';
import { addToCart, addToWishlist, addToCompare, filterCategory } from '../../actions';
import DProductListItemSugerido from '../collection/common/DProducListItemSugerido';

export class DSugeridos extends Component {
    constructor(props) {
        super(props);
        this.state = { data: [], error: false, load: true };
    }

    async componentDidMount() {
        try {
            const response = await getData('reco-products');
            const data = response['data'];

            this.setState({
                data: data,
                load: false,
            });
        } catch (error) {
            console.log(error);

            this.setState({
                load: false,
                error: true,
            });
        }
    }

    render() {
        const { load, data, error } = this.state;
        const { addToCart, symbol, addToWishlist, addToCompare, products } = this.props;

        const list_products = products.filter((producto) => data.some((value) => value.id == producto.id));

        console.log(list_products);
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal fluio-font">
                    <div className="d-sugerido-box">
                        <div className="header-title-d-sugerido tac-desk">No olvides llevar</div>
                        {load ? (
                            <div className="row">
                                <div className="col-md-12 text-center section-b-space mt-5 no-found">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`}
                                        className="img-fluid mb-4"
                                    />
                                    <h3>Cargando</h3>
                                </div>
                            </div>
                        ) : error ? (
                            <div className="tac error-sugerido">No se pudo cargar productos</div>
                        ) : (
                            <div className="container-fluid">
                                <div className="row">
                                    {list_products.map((product, index) => (
                                        <div className={'col-xl-4 col-md-6 col-6 mt-4'} key={index}>
                                            <DProductListItemSugerido
                                                product={product}
                                                symbol={symbol}
                                                onAddToCompareClicked={() => addToCompare(product)}
                                                onAddToWishlistClicked={() => addToWishlist(product)}
                                                onAddToCartClicked={addToCart}
                                                key={index}
                                                products_length={list_products.length}
                                                index={index}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <a href={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-suge-cont">
                        Continuar
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    products: getLocationProducts(),
    symbol: state.data.symbol,
});

export default connect(
    mapStateToProps,
    { addToCart, addToWishlist, addToCompare, filterCategory }
)(withRouter(DSugeridos));
