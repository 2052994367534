import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { filterCategoryupdateLoadHome } from '../../actions';
import GoogleLogin from 'react-google-login';
import Google from './Buttons/Google';
import { getData, postData } from '../../servicios';

class GoogleLoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //id:"947705782989-ud93aaqo88aepsq7435o8c6fgnjb2hf1.apps.googleusercontent.com" //web test
            id: "8068158134-9sjh4ut8qicc6b8u52t3men3oflkb515.apps.googleusercontent.com" //web production
        };
    }

    componentDidMount(){
    }

    googleSuccess = async (e) => {

        console.log(e)

        let json={
            access_token:e.accessToken
        }
        console.log(json)
        const redURL='http://localhost:3000/login'

        try{
            const response= await getData(`customer_auth/google_oauth2/callback`,{})
            //this.props.dataLogin(response['data'].data,response.headers)

            console.log(response)


        }catch(err){
            this.setState({
                loginbool:true
            })
        }
        
    }

    googleFail = (e) => {

        console.log(e)
        
    }
    
    

    render() {

        
        return (
            <div >
                <GoogleLogin
                    clientId={this.state.id}
                    buttonText="Login con google"
                    cookiePolicy={'single_host_origin'}
                    onSuccess={this.googleSuccess}
                    onFailure={this.googleFail}
                    uxMode="popup"
                    className="google"
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

export default connect(
    mapStateToProps,
    {  }
)(withRouter(GoogleLoginComponent));