import {
  ADD_TO_EXTERNAL,
  REMOVE_FROM_EXTERNAL,
  DECREMENT_EXTERNAL,
  UPDATE_HORA_EXTERNAL,
  CLEAR_EXTERNAL,
  ACTUALIZAR_EXTERNAL,
} from "../constants/ActionTypes";

export default function externalReducer(
  state = {
    cart: [],
    programacion: "",
  },
  action
) {
  switch (action.type) {
    case ADD_TO_EXTERNAL:
      const productId = action.product.id;
      if (state.cart.findIndex((product) => product.id === productId) !== -1) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.id === productId) {
            cartAcc.push({
              ...product,
              qty: product.qty + 1,
              sum:
                (product.price - (product.price * product.discount) / 100) *
                (product.qty + 1),
            }); // Increment qty
          } else {
            cartAcc.push(product);
          }

          return cartAcc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum:
              ((action.product.price * action.product.discount) / 100) *
              action.qty,
          },
        ],
        programacion: action.programacion,
      };

    case DECREMENT_EXTERNAL:
      if (
        state.cart.findIndex((product) => product.id === action.productId) !==
        -1
      ) {
        const cart = state.cart.reduce((cartAcc, product) => {
          if (product.id === action.productId && product.qty > 1) {
            //console.log('price: '+product.price+'Qty: '+product.qty)
            cartAcc.push({
              ...product,
              qty: product.qty - 1,
              sum:
                (product.price - (product.price * product.discount) / 100) *
                (product.qty - 1),
            }); // Decrement qty
          } else {
            cartAcc.push(product);
          }

          return cartAcc;
        }, []);

        return { ...state, cart };
      }

      return {
        ...state,
        cart: [
          ...state.cart,
          {
            ...action.product,
            qty: action.qty,
            sum: action.product.price * action.qty,
          },
        ],
      };

    case REMOVE_FROM_EXTERNAL:
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.product_id.id),
      };
    case UPDATE_HORA_EXTERNAL:
      return {
        ...state,
        programacion: action.programacion,
      };
    case CLEAR_EXTERNAL:
      return {
        ...state,
        cart: [],
      };
    case ACTUALIZAR_EXTERNAL:
      const productId2 = action.product.id;
      const cart = state.cart.reduce((cartAcc, product) => {
        if (product.id === productId2) {
          cartAcc.push({ ...product }); // actualiza el producto
        } else {
          cartAcc.push(product);
        }

        return cartAcc;
      }, []);

      return { ...state, cart };

    default:
  }
  return state;
}
