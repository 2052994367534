import React, {Component} from 'react';
import AliadoListing from "./common/aliado-listing";
import {filterCategory} from '../../actions/index'
import { connect } from 'react-redux';
import CategoriesSlider from '../common/CategoriesSlider';
import {cargarStorage} from '../../funciones/cargarDatos'
import Banner from '../common/Banner';

class CollectionAliado extends Component {

    state = {
        layoutColumns:3
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = (e) => {
		e.preventDefault()
		this.props.filterCategory([]);
		this.props.history.push("/productos/"+this.state.text)
    }

    componentDidMount(){
        cargarStorage()
    }

    render (){

        const {categories,loading_products}=this.props

        const categoria_id=this.props.match.params.category

        let categoria=categories.filter((value)=>{
            return value.seo==categoria_id
        })

        return (
            <div>

                <Banner/>

                <CategoriesSlider categoria_id={categoria_id}/>

                <div>
                    <h3 className="center mb-center90 ttn">{categoria.length>0 ? categoria[0].name : ''}</h3>
                </div>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">

                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-xl-12">

                                                                    </div>
                                                                </div>
                                                                <div className="row">

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {
                                                            loading_products==false?

                                                                /*Products Listing Component*/
                                                                <AliadoListing 
                                                                    colSize={this.state.layoutColumns} 
                                                                    allies_ids={categoria.length>0 ? categoria[0].aliados : []}
                                                                    categoria={categoria_id}
                                                                />

                                                            :

                                                                <div className="row">
                                                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                                                        <h3>Cargando</h3>
                                                                    </div>
                                                                </div>
                                                            
                                                        }

                                                        

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categories: state.categories.categories,
    loading_products:state.data.loading_products
})

export default connect(
    mapStateToProps,
    {filterCategory}
)(CollectionAliado)