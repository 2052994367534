import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import { getData, getCartLength } from '../../../../servicios';
import { filterCategory, updateCategories,clearOrden,dataLogout } from '../../../../actions';
import { connect } from 'react-redux';
import store from '../../../../store';
import cartIcon from '../.././../../assets/images/cart.png'

class HomeNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' },
            categories:[]
        }
    }

    async componentWillMount() {
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } })
        }
    }

    openNav() {
        this.setState({ navClose: { right: '0px' } })
        let cuenta=document.getElementById("cuenta")
        if(this.props.ordenes>0 && !cuenta.nextElementSibling.classList.contains('opensubmenu') ){
            cuenta.click()
        }
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } })
    }

    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector("#main-menu").classList.add("hover-unset");
        }
    }

    handleSubmenu = (event) => {
        event.preventDefault()
        if (event.target.classList.contains('sub-arrow'))
            return;

        if(event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu')
        else{
            document.querySelectorAll('.nav-submenu').forEach(function (value) {
                value.classList.remove('opensubmenu');
            });
            //document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu')
        }
    }

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;
            
        if(event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
        else{
            document.querySelectorAll('.menu-content').forEach(function (value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
        }
    }

    goByCategory = (e,category) => {
        e.preventDefault()
        this.closeNav()
        //this.props.filterCategory([category])
        this.props.history.push("/categoria/"+category)
        //window.location.reload()
    }

    goByPasillo = (e,pasillo) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/pasillo/"+pasillo)
        //window.location.reload()
    }

    goProducts = (e) => {
        e.preventDefault()
        this.props.filterCategory([])
        this.props.history.push("/productos")
        //window.location.reload()
    }

    goAllAliados = (e) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/nuestros-aliados")
    }

    goPerfil = (e) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/profile")
    }

    goPedidos = (e) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/orders")
    }

    goRepeat = (e) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/orders?tab=2")
    }

    goLogin = (e) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/login")
    }

    goRegister = (e) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/register")
    }

    goAllAliados = (e) => {
        e.preventDefault()
        this.closeNav()
        this.props.history.push("/nuestros-aliados")
    }

    goTYC = (e) => {

        e.preventDefault()
        this.closeNav()
        this.props.history.push("/terminos-y-condiciones")
        
    }

    logout = (e) => {
        e.preventDefault()

        const {dataLogout,clearOrden}=this.props
        clearOrden()
        dataLogout()
        this.props.history.push(process.env.PUBLIC_URL+"/")
    
    }
    
    
    


    render() {
        const { categories,tamaño,customer,ordenes,halls } = this.props;


        const list_menu_categories=categories.filter((value)=>value.pri<77).map((val_c,in_c)=>{

            return(

                <div className="col mega-box" key={in_c}>
                    <div className="link-section">
                        <div className="menu-title" >

                            <div className="menu-title mb-2" style={{display: 'flex'}} >
                                <h5 onClick={(e)=>{this.goByCategory(e,val_c.seo)}} className="menu-category-label" >
                                    {val_c.name}                                 
                                </h5>
                                <span className="pasillo-plus nav-display2" onClick={(e) => this.handleMegaSubmenu(e)}>+</span>
                            </div>

                            <div className="menu-content">
                                <ul>
                                    {
                                        halls.filter(hall=>hall.category_id==val_c.id).map((val,i)=>
                                            <li key={i}>
                                                <Link to={`${process.env.PUBLIC_URL}/#`} onClick={(e)=>{this.goByPasillo(e,val.seo)}}>{val.name}</Link>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            )
            
        })


        return (
            <div>

                <div className="main-navbar2">
                    <div id="mainnav" >
                        <ul className="nav-menu">

                            <li className="" >
                                <Link to="/" className="nav-link ttn home-link-nav" >
                                    Inicio
                                </Link>
                            </li>

                            <li className="" >
                                <Link to="#" className="nav-link ttn home-link-nav" onClick={this.goAllAliados}>
                                    Aliados
                                </Link>
                            </li>

                            <li className="normal-menu">
                                <Link to="#" className="nav-link ttn home-link-nav" onClick={(e) => this.handleSubmenu(e)}>
                                    Contacto
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <p>
                                        <li>
                                            <a href="tel:+573107139466" className="ttn">
                                                <i className="fa fa-phone"></i>
                                                (+57) 3107139466
                                            </a>
                                        </li>
                                        {/*
                                        <li>
                                            <a href="#">
                                                <i className="fa fa-phone"></i>
                                                123456
                                            </a>
                                        </li>
                                        */}
                                        <li>
                                            <a href="mailto:info@fluio.com.co" className="ttn">
                                                <i className="fa fa-envelope-o"></i>
                                                info@fluio.com.co
                                            </a>
                                        </li>

                                    </p>
                                </ul>
                            </li>
                            {/*
                            <li>
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    Ayuda
                                </Link>
                            </li>
                            */}
                            
                            <li className="mega-menu">
                                <Link to="#" className="dropdown home-link-nav" onClick={(e) => this.handleSubmenu(e)}>
                                    Categorias
                                    <span className="sub-arrow"></span>
                                </Link>
                                <div className="mega-menu-container">
                                    <div className="container">
                                        <div className="row">

                                            {list_menu_categories}

                                        </div>
                                    </div>
                                </div>                             
                            </li>


                            

                            <li className="mb-display" >
                                <Link to="/terminos-y-condiciones" className="nav-link ttn" onClick={this.goTYC} >
                                    Términos y condiciones
                                </Link>
                            </li>
                        
                            {/*
                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {'shop'}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >{('category_left_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/right-sidebar/collection`} >{('category_right_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/no-sidebar/collection`} >{('category_no_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/metro/collection`} >{('category_metro')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/full-width/collection`} >{('category_full_width')}</Link></li>
                                </ul>
                            </li>
                            
                            
                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {('products')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/product/1`} >{('left_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/right-sidebar/product/1`} >{('right_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/no-sidebar/product/1`} >{('no_sidebar')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/col-left/product/1`} >{('three_col_thumbnail_left')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/col-right/product/1`} >{('three_col_thumbnail_right')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/column/product/1`} >{('thumbnail_below')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/left-image/product/1`} >{('thumbnail_left')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/right-image/product/1`} >{('thumbnail_right')}</Link></li>
                                </ul>
                            </li>
                            */}
                                                   
                            {/*
                            <li className="mega-menu">
                                <Link to="#" className="dropdown" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('features')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <div className="mega-menu-container" >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('portfolio')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/2`} >{translate('portfolio_grid_2')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/3`} >{translate('portfolio_grid_3')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-grid/4`} >{translate('portfolio_grid_4')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-masonary/2`} >{translate('portfolio_masonary_2')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-masonary/3`} >{translate('portfolio_masonary_3')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-masonary/4`} >{translate('portfolio_masonary_4')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/portfolio-masonary/full`} >{translate('portfolio_masonary_full')}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('theme_elements')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content">
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-title`} >{translate('element_title')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-banner`} >{translate('collection_banner')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-slider`} >{translate('home_slider')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-category`} >{translate('category')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-service`} >{translate('service')}</Link></li>
                                                            {/*<li><Link to={`${process.env.PUBLIC_URL}/features/element-ratio`} >{translate('image_size_ratio')}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('product_elements')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content" >
                                                        <ul>
                                                            <li className="up-text"><Link to={`${process.env.PUBLIC_URL}/features/element-product-box`} >{translate('product_box')}<span>10+</span></Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-product-slider`} >{translate('product_slider')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-product-no-slider`} >{translate('no_slider')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-product-multiple-slider`} >{translate('multi_slider')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/features/element-product-tab`} >{translate('tab')}</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('email_template')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content" >
                                                        <ul>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/email-template.html`} target="_blank">{translate('order_success')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/email-template-two.html`} target="_blank">{translate('order_success')}2</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/email-order-success.html`} target="_blank">{translate('email_template')}</Link></li>
                                                            <li><Link to={`${process.env.PUBLIC_URL}/email-order-success-two.html`} target="_blank">{translate('email_template')}2</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title">
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('accessories')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content" >
                                                        <ul>
                                                            <li><a href="#">{translate('fashion_jewellery')}</a></li>
                                                            <li><a href="#">{translate('caps_and_hats')}</a></li>
                                                            <li><a href="#">{translate('precious_jewellery')}</a></li>
                                                            <li><a href="#">{translate('necklaces')}</a></li>
                                                            <li><a href="#">{translate('earrings')}</a></li>
                                                            <li><a href="#">{translate('rings_wrist_wear')}</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col mega-box">
                                                <div className="link-section">
                                                    <div className="menu-title" >
                                                        <h5 onClick={(e) => this.handleMegaSubmenu(e)}>
                                                            {translate('men_accessories')}
                                                            <span className="sub-arrow"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="menu-content" >
                                                        <ul>
                                                            <li><a href="#">{translate('ties')}</a></li>
                                                            <li><a href="#">{translate('cufflinks')}</a></li>
                                                            <li><a href="#">{translate('pockets_squares')}</a></li>
                                                            <li><a href="#">{translate('helmets')}</a></li>
                                                            <li><a href="#">{translate('scarves')}</a></li>
                                                            <li><a href="#">{translate('phone_cases')}</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </li>
                            */}
                            {/*
                            <li>
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {translate('pages')}
                                    <span className="sub-arrow"></span>
                                </Link>
                                <ul className="nav-submenu">
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/about-us`} >{translate('about_us')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/404`} >404</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/lookbook`} >{translate('lookbook')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/login`} >{translate('login')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/register`} >{translate('register')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/search`} >{translate('search')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/collection`} >{translate('collection')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/forget-password`} >{translate('forget_password')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/contact`} >{translate('contact')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/dashboard`} >{translate('dashboard')}</Link></li>
                                    <li><Link to={`${process.env.PUBLIC_URL}/pages/faq`} >{translate('FAQ')}</Link></li>
                                </ul>
                            </li>
                            */}
                            {/*
                            <li >
                                <Link to="#" className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                                    {'blog'}
                                </Link>
                            </li>
                            */}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
    categories: state.categories.categories,
    halls: state.halls.halls,
    tamaño: getCartLength(),
    customer: state.customer.data,
    ordenes: state.orden.pendientes.length,
    //ordenes: 1
})

export default connect(
    mapStateToProps,
    { filterCategory,updateCategories,clearOrden,dataLogout }
)(withRouter(HomeNav));