import React, { Component } from 'react';
import { isUnaHoraUntil, isAfterHora, validAllies } from '../../funciones/general';
import {
    getListaHorasRepeticionCore,
    getArrayDateCore,
    getDateOrdenadoHuertaCongelado,
    detFechaPatronSnack,
    detRadioPatronSnack,
    initialHourSnack,
    getArrayPatronSnack,
    detFechaPatronCore,
    detRadioPatronCore,
    initialHourCore,
    getArrayPatronCore,
    getNextMartes,
    getNextJueves,
    getNextSabado,
    getTimeRange,
    getArrayDateExternal,
    getFechaPatronHuertaYCongelado,
    transformDateHuertaToCore,
} from '../../funciones/patrones';
import horasCore from '../../api/horarios/tipoCore.json';
import horasSnack from '../../api/horarios/tipoSnack.json';
import horasHuerta from '../../api/horarios/tipoHuerta.json';
import { updateCore, updateHuerta, updateSnack, updateExternal } from '../../actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDiffHoraria, getDiffMinutes } from '../../servicios';
import * as moment from 'moment';
import { getDiaMesEspanol, getDiaMesEspanolHuertaCongelado } from '../../funciones/momentEspañol';
import { isOrderCoreHuerta } from '../../funciones/checkout';
import Modal from 'react-responsive-modal';
import DeliveryForm from '../Modals/DeliveryForm';

class ProgramingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            radioCore: detRadioPatronCore(horasCore),
            listadoCore: getArrayPatronCore(horasCore),
            fechaCore: detFechaPatronCore(horasCore),
            horaCore: '',

            radioSnack: detRadioPatronSnack(horasSnack),
            listadoSnack: getArrayPatronSnack(horasSnack),
            fechaSnack: detFechaPatronSnack(horasSnack),
            horaSnack: '',

            horaHuerta: horasHuerta[0].hora,
            radioHuerta: this.getRadioValue(getDateOrdenadoHuertaCongelado()[0].day),

            fechaExternal: getArrayDateExternal()[0],
            openProgramingType: isOrderCoreHuerta(),

            programingType: 'diffDay',
        };
    }

    componentDidMount() {
        this.setState(
            {
                horaSnack: initialHourSnack(horasSnack, this.state.fechaSnack),
                horaCore: initialHourCore(horasCore, this.state.fechaCore),
            },
            () => {
                this.submit();
            }
        );
    }

    closeProgramingType = () => {
        this.setState({ openProgramingType: false });
    };

    updatePrograming = (val) => {
        this.setState({
            programingType: val,
        });

        if (val == 'sameDay') {
            const fechaHuerta = getFechaPatronHuertaYCongelado(
                this.getRadioValue(getDateOrdenadoHuertaCongelado()[0].day)
            );
            const fechaCore = transformDateHuertaToCore(fechaHuerta);
            this.setState(
                {
                    radioHuerta: this.getRadioValue(getDateOrdenadoHuertaCongelado()[0].day),
                    fechaCore: fechaCore,
                    fechaSnack: fechaCore,
                    horaHuerta: horasHuerta[0].hora,
                    horaCore: horasHuerta[0].hora,
                    horasSnack: horasHuerta[0].hora,
                    radioCore: 2,
                    radioSnack: 2,
                },
                () => {
                    this.submit();
                }
            );
        }
    };

    getRadioValue = (day) => {
        switch (day) {
            case 'martes':
                return 'mar';
            case 'jueves':
                return 'jue';
            case 'sabado':
                return 'sab';
            default:
                return 'sab';
        }
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        const component = this;

        this.setState(
            {
                [name]: value,
            },
            () => {
                this.submit();
            }
        );
    };

    handleChangeSameDay = (e) => {
        const { name, value } = e.target;
        const component = this;

        this.setState(
            {
                [name]: value,
            },
            () => {
                this.submitSameDay();
            }
        );
    };

    handleChangeSameDay = (e) => {
        const { name, value } = e.target;
        const component = this;

        this.setState(
            {
                [name]: value,
            },
            () => {
                this.submitSameDay();
            }
        );
    };

    handleHoraSnack = (e) => {
        const { value } = e.target;
        const today = moment().format('YYYY-MM-DD');

        //caso mismo dia
        if (today == value) {
            const list_horas = horasSnack.filter((value, index) => {
                return getDiffMinutes(value.hora) > 170;
            });

            //para llenar las horas al escoger una fecha
            if (list_horas.length > 0) {
                this.setState(
                    {
                        listadoSnack: list_horas,
                        horaSnack: list_horas[0].hora,
                    },
                    () => {
                        this.submit();
                    }
                );
                //caso que deja la lista vacio si no hay horas disponibles
            } else {
                this.setState(
                    {
                        listadoSnack: list_horas,
                    },
                    () => {
                        this.submit();
                    }
                );
            }

            //caso un dia programado en el futuro
        } else {
            this.setState(
                {
                    listadoSnack: horasSnack,
                    horaSnack: horasSnack[0].hora,
                },
                () => {
                    this.submit();
                }
            );
        }
    };

    handleHora = (e) => {
        const { value } = e.target;
        const today = moment().format('YYYY-MM-DD');

        //caso mismo dia
        if (today == value) {
            const list_horas = horasCore.filter((value, index) => {
                return getDiffMinutes(value.hora) > 170;
            });

            //para llenar las horas al escoger una fecha
            if (list_horas.length > 0) {
                this.setState(
                    {
                        listadoCore: list_horas,
                        horaCore: list_horas[0].hora,
                    },
                    () => {
                        this.submit();
                    }
                );
                //caso que deja la lista vacio si no hay horas disponibles
            } else {
                this.setState(
                    {
                        listadoCore: list_horas,
                    },
                    () => {
                        this.submit();
                    }
                );
            }

            //caso un dia programado en el futuro
        } else {
            this.setState(
                {
                    listadoCore: horasCore,
                    horaCore: horasCore[0].hora,
                },
                () => {
                    this.submit();
                }
            );
        }
    };

    submit = () => {
        const {
            radioSnack,
            fechaSnack,
            horaSnack,

            radioCore,
            fechaCore,
            horaCore,

            radioHuerta,
            horaHuerta,

            fechaExternal,
        } = this.state;

        const { updateCore, updateSnack, updateHuerta, updateExternal } = this.props;

        let programacionSnack;

        if (radioSnack == 1) {
            programacionSnack = 1;
        } else {
            programacionSnack = {
                hora: horaSnack,
                fecha: fechaSnack,
            };
        }

        updateSnack(programacionSnack);

        let programacionCore;

        if (radioCore == 1) {
            programacionCore = 1;
        } else {
            programacionCore = {
                hora: horaCore,
                fecha: fechaCore,
            };
        }

        updateCore(programacionCore);

        let programacionHuerta;

        programacionHuerta = {
            hora: horaHuerta ? horaHuerta : horasHuerta[0].hora,
            dia: radioHuerta,
        };

        updateHuerta(programacionHuerta);

        let programacionExternal;

        programacionExternal = {
            fecha: fechaExternal,
        };

        updateExternal(programacionExternal);
    };

    submitSameDay = () => {
        const {
            radioCore,
            horaCore,

            radioHuerta,
            horaHuerta,
        } = this.state;

        const { updateCore, updateSnack, updateHuerta } = this.props;

        const fechaHuerta = getFechaPatronHuertaYCongelado(radioHuerta);
        const fechaCore = transformDateHuertaToCore(fechaHuerta);

        console.log(fechaCore);

        let programacionSnack;

        programacionSnack = {
            hora: horaHuerta,
            fecha: fechaCore,
        };

        updateSnack(programacionSnack);

        let programacionCore;

        programacionCore = {
            hora: horaHuerta,
            fecha: fechaCore,
        };

        updateCore(programacionCore);

        let programacionHuerta;

        programacionHuerta = {
            hora: horaHuerta,
            dia: radioHuerta,
        };

        updateHuerta(programacionHuerta);
    };

    handleRadioSnack = (event) => {
        const { value } = event.target;
        this.setState(
            {
                radioSnack: value,
            },
            () => {
                this.submit();
            }
        );
    };

    handleRadioCore = (event) => {
        const { value } = event.target;
        this.setState(
            {
                radioCore: value,
            },
            () => {
                this.submit();
            }
        );
    };

    getRadioCheck = (day) => {
        const { radioHuerta } = this.state;

        switch (day) {
            case 'martes':
                return radioHuerta == 'mar';
            case 'jueves':
                return radioHuerta == 'jue';
            case 'sabado':
                return radioHuerta == 'sab';
            default:
                return radioHuerta == 'sab';
        }
    };

    getRadioDate = (day) => {
        switch (day) {
            case 'martes':
                return getDiaMesEspanolHuertaCongelado(getNextMartes());
            case 'jueves':
                return getDiaMesEspanolHuertaCongelado(getNextJueves());
            case 'sabado':
                return getDiaMesEspanolHuertaCongelado(getNextSabado());
            default:
                return getDiaMesEspanolHuertaCongelado(getNextSabado());
        }
    };

    getExteralPrograming = () => {
        const { external } = this.props;

        if (external.length > 0) {
            const list_fechas_external = getArrayDateExternal().map((value, index) => {
                return (
                    <option value={value} key={index}>
                        {getDiaMesEspanol(value)}
                    </option>
                );
            });

            return (
                <>
                    <div className="form-row margin-zero">
                        <div className="form-group col-12 pl-0">
                            <select
                                name="fechaExternal"
                                onChange={(e) => {
                                    this.handleChange(e);
                                }}
                                value={this.state.fechaExternal || ''}
                                className="form-control input-style"
                                required
                            >
                                {list_fechas_external}
                            </select>
                        </div>
                    </div>
                </>
            );
        }

        return <></>;
    };

    renderNormalPrograming = () => {
        const { snack, core, huerta } = this.props;
        const { listadoSnack, listadoCore } = this.state;

        const list_horas_core = listadoCore.map((value, index) => {
            return (
                <option value={value.hora} key={index}>
                    {getTimeRange(value.hora)}
                </option>
            );
        });

        const list_fechas_core = getArrayDateCore(horasCore).map((value, index) => {
            return (
                <option value={value} key={index}>
                    {getDiaMesEspanol(value)}
                </option>
            );
        });

        const list_horas_snack = listadoSnack.map((value, index) => {
            return (
                <option value={value.hora} key={index}>
                    {getTimeRange(value.hora)}
                </option>
            );
        });

        const list_fechas_snack = getArrayDateCore(horasSnack).map((value, index) => {
            return (
                <option value={value} key={index}>
                    {getDiaMesEspanol(value)}
                </option>
            );
        });

        const list_horas_huerta = horasHuerta.map((value, index) => {
            return (
                <option value={value.hora} key={index}>
                    {value.hora}
                </option>
            );
        });

        const list_radio_huerta = getDateOrdenadoHuertaCongelado().map((value, i) => {
            return (
                <label className="container-radio mb-4" key={i}>
                    {this.getRadioDate(value.day)}

                    <input
                        type="radio"
                        name="radioHuerta"
                        value={this.getRadioValue(value.day)}
                        onChange={this.handleChange}
                        checked={this.getRadioCheck(value.day)}
                        className="radio-space"
                    />
                    <span className="checkmark-radio" />
                </label>
            );
        });

        return (
            <div>
                {core.length > 0 ? (
                    <>
                        <div className="form-group tac-desk margin-zero">
                            {isUnaHoraUntil('18:00') && isAfterHora('9:30') && validAllies() ? (
                                <label className="container-radio mb-4">
                                    Entrega en menos de 90 minutos
                                    <input
                                        type="radio"
                                        value={1}
                                        onChange={this.handleRadioCore}
                                        checked={this.state.radioCore == 1}
                                    />
                                    <span className="checkmark-radio" />
                                </label>
                            ) : (
                                ''
                            )}

                            <label className="container-radio mb-4">
                                Envío programado
                                <input
                                    type="radio"
                                    value={2}
                                    onChange={this.handleRadioCore}
                                    checked={this.state.radioCore == 2}
                                />
                                <span className="checkmark-radio" />
                            </label>
                        </div>

                        {this.state.radioCore == 2 ? (
                            <div className="form-row margin-zero">
                                <div className="form-group col-6 pl-0">
                                    <select
                                        name="fechaCore"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                            this.handleHora(e);
                                        }}
                                        value={this.state.fechaCore || ''}
                                        className="form-control input-style"
                                        required
                                    >
                                        {list_fechas_core}
                                    </select>
                                </div>

                                <div className="form-group col-6 padding-0">
                                    <select
                                        name="horaCore"
                                        onChange={this.handleChange}
                                        value={this.state.horaCore || ''}
                                        className="form-control input-style"
                                        required
                                    >
                                        {list_horas_core}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}

                {snack.length > 0 ? (
                    <>
                        {core.length > 0 ? (
                            <div className="modal-separator">
                                <hr />
                            </div>
                        ) : (
                            <></>
                        )}

                        <div>
                            <div className="programing-body-title tac-desk">Snacks</div>
                        </div>
                        <div className="form-group tac-desk margin-zero">
                            {isUnaHoraUntil('18:00') && isAfterHora('9:30') && validAllies() ? (
                                <label class="container-radio mb-4">
                                    Entrega en menos de 90 minutos
                                    <input
                                        type="radio"
                                        value={1}
                                        onChange={this.handleRadioSnack}
                                        checked={this.state.radioSnack == 1}
                                    />
                                    <span class="checkmark-radio" />
                                </label>
                            ) : (
                                ''
                            )}

                            <label className="container-radio mb-4">
                                Envío programado
                                <input
                                    type="radio"
                                    value={2}
                                    onChange={this.handleRadioSnack}
                                    checked={this.state.radioSnack == 2}
                                />
                                <span className="checkmark-radio" />
                            </label>
                        </div>

                        {this.state.radioSnack == 2 ? (
                            <div className="form-row margin-zero">
                                <div className="form-group col-6 pl-0">
                                    <select
                                        name="fechaSnack"
                                        onChange={(e) => {
                                            this.handleChange(e);
                                            this.handleHoraSnack(e);
                                        }}
                                        value={this.state.fechaSnack || ''}
                                        className="form-control input-style"
                                        required
                                    >
                                        {list_fechas_snack}
                                    </select>
                                </div>

                                <div className="form-group col-6 padding-0">
                                    <select
                                        name="horaSnack"
                                        onChange={this.handleChange}
                                        value={this.state.horaSnack || ''}
                                        className="form-control input-style"
                                        required
                                    >
                                        {list_horas_snack}
                                    </select>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}

                {huerta.cart.length > 0 ? (
                    <>
                        {core.length > 0 || snack.length > 0 ? (
                            <div className="modal-separator">
                                <hr />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div>
                            <div className="programing-body-title tac-desk">Huerta orgánica programada</div>
                        </div>
                        <div className="form-group tac-desk">{list_radio_huerta}</div>
                        <div className="form-group">
                            <select
                                name="horaHuerta"
                                onChange={this.handleChange}
                                value={this.state.horaHuerta || ''}
                                className="form-control input-style"
                                required
                            >
                                {list_horas_huerta}
                            </select>
                        </div>
                    </>
                ) : (
                    ''
                )}

                {this.getExteralPrograming()}
            </div>
        );
    };

    renderSamePrograming = () => {
        const list_horas_huerta = horasHuerta.map((value, index) => {
            return (
                <option value={value.hora} key={index}>
                    {value.hora}
                </option>
            );
        });

        const list_radio_huerta = getDateOrdenadoHuertaCongelado()
            .slice(0, 2)
            .map((value, i) => {
                return (
                    <label className="container-radio mb-4" key={i}>
                        {this.getRadioDate(value.day)}

                        <input
                            type="radio"
                            name="radioHuerta"
                            value={this.getRadioValue(value.day)}
                            onChange={this.handleChangeSameDay}
                            checked={this.getRadioCheck(value.day)}
                            className="radio-space"
                        />
                        <span className="checkmark-radio" />
                    </label>
                );
            });

        return (
            <>
                <div className="form-group tac-desk">{list_radio_huerta}</div>
                <div className="form-group">
                    <select
                        name="horaHuerta"
                        onChange={this.handleChangeSameDay}
                        value={this.state.horaHuerta || ''}
                        className="form-control input-style"
                        required
                    >
                        {list_horas_huerta}
                    </select>
                </div>
            </>
        );
    };

    render() {
        const { openProgramingType, programingType } = this.state;

        return (
            <>
                <div>
                    <div>
                        <div className="checkout-title mt-4">
                            <h3 className="check-title">Programa tu pedido</h3>
                        </div>
                        {programingType === 'diffDay' ? this.renderNormalPrograming() : this.renderSamePrograming()}
                    </div>
                    <Modal
                        open={openProgramingType}
                        onClose={this.closeProgramingType}
                        center
                        classNames={{
                            modal: 'modal-mobile-style',
                            closeButton: 'close-forgotten',
                        }}
                        closeOnOverlayClick={false}
                        showCloseIcon={false}
                    >
                        <DeliveryForm updatePrograming={this.updatePrograming} close={this.closeProgramingType} />
                    </Modal>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    snack: state.snack.cart,
    core: state.core.cart,
    huerta: state.huerta,
    external: state.external.cart,
});

export default connect(
    mapStateToProps,
    { updateCore, updateSnack, updateHuerta, updateExternal }
)(withRouter(ProgramingPage));
