import * as moment from 'moment';
import { getDiffHoraria, getDiffMinutes } from '../servicios';
import store from '../store';
import Swal from 'sweetalert2';
import {
    addToSnack,
    incrementSnack,
    decrementSnack,
    removeFromSnack,
    addToCore,
    incrementCore,
    removeFromCore,
    decrementCore,
    addToHuerta,
    incrementHuerta,
    decrementHuerta,
    removeFromHuerta,
    addToExternal,
    incrementExternal,
    decrementExternal,
    removeFromExternal,
} from '../actions';
import { isActualDirectionLocal } from './cities';

export const isDomingo = () => {
    let dia = moment().isoWeekday();

    if (dia == 7) {
        return true;
    } else {
        return false;
    }
};

export const getToday = () => {
    return moment().format('DD/MM/YYYY');
};

export const getAmericanToLatinDate = (value) => {
    return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

export const isLunes = () => {
    let dia = moment().isoWeekday();

    if (dia == 1) {
        return true;
    } else {
        return false;
    }
};

export const isMiercoles = () => {
    let dia = moment().isoWeekday();

    if (dia == 3) {
        return true;
    } else {
        return false;
    }
};

export const isBeforeJueves = () => {
    let dia = moment().isoWeekday();

    if (dia < 4) {
        return true;
    } else {
        return false;
    }
};

export const isSabado = () => {
    let dia = moment().isoWeekday();

    if (dia == 6) {
        return true;
    } else {
        return false;
    }
};

export const isViernes = () => {
    let dia = moment().isoWeekday();

    if (dia == 5) {
        return true;
    } else {
        return false;
    }
};

export const checkDisponible = (product) => {
    if (product.activo == 0 || product.activo_hall == 0 || product.stock == 0) {
        return 'grey-filter';
    } else {
        return '';
    }
};

export const isProductoInactivo = (product) => {
    if (product.activo == 0 || product.activo_hall == 0) {
        return true;
    } else {
        return false;
    }
};

/*para saber si hay al menos una horas hasta la hora dada
formato 24H
*/

export const isUnaHoraUntil = (hora) => {
    if (getDiffHoraria(hora) > 0) {
        return true;
    } else {
        return false;
    }
};

export const validAllies = () => {
    let allies = [];

    const points = [3, 18, 40, 5, 31, 19, 51, 33];

    const core = store.getState().core.cart;
    const snack = store.getState().snack.cart;
    const cena = store.getState().cena.cart;

    core.forEach((val) => {
        if (!allies.includes(val.ally) && points.includes(val.ally)) {
            allies.push(val.ally);
        }
    });

    snack.forEach((val) => {
        if (!allies.includes(val.ally) && points.includes(val.ally)) {
            allies.push(val.ally);
        }
    });

    cena.forEach((val) => {
        if (!allies.includes(val.ally) && points.includes(val.ally)) {
            allies.push(val.ally);
        }
    });

    return allies.length <= 2 ? true : false;
};

export const isAfterHora = (hora) => {
    if (getDiffMinutes(hora) <= 0) {
        return true;
    } else {
        return false;
    }
};

export const isBeforeHora = (hora) => {
    if (getDiffMinutes(hora) >= 0) {
        return true;
    } else {
        return false;
    }
};

export const isHorasDisponibles1h = (horas) => {
    const list_horas = horas.filter((value, index) => {
        if (getDiffHoraria(value.hora) > 0) {
            return true;
        }
    });

    if (list_horas.length > 0) {
        return true;
    } else {
        return false;
    }
};

export const isNumberHorasDisponibles = (horas, number) => {
    const list_horas = horas.filter((value, index) => {
        if (getDiffHoraria(value.hora) > number - 1) {
            return true;
        }
    });

    if (list_horas.length > 0) {
        return true;
    } else {
        return false;
    }
};

export const isHorainArray = (hora, array) => {
    if (array.some((value) => value.hora == hora)) {
        return true;
    } else {
        return false;
    }
};

export const getProductButtonText = (product) => {
    if (product.activo_hall == 0) {
        return 'Aliado cerrado';
    }
    if (product.activo == 0 || product.stock == 0) {
        return 'Producto agotado';
    }

    return 'Añadir al carrito';
};

export const capitalizeWord = (value) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isLogin = () => {
    if (store.getState().customer.data) {
        return true;
    } else {
        return false;
    }
};

export const notWeekend = () => {
    let dia = moment().isoWeekday();

    if (dia == 6 || dia == 7) {
        return false;
    } else {
        return true;
    }
};

export const noAccent = (value) => {
    const string_norm = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return string_norm;
};

export const shuffle = (a) => {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
};

export const stringPreview = (value, length) => {
    if (value) {
        if (value.length > length) {
            return value.substring(0, length) + '..';
        } else {
            return value;
        }
    } else {
        return '';
    }
};

export const sweetQuestion = (title, html) => {
    return Swal.fire({
        title: title,
        html: html,

        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar',
    });
};

export const sweetErrorMsg = (title) => {
    return Swal.fire({
        title: title,
        icon: 'error',
    });
};

export const sweetPop = (title, text, type) => {
    return Swal.fire({
        icon: type,
        title: title,
        text: text,
    });
};

export const getStatusText = (status) => {
    switch (status) {
        case 'OK':
            return 'Aprobada';
            break;
        case 'PENDING':
            return 'Pendiente';
            break;
        case 'NOT_AUTHORIZED':
            return 'Rechazada';
            break;
        case 'FAILED':
            return 'Fallida';
            break;
        default:
            return '';
            break;
    }
};

export const isToday = (someDate) => {
    const today = new Date();
    return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    );
};

export const isPair = (num) => {
    let value = num % 2;

    if (value == 0) {
        return true;
    } else {
        return false;
    }
};

export const getSection1Color = (index, color) => {
    let bool = isPair(index);

    if (index > 1) {
        if (bool) {
            return '#FFF';
        } else {
            return color;
        }
    } else {
        if (bool) {
            return '#FFF';
        } else {
            return color;
        }
    }
};

export const getSection1Border = (index, color) => {
    let bool = isPair(index);

    if (index > 2) {
        if (bool) {
            return 'no-border';
        } else {
            return 'no-border';
        }
    } else {
        if (bool) {
            return 'no-border';
        } else {
            return 'no-border';
        }
    }
};

export const isAppColor = (value) => {
    if (value == 0) {
        return false;
    } else {
        return true;
    }
};

export const getSection1ColorLabel = (index, color) => {
    let bool = isPair(index);

    if (index > 1) {
        if (bool) {
            return 'black';
        } else {
            return 'black';
        }
    } else {
        if (!bool) {
            return 'black';
        } else {
            return 'black';
        }
    }
};

export const productInCart = (product, tipo) => {
    if (!isActualDirectionLocal()) {
        return isInExternal(product);
    }

    switch (tipo) {
        case 0:
            return isInSnack(product);
            break;
        case 1:
            return isInCore(product);
            break;
        case 4:
            return isInHuerta(product);
            break;
        default:
            return false;
            break;
    }
};

export const productInCartSearch = (product) => {
    switch (product.patron) {
        case 0:
            return isInSnack(product);
            break;
        case 1:
            return isInCore(product);
            break;
        case 4:
            return isInHuerta(product);
            break;
        default:
            return false;
            break;
    }
};

export const isInSnack = (producto) => {
    let cart = store.getState().snack.cart;

    return cart.find((value) => value.id == producto.id);
};

export const isInExternal = (producto) => {
    let cart = store.getState().external.cart;

    return cart.find((value) => value.id == producto.id);
};

export const isInCore = (producto) => {
    let cart = store.getState().core.cart;

    return cart.find((value) => value.id == producto.id);
};

export const isInHuerta = (producto) => {
    let cart = store.getState().huerta.cart;

    return cart.find((value) => value.id == producto.id);
};

export const addFromSlider = (product, patron) => {
    if (!isActualDirectionLocal()) {
        store.dispatch(addToExternal(product, '', 1));
        return;
    }

    switch (patron) {
        case 0:
            store.dispatch(addToSnack(product, '', 1));
            break;
        case 1:
            store.dispatch(addToCore(product, '', 1));
            break;
        case 4:
            store.dispatch(addToHuerta(product, '', 1));
            break;
        default:
            return false;
            break;
    }
};

export const addFromSliderSugerido = (product, patron) => {
    const programCore = store.getState().core.programacion;
    const programSnack = store.getState().snack.programacion;
    const programHuerta = store.getState().huerta.programacion;
    const programExternal = store.getState().external.programacion;

    if (!isActualDirectionLocal()) {
        store.dispatch(addToExternal(product, programExternal, 1));
        return;
    }

    switch (patron) {
        case 0:
            store.dispatch(addToSnack(product, programSnack, 1));
            break;
        case 1:
            store.dispatch(addToCore(product, programCore, 1));
            break;
        case 4:
            store.dispatch(addToHuerta(product, programHuerta, 1));
            break;
        default:
            return false;
            break;
    }
};

export const incrementInSlider = (e, product, qty, patron) => {
    e.preventDefault();

    if (qty >= getMaxProd(product)) {
        return false;
    }

    if (!isActualDirectionLocal()) {
        store.dispatch(incrementExternal(product, 1));
        return;
    }

    switch (patron) {
        case 0:
            store.dispatch(incrementSnack(product, 1));
            break;
        case 1:
            store.dispatch(incrementCore(product, 1));
            break;
        case 4:
            store.dispatch(incrementHuerta(product, 1));
            break;
        default:
            return false;
            break;
    }
};

export const decrementInSlider = (e, product, qty, patron) => {
    e.preventDefault();

    if (qty == 1) {
        return removeFromSlider(product, patron);
    }

    if (!isActualDirectionLocal()) {
        store.dispatch(decrementExternal(product.id));
        return;
    }

    switch (patron) {
        case 0:
            store.dispatch(decrementSnack(product.id));
            break;
        case 1:
            store.dispatch(decrementCore(product.id));
            break;
        case 4:
            store.dispatch(decrementHuerta(product.id));
            break;
        default:
            return false;
            break;
    }
};

export const removeFromSlider = (product, tipo) => {
    if (!isActualDirectionLocal()) {
        store.dispatch(removeFromExternal(product, 1));
    }

    switch (tipo) {
        case 0:
            return store.dispatch(removeFromSnack(product));
            break;
        case 1:
            return store.dispatch(removeFromCore(product));
            break;
        case 4:
            return store.dispatch(removeFromHuerta(product));
            break;
        default:
            break;
    }
};

export const getJerarquiaLabel = (val) => {
    if (val == 1) {
        return 'Envío express';
    } else {
        return 'Envío programado';
    }
};

export const findCategory = (id) => {
    return store.getState().categories.categories.find((val) => val.id == id);
};

export const findHall = (id) => {
    return store.getState().halls.halls.find((val) => val.id == id);
};

export const priceNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getPrice = (product) => {
    if (product.if_offer) {
        return product.price_offer;
    } else {
        return product.price;
    }
};

export const isSurveyComplete = () => {
    const isSurveyComplete = store.getState().data.survey_complete;

    if (isSurveyComplete) {
        return true;
    } else {
        return false;
    }
};

export const isMobileSize = () => {
    const size = window.innerWidth;

    if (size < 600) {
        return true;
    } else {
        return false;
    }
};

export const existStock = (item) => {
    if (item.stock > 0) {
        return true;
    } else {
        return false;
    }
};

export const getMaxProd = (item) => {
    if (item.stock <= 15) {
        return item.stock;
    } else {
        return 15;
    }
};

export const formatNumber = (number) => {
    return parseInt(number)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const reduceObjectArray = (array) => {
    return array.reduce(
        function(p, c) {
            // create an identifying id from the object values
            var id = c.id;

            // if the id is not found in the temp array
            // add the object to the output array
            // and add the key to the temp array
            if (p.temp.indexOf(id) === -1) {
                p.out.push(c);
                p.temp.push(id);
            }
            return p;

            // return the deduped array
        },
        {
            temp: [],
            out: [],
        }
    ).out;
};
