import React, { Component } from 'react'
import { toastSuccess,toastError } from '../../../../alertas'
import { connect } from 'react-redux'
import { addToCena } from '../../../../actions'
import horas from '../../../../api/horarios/cenaSemana.json'
import horas_fin from '../../../../api/horarios/cenaFin.json'
import * as moment from 'moment';
import { getDia, getArrayPatronSemanaYFin } from '../../../../servicios'
import { validateCena, postreByHall, bebidaByHall } from '../../../../services'


class ItemTipoCena extends Component {

    constructor(props){
        super(props)
        this.state={
            hora:props.hora,
            add:'',
            cantidad: 1,
            postre:'',
            bebida:'',
            num_postre:1,
            num_bebida:1,
            comment:'',
        }
        

    }


    agregarCarrito = (product) => {

        const {carrito,addToCena}= this.props
        const {cantidad,bebida,postre,num_postre,num_bebida,comment}= this.state
        const numero_plato=parseInt(cantidad)

        //determina si el item esta en el carrito para saber si agregarlo o no
        const bool=carrito.some((item) => {
            return item.id==product.id
        })

        product.bebida=bebida
        product.postre=postre

        product.num_bebida=num_bebida
        product.num_postre=num_postre
        product.comment=comment

        
        const hora=this.state.hora

        if(bool){
            toastError("El producto ya esta en el carrito")
        }else{

            if(validateCena(numero_plato)){
                addToCena(product,hora,numero_plato)
                toastSuccess("Se agrego al carrito")
            }else{
                toastError("Limite de platos superado")
            }
            
        }
        this.props.close()
    }

    handleSubmit = async (e,product) => {
        e.preventDefault()
        this.agregarCarrito(product)
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    setHoraDefault = () => {

        const list_horas=getArrayPatronSemanaYFin(horas,horas_fin)

        //caso en que existe al menos una hora
        if(list_horas.length>0){
            this.setState({
                hora:list_horas[0].hora
            })
        }
        
    }

    componentDidMount(){

        //algorimo para validar hora seleccionada
        const {hora} =this.props
        const lista_horas=getArrayPatronSemanaYFin(horas,horas_fin)

        // caso existe una hora de entrega
        if(hora){
            /**condicion para saber si 
             * la hora almacenada es valida
             */
            if(!lista_horas.some((value,index)=>
                value.hora==hora
            )){

                this.setHoraDefault()

            }

        //caso no hay hora de entrega
        }else{

            this.setHoraDefault()

        }

    }


    

    mananaDisponibe = () => {

        let dia=moment().isoWeekday()

        if(dia==6 || dia==7 || dia==5){
            return false
        }else{
            return true
        }
        
    }

    restar = (e,key) => {

        e.preventDefault()
        this.setState({
            [key]: this.state[key]-1
        })
        
    }


    sumar = (e,key) => {

        e.preventDefault()
        this.setState({
            [key]: this.state[key]+1
        })
        
    }

    getPostre(id_add){
        const {add_postre}=this.props

        return add_postre.find((value)=>
            value.id==id_add
        )

    }

    getBebida(id_add){
        const {add_bebida}=this.props

        return add_bebida.find((value)=>
            value.id==id_add
        )

    }


    getHoras = () => {

        return getArrayPatronSemanaYFin(horas,horas_fin).map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

    }
    

    render() {

        const {product,symbol,add_postre,add_bebida}=this.props
        const {cantidad,postre,bebida,num_bebida,num_postre}=this.state
        const lista_hora=this.getHoras()

        const list_postre=postreByHall(product.hall).map((value)=>{
            return(
            <option value={value.id} key={value.id}>{value.name}</option>
            )
        })

        const list_bebida=bebidaByHall(product.hall).map((value)=>{
            return(
            <option value={value.id} key={value.id}>{value.name}</option>
            )
        })

        return (
            <div className="modal-dialog modal-lg modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-6  col-xs-12">
                                <div className="quick-view-img">
                                    <div className="mb-none">Plato:</div>
                                    <img src={product.variants?
                                        this.state.image?this.state.image:product.variants[0].images
                                        :product.pictures[0]} alt="" className="modal-image-style" />
                                </div>
                                
                                {
                                    postre ?
                                    <>
                                        <div className="mb-none">Postre:</div>
                                        <div className="quick-view-img">
                                            <img src={this.getPostre(this.state.postre).image} alt="" className="img-fluid mb-none mw50" />
                                        </div>
                                    </>
                                    :''
                                }

                                {
                                    bebida ?
                                    <>
                                        <div className="mb-none">Bebida:</div>
                                        <div className="quick-view-img">
                                            <img src={this.getBebida(this.state.bebida).image} alt="" className="img-fluid mb-none mw50" />
                                        </div>
                                    </>
                                    :''
                                }
                                
                            </div>
                            <div className="col-lg-6 rtl-text">
                                <div className="product-right">
                                    <h2 className="ttn"> {product.name} </h2>
                                    <div className="border-product">
                                        <p>{product.description}</p>
                                    </div>
                                    <h3>{symbol}{product.price}
                                    {/*
                                        <del><span className="money">{symbol}{product.price}</span></del>
                                    */}
                                    </h3>
                                    {product.variants?
                                    <ul className="color-variant">
                                        {product.variants.map((vari, i) =>
                                            <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                        }
                                    </ul>:''}                                  
                                    
                                    <form onSubmit={(e)=>this.handleSubmit(e,product)}>


                                        <>

                                            <div className="form-group">
                                            <label htmlFor="hora" className="col-form-label" >{"Selecciona la hora a la que deseas recibir "+getDia(horas,horas_fin)+" tus productos"}</label>
                                                <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                                    {lista_hora}
                                                </select>
                                            </div>                                       

                                            <div className="form-group">
                                                <label htmlFor="postre" className="col-form-label" >Postre:</label>
                                                <select name="postre" onChange={this.handleChange} value={this.state.postre || ''} className="form-control">
                                                    <option  value="">N/A</option>  
                                                    {list_postre}                                                                                     
                                                </select>
                                            </div>

                                            

                                            {
                                                postre ?


                                                    
                                                    <>

                                                        <p className="tac">${this.getPostre(this.state.postre).price+" c/u"}</p>
                                                        <label htmlFor="cantidad" className="col-form-label" >Cantidad de postres:</label>
                                                        <div className="input-group">
                                                
                                                            <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-left-minus" onClick={(e) => this.restar(e,"num_postre")} data-type="minus" data-field="" disabled={(num_postre <= 1)? true : false}>
                                                                    <i className="fa fa-angle-left"></i>
                                                                </button>
                                                            </span>
                                                            <input type="text" name="num_postres" value={num_postre} readOnly={true} className="form-control input-number tac" />
                                                            <span className="input-group-prepend">
                                                                <button className="btn quantity-right-plus" onClick={(e) => this.sumar(e,"num_postre")}  data-type="plus" disabled={(num_postre >= 5)? true : false}>
                                                                    <i className="fa fa-angle-right"></i>
                                                                </button>
                                                            </span>
                                                        </div>

                                                        {/*                                            
                                                        <img src={this.getPostre(this.state.postre).image} alt="Imagen del postre" className="add-img mb-display"/>
                                                        
                                                        */}

                                                    </>
                                                    
                                                :''
                                            }


                                            <div className="form-group">
                                                <label htmlFor="bebida" className="col-form-label" >Bebida:</label>
                                                <select name="bebida" onChange={this.handleChange} value={this.state.bebida || ''} className="form-control">
                                                    <option  value="">N/A</option>  
                                                    {list_bebida}                                                                                     
                                                </select>
                                            </div>


                                            {
                                                bebida ?
                                                    
                                                    <>
                                                        <p className="tac">${this.getBebida(this.state.bebida).price+" c/u"}</p>
                                                        <label htmlFor="cantidad" className="col-form-label" >Cantidad de bebidas:</label>
                                                        <div className="input-group">
                                                
                                                            <span className="input-group-prepend">
                                                                <button type="button" className="btn quantity-left-minus" onClick={(e) => this.restar(e,"num_bebida")} data-type="minus" data-field="" disabled={(num_bebida <= 1)? true : false}>
                                                                    <i className="fa fa-angle-left"></i>
                                                                </button>
                                                            </span>
                                                            <input type="text" name="num_bebida" value={num_bebida} readOnly={true} className="form-control input-number tac" />
                                                            <span className="input-group-prepend">
                                                                <button className="btn quantity-right-plus" onClick={(e) => this.sumar(e,"num_bebida")}  data-type="plus" disabled={(num_bebida >= 5)? true : false}>
                                                                    <i className="fa fa-angle-right"></i>
                                                                </button>
                                                            </span>
                                                        </div>

                                                        {/*  
                                                        <img src={this.getBebida(this.state.bebida).image} alt="Imagen de la bebida" className="add-img mb-display"/>
                                                        */}
                                                        
                                                    </>
                                                    
                                                :''
                                            }

                                            <div className="form-group">
                                                <label htmlFor="comment" className="col-form-label" >Comentarios:</label>
                                                <textarea name="comment" className="form-control" rows="2" onChange={this.handleChange} value={this.state.comment || ''} placeholder="Sin lechuga, sin cebolla, etc"/>
                                            </div>

                                            <div className="form-row">

                                                <div className="col-6">

                                                    <label htmlFor="cantidad" className="col-form-label cantidad-modal-label" >Cantidad de platos:</label>
                                                    <div className="input-group">
                                            
                                                        <span className="input-group-prepend">
                                                            <button type="button" className="btn quantity-left-minus" onClick={(e) => this.restar(e,"cantidad")} data-type="minus" data-field="" disabled={(cantidad <= 1)? true : false}>
                                                                <i className="fa fa-angle-left"></i>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="cantidad" value={cantidad} readOnly={true} className="form-control input-number tac" />
                                                        <span className="input-group-prepend">
                                                            <button className="btn quantity-right-plus" onClick={(e) => this.sumar(e,"cantidad")}  data-type="plus" disabled={(cantidad >= 15)? true : false}>
                                                                <i className="fa fa-angle-right"></i>
                                                            </button>
                                                        </span>
                                                    </div>

                                                </div>

                                                <div className="col-6">

                                                    <div className="product-buttons">
                                                        <button  className="btn btn-solid ttn btn-modal-product" >Añadir al carrito</button>                                                  
                                                    </div>
                                                    
                                                </div>


                                            </div>

                                        </>                                                   

                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    total: state.data.products,
    carrito: state.cena.cart,
    symbol: state.data.symbol,
    hora: state.cena.hora_entrega,
    add_postre: state.data.postres,
    add_bebida: state.data.bebidas,
})

export default connect(
    mapStateToProps, {addToCena}
)(ItemTipoCena)
