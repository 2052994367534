import React, { Component } from 'react'
import { toastSuccess,toastError } from '../../../../alertas'
import { connect } from 'react-redux'
import { addToCart } from '../../../../actions'
import horas from '../../../../api/horarios/tipoPlanAlimenticio.json'
import semana from '../../../../api/semana.json'
import * as moment from 'moment';
import FormError from '../../../common/FormError'
import { getNextMonday } from '../../../../servicios'
import { validatePlan } from '../../../../services'


class TipoQuincenalPage extends Component {

    constructor(props){
        super(props)
        this.state={
            hora:null,
            dias1:[],
            dias2:[],
            bool_dias:false,
            cantidad: 1
        }
    }


    agregarCarrito = (product) => {

        const {carrito,addToCart}= this.props
        const {cantidad}= this.state
        const numero_plato=parseInt(cantidad)

        //determina si el item esta en el carrito para saber si agregarlo o no
        const bool=carrito.some((item) => {
            return item.id==product.id
        })

        const programacion={
            hora: this.state.hora,
            semana1:this.state.dias1,
            semana2:this.state.dias2
        }

        if(bool){
            toastError("El producto ya esta en el carrito")
        }else{

            if(validatePlan(numero_plato)){
                addToCart(product,programacion,numero_plato)
                toastSuccess("Se agrego al carrito")
            }else{
                toastError("Limite de planes superado")
            }
            
        }
        this.props.close()
    }

    handleSubmit = async (e,product) => {
        e.preventDefault()
        const {dias1,dias2}=this.state
        if(dias1.length>=2 && dias2.length>=2){
            this.agregarCarrito(product)
        }else{
            this.setState({
                bool_dias:true
            })
        }

        
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    clickHandleDias(event, dias) {

        var index = dias.indexOf(parseInt(event.target.value));
        if (event.target.checked){
            dias.push(parseInt(event.target.value)); // push in array checked value
        }else{
            dias.splice(index, 1); // removed in array unchecked value
        }

        this.setState({
            dias1:dias
        },()=>{
            console.log(this.state.dias1)
        })
    }

    clickHandleDias2(event, dias) {

        var index = dias.indexOf(parseInt(event.target.value));
        if (event.target.checked){
            dias.push(parseInt(event.target.value)); // push in array checked value
        }else{
            dias.splice(index, 1); // removed in array unchecked value
        }

        this.setState({
            dias2:dias
        },()=>{
            console.log(this.state.dias2)
        })
    }

    componentDidMount(){

        const list_horas=horas.map((value)=>{

            return value.hora
            
        })

        this.setState({
            hora:list_horas[0]
        })

    }

    restar = (e) => {

        e.preventDefault()
        this.setState({
            cantidad: this.state.cantidad-1
        })
        
    }

    sumar = (e) => {

        e.preventDefault()
        this.setState({
            cantidad: this.state.cantidad+1
        })
        
    }
    

    render() {

        const {product,symbol}=this.props
        const {dias1,dias2,cantidad}=this.state

        const list_horas=horas.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_checkbox1=semana.map((value,index)=>{

            return(
                <div className="form-checkbox" key={index}>
                    <input type="checkbox" onChange={(e) => this.clickHandleDias(e,dias1)}   checked={dias1.includes(value.id)? true : false} value={value.id} />
                    <label className="ml-2"
                            htmlFor={value.name}>{value.name}</label>
                </div>
            )
            
        })

        const list_checkbox2=semana.map((value,index)=>{

            return(
                <div className="form-checkbox" key={index}>
                    <input type="checkbox" onChange={(e) => this.clickHandleDias2(e,dias2)}   checked={dias2.includes(value.id)? true : false} value={value.id} />
                    <label className="ml-2"
                            htmlFor={value.name}>{value.name}</label>
                </div>
            )
            
        })

        const primer_lunes=getNextMonday()
        const primer_viernes=moment(primer_lunes,'DD/MM/YYYY').add(4, 'days').format('DD/MM/YYYY')

        const segundo_lunes=moment(primer_lunes,'DD/MM/YYYY').add(7, 'days').format('DD/MM/YYYY')
        const segundo_viernes=moment(primer_lunes,'DD/MM/YYYY').add(11, 'days').format('DD/MM/YYYY')


        return (
            <div className="modal-dialog modal-lg modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-lg-12 rtl-text">
                                <div className="product-right">
                                    <h2 className="ttn"> {product.name} </h2>
                                    <div className="border-product">
                                        <p>{product.description}</p>
                                    </div>
                                    <h3>{symbol}{product.price}
                                        {/*
                                            <del><span className="money">{symbol}{product.price}</span></del>
                                        */}
                                    </h3>
                                    {product.variants?
                                    <ul className="color-variant">
                                        {product.variants.map((vari, i) =>
                                            <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                        }
                                    </ul>:''}
                                    <form onSubmit={(e)=>this.handleSubmit(e,product)}>


                                        <div className="form-group">
                                            <label htmlFor="hora" className="col-form-label" >Hora :</label>
                                            <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                                {list_horas}
                                            </select>
                                        </div>

                                        <div className="row">

                                            <fieldset className="form-group col-xl-6 col-sm-12">
                                                <div className="ml-2 row">

                                                    <legend className="col-form-label pt-0"> 
                                                        {primer_lunes} al {primer_viernes}
                                                    </legend>

                                                    <div className="">

                                                        {list_checkbox1}

                                                    </div>
                                                </div>
                                            </fieldset>

                                            <fieldset className="form-group col-xl-6 col-sm-12">
                                                <div className="ml-2 row">

                                                    <legend className="col-form-label pt-0"> 
                                                        {segundo_lunes} al {segundo_viernes}
                                                    </legend>

                                                    <div className="">

                                                        {list_checkbox2}

                                                    </div>
                                                </div>
                                            </fieldset>

                                        </div>

                                        <FormError show={this.state.bool_dias} text="Debes escoger un mínimo de 2 dias a la semana" />

                                        <div className="form-row">

                                            <div className="col-6">

                                                <label htmlFor="cantidad" className="col-form-label cantidad-modal-label" >Cantidad:</label>
                                                <div className="input-group">
                                        
                                                    <span className="input-group-prepend">
                                                        <button type="button" className="btn quantity-left-minus" onClick={(e) => this.restar(e,"cantidad")} data-type="minus" data-field="" disabled={(cantidad <= 1)? true : false}>
                                                            <i className="fa fa-angle-left"></i>
                                                        </button>
                                                    </span>
                                                    <input type="text" name="cantidad" value={cantidad} readOnly={true} className="form-control input-number tac" />
                                                    <span className="input-group-prepend">
                                                        <button className="btn quantity-right-plus" onClick={(e) => this.sumar(e,"cantidad")}  data-type="plus" disabled={(cantidad >=15)? true : false}>
                                                            <i className="fa fa-angle-right"></i>
                                                        </button>
                                                    </span>
                                                </div>

                                            </div>

                                            <div className="col-6">

                                                <div className="product-buttons">                                                   
                                                        <button  className="btn btn-solid ttn btn-modal-product" >Añadir al carrito</button>                                              
                                                </div>
                                                
                                            </div>

                                        </div>

                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    total: state.data.products,
    carrito: state.cartList.cart,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, {addToCart}
)(TipoQuincenalPage)