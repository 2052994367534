import React, { Component, Fragment } from "react";
import { toastSuccess, toastError } from "../../../../alertas";
import { connect } from "react-redux";
import {
  addToCore,
  removeFromCore,
  addToExternal,
  removeFromExternal,
} from "../../../../actions";
import horas from "../../../../api/horarios/tipoCore.json";
import * as moment from "moment";
import { getDiffHoraria } from "../../../../servicios";
import FormError from "../../../common/FormError";
import { validateCore } from "../../../../services";
import { getDiaMesEspanol } from "../../../../funciones/momentEspañol";
import {
  isUnaHoraUntil,
  isAfterHora,
  priceNumber,
  getMaxProd,
} from "../../../../funciones/general";
import {
  detFechaPatronCore,
  detRadioPatronCore,
  getArrayPatronCore,
  initialHourCore,
  getMinDateCore,
  getArrayDateCore,
} from "../../../../funciones/patrones";
import {
  cleverAddToCart,
  cleverViewProduct,
} from "../../../../funciones/clevertap";
import PriceListingModal from "../PriceListingModal";
import NotAvailableProd from "../NotAvailableProd";
import { isActualDirectionLocal } from "../../../../funciones/cities";

class ItemTipoCore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hora: "",
      fecha: detFechaPatronCore(horas),
      radio: detRadioPatronCore(horas),
      bool: true,
      listado: getArrayPatronCore(horas),
      cantidad: props.inCart ? props.inCart.qty : 1,
      comment: props.inCart ? props.inCart.comment : "",
    };
  }

  agregarCarrito = (product) => {
    const {
      carrito,
      addToCore,
      addToExternal,
      core,
      inCart,
      removeFromCore,
      actualDirectionLocal,
      removeFromExternal,
      external,
    } = this.props;
    const { radio, hora, fecha, comment } = this.state;
    const { cantidad } = this.state;
    const numero_plato = parseInt(cantidad);

    //determina si el item esta en el carrito para saber si agregarlo o no
    const bool = carrito.some((item) => {
      return item.id == product.id;
    });

    product.comment = comment;

    if (inCart) {
      if (actualDirectionLocal) {
        removeFromCore(inCart);
      } else {
        removeFromExternal(inCart);
      }
    }

    if (validateCore(numero_plato)) {
      cleverAddToCart(product);
      if (actualDirectionLocal) {
        addToCore(product, core.programacion, numero_plato);
      } else {
        addToExternal(product, external.programacion, numero_plato);
      }

      toastSuccess("Carrito actualizado");
    } else {
      toastError("Limite de articulos de mercado");
    }

    this.props.close();
  };

  handleSubmit = async (e, product) => {
    e.preventDefault();
    this.agregarCarrito(product);
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  //metodo para cargar las horas
  handleHora = (e) => {
    const { value } = e.target;
    const today = moment().format("YYYY-MM-DD");

    //caso mismo dia
    if (today == value) {
      const list_horas = horas.filter((value, index) => {
        return getDiffHoraria(value.hora) > 0;
      });

      //para llenar los horas al escoger una fecha
      if (list_horas.length > 0) {
        this.setState({
          listado: list_horas,
          hora: list_horas[0].hora,
        });
        //caso que deja la lista vacio si no hay horas disponibles
      } else {
        this.setState({
          listado: list_horas,
        });
      }

      //casi un dia programado en el futuro
    } else {
      this.setState({
        listado: horas,
        hora: horas[0].hora,
      });
    }
  };

  handleRadio = (event) => {
    const { value } = event.target;
    this.setState({
      radio: value,
    });
  };

  restar = (e) => {
    e.preventDefault();
    this.setState({
      cantidad: this.state.cantidad - 1,
    });
  };

  sumar = (e) => {
    e.preventDefault();
    this.setState({
      cantidad: this.state.cantidad + 1,
    });
  };

  componentDidMount() {
    const { product } = this.props;

    cleverViewProduct(product);

    this.setState({
      hora: initialHourCore(horas, this.state.fecha),
    });
  }

  render() {
    const { product, symbol } = this.props;
    const { listado, cantidad } = this.state;

    const list_horas = listado.map((value, index) => {
      return (
        <option value={value.hora} key={index}>
          {value.hora}
        </option>
      );
    });

    const list_fechas = getArrayDateCore(horas).map((value, index) => {
      return (
        <option value={value} key={index}>
          {getDiaMesEspanol(value)}
        </option>
      );
    });

    return (
      <div
        className="modal-dialog modal-lg modal-dialog-centered w-vista-r"
        role="document"
      >
        <div className="modal-content quick-view-modal">
          <div className="modal-body">
            <div className="row margin-zero mb-none">
              <div className="col-lg-6  col-xs-12">
                <div className="quick-view-img">
                  <img
                    src={
                      product.variants
                        ? this.state.image
                          ? this.state.image
                          : product.variants[0].images
                        : product.pictures[0]
                    }
                    alt=""
                    className="modal-image-style"
                  />
                </div>
              </div>
              <div className="col-lg-6 rtl-text padding-0">
                <div className="product-right">
                  <h2> {product.name} </h2>
                  <div className="border-product">
                    <p>{product.description}</p>
                  </div>
                  <PriceListingModal product={product} />
                  {product.variants ? (
                    <ul className="color-variant">
                      {product.variants.map((vari, i) => (
                        <li
                          className={vari.color}
                          key={i}
                          title={vari.color}
                          onClick={() => this.onClickHandle(vari.images)}
                        />
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                  <form onSubmit={(e) => this.handleSubmit(e, product)}>
                    {product.if_coment == 1 && product.stock > 0 ? (
                      <div className="form-group">
                        <label htmlFor="comment" className="col-form-label">
                          Comentarios:
                        </label>
                        <textarea
                          name="comment"
                          className="form-control"
                          rows="2"
                          onChange={this.handleChange}
                          value={this.state.comment || ""}
                          placeholder="Añade comentarios al pedido de este producto"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-row">
                      {product.stock > 0 ? (
                        <>
                          <div className="col-6">
                            <label
                              htmlFor="cantidad"
                              className="col-form-label cantidad-modal-label"
                            >
                              Cantidad:
                            </label>
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn quantity-left-minus"
                                  onClick={(e) => this.restar(e, "cantidad")}
                                  data-type="minus"
                                  data-field=""
                                  disabled={cantidad <= 1 ? true : false}
                                >
                                  <i className="fa fa-angle-left" />
                                </button>
                              </span>
                              <input
                                type="text"
                                name="cantidad"
                                value={cantidad}
                                readOnly={true}
                                className="form-control input-number tac"
                              />
                              <span className="input-group-prepend">
                                <button
                                  className="btn quantity-right-plus"
                                  onClick={(e) => this.sumar(e, "cantidad")}
                                  data-type="plus"
                                  disabled={
                                    cantidad >= getMaxProd(product)
                                      ? true
                                      : false
                                  }
                                >
                                  <i className="fa fa-angle-right" />
                                </button>
                              </span>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="product-buttons">
                              <button className="btn btn-solid ttn btn-modal-product">
                                Añadir al carrito
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <NotAvailableProd />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="mb-display fluio-font">
              <div className="modal-prod-title">{product.name}</div>

              <div className="modal-prod-name">{product.aliado}</div>

              <div className="quick-view-img">
                <img
                  src={
                    product.variants
                      ? this.state.image
                        ? this.state.image
                        : product.variants[0].images
                      : product.pictures[0]
                  }
                  alt=""
                  className="modal-image-style"
                />
              </div>

              <PriceListingModal product={product} />

              <div className="modal-prod-desc">{product.description}</div>

              {product.stock > 0 ? (
                <>
                  <div className="modal-container-qty">
                    <div>
                      <span className="modal-cantidad-text">Cantidad</span>

                      <span
                        className="modal-min"
                        onClick={(e) => {
                          if (cantidad > 1) {
                            this.restar(e, "cantidad");
                          }
                        }}
                      >
                        -
                      </span>

                      <span
                        className="modal-plus"
                        onClick={(e) => {
                          if (cantidad < getMaxProd(product)) {
                            this.sumar(e, "cantidad");
                          }
                        }}
                      >
                        +
                      </span>

                      <span className="modal-cantidad-number">{cantidad}</span>
                    </div>
                  </div>

                  <div className="container-button-prod">
                    <button
                      onClick={(e) => this.handleSubmit(e, product)}
                      className="btn modal-btn-add ttn"
                      style={{ width: "100%" }}
                    >
                      Añadir al carrito
                    </button>
                  </div>
                </>
              ) : (
                <NotAvailableProd />
              )}
            </div>

            <div className="mb-display xprod" onClick={this.props.close}>
              X
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  total: state.data.products,
  carrito: state.core.cart,
  symbol: state.data.symbol,
  core: state.core,
  external: state.external,
  actualDirectionLocal: isActualDirectionLocal(),
});

export default connect(
  mapStateToProps,
  { addToCore, removeFromCore, removeFromExternal, addToExternal }
)(ItemTipoCore);
