import React, {Component} from 'react';
import {Link} from 'react-router-dom'

class Breadcrumb extends Component {
    render (){
        const {title} = this.props;
        return (
            <div className="breadcrumb-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="page-title">
                                <h3 className="ttn">{title}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Breadcrumb;