import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ProductPageView from './ProductPageView'

class Product extends Component {

    constructor (props) {
        super (props)
        this.state={

        }
    }

    render() {
        const {loading_products, products} = this.props
        const seo = this.props.match.params.seo
        const prod = products.find(val=>val.seo==seo)

        return (
            <div className="container mt-5 mb-5">
                {
                    loading_products==false ?

                    <div>

                        <ProductPageView prod={prod} />
                        
                    </div>

                    :

                    <div className="row">
                        <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                            <h3>Cargando</h3>
                        </div>
                    </div>

                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.data.products,
    loading_products:state.data.loading_products
})

export default connect(
    mapStateToProps, { }
)(withRouter(Product))
