import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import FormError from '../common/FormError';
import 'react-toastify/dist/ReactToastify.min.css';
import { toastSuccess, toastError } from '../../alertas';
import { withRouter } from 'react-router-dom';
import { postData } from '../../servicios';
import { dataLogin } from '../../actions';
import { connect } from 'react-redux';
import MapTool from '../common/MapTool';
import Modal from 'react-responsive-modal';
import { cleverOnLogin, cleverRegistered } from '../../funciones/clevertap';

class Register extends Component {

    constructor (props) {
        super (props)
        this.state={
            boolpass:false,
            boolemail:false,
            bool_directions:false,
            load:false,
            openMap:false,
            ci:'',
            directions:[]
        }

    }

    register = async (e) => {

        e.preventDefault()


        //reinicia errores que pueden o no haber sido corregidos
        this.setState({
            boolemail:false,
            boolpass:false,
            bool_directions:false,
            load: true
        })

        if(this.state.directions.length==0){
            this.setState({
                bool_directions:true,
                load:false
            })
            return
        }

        //validacion de que las contraseñas coincidan


        const json={
            email:this.state.email,
            name: this.state.name,
            nickname:this.state.nickname,
            password:this.state.password,
            password_confirmation:this.state.password_confirmation,
            ci:this.state.ci,
            tel:this.state.tel,
            directions:this.state.directions,
            /**
            addresses_attributes:[{
                dir: this.state.dir,
                df : true
            }]
             */
        }

        if(this.state.cname && this.state.cnum && this.state.mes && this.state.anno && this.state.cvc){
            json.cards_attributes=[{
                name:this.state.cname,
                number:this.state.cnum,
                month:this.state.mes,
                year: this.state.anno,
                cvc: this.state.cvc,
                df: true
            }]
        }

        if(this.state.lat){
            json.addresses_attributes=[{
                dir: this.state.dir,
                lat:this.state.lat,
                lon:this.state.lon,
                df : true
            }]
        }

        console.log(json)

        try{
            const response= await postData("customer_auth/",json)
            this.props.dataLogin(response['data'].data,response.headers)
            cleverOnLogin(response['data'].data)
            cleverRegistered(response['data'].data)
            toastSuccess("Registro Existoso")
            window.location.href="/"
        }catch(err){
            const error=err.response.data.errors.full_messages
            //validacion para saber si el email ya fue utilizado
            if(error.includes("Email has already been taken")){
                this.setState({
                    boolemail:true,
                })
            }

            if(error.includes("Password confirmation doesn't match Password")){
                this.setState({
                    boolpass:true,
                })
            }

            this.setState({
                load:false
            })

            toastError("Ocurrió un error en el registro, revisa el formulario y tu conexión")
        }
    

    }

    handleChange= (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    openMap = (e) => {
        e.preventDefault()
        this.setState({ openMap: true });
    };

    closeMap = () => {
        this.setState({ openMap: false });
    };

    setCordenates = (lon,lat) => {

        this.setState({
            lon:lon,
            lat:lat,
            openMap:false
        })
        
    }

    handleDirectionChange = (e,index) => {
        
        const { name, value,type } = e.target;
        const {directions}=this.state

        const list = [...directions];
        list[index] = value;
        this.setState({
            directions:list
        })
        
    }


    handleAdd = (e) => {
        e.preventDefault()
        const {directions}=this.state
        this.setState({
            directions:[...directions,'']
        })
    };

    handleRemove = (e,i) => {
        e.preventDefault()
        const {directions}=this.state

        const list = [...directions];
        list.splice(i, 1);
        this.setState({
            directions:list
        })

    };

    


    render (){

        const {boolpass,boolemail,load,directions,bool_directions} =this.state


        const list_directions=directions.map((x, i) => {

            return (
              <div className="form-row mt-2" key={i}>
                <div className="col-sm-12 form-group margin-zero">
                    <label htmlFor="name">Dirección</label>
                    <input
                        type="text"
                        name="direction"
                        value={x}
                        className="form-control mb-2"
                        required
                        onChange={e => this.handleDirectionChange(e, i)}
                    />
                </div>

                <div className="btn-box mb-3 dinam-button-box">
                  {directions.length !== 0 && <button className="btn btn-cart" onClick={(e)=>{this.handleRemove(e,i)}}>-</button>}
                  {directions.length - 1 === i && <button className="btn btn-cart" onClick={this.handleAdd}>+</button>}
                </div>
                
              </div>
            );
        })
        


        return (

            <div>
                <Breadcrumb title={'Registro'}/>


                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="ttn">Crear cuenta</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.register}>

                                        <h4 className="tac">Datos personales</h4>

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="name">Nombre</label>
                                                <input type="text" className="form-control" name="name" placeholder="Nombre completo" onChange={this.handleChange} required />
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="email">Correo</label>
                                                <input type="email" className="form-control" name="email" placeholder="Correo electrónico" onChange={this.handleChange} required />
                                                <FormError text="El correo indicado ya esta en uso" show={boolemail}/>
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="password">Contraseña</label>
                                                <input type="password" className="form-control" name="password" placeholder="Contraseña" onChange={this.handleChange} required />
                                                <FormError text="Las contraseñas no coinciden" show={boolpass}/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="password_confirmation" className="ttn">Confirmar contraseña</label>
                                                <input type="password" className="form-control" name="password_confirmation" placeholder="Confirma la contraseña" onChange={this.handleChange} required />
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="tel">Celular</label>
                                                <input type="text" className="form-control" name="tel" placeholder="Celular" onChange={this.handleChange} required />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="ci">Cédula</label>
                                                <input type="text" className="form-control" name="ci" placeholder="Cédula" onChange={this.handleChange}/>
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="nickname">Usuario</label>
                                                <input type="text" className="form-control" name="nickname" placeholder="Nombre de usuario" onChange={this.handleChange} required />
                                            </div>

                                            {/*

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="dir" className="ttn">Direccíon de envío</label>
                                                <input type="text" className="form-control" name="dir" placeholder="Dirección" onChange={this.handleChange} required />
                                            </div>

                                            */}

                                        </div>

                                        {/*

                                        <div className="form-row">
                                            
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="lat">Ubicacion</label>
                                                <input type="text" className="form-control" name="lat" 
                                                value={this.state.lat?"Lat:"+this.state.lat+" "+"Lon:"+this.state.lon:"Sin ubicación"}  
                                                disabled />
                                            </div>

                                            <div className="col-md-6 form-group">

                                                <button className="btn btn-solid btn-center ttn mt-4" onClick={this.openMap}>
                                                    Ver mapa
                                                    <i className="fa fa-map-marker map-icon"/>
                                                </button> 
                                                
                                            </div>
                                     
                                        </div>
                                        */}

                                        <h4 className="tac">Direcciones</h4>

                                        {list_directions}

                                        {
                                            list_directions.length==0?
                                            <div className="btn-box">
                                                <button className="btn btn-cart" onClick={this.handleAdd}>+</button>
                                            </div>
                                            :''
                                        }

                                        <div className="mt-2">
                                            <FormError text="Debes incluir al menos 1 dirección" show={bool_directions}/>
                                        </div>                                      

                                        <h4 className="tac ttn">(Opcional) Tarjeta de crédito, registrala si quieres usar este metodo de pago </h4>
                                        <h5 className="tac font-10 ttn">
                                            - Toda tu informacion esta protegida por medio de la pasarela de pago Kushki (https://www.kushkipagos.com)
                                            más informacion -
                                        </h5>

                                        <div className="form-row">

                                            <div className="col-sm-6 form-group">
                                                <label htmlFor="cname">Nombre</label>
                                                <input type="text" className="form-control" name="cname" placeholder="Nombre" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-6 form-group">
                                                <label htmlFor="cnum" className="ttn">Número de tarjeta</label>
                                                <input type="num" className="form-control" name="cnum" placeholder="Numero" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-3 form-group">
                                                <label htmlFor="mes">MM</label>
                                                <input type="num" className="form-control" min={1} max={12} name="mes" placeholder="MM" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-3 form-group">
                                                <label htmlFor="anno">AA</label>
                                                <input type="num" className="form-control" min={0} max={99} name="anno" placeholder="AA" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-6 form-group">
                                                <label htmlFor="cvc">CVC</label>
                                                <input type="num" className="form-control" min={0} name="cvc" placeholder="CVC" onChange={this.handleChange} />
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            {
                                                load ?
                                                    <div className="col-md-6 text-center section-b-space mt-5 no-found" >
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                                        <h3>Registrando</h3>
                                                    </div>
                                                :
                                                <div className="col-md-6 form-group">
                                                    <button type="submit" className="btn btn-solid btn-center mt-26px ttn">Crear cuenta</button>
                                                </div>
                                            }

                                            <div className="col-md-6">
                                                Al crear tu cuenta estas aceptando nuestros <a href="/terminos-y-condiciones">terminos y condiciones</a>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal open={this.state.openMap} onClose={this.closeMap} center classNames={{'modal': 'map-mb',closeButton: "close-forgotten"}} >
                        <div className="modal-dialog modal-dialog-centered w-vista-r map-dialog" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <MapTool  
                                        submit={this.setCordenates} 
                                        lat={this.state.lat} 
                                        lon={this.state.lon}
                                    />
                                </div>
                            </div>
                        </div>
                </Modal>

            </div>

        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps, { dataLogin }
)(withRouter(Register))
