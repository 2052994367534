import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import {filterCategory} from '../../actions/index'
import { connect } from 'react-redux';
import Sugerencias from '../Sugerencias';
import Modal from 'react-responsive-modal';
import Banner from '../common/Banner';

class CollectionLeftSidebar extends Component {

    state = {
        layoutColumns:3,
        open:false
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = (e) => {
		e.preventDefault()
		this.props.filterCategory([]);
		this.props.history.push("/productos/"+this.state.text)
    }

    openModal = (e) => {
        e.preventDefault()
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    render (){
        const texto =this.props.match.params.text
        return (
            <div>

                <Banner/>

                <Breadcrumb title={'Productos'}/>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={20} offsetBottom={20}>
                                        <div>
                                            <Filter/>
                                        </div>
                                    </StickyBox>
                                    {/*side-bar banner end here*/}
                                </div>
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row mb-2">
                                                                    <div className="col-4 pr-0">
                                                                        {/*
                                                                        <div className="filter-main-btn">
                                                                            <span onClick={this.openFilter}
                                                                                className="filter-btn btn btn-theme"><i
                                                                                className="fa fa-filter"
                                                                                aria-hidden="true"></i> Filtros</span>
                                                                        </div>
                                                                        */}
                                                                        <button className="btn btn-solid ttn filtro-btn filtro-display mt-2 " onClick={this.openFilter}><i
                                                                                className="fa fa-filter" aria-hidden="true"></i> Filtros
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <button type="submit" className="btn btn-solid ttn suggets-btn-fix mt-2" onClick={this.openModal}>¿Hay algo que no encuentras?</button>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Products Listing Component*/}
                                                        <ProductListing texto={texto} colSize={this.state.layoutColumns}/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal open={this.state.open} onClose={this.closeModal} center classNames={{'modal': 'mb-modal',closeButton: "close-forgotten"}} >
                        <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <Sugerencias close={this.closeModal}/>
                                </div>
                            </div>
                        </div>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps,
    {filterCategory}
)(CollectionLeftSidebar)
