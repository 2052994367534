import React, { Component } from "react";
import { toastSuccess, toastError } from "../../../../alertas";
import { connect } from "react-redux";
import {
  addToHuerta,
  addToExternal,
  removeFromHuerta,
  removeFromExternal,
} from "../../../../actions";
import horas from "../../../../api/horarios/tipoHuerta.json";
import store from "../../../../store";
import { validateHuerta } from "../../../../services";
import {
  getFechaPatronHuertaYCongelado,
  getNextMartes,
  getNextSabado,
  getDateOrdenadoHuertaCongelado,
  getNextJueves,
} from "../../../../funciones/patrones";
import { getDiaMesEspanolHuertaCongelado } from "../../../../funciones/momentEspañol";
import * as moment from "moment";
import {
  cleverAddToCart,
  cleverViewProduct,
} from "../../../../funciones/clevertap";
import { getMaxProd, priceNumber } from "../../../../funciones/general";
import PriceListingModal from "../PriceListingModal";
import NotAvailableProd from "../NotAvailableProd";
import { isActualDirectionLocal } from "../../../../funciones/cities";

class ItemTipoHuerta extends Component {
  constructor(props) {
    super(props);

    if (props.huerta.programacion) {
      this.state = {
        hora: props.huerta.programacion.hora,
        cantidad: props.inCart ? props.inCart.qty : 1,
        radio: props.huerta.programacion.dia,
      };
    } else {
      this.state = {
        hora: horas[0].hora,
        cantidad: props.inCart ? props.inCart.qty : 1,
        radio: this.getRadioValue(getDateOrdenadoHuertaCongelado()[0].day),
      };
    }
  }

  agregarCarrito = (product) => {
    const {
      addToHuerta,
      huerta,
      external,
      removeFromHuerta,
      inCart,
      removeFromExternal,
      addToExternal,
      actualDirectionLocal,
    } = this.props;
    const { cantidad, hora, radio } = this.state;
    const numero_plato = parseInt(cantidad);

    if (inCart) {
      if (actualDirectionLocal) {
        removeFromHuerta(inCart);
      } else {
        removeFromExternal(inCart);
      }
    }

    if (validateHuerta(numero_plato)) {
      cleverAddToCart(product);

      if (actualDirectionLocal) {
        addToHuerta(product, huerta.programacion, numero_plato);
      } else {
        addToExternal(product, external.programacion, numero_plato);
      }

      toastSuccess("Carrito actualizado");
    } else {
      toastError("Limite de articulos de huerta alcanzado");
    }

    this.props.close();
  };

  handleSubmit = async (e, product) => {
    e.preventDefault();
    this.agregarCarrito(product);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    const { product } = this.props;
    cleverViewProduct(product);
  }

  restar = (e) => {
    e.preventDefault();
    this.setState({
      cantidad: this.state.cantidad - 1,
    });
  };

  sumar = (e) => {
    e.preventDefault();
    this.setState({
      cantidad: this.state.cantidad + 1,
    });
  };

  getRadioCheck = (day) => {
    const { radio } = this.state;

    switch (day) {
      case "martes":
        return radio == "mar";
      case "jueves":
        return radio == "jue";
      case "sabado":
        return radio == "sab";
      default:
        return radio == "sab";
    }
  };

  getRadioDate = (day) => {
    switch (day) {
      case "martes":
        return getDiaMesEspanolHuertaCongelado(getNextMartes());
      case "jueves":
        return getDiaMesEspanolHuertaCongelado(getNextJueves());
      case "sabado":
        return getDiaMesEspanolHuertaCongelado(getNextSabado());
      default:
        return getDiaMesEspanolHuertaCongelado(getNextSabado());
    }
  };

  getRadioValue = (day) => {
    switch (day) {
      case "martes":
        return "mar";
      case "jueves":
        return "jue";
      case "sabado":
        return "sab";
      default:
        return "sab";
    }
  };

  render() {
    //console.log(moment().format("DD-MM-YYYY HH:mm:ss"))

    const { product, symbol } = this.props;
    const { cantidad, radio } = this.state;

    const list_horas = horas.map((value, index) => {
      return (
        <option value={value.hora} key={index}>
          {value.hora}
        </option>
      );
    });

    const list_radio = getDateOrdenadoHuertaCongelado().map((value, i) => {
      return (
        <label className="radio-inline" key={i}>
          <input
            type="radio"
            name="radio"
            value={this.getRadioValue(value.day)}
            onChange={this.handleChange}
            checked={this.getRadioCheck(value.day)}
          />

          {this.getRadioDate(value.day)}
        </label>
      );
    });

    return (
      <div
        className="modal-dialog modal-lg modal-dialog-centered w-vista-r"
        role="document"
      >
        <div className="modal-content quick-view-modal">
          <div className="modal-body">
            <div className="row mb-none">
              <div className="col-lg-6  col-xs-12">
                <div className="quick-view-img">
                  <img
                    src={
                      product.variants
                        ? this.state.image
                          ? this.state.image
                          : product.variants[0].images
                        : product.pictures[0]
                    }
                    alt=""
                    className="modal-image-style"
                  />
                </div>
              </div>
              <div className="col-lg-6 rtl-text">
                <div className="product-right">
                  <h2 className="ttn"> {product.name} </h2>
                  <div className="border-product">
                    <p>{product.description}</p>
                  </div>
                  <PriceListingModal product={product} />
                  {product.variants ? (
                    <ul className="color-variant">
                      {product.variants.map((vari, i) => (
                        <li
                          className={vari.color}
                          key={i}
                          title={vari.color}
                          onClick={() => this.onClickHandle(vari.images)}
                        />
                      ))}
                    </ul>
                  ) : (
                    ""
                  )}
                  <form onSubmit={(e) => this.handleSubmit(e, product)}>
                    <div className="form-row">
                      {product.stock > 0 ? (
                        <>
                          <div className="col-6">
                            <label
                              htmlFor="cantidad"
                              className="col-form-label cantidad-modal-label"
                            >
                              Cantidad:
                            </label>
                            <div className="input-group">
                              <span className="input-group-prepend">
                                <button
                                  type="button"
                                  className="btn quantity-left-minus"
                                  onClick={(e) => this.restar(e, "cantidad")}
                                  data-type="minus"
                                  data-field=""
                                  disabled={cantidad <= 1 ? true : false}
                                >
                                  <i className="fa fa-angle-left" />
                                </button>
                              </span>
                              <input
                                type="text"
                                name="cantidad"
                                value={cantidad}
                                readOnly={true}
                                className="form-control input-number tac"
                              />
                              <span className="input-group-prepend">
                                <button
                                  className="btn quantity-right-plus"
                                  onClick={(e) => this.sumar(e, "cantidad")}
                                  data-type="plus"
                                  disabled={
                                    cantidad >= getMaxProd(product)
                                      ? true
                                      : false
                                  }
                                >
                                  <i className="fa fa-angle-right" />
                                </button>
                              </span>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="product-buttons">
                              <button className="btn btn-solid ttn btn-modal-product">
                                Añadir al carrito
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <NotAvailableProd />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="mb-display fluio-font">
              <div className="modal-prod-title">{product.name}</div>

              <div className="modal-prod-name">{product.aliado}</div>

              <div className="quick-view-img">
                <img
                  src={
                    product.variants
                      ? this.state.image
                        ? this.state.image
                        : product.variants[0].images
                      : product.pictures[0]
                  }
                  alt=""
                  className="modal-image-style"
                />
              </div>

              <PriceListingModal product={product} />

              <div className="modal-prod-desc">{product.description}</div>

              {product.stock > 0 ? (
                <>
                  <div className="modal-container-qty">
                    <div>
                      <span className="modal-cantidad-text">Cantidad</span>

                      <span
                        className="modal-min"
                        onClick={(e) => {
                          if (cantidad > 1) {
                            this.restar(e, "cantidad");
                          }
                        }}
                      >
                        -
                      </span>

                      <span
                        className="modal-plus"
                        onClick={(e) => {
                          if (cantidad < getMaxProd(product)) {
                            this.sumar(e, "cantidad");
                          }
                        }}
                      >
                        +
                      </span>

                      <span className="modal-cantidad-number">{cantidad}</span>
                    </div>
                  </div>

                  <div className="container-button-prod">
                    <button
                      onClick={(e) => this.handleSubmit(e, product)}
                      className="btn modal-btn-add ttn"
                      style={{ width: "100%" }}
                    >
                      Añadir al carrito
                    </button>
                  </div>
                </>
              ) : (
                <NotAvailableProd />
              )}
            </div>

            <div className="mb-display xprod" onClick={this.props.close}>
              X
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  total: state.data.products,
  carrito: state.huerta.cart,
  symbol: state.data.symbol,
  huerta: state.huerta,
  external: state.external,
  actualDirectionLocal: isActualDirectionLocal(),
});

export default connect(
  mapStateToProps,
  { addToHuerta, removeFromHuerta, removeFromExternal, addToExternal }
)(ItemTipoHuerta);
