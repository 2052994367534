import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link, withRouter} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterOne extends Component {

    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }



    render () {

        const {categories}=this.props

        const list_cate=categories.filter((value)=>value.pri<77 && value.jerarquia!=2).map((val)=>
            <div key={val.id}>

                <Link to={'/categoria/'+val.seo} className='black-link'>
                    {val.name}
                </Link>
            </div>
        )

        

        return (
            <>
            <footer className="footer-light mb-display">

                <div className="sub-footer ">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-4 col-md-4 col-sm-12">
                                <div className="footer-end">
                                    <p><i className="fa fa-copyright" aria-hidden="true"></i>Fluio: La App que te cuida</p>
                                    <p className="mb-none"><a href="/terminos-y-condiciones">Términos y condiciones</a></p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-md-4 col-sm-12 tac mb-none">

                                <a href='https://play.google.com/store/apps/details?id=com.avssol.dev.fluio&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <img className="google-badge" alt='Disponible en Google Play' src='/assets/images/google-play-badge.png'/>
                                </a>

                                <a href='https://apps.apple.com/us/app/fluio/id1499893880?ls=1'>
                                    <img className="apple-badge" alt='Disponible en Google Play' src='/assets/images/app-store-badge.svg'/>
                                </a>

                            </div>

                            <div className="col-xl-4 col-md-4 col-sm-12">
                                <div className="payment-card-bottom">
                                    <ul>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`} alt="" /></a>
                                        </li>
                                        <li>
                                            <a href="#"><img className="payment-icon" src={`${process.env.PUBLIC_URL}/assets/images/icon/pse.png`} alt="" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>

            <footer className="shop-footer mb-none">
                <div className="footer-container">

                    <div className="row">

                        <div className="col-4">

                            <p className="mb-none footer-s1">
                                <a href="/terminos-y-condiciones" className='footer-terms'>
                                    Términos y condiciones
                                </a>
                            </p>

                            <div className="footer-s2">
                                Línea de atención al cliente
                                <br/>
                                <a href="https://wa.me/573107139466" className="footer-ws">
                                    +(57) 3107139466
                                </a>
                            </div>

                        </div>

                        <div className="col-4">

                            <div className="footer-s1">
                                Contáctanos
                                <br/>
                                <a href="mailto:info@fluio.com.co" data-toggle="tooltip" title="info@fluio.com.co" className="footer-ws">
                                    info@fluio.com.co
                                </a>
                            </div>

                            <div className="footer-s2">
                                Categorías
                                <br/>
                                <div className='mt-2 footer-cate'>

                                    {list_cate}

                                </div>
                            </div>
                            
                        </div>

                        <div className="col-4 tac">

                                <a href='https://play.google.com/store/apps/details?id=com.avssol.dev.fluio&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                    <img className="google-badge" alt='Disponible en Google Play' src='/assets/images/google-play-badge.png'/>
                                </a>

                                <div className="mt-3"></div>

                                <a href='https://apps.apple.com/us/app/fluio/id1499893880?ls=1' className='mb-3'>
                                    <img className="apple-badge" alt='Disponible en Google Play' src='/assets/images/app-store-badge.svg'/>
                                </a>
                            
                        </div>

                    </div>

                </div>
            </footer>
            </>
        )
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
    categories: state.categories.categories,
    user:state.customer.data,
    headers: state.customer.headers,
    load: state.customer.load_home,
    products: state.data.products,
    ordenes: state.orden.pendientes.length,
})

export default connect(
    mapStateToProps,
    { }
)(withRouter(FooterOne));
