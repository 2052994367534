import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLocationProducts } from '../../funciones/cities';
import { getData } from '../../servicios';
import { addToCart, addToWishlist, addToCompare, filterCategory } from '../../actions';

export class DeliveryForm extends Component {
    constructor(props) {
        super(props);
        this.state = { radio: 'sameDay' };
    }

    handleRadio = (event) => {
        const { value } = event.target;
        this.setState({
            radio: value,
        });
    };

    submit = (e) => {
        e.preventDefault();
        this.props.updatePrograming(this.state.radio);
        this.props.close();
    };

    render() {
        const {} = this.state;

        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal fluio-font">
                    <div>
                        <div className="header-title-d-sugerido tac-desk">
                            Escoge como quieres recibir tus productos
                        </div>
                        <div className="mt-2">
                            Tu pedido incluye productos de Huerta Orgánica programada y productos de entrega inmediata.
                            Selecciona cómo te gustaría recibirlos
                        </div>
                        <form className="mt-4">
                            <div className="form-group tac-desk margin-zero">
                                <label className="container-radio mb-4">
                                    Recibir todos los productos el mismo día
                                    <input
                                        type="radio"
                                        value={'sameDay'}
                                        onChange={this.handleRadio}
                                        checked={this.state.radio == 'sameDay'}
                                    />
                                    <span className="checkmark-radio delivery-radio-mb" />
                                </label>
                            </div>

                            <div className="form-group tac-desk margin-zero">
                                <label className="container-radio mb-4">
                                    Recibir los productos inmediatos ahora y los de Huerta Orgánica después
                                    <input
                                        type="radio"
                                        value={'diffDay'}
                                        onChange={this.handleRadio}
                                        checked={this.state.radio == 'diffDay'}
                                    />
                                    <span className="checkmark-radio delivery-radio-mb" />
                                </label>
                            </div>

                            <button
                                type="button"
                                onClick={this.submit}
                                className="btn modal-btn-add ttn"
                                style={{ width: '100%' }}
                            >
                                Aceptar
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    products: getLocationProducts(),
    symbol: state.data.symbol,
});

export default connect(
    mapStateToProps,
    { addToCart, addToWishlist, addToCompare, filterCategory }
)(withRouter(DeliveryForm));
