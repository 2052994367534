import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { removeFromCena, incrementCena, decrementCena } from "../../../actions";
import {
  getTipoCategoria,
  validateCena,
  calcularRestaurante,
  getTotalCena,
  getDeliveryCost,
} from "../../../services";
import Normal from "../programacion/Normal";
import { toastError } from "../../../alertas";
import Adicionales from "../programacion/Adicionales";
import ProgramarCena from "../programacion/ProgramarCena";

class CartCena extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openAdd: false,
      product: null,
    };
  }

  openCena = () => {
    this.setState({
      open: true,
    });
  };

  closeCena = () => {
    this.setState({
      open: false,
    });
  };

  openAdd = () => {
    this.setState({
      openAdd: true,
    });
  };

  closeAdd = () => {
    this.setState({
      openAdd: false,
    });
  };

  handleModal = (e) => {
    e.preventDefault();
    this.openCena();
  };

  handleAdd = (e, product) => {
    e.preventDefault();

    this.setState(
      {
        product: product,
      },
      () => {
        this.openAdd();
      }
    );
  };

  agregar = (item) => {
    if (validateCena(1)) {
      this.props.incrementCena(item, 1);
    } else {
      toastError("Limite de platos superado");
    }
  };

  render() {
    const { cartItems, symbol, total, delivery, tipo_categoria } = this.props;

    const img_categorias = tipo_categoria.map((value, index) => {
      return (
        <img src={value.image} alt="" key={index} className="mb-cate-cart" />
      );
    });

    return cartItems.length > 0 ? (
      <>
        <hr className="hr-cart" />
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12" id="accordion">
                {cartItems.length >= 3 ? (
                  <div className="card-header" id="headingOne">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <h5 className="mb-0">
                          <button
                            className="btn btn-link theme-color wrap-buttom mt-1"
                            data-toggle="collapse"
                            data-target="#collapseRestaurante"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            {img_categorias}
                          </button>
                        </h5>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-sm-6 col-8">
                      <h5 className="mb-0">
                        <button className="btn btn-link theme-color wrap-buttom mt-1">
                          {img_categorias}
                        </button>
                      </h5>
                    </div>

                    <div className="col-4 col-sm-6 center flr">
                      <div className="row flr envio-box2">
                        <div className="d-flex flex-row center">
                          <strong className="envio-label mb-font-15">
                            Editar envío
                          </strong>
                          <i
                            className="fa fa-clock-o pointer clock-icon"
                            onClick={(e) => {
                              this.handleModal(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  id="collapseRestaurante"
                  className={cartItems.length >= 3 ? "collapse show" : ""}
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <br />

                  {cartItems.length >= 3 ? (
                    <div className="row flr envio-box" />
                  ) : (
                    ""
                  )}

                  <table className="table cart-table table-responsive-xs fix-table-cart">
                    <thead className="thead">
                      <tr className="table-head">
                        <th scope="col">
                          <strong className="mb-none">Imagen</strong>
                          <strong className="mb-display">Producto</strong>
                        </th>
                        <th scope="col">
                          <strong className="mb-none">Nombre</strong>
                          <strong className="mb-display">Acciones</strong>
                        </th>
                        <th scope="col">
                          <strong className="mb-none">Precio</strong>
                          <strong className="mb-display">Precios</strong>
                        </th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Extras</th>
                        <th scope="col">Borrar</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    {cartItems.map((item, index) => {
                      return (
                        <tbody key={index} className="tbody">
                          <tr>
                            <td>
                              <Link to="#" onClick={(e) => e.preventDefault()}>
                                <img
                                  src={
                                    item.variants
                                      ? item.variants[0].images
                                      : item.pictures[0]
                                  }
                                  alt=""
                                  className="fix-img-cart"
                                />
                              </Link>
                              <Link
                                to="#"
                                className="mb-display"
                                onClick={(e) => e.preventDefault()}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="mb-p0">
                              <Link
                                to="#"
                                className="mb-none"
                                onClick={(e) => e.preventDefault()}
                              >
                                {item.name}
                              </Link>

                              {/*este bloque se utiliza para el responsive cuando la pantlla es pequeña*/}
                              <div className="mobile-cart-content row">
                                <div className="col-xs-3">
                                  <div className="qty-box">
                                    <div className="input-group">
                                      <span className="input-group-prepend">
                                        <button
                                          type="button"
                                          className="btn quantity-left-minus"
                                          onClick={() =>
                                            this.props.decrementCena(item.id)
                                          }
                                          data-type="minus"
                                          data-field=""
                                        >
                                          <i className="fa fa-angle-left" />
                                        </button>
                                      </span>
                                      <input
                                        type="text"
                                        name="quantity"
                                        value={item.qty}
                                        readOnly={true}
                                        className="form-control input-number"
                                      />

                                      <span className="input-group-prepend">
                                        <button
                                          className="btn quantity-right-plus"
                                          onClick={() => this.agregar(item)}
                                          data-type="plus"
                                          disabled={
                                            item.qty >= 5 ? true : false
                                          }
                                        >
                                          <i className="fa fa-angle-right" />
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-3">
                                  <Link
                                    to="/#"
                                    onClick={(e) => {
                                      this.handleAdd(e, item);
                                    }}
                                    className="mt-2"
                                  >
                                    <img
                                      src="/assets/images/adicionales.png"
                                      className="image-30"
                                      alt=""
                                    />
                                  </Link>
                                </div>
                                <div className="col-xs-3 mt-2">
                                  <h2 className="td-color">
                                    <span
                                      className="icon pointer"
                                      onClick={() =>
                                        this.props.removeFromCena(item)
                                      }
                                    >
                                      <i className="icon-close mobile-x" />
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </td>

                            <td className="mb-display">
                              <div className="col-xs-3 mt-5">
                                <h2 className="td-color font-15">
                                  {symbol}
                                  {item.price}
                                </h2>
                              </div>
                              <h2 className="td-color font-15">
                                Total:{symbol + calcularRestaurante(item)}
                              </h2>
                            </td>

                            {/*este bloque se utiliza para el responsive cuando la pantlla es "normal" */}
                            <td>
                              <h2>
                                {symbol}
                                {item.price}
                              </h2>
                            </td>

                            <td>
                              <div className="qty-box">
                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-left-minus"
                                      onClick={() =>
                                        this.props.decrementCena(item.id)
                                      }
                                      data-type="minus"
                                      data-field=""
                                    >
                                      <i className="fa fa-angle-left" />
                                    </button>
                                  </span>
                                  <input
                                    type="text"
                                    name="quantity"
                                    value={item.qty}
                                    readOnly={true}
                                    className="form-control input-number"
                                  />

                                  <span className="input-group-prepend">
                                    <button
                                      className="btn quantity-right-plus"
                                      onClick={() => this.agregar(item)}
                                      data-type="plus"
                                      disabled={item.qty >= 5 ? true : false}
                                    >
                                      <i className="fa fa-angle-right" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              {item.qty >= item.stock
                                ? "Se alcanzo el límite"
                                : ""}
                            </td>
                            <td>
                              <Link
                                to="/#"
                                onClick={(e) => {
                                  this.handleAdd(e, item);
                                }}
                              >
                                <img
                                  src="/assets/images/adicionales.png"
                                  className="image45"
                                  alt=""
                                />
                              </Link>
                            </td>
                            {/*
                                                        <td>
                                                            <Link to="/#" onClick={(e)=>{this.handleModal(e,item)}}>
                                                                <img src="/assets/images/clock.png" className="image45" alt="" />
                                                            </Link>
                                                        </td>
                                                        */}
                            <td>
                              <span
                                className="icon pointer"
                                onClick={() => this.props.removeFromCena(item)}
                              >
                                <i className="fa fa-times" />
                              </span>
                            </td>
                            <td>
                              <h2 className="td-color">
                                {symbol} {calcularRestaurante(item)}
                              </h2>
                            </td>
                          </tr>

                          {/*
                                                    <tr >
                                                        <td>
                                                            Hora de entrega: 14:30
                                                            Del 24/02/2020 al 29/02/2020
                                                            Dias: Lunes,Martes

                                                        </td>
                                                    </tr>
                                                    */}
                        </tbody>
                      );
                    })}
                  </table>

                  <table className="table cart-table table-responsive-md sub-fix">
                    <tfoot>
                      <tr>
                        <td>Delivery :</td>
                        <td>
                          <h2 className="mb-mt-1">
                            {cartItems.length > 0
                              ? symbol + delivery
                              : symbol + 0}
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td>Sub total :</td>
                        <td>
                          <h2 className="mb-mt-1">{symbol + total}</h2>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Modal
            classNames={{ modal: "mb-modal" }}
            open={this.state.open}
            onClose={this.closeCena}
          >
            <ProgramarCena close={this.closeCena} />
          </Modal>
          <Modal
            classNames={{ modal: "mb-modal" }}
            open={this.state.openAdd}
            onClose={this.closeAdd}
          >
            <Adicionales product={this.state.product} close={this.closeAdd} />
          </Modal>
        </section>
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.cena.cart,
  symbol: state.data.symbol,
  total: getTotalCena(state.cena.cart),
  delivery: getDeliveryCost(6),
  tipo_categoria: getTipoCategoria(6),
});

export default connect(
  mapStateToProps,
  { removeFromCena, incrementCena, decrementCena }
)(CartCena);
