import React, { Component } from 'react'
import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { lonBogota, latBogota } from '../../constants/data';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point';
import TileJSON from 'ol/source/TileJSON';
import VectorSource from 'ol/source/Vector';
import {Icon, Style, Fill, Circle} from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import mapIcon from '../../assets/images/map-icon.png'
import * as olTransform from 'ol/transform';
import FormError from './FormError';

export class MapTool extends Component {


    constructor(){
        super()
        this.state={
            actual_layer:'',
            loading:false,
            lon:'',
            lat:'',
            error:false
        }
    }

    getLayer = (lon,lat) => {

        var iconFeature = new Feature({
            geometry: new Point([lon, lat]),
            population: 4000,
            rainfall: 500
        });
            
        var iconStyle = new Style({
            image: new Circle({
                radius: 9,
                fill: new Fill({color: 'red'})
            })
        })
            
        iconFeature.setStyle(iconStyle);
            
        var vectorSource = new VectorSource({
            features: [iconFeature]
        });
            
        var vectorLayer = new VectorLayer({
            source: vectorSource
        });

        return vectorLayer

    }
    
    

    componentDidMount(){

        const {lon,lat}=this.props

          
        var rasterLayer = new TileLayer({
            source: new OSM()
        })

        let layers=[rasterLayer]

        if(this.props.lat){

            let initial_layer=this.getLayer(this.props.lon,this.props.lat)

            layers.push(initial_layer)

            this.setState({
                actual_layer:initial_layer
            })
        }

        var map = new Map({
            layers: layers,
            target: document.getElementById('map'),
            view: new View({
                projection: 'EPSG:4326',
                center: [
                    lon?lon:lonBogota,
                    lat?lat:latBogota
                ],
                zoom: 13
            })
        });

        map.on('singleclick', (evt) => {

            //console.log(evt.coordinate)
            let layer=this.getLayer(evt.coordinate[0],evt.coordinate[1])

            map.removeLayer(this.state.actual_layer)
            map.addLayer(layer)

            this.setState({
                actual_layer:layer,
                lat:evt.coordinate[1],
                lon:evt.coordinate[0]
            })
              
        });

    }

    submit = () => {

        this.setState({
            error:false
        })

        if(this.state.lat){

            this.props.submit(this.state.lon,this.state.lat)
       
        }else{

            this.setState({
                error:true
            })

        }

        
        
    }
    

    render() {
        return (
            <div>
                {
                    this.state.loading?
                        <div className="row h-196">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                <h3>{"Guardando"}</h3>
                            </div>
                        </div>
                    :
                    <>
                        <h2>Indica tu ubicacion dando click</h2>
                        <p>Actualmente fluio solo entrega domicilios en bogota</p>
                        <div id="map" style={{ width: "100%", height: "360px" }}></div>
                        <FormError show={this.state.error} text="Debes indicar un punto en el mapa" />
                        <div className="text-center mt-2">
                            <button className="btn btn-cart" onClick={this.submit} >Aceptar</button>
                        </div>
                    </>
                }
                
            </div>
        )
    }
}

export default MapTool
