import React, { Component } from 'react'
import { getArrayByTipo, getDiffHoraria, getDiffMinutes } from '../../../../servicios'
import { addToCore,addToSnack,addToHuerta } from '../../../../actions'
import { connect } from 'react-redux'
import { getArrayForRepeat, getDataOfPedido } from '../../../../funciones/cart'
import { isUnaHoraUntil, isAfterHora } from '../../../../funciones/general'
import horasCore from '../../../../api/horarios/tipoCore.json'
import horasSnack from '../../../../api/horarios/tipoSnack.json'
import horasHuerta from '../../../../api/horarios/tipoHuerta.json'
import { detRadioPatronCore, getArrayPatronCore, getArrayDateCore,getListaHorasRepeticionCore, getNextJueves, getNextSabado, getDateOrdenadoHuertaCongelado,getNextMartes  } from '../../../../funciones/patrones'
import FormError from '../../../common/FormError'
import { getDiaMesEspanol, getDiaMesEspanolHuertaCongelado } from '../../../../funciones/momentEspañol'
import * as moment from 'moment';
import { limpiarCarrito } from '../../../../services'
import { withRouter } from 'react-router-dom'

export class RepetirPedido extends Component {

    constructor(props){
        super(props)
        this.state={

            radioCore:(isUnaHoraUntil("18:00") && isAfterHora("9:30")) ? 1 : 2,
            listadoCore:getListaHorasRepeticionCore(horasCore),
            fechaCore:getArrayDateCore(horasCore)[0],
            horaCore:getListaHorasRepeticionCore(horasCore)[0].hora,

            radioSnack:(isUnaHoraUntil("18:00") && isAfterHora("9:30")) ? 1 : 2,
            listadoSnack:getListaHorasRepeticionCore(horasSnack),
            fechaSnack:getArrayDateCore(horasSnack)[0],
            horaSnack:getListaHorasRepeticionCore(horasSnack)[0].hora,

            horaHuerta:horasHuerta[0].hora,
            radioHuerta:this.getRadioValue(getDateOrdenadoHuertaCongelado()[0].day)

        }
    }

    handleRadioCore = (event) => {
        const {value}= event.target
        this.setState({
            radioCore: value
        })

    }

    handleRadioSnack= (event) => {
        const {value}= event.target
        this.setState({
            radioSnack: value
        })

    }

    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    //metodo para cargar las horas
    handleHora = (e) => {

        const {value}= e.target
        const today=moment().format('YYYY-MM-DD')

        //caso mismo dia
        if(today==value){

            const list_horas=horasCore.filter((value,index)=>{
                return (getDiffMinutes(value.hora)>170)
            })

            //para llenar las horas al escoger una fecha
            if(list_horas.length>0){
                this.setState({
                    listadoCore:list_horas,
                    horaCore:list_horas[0].hora
                })
            //caso que deja la lista vacio si no hay horas disponibles
            }else{
                this.setState({
                    listadoCore:list_horas
                })
            }

        //caso un dia programado en el futuro
        }else{
            this.setState({
                listadoCore:horasCore,
                horaCore:horasCore[0].hora
            })

        }
        
    }

    handleHoraSnack = (e) => {

        const {value}= e.target
        const today=moment().format('YYYY-MM-DD')

        //caso mismo dia
        if(today==value){

            const list_horas=horasSnack.filter((value,index)=>{
                return (getDiffMinutes(value.hora)>170)
            })

            //para llenar las horas al escoger una fecha
            if(list_horas.length>0){
                this.setState({
                    listadoSnack:list_horas,
                    horasSnack:list_horas[0].hora
                })
            //caso que deja la lista vacio si no hay horas disponibles
            }else{
                this.setState({
                    listadoSnack:list_horas
                })
            }

        //caso un dia programado en el futuro
        }else{
            this.setState({
                listadoSnack:horasSnack,
                horasSnack:horasSnack[0].hora
            })

        }
        
    }

    submit = (e) => {

        e.preventDefault()

        const {

            radioSnack,
            fechaSnack,
            horaSnack,

            radioCore,
            fechaCore,
            horaCore,

            radioHuerta,
            horaHuerta,
            
        }=this.state

        const {
            orden,
            addToCore,
            addToSnack,
            addToHuerta
        }=this.props


        const productos=orden.productos

        limpiarCarrito()

        const snack=getArrayByTipo(productos,0)
        const new_snack=getArrayForRepeat(snack)

        const core=getArrayByTipo(productos,1)
        const new_core=getArrayForRepeat(core)

        const huerta=getArrayByTipo(productos,4)
        const new_huerta=getArrayForRepeat(huerta)
        

        let programacionSnack

        if(radioCore==1){
            programacionSnack=1
        }else{
            programacionSnack={
                hora:horaSnack,
                fecha:fechaSnack
            }
        }

        new_snack.map((value)=>
            addToSnack(value,programacionSnack,getDataOfPedido(value,snack).amount)
        )


        let programacionCore

        if(radioCore==1){
            programacionCore=1
        }else{
            programacionCore={
                hora:horaCore,
                fecha:fechaCore
            }
        }


        new_core.map((value)=>
            addToCore(value,programacionCore,getDataOfPedido(value,core).amount)
        )


        let programacionHuerta

        programacionHuerta={
            hora:horaHuerta,
            dia:radioHuerta
        }

        new_huerta.map((value)=>
            addToHuerta(value,programacionHuerta,getDataOfPedido(value,huerta).amount)
        )

        this.props.history.push("/cart")

    }

    getRadioCheck = (day) => {

        const {radioHuerta}=this.state

        switch (day) {
            case "martes":
                return radioHuerta=="mar"
            case "jueves":
                return radioHuerta=="jue"
            case "sabado":
                return radioHuerta=="sab"
            default:
                return radioHuerta=="sab"
        }

    }

    getRadioValue = (day) => {

        switch (day) {
            case "martes":
                return "mar"
            case "jueves":
                return "jue"
            case "sabado":
                return "sab"
            default:
                return "sab"
        }
        
    }

    getRadioDate = (day) => {

        switch (day) {
            case "martes":
                return getDiaMesEspanolHuertaCongelado(getNextMartes())
            case "jueves":
                return getDiaMesEspanolHuertaCongelado(getNextJueves())
            case "sabado":
                return getDiaMesEspanolHuertaCongelado(getNextSabado())
            default:
                return getDiaMesEspanolHuertaCongelado(getNextSabado())
        }
        
    }
    


    render() {

        const {
            listadoCore,
            listadoSnack
        }=this.state
        const {orden}=this.props
        const productos=orden.productos

        const snack=getArrayByTipo(productos,0)
        const core=getArrayByTipo(productos,1)

        const new_snack=getArrayForRepeat(snack)
        const new_core=getArrayForRepeat(core)

        const huerta=getArrayByTipo(productos,4)
        const new_huerta=getArrayForRepeat(huerta)


        const list_horas_core=listadoCore.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_fechas_core=getArrayDateCore(horasCore).map((value,index)=>{

            return(
                <option  value={value} key={index}>{getDiaMesEspanol(value)}</option>
            )

        })

        const list_horas_snack=listadoSnack.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_fechas_snack=getArrayDateCore(horasSnack).map((value,index)=>{

            return(
                <option  value={value} key={index}>{getDiaMesEspanol(value)}</option>
            )

        })

        const list_horas_huerta=horasHuerta.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_radio_huerta=getDateOrdenadoHuertaCongelado().map((value,i)=>{
            return(

                <label className="form-check p-0 radio-style" key={i}>
                    <input 
                        type="radio" 
                        name="radioHuerta"  
                        value={this.getRadioValue(value.day)} 
                        onChange={this.handleChange} 
                        checked={this.getRadioCheck(value.day)}
                    />

                    {this.getRadioDate(value.day)}

                </label>

            )
        })


        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal fluio-font">

                    <div>
                        <div className="header-title tac-desk" >Repetir pedido</div>
                    </div>

                    <form onSubmit={(e)=>e.preventDefault()}>

                        {
                            new_snack.length>0?
                            <>
                                <div>
                                    <div className="body-title tac-desk">Snacks</div>
                                </div>
                                <div className="form-group tac-desk margin-zero">

                                    {
                                        isUnaHoraUntil("18:30") && isAfterHora("9:00") ? 

                                        <label className="form-check p-0 margin-radio color-grey radio-style">
                                            <input type="radio"  value={1} onChange={this.handleRadioSnack} checked={this.state.radioSnack == 1} />Entrega en menos de 90 minutos
                                        </label>
                                        
                                        :
                                        ''
                                    }

                                    <label className="p-0 form-check color-grey radio-style">
                                        <input type="radio"  value={2} onChange={this.handleRadioSnack} checked={this.state.radioSnack == 2} />Envío programado
                                    </label>

                                </div>

                                {this.state.radioSnack==2 ?

                                    <div className="form-row margin-zero">
                                        
                                        <div className="form-group col-8 pl-0">
                                            <select  name="fechaSnack" onChange={(e)=>{
                                                    this.handleChange(e)
                                                    this.handleHoraSnack(e)
                                            }} value={this.state.fechaSnack || ''} className="form-control input-style" required>
                                                {list_fechas_snack}
                                            </select>
                                        </div>

                                        <div className="form-group col-4 padding-0">
                                            <select className="input-style" name="horaSnack" onChange={this.handleChange} value={this.state.horaSnack || ''} className="form-control input-style" required>
                                                {list_horas_snack}
                                            </select>
                                        </div>
                                      
                                    </div>
                                : '' }
                            </>
                            :''
                        }

                        {
                            new_core.length>0?
                            <>
                                <div>
                                    <div className="body-title tac-desk">Mercado</div>
                                </div>
                                <div className="form-group tac-desk margin-zero">

                                    {
                                        isUnaHoraUntil("18:30") && isAfterHora("9:00") ? 

                                        <label className="p-0 form-check radio-style">
                                            <input type="radio"  value={1} onChange={this.handleRadioCore} checked={this.state.radioCore == 1} />Entrega en menos de 90 minutos
                                        </label>
                                        
                                        :
                                        ''
                                    }

                                    <label className="p-0 form-check radio-style">
                                        <input type="radio"  value={2} onChange={this.handleRadioCore} checked={this.state.radioCore == 2} />Envío programado
                                    </label>

                                </div>

                                {this.state.radioCore==2 ?

                                    <div className="form-row margin-zero">
                                        
                                        <div className="form-group col-8 pl-0">
                                            <select  name="fechaCore" onChange={(e)=>{
                                                    this.handleChange(e)
                                                    this.handleHora(e)
                                            }} value={this.state.fechaCore || ''} className="form-control input-style" required>
                                                {list_fechas_core}
                                            </select>
                                        </div>

                                        <div className="form-group col-4 padding-0">
                                            <select  name="horaCore" onChange={this.handleChange} value={this.state.horaCore || ''} className="form-control input-style" required>
                                                {list_horas_core}
                                            </select>
                                        </div>
                                      
                                    </div>
                                : '' }
                            </>
                            :''
                        }

                        {
                            new_huerta.length>0?
                            <>
                                <div>
                                    <div className="body-title tac-desk">Huerta orgánica</div>
                                </div>
                                <div className="form-group tac-desk">
                                    {list_radio_huerta}
                                </div>
                                <div className="form-group">
                                    <select name="horaHuerta" onChange={this.handleChange} value={this.state.horaHuerta || ''} className="form-control input-style" required>
                                        {list_horas_huerta}
                                    </select>
                                </div>
                            </>
                            :''
                        }

                        <div className="tac mt-5">
                            <button  className="btn btn-solid ttn" style={{width: '100%'}} onClick={this.submit} >Generar Pedido</button>                                                  
                        </div>

                    </form>

                    

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
    categories: state.categories.categories,
    user:state.customer.data,
    headers: state.customer.headers,
    load: state.customer.load_home,
    productList: state.data.products,
})

export default connect(
    mapStateToProps, {addToCore,addToSnack,addToHuerta}
)(withRouter(RepetirPedido))
