import React, { Component } from 'react'
import { getTotalOrden } from '../../../../../servicios'
import { getDiaMesEspanol } from '../../../../../funciones/momentEspañol'
import { getTimeRange } from '../../../../../funciones/patrones'
import { formatNumber } from '../../../../../funciones/general'

class OrdenCore extends Component {
    render() {

        const {productos,match}=this.props

        if(productos.length>0){

            return (
                <div>
                    
                    <div className="pedidos-title">Mercado</div>

                    <div className="row">
                        <div className="col-4">
                            <strong>Producto</strong>
                        </div>
                        <div className="col-8">
                            <strong>Costo</strong>                       
                        </div>
                    </div>
                    <br/>

                    {
                        productos.map((item, index) => {
                            return (

                                <div key={index}>

                                    <div className="row" >
                                        <div className="col-4">
                                            {item.producto} (×{item.amount})  
                                        </div>
                                        <div className="col-8">
                                            ${formatNumber(item.total)}
                                        </div>
                                        {
                                            item.com ?
                                                <div className="col-12">
                                                    <strong>Comentario:</strong>
                                                    {item.com}
                                                </div>
                                            :''
                                        } 
                                    </div>

                                    <hr/>

                                </div>
                            
                            )
                        })
                    }

                    <div className="row">
                        <div className="col-4">
                            <strong>Entrega:</strong>
                        </div>
                        <div className="col-4">
                            {
                                productos[0].hora=="1" ?
                                "Entrega en menos de 90 minutos"
                                :getDiaMesEspanol(productos[0].fecha_i)+" de "+getTimeRange(productos[0].hora)
                            }
                                             
                        </div>
                    </div>

                    {
                        !match?
                        <div className="row">
                            <div className="col-4">
                                <strong>Delivery:</strong>
                            </div>
                            <div className="col-4">
                                ${formatNumber(productos[0].delivery)}             
                            </div>
                        </div>
                        :''
                    }

                    

                    <div className="row">
                        <div className="col-4">
                            <strong>Total:</strong>
                        </div>
                        <div className="col-4">
                            ${formatNumber(getTotalOrden(productos,1,match))}             
                        </div>
                    </div>

                    <br/>
                 
                </div>
            )

        }else{
            return ''
        }
        
    }
}

export default OrdenCore
