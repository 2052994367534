export const latBogota = 4.60971;
export const lonBogota = -74.08175;

export const primaryCites = ['Bogotá', 'Chía'];

// export const Cities = [
//     'Bogotá',
//     'Chía',
//     'Barranquilla',
//     'Cali',
//     'Cartagena de Indias',
//     'Medellín',
//     'Pereira',
//     'Manizales',
// ];

export const Cities = ['Bogotá', 'Chía'];

export const Countries = ['Colombia'];
