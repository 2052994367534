import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getArrayCategoriesSlider } from '../../funciones/pasillo'
import { LazyImage } from "react-lazy-images";

class CategoriesSlider extends Component {

    constructor(props){
        super()
        this.state={
            shuffle_array:getArrayCategoriesSlider(props.categories,props.categoria_id),
            id:props.categoria_id,
        }
    }

    goBycategory(category){

        if(category.tipo==2 || category.tipo==6){
            this.props.history.push("/aliados/"+category.seo)
        }else if(category.jerarquia==1){
            this.props.history.push("/menu-huerta")
        }else{
            this.props.history.push("/categoria/"+category.seo)
        }

    }

    static getDerivedStateFromProps(nextProps, prevState){

        if(prevState.shuffle_array.find(value=> value.id==nextProps.categoria_id)){
            return{
                shuffle_array:getArrayCategoriesSlider(nextProps.categories,nextProps.categoria_id),
                id:nextProps.id,
            }
        }else{
            return null
        }
    }


    render() {

        const {shuffle_array}= this.state

        let slider_catgories=shuffle_array.filter((value)=>value.pri<77 && value.jerarquia!=2).map((value,i)=>{
            return(
                <div key={i} className="mr-2 inline-categories" onClick={()=>{this.goBycategory(value)}}>

                    <LazyImage
                        src={value.image}
                        alt={value.name}
                        placeholder={({ imageProps, ref }) => (
                            <img
                                className="loader-img-3"
                                ref={ref} 
                                src={`${process.env.PUBLIC_URL}/assets/images/loading6.gif`}
                                alt={imageProps.alt}
                            />
                        )}
                        actual={({ imageProps }) => 
                            <img {...imageProps} className="categorie-slider-img" />
                        }
                    />

                    {value.name}
                </div>
            )
        })

        return (
            <div className="categories-slider-box mb-display">

                <div className="w-max-content">

                    {slider_catgories}

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.categories.categories,
})

export default connect(
    mapStateToProps,
    { }
)(withRouter(CategoriesSlider));
