import React, { Component } from 'react'
import { connect } from 'react-redux'
import horas from '../../../api/horarios/tipoRestaurante.json'
import { updateRestaurante } from '../../../actions/index.js'
import store from '../../../store/index.js'
import * as moment from 'moment';
import { getDiffHoraria, getDiffMinutes } from '../../../servicios.js'

class Normal extends Component {

    constructor(props){
        super(props)
        this.state={
            hora: props.hora
        }
    }

    submit = (e) => {
        e.preventDefault()
        const hora=this.state.hora
        this.props.updateRestaurante(hora)
        this.props.close()
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    notWeekend = () => {

        let dia=moment().isoWeekday()

        if(dia==6 || dia==7){
            return false
        }else{
            return true
        }
        
    }

    mananaDisponibe = () => {

        let dia=moment().isoWeekday()

        if(dia==6 || dia==7 || dia==5){
            return false
        }else{
            return true
        }
        
    }

    render() {

        const list_horas=horas.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const val_horas=horas.filter((value,index)=>{

            if(getDiffMinutes(value.hora)>=50){

                return true

            }

        })
        
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title" >Programar envío</h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submit}>

                            {val_horas.length>0 && this.notWeekend() ?

                                <div className="form-group">
                                    <label htmlFor="hora" className="col-form-label" >Selecciona la hora a la que deseas recibir HOY tus productos</label>
                                    <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                        {list_horas}
                                    </select>
                                </div>
                            :
                                this.mananaDisponibe()?

                                    <div className="form-group">
                                        <label htmlFor="hora" className="col-form-label" >Selecciona la hora a la que deseas recibir MAÑANA tus productos</label>
                                        <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                            {list_horas}
                                        </select>
                                    </div>

                                :
                                    <div className="form-group">
                                        <label htmlFor="hora" className="col-form-label" >Selecciona la hora a la que deseas recibir EL LUNES tus productos</label>
                                        <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                            {list_horas}
                                        </select>
                                    </div>
                            }

                            <button className="btn btn-solid"> Guardar</button>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    hora: state.restaurante.hora_entrega
})

export default connect(
    mapStateToProps,{updateRestaurante}
)(Normal)
