import React, { Component } from 'react'

export class HomeIcons extends Component {
    render() {
        return (
            <>
            <div className="home-icon-container mb-display">

                <a href="mailto:info@fluio.com.co" data-toggle="tooltip" title="info@fluio.com.co" className="px-2">
                    <i className="fa fa-envelope new-home-icon"/>
                </a>
                <a href="tel:+573107139466" data-toggle="tooltip" title="(+57)3107139466" className="px-2">
                    <i className="fa fa-phone new-home-icon"/>
                </a>
                <a href="https://wa.me/573107139466" className="px-2">
                    <i className="fa fa-whatsapp new-home-icon"/>
                </a>
                <a href="instagram://user?username=fluio.co" className="px-2">
                    <i className="fa fa-instagram new-home-icon"/>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/FLUIO-101854164543495/" className="px-2">
                    <i className="fa fa-facebook-square new-home-icon"/>
                </a>
            </div>
            <div className="home-icon-container2 mb-none">

                <a href="mailto:info@fluio.com.co" data-toggle="tooltip" title="info@fluio.com.co" className="px-2">
                    <i className="fa fa-envelope new-home-icon"/>
                </a>
                <a href="tel:+573107139466" data-toggle="tooltip" title="(+57)3107139466" className="px-2">
                    <i className="fa fa-phone new-home-icon"/>
                </a>
                <a href="https://wa.me/573107139466" className="px-2">
                    <i className="fa fa-whatsapp new-home-icon"/>
                </a>
                <a href="https://www.instagram.com/fluio.co/?hl=es-la" className="px-2 mb-none">
                    <i className="fa fa-instagram new-home-icon"/>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/FLUIO-101854164543495/" className="px-2">
                    <i className="fa fa-facebook-square new-home-icon"/>
                </a>
            </div>
            </>
        )
    }
}

export default HomeIcons
