import React, { Component } from 'react'
import { connect } from 'react-redux'
import semana from '../../../api/semana.json'
import horas from '../../../api/horarios/tipoPlanAlimenticio.json'
import FormError from '../../common/FormError.jsx'
import * as moment from 'moment';
import { getNextMonday } from '../../../servicios.js'
import { updateCart } from '../../../actions/index.js'

class ProgramableQuincenal extends Component {

    constructor(props){
        super(props)
        console.log(props)
        this.state={
            hora: props.product.programacion.hora,
            dias1:props.product.programacion.semana1,
            dias2:props.product.programacion.semana2,
            bool_dias:false
        }
    }

    submit = (e) => {
        e.preventDefault()
        const{dias1,dias2}=this.state

        if(dias1.length<2 || dias2.length<2){
            this.setState({
                bool_dias:true
            })
        }else{
            const product=this.props.product
            product.programacion={
                hora:this.state.hora,
                semana1:this.state.dias1,
                semana2:this.state.dias2
            }
            this.props.updateCart(product)
            this.props.close()
            
        }
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    clickHandleDias(event, dias) {

        var index = dias.indexOf(parseInt(event.target.value));
        if (event.target.checked){
            dias.push(parseInt(event.target.value)); // push in array checked value
        }else{
            dias.splice(index, 1); // removed in array unchecked value
        }

        this.setState({
            dias1:dias
        },()=>{
            console.log(this.state.dias)
        })
    }

    clickHandleDias2(event, dias) {

        var index = dias.indexOf(parseInt(event.target.value));
        if (event.target.checked){
            dias.push(parseInt(event.target.value)); // push in array checked value
        }else{
            dias.splice(index, 1); // removed in array unchecked value
        }

        this.setState({
            dias2:dias
        },()=>{
            console.log(this.state.dias)
        })
    }


    render() {

        const list_horas=horas.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const {dias1,dias2}=this.state

        const list_checkbox1=semana.map((value,index)=>{

            return(
                <div className="form-checkbox" key={index}>
                    <input type="checkbox" onChange={(e) => this.clickHandleDias(e,dias1)}   checked={dias1.includes(value.id)? true : false} value={value.id} />
                    <label className="ml-2"
                            htmlFor={value.name}>{value.name}</label>
                </div>
            )
            
        })

        const list_checkbox2=semana.map((value,index)=>{

            return(
                <div className="form-checkbox" key={index}>
                    <input type="checkbox" onChange={(e) => this.clickHandleDias2(e,dias2)}   checked={dias2.includes(value.id)? true : false} value={value.id} />
                    <label className="ml-2"
                            htmlFor={value.name}>{value.name}</label>
                </div>
            )
            
        })

        const primer_lunes=getNextMonday()
        const primer_viernes=moment(primer_lunes,'DD/MM/YYYY').add(4, 'days').format('DD/MM/YYYY')

        const segundo_lunes=moment(primer_lunes,'DD/MM/YYYY').add(7, 'days').format('DD/MM/YYYY')
        const segundo_viernes=moment(primer_lunes,'DD/MM/YYYY').add(11, 'days').format('DD/MM/YYYY')


        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h4 className="modal-title" >Programación quincenal</h4>
                    </div>
                    {this.props.product.name}
                    <div className="modal-body">
                        <form onSubmit={this.submit}>

                            <div className="form-group">
                                <label htmlFor="hora" className="col-form-label" >Hora :</label>
                                <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                    {list_horas}
                                </select>
                            </div>

                            <div className="row">

                                <fieldset className="form-group col-xl-6 col-sm-12">
                                    <div className="ml-2 row">

                                        <legend className="col-form-label pt-0"> 
                                            {primer_lunes} al {primer_viernes}
                                        </legend>

                                        <div className="">

                                            {list_checkbox1}

                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="form-group col-xl-6 col-sm-12">
                                    <div className="ml-2 row">

                                        <legend className="col-form-label pt-0"> 
                                            {segundo_lunes} al {segundo_viernes}
                                        </legend>

                                        <div className="">

                                            {list_checkbox2}

                                        </div>
                                    </div>
                                </fieldset>

                            </div>

                            <FormError show={this.state.bool_dias} text="Debes escoger un mínimo de 2 dias a la semana" />
                            <button className="btn btn-solid"> Guardar</button>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps,{updateCart}
)(ProgramableQuincenal)