import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addToCart, addToWishlist, addToCompare } from '../../../actions'
import ProductListItem2 from './product-list-item2';
import { withRouter } from 'react-router-dom';
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";



class ProductListingPasillosTag extends Component {


    constructor(props){
        super(props)
        this.state={
            productos:[]
        }
    }

    goToPasillo = (e,pasillo) => {
        e.preventDefault()
        const {categoria_seo} = this.props;
        window.location.href=process.env.PUBLIC_URL+"/tag/"+categoria_seo+"/"+pasillo
    }


    render() {
        const {pasillo, addToCart, symbol, addToWishlist, 
            addToCompare,categoria,products,pasillo_seo,hallValue} = this.props;

        const list_productos=products.filter(value=>
            value.tags?
            value.hallsId.includes(pasillo) && value.tags.includes(parseInt(this.props.categoria))
            :false
        )
        
        if(categoria==2){

            list_productos.sort((product1, product2) => {
                return product2.price > product1.price ? -1 : 1;
            })

        }

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1424 },
                items: 4,
                slidesToSlide: 1, // optional, default to 1.
                partialVisibilityGutter: 40,
            },
            tablet: {
                breakpoint: { max: 1424, min: 764 },
                items: 2,
                slidesToSlide: 2, // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 764, min: 0 },
                items: 2.5,
                slidesToSlide: 2
            },
        };

        var settings = {
            dots: true,
            infinite: false,
            speed: 200,
            lazyLoad:'progressive',
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows:false,
            dots:false,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 1500,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    arrows:true
                    }
                },
            ]
          };

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid mb-no-padding">


                            {/*
                            <a href="#" className="mb-display text-deco" onClick={(e)=>{this.goToPasillo(e,pasillo)}}>Ver más</a>
                            */}
                            <br/>
                            {/*validaciones con ternarios para que se muestre de forma correcta en movil(cambios en css)
                            {productos.length>2?
                                
                                
                                <Slider {...settings}>
                                    { 
                                        productos.map((product, index) =>


                                            <div key={index} className={"padding-10 mb-no-padding "}>
                                                <ProductListItem2 product={product} symbol={symbol}
                                                onAddToCompareClicked={() => addToCompare(product)}
                                                onAddToWishlistClicked={() => addToWishlist(product)}
                                                onAddToCartClicked={addToCart} key={index}
                                                products_length={productos.length}
                                                index={index}/>
                                            </div>
                                        
                                        )
                                    }
                                </Slider>
                            */}

                            {list_productos.length>2?

                                <>
                                <div className="slider-box mb-display">

                                    <div className="w-max-content">

                                    { 
                                        list_productos.map((product, index) =>


                                            <div key={index} className={"mr-2 inline"}>
                                                <ProductListItem2 product={product} symbol={symbol}
                                                onAddToCompareClicked={() => addToCompare(product)}
                                                onAddToWishlistClicked={() => addToWishlist(product)}
                                                onAddToCartClicked={addToCart} key={index}
                                                products_length={list_productos.length}
                                                index={index}
                                                hallValue={hallValue}
                                                />
                                            </div>
                                        
                                        )
                                    }

                                    </div>

                                   
                                    
                                </div>


                                <div className="mb-none">
                                    <Slider {...settings}>
                                    { 
                                        list_productos.map((product, index) =>


                                            <div key={index} className={"padding-10 mb-no-padding "}>
                                                <ProductListItem2 product={product} symbol={symbol}
                                                onAddToCompareClicked={() => addToCompare(product)}
                                                onAddToWishlistClicked={() => addToWishlist(product)}
                                                onAddToCartClicked={addToCart} key={index}
                                                products_length={list_productos.length}
                                                index={index}
                                                hallValue={hallValue}
                                                />
                                            </div>
                                        
                                        )
                                    }
                                    </Slider>
                                </div>
                                </>
                            
                            :
                                
                            list_productos.length>1?
                                
                                <div className="row">
                                    {
                                    list_productos.filter((product,index)=> index<4 ).map((product, index) =>

                                    <div key={index} className="ml-1 padding-10 mb-no-padding col-4 col-md-3">
                                        <ProductListItem2 product={product} symbol={symbol}
                                        onAddToCompareClicked={() => addToCompare(product)}
                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                        onAddToCartClicked={addToCart} key={index}
                                        hallValue={hallValue}
                                        />
                                    </div>
                                    )
                                    }

                                </div>
                                
                                :
                                list_productos.filter((product,index)=> index<4 ).map((product, index) =>

                                    <div key={index} className="padding-10 mb-no-padding col-4 col-md-3">
                                        <ProductListItem2 product={product} symbol={symbol}
                                        onAddToCompareClicked={() => addToCompare(product)}
                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                        onAddToCartClicked={addToCart} key={index}
                                        hallValue={hallValue}
                                        />
                                    </div>
                                )
                                
 
                            }          
                                
                            <button className="btn btn-outline btn-center mt-5 mb-none" onClick={(e)=>{this.goToPasillo(e,pasillo_seo)}}>Ver más</button>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    symbol: state.data.symbol,
    products: state.data.products
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare}
)(withRouter(ProductListingPasillosTag))