import React, { Component } from 'react'
import { connect } from 'react-redux'
import { semana } from '../../../api/arrays'
import * as moment from 'moment';
import { getNextMonday } from '../../../servicios';



class DetalleMensual extends Component {

    constructor(props){
        super(props)
        this.state={
            product: props.product
        }
    }

    getNumberDelivery = (product) => {

        switch (product.tipo_cate) {
            case 2:
                return 1
            case 3:

                if(product.tipo_hall==1){

                    return (product.programacion.semana1.length)
                }
                if(product.tipo_hall==2){
        
                    return (product.programacion.semana1.length+product.programacion.semana2.length)
                }
        
                if(product.tipo_hall==4){
        
                    const dias_semanas=product.programacion.semana1.length+product.programacion.semana2.length+
                    product.programacion.semana3.length+product.programacion.semana4.length
        
                    return (dias_semanas)
                }
                
            default:
                return 0;
        }
        
    }

    getCostoPlan = (product) => {

        if(product.tipo_hall==1){

            return (product.price)*(product.programacion.semana1.length)
        }
        if(product.tipo_hall==2){

            return (product.price)*(product.programacion.semana1.length+product.programacion.semana2.length)
        }

        if(product.tipo_hall==4){

            const dias_semanas=product.programacion.semana1.length+product.programacion.semana2.length+
            product.programacion.semana3.length+product.programacion.semana4.length

            return (product.price)*(dias_semanas)
        }
        
    }


    
    

    render() {

        const {product}= this.state
        const {delivery,symbol}=this.props

        const semana1=product.programacion.semana1.map((dia,index)=>{
            return(
                <li className="li" key={index}>
                    {semana[dia-1]}
                </li>             
            )
        })

        const semana2=product.programacion.semana2.map((dia,index)=>{
            return(
                <li className="li" key={index}>
                    {semana[dia-1]}
                </li>             
            )
        })

        const semana3=product.programacion.semana3.map((dia,index)=>{
            return(
                <li className="li" key={index}>
                    {semana[dia-1]}
                </li>             
            )
        })

        const semana4=product.programacion.semana4.map((dia,index)=>{
            return(
                <li className="li" key={index}>
                    {semana[dia-1]}
                </li>             
            )
        })

        const primer_lunes=getNextMonday()
        const primer_viernes=moment(primer_lunes,'DD/MM/YYYY').add(4, 'days').format('DD/MM/YYYY')

        const segundo_lunes=moment(primer_lunes,'DD/MM/YYYY').add(7, 'days').format('DD/MM/YYYY')
        const segundo_viernes=moment(primer_lunes,'DD/MM/YYYY').add(11, 'days').format('DD/MM/YYYY')

        const tercer_lunes=moment(primer_lunes,'DD/MM/YYYY').add(14, 'days').format('DD/MM/YYYY')
        const tercer_viernes=moment(primer_lunes,'DD/MM/YYYY').add(18, 'days').format('DD/MM/YYYY')

        const cuarto_lunes=moment(primer_lunes,'DD/MM/YYYY').add(21, 'days').format('DD/MM/YYYY')
        const cuarto_viernes=moment(primer_lunes,'DD/MM/YYYY').add(25, 'days').format('DD/MM/YYYY')
        
        return (
            <div className="modal-dialog  modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <strong className="font-20" >Detalles de entrega</strong>
                    </div>
                    <br/>

                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <form onSubmit={this.submit}>

                                    <div>
                                        <strong>Producto:</strong>
                                        {product.name}
                                    </div>
        
                                    <div>
                                        <strong>Hora de entrega:</strong>
                                        {product.programacion.hora}
                                    </div>
                                    <div>
                                        <strong>Costo unitario:</strong>
                                        {symbol}{product.price}
                                    </div>
                                    

                                </form>

                            </div>

                            <div className="col-md-6">
                                <div>
                                    <strong>Cantidad: </strong>
                                    {product.qty}
                                </div>
                                <div>
                                    <strong>Delivery:</strong>
                                    {symbol}{delivery}(x{this.getNumberDelivery(product)})
                                </div>
                                <div>
                                    <strong >Total:</strong>
                                    {symbol}{this.getCostoPlan(product)*product.qty+delivery*this.getNumberDelivery(product)}
                                </div>
                            </div>

                            <div className="center col-12">
                                <strong>Días de entrega</strong>
                            </div>

                            <fieldset className="form-group col-xl-6 col-sm-12">
                                <div className="ml-2 row">

                                    <legend className="col-form-label pt-0 bold"> 
                                        {primer_lunes} al {primer_viernes}
                                    </legend>

                                    <div className="">

                                        <ul>
                                            {semana1}                             
                                        </ul>

                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="form-group col-xl-6 col-sm-12">
                                <div className="ml-2 row">

                                    <legend className="col-form-label pt-0 bold"> 
                                        {segundo_lunes} al {segundo_viernes}
                                    </legend>

                                    <div className="">

                                        <ul>
                                            {semana2}                             
                                        </ul>

                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="form-group col-xl-6 col-sm-12">
                                <div className="ml-2 row">

                                    <legend className="col-form-label pt-0 bold"> 
                                        {tercer_lunes} al {tercer_viernes}
                                    </legend>

                                    <div className="">

                                        <ul>
                                            {semana3}                             
                                        </ul>

                                    </div>
                                </div>
                            </fieldset>

                            <fieldset className="form-group col-xl-6 col-sm-12">
                                <div className="ml-2 row">

                                    <legend className="col-form-label pt-0 bold"> 
                                        {cuarto_lunes} al {cuarto_viernes}
                                    </legend>

                                    <div className="">

                                        <ul>
                                            {semana4}                             
                                        </ul>

                                    </div>
                                </div>
                            </fieldset>

                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    delivery: state.delivery.plan
})

export default connect(
    mapStateToProps
)(DetalleMensual)