import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import { removeFromCore, incrementCore, decrementCore } from "../../../actions";
import {
  getTotalNormal,
  getTipoCategoria,
  validateCore,
  getDeliveryCost,
} from "../../../services";
import ProgramarCore from "../programacion/ProgramarCore";
import { toastError } from "../../../alertas";
import { CoreHuertaMatch } from "../../../funciones/checkout";
import Comentarios from "../programacion/Comentarios";
import { formatNumber, getMaxProd, getPrice } from "../../../funciones/general";

class CartCore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openAdd: false,
      product: null,
    };
  }

  openCore = () => {
    this.setState({
      open: true,
    });
  };

  closeCore = () => {
    this.setState({
      open: false,
    });
  };

  handleModal = (e) => {
    e.preventDefault();
    this.openCore();
  };

  agregar = (item) => {
    if (validateCore(1)) {
      this.props.incrementCore(item, 1);
    } else {
      toastError("No puedes agregar mas articulos de mercado");
    }
  };

  handleAdd = (e, product) => {
    e.preventDefault();

    this.setState(
      {
        product: product,
      },
      () => {
        this.openAdd();
      }
    );
  };

  openAdd = () => {
    this.setState({
      openAdd: true,
    });
  };

  closeAdd = () => {
    this.setState({
      openAdd: false,
    });
  };

  render() {
    const { cartItems, symbol, total, delivery, tipo_categoria } = this.props;

    const img_categorias = tipo_categoria.map((value, index) => {
      return (
        <img src={value.image} alt="" key={index} className="mb-cate-cart" />
      );
    });

    return cartItems.length > 0 ? (
      <>
        <section className="cart-section section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-sm-12" id="accordion">
                <div
                  id="collapseCore"
                  className={cartItems.length >= 3 ? "collapse show" : ""}
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <br />

                  {cartItems.length >= 3 ? (
                    <div className="row flr envio-box" />
                  ) : (
                    ""
                  )}

                  <table className="table cart-table table-responsive-xs fix-table-cart">
                    <thead className="thead-group">
                      <tr className="table-head">
                        <th scope="col">
                          <strong className="mb-none">Imagen</strong>
                          <strong className="mb-display">Producto</strong>
                        </th>
                        <th scope="col">
                          <strong className="mb-none">Nombre</strong>
                          <strong className="mb-display">Cantidad</strong>
                        </th>
                        <th scope="col">
                          <strong className="mb-none">Precio</strong>
                          <strong className="mb-display">Precio</strong>
                        </th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Borrar</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    {cartItems.map((item, index) => {
                      return (
                        <tbody key={index} className="tbody-group">
                          <tr>
                            <td>
                              <Link to="#" onClick={(e) => e.preventDefault()}>
                                <img
                                  src={
                                    item.variants
                                      ? item.variants[0].images
                                      : item.pictures[0]
                                  }
                                  alt=""
                                  className="fix-img-cart"
                                />
                              </Link>
                              <Link
                                to="#"
                                className="mb-display"
                                onClick={(e) => e.preventDefault()}
                              >
                                {item.name}
                              </Link>
                            </td>
                            <td className="mb-p0">
                              <Link
                                to="#"
                                className="mb-none"
                                onClick={(e) => e.preventDefault()}
                              >
                                {item.name}
                              </Link>

                              {/*este bloque se utiliza para el responsive cuando la pantlla es pequeña*/}
                              <div className="mobile-cart-content row">
                                <div className="col-xs-3">
                                  <div className="qty-box">
                                    <div className="input-group">
                                      <span className="input-group-prepend">
                                        <button
                                          type="button"
                                          className="btn quantity-left-minus"
                                          onClick={() =>
                                            this.props.decrementCore(item.id)
                                          }
                                          data-type="minus"
                                          data-field=""
                                        >
                                          <i className="fa fa-angle-left" />
                                        </button>
                                      </span>
                                      <input
                                        type="text"
                                        name="quantity"
                                        value={item.qty}
                                        readOnly={true}
                                        className="form-control input-number"
                                      />

                                      <span className="input-group-prepend">
                                        <button
                                          className="btn quantity-right-plus"
                                          onClick={() => this.agregar(item)}
                                          data-type="plus"
                                          disabled={
                                            item.qty >= getMaxProd(item)
                                              ? true
                                              : false
                                          }
                                        >
                                          <i className="fa fa-angle-right" />
                                        </button>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {item.if_coment == 1 ? (
                                  <div className="col-xs-3">
                                    <Link
                                      to="/#"
                                      onClick={(e) => {
                                        this.handleAdd(e, item);
                                      }}
                                    >
                                      <i className="fa fa-comment comment-icon mt-4" />
                                    </Link>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="col-xs-3 mt-4">
                                  <h2 className="td-color">
                                    <span
                                      className="icon pointer"
                                      onClick={() =>
                                        this.props.removeFromCore(item)
                                      }
                                    >
                                      <i className="icon-close mobile-x" />
                                    </span>
                                  </h2>
                                </div>
                              </div>
                            </td>

                            <td className="mb-display">
                              <div className="col-xs-3 mt-5">
                                <h2 className="td-color font-15">
                                  {symbol}
                                  {formatNumber(getPrice(item))}
                                </h2>
                              </div>
                              <h2 className="td-color font-15">
                                Total:
                                {symbol +
                                  formatNumber(getPrice(item) * item.qty)}
                              </h2>
                            </td>

                            {/*este bloque se utiliza para el responsive cuando la pantlla es "normal" */}
                            <td>
                              <h2>
                                {symbol}
                                {formatNumber(getPrice(item))}
                              </h2>
                            </td>

                            <td>
                              <div className="qty-box">
                                {item.if_coment == 1 ? (
                                  <Link
                                    to="/#"
                                    onClick={(e) => {
                                      this.handleAdd(e, item);
                                    }}
                                  >
                                    <i className="fa fa-comment comment-icon" />
                                  </Link>
                                ) : (
                                  ""
                                )}

                                <div className="input-group">
                                  <span className="input-group-prepend">
                                    <button
                                      type="button"
                                      className="btn quantity-left-minus"
                                      onClick={() =>
                                        this.props.decrementCore(item.id)
                                      }
                                      data-type="minus"
                                      data-field=""
                                    >
                                      <i className="fa fa-angle-left" />
                                    </button>
                                  </span>
                                  <input
                                    type="text"
                                    name="quantity"
                                    value={item.qty}
                                    readOnly={true}
                                    className="form-control input-number"
                                  />

                                  <span className="input-group-prepend">
                                    <button
                                      className="btn quantity-right-plus"
                                      onClick={() => this.agregar(item)}
                                      data-type="plus"
                                      disabled={
                                        item.qty >= getMaxProd(item)
                                          ? true
                                          : false
                                      }
                                    >
                                      <i className="fa fa-angle-right" />
                                    </button>
                                  </span>
                                </div>
                              </div>
                              {item.qty >= getMaxProd(item)
                                ? "Se alcanzo el límite"
                                : ""}
                            </td>
                            {/*
                                                        <td>
                                                            <Link to="/#" onClick={(e)=>{this.handleModal(e,item)}}>
                                                                <img src="/assets/images/clock.png" className="image45" alt="" />
                                                            </Link>
                                                        </td>
                                                        */}
                            <td>
                              <span
                                className="icon pointer"
                                onClick={() => this.props.removeFromCore(item)}
                              >
                                <i className="fa fa-times" />
                              </span>
                            </td>
                            <td>
                              <h2 className="td-color">
                                {symbol}{" "}
                                {formatNumber(getPrice(item) * item.qty)}
                              </h2>
                            </td>
                          </tr>

                          {/*
                                                    <tr >
                                                        <td>
                                                            Hora de entrega: 14:30
                                                            Del 24/02/2020 al 29/02/2020
                                                            Dias: Lunes,Martes

                                                        </td>
                                                    </tr>
                                                    */}
                        </tbody>
                      );
                    })}
                  </table>

                  <table className="table cart-table table-responsive-md sub-fix">
                    <tfoot>
                      {!CoreHuertaMatch() ? (
                        <tr>
                          <td>Delivery :</td>
                          <td>
                            <h2 className="mb-mt-1">
                              {cartItems.length > 0
                                ? symbol + formatNumber(delivery)
                                : symbol + 0}
                            </h2>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                      <tr>
                        <td>Sub total :</td>
                        <td>
                          <h2 className="mb-mt-1">
                            {symbol + formatNumber(total)}{" "}
                          </h2>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Modal
            classNames={{ modal: "mb-modal" }}
            open={this.state.open}
            onClose={this.closeCore}
          >
            <ProgramarCore close={this.closeCore} />
          </Modal>
          <Modal
            classNames={{ modal: "mb-modal" }}
            open={this.state.openAdd}
            onClose={this.closeAdd}
          >
            <Comentarios product={this.state.product} close={this.closeAdd} />
          </Modal>
        </section>
      </>
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.core.cart,
  symbol: state.data.symbol,
  total: getTotalNormal(state.core.cart, 1),
  delivery: getDeliveryCost(1),
  tipo_categoria: getTipoCategoria(1),
});

export default connect(
  mapStateToProps,
  { removeFromCore, incrementCore, decrementCore }
)(CartCore);
