// Get Products
export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_CATEGORY = "FILTER_CATEGORY";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";

export const ADD_TO_HUERTA = "ADD_TO_HUERTA";
export const UPDATE_HORA_HUERTA = "UPDATE_HORA_HUERTA";
export const REMOVE_FROM_HUERTA = "REMOVE_FROM_HUERTA";
export const DECREMENT_HUERTA = "DECREMENT_HUERTA";

export const ADD_TO_CONGELADO = "ADD_TO_CONGELADO";
export const UPDATE_HORA_CONGELADO = "UPDATE_HORA_CONGELADO";
export const REMOVE_FROM_CONGELADO = "REMOVE_FROM_CONGELADO";
export const DECREMENT_CONGELADO = "DECREMENT_CONGELADO";

export const ADD_TO_CORE = "ADD_TO_CORE";
export const UPDATE_HORA_CORE = "UPDATE_HORA_CORE";
export const REMOVE_FROM_CORE = "REMOVE_FROM_CORE";
export const DECREMENT_CORE = "DECREMENT_CORE";

export const ADD_TO_EXTERNAL = "ADD_TO_EXTERNAL";
export const UPDATE_HORA_EXTERNAL = "UPDATE_HORA_EXTERNAL";
export const REMOVE_FROM_EXTERNAL = "REMOVE_FROM_EXTERNAL";
export const DECREMENT_EXTERNAL = "DECREMENT_EXTERNAL";

export const ADD_TO_SNACK = "ADD_TO_SNACK";
export const UPDATE_HORA_SNACK = "UPDATE_HORA_SNACK";
export const REMOVE_FROM_SNACK = "REMOVE_FROM_SNACK";
export const DECREMENT_SNACK = "DECREMENT_SNACK";

export const ADD_TO_RESTAURANTE = "ADD_TO_RESTAURANTE";
export const UPDATE_HORA_RESTAURANTE = "UPDATE_HORA_RESTAURANTE";
export const REMOVE_FROM_RESTAURANTE = "REMOVE_FROM_RESTAURANTE";
export const DECREMENT_RESTAURANTE = "DECREMENT_RESTAURANTE";

export const ADD_TO_CENA = "ADD_TO_CENA";
export const UPDATE_HORA_CENA = "UPDATE_HORA_CENA";
export const REMOVE_FROM_CENA = "REMOVE_FROM_CENA";
export const DECREMENT_CENA = "DECREMENT_CENA";

export const UPDATE_DELIVERY = "UPDATE_DELIVERY";

export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
export const UPDATE_HALLS = "UPDATE_HALLS";

export const UPDATE_ALIADO = "UPDATE_ALIADO";

//para limpiar el carrito
export const CLEAR_PLAN = "CLEAR_PLAN";
export const CLEAR_RESTAURANTE = "CLEAR_RESTAURANTE";
export const CLEAR_HUERTA = "CLEAR_HUERTA";
export const CLEAR_CONGELADO = "CLEAR_CONGELADO";
export const CLEAR_CORE = "CLEAR_CORE";
export const CLEAR_EXTERNAL = "CLEAR_EXTERNAL";
export const CLEAR_SNACK = "CLEAR_SNACK";
export const CLEAR_CENA = "CLEAR_CENA";

//cosas del envio
export const UPDATE_ENVIO = "UPDATE_ENVIO";

//cosas de header
export const UPDATE_HEADERS = "UPDATE_HEADERS";

//actualizar usuario
export const UPDATE_USER = "UPDATE_USER";

//actualizar el restaurante
export const ACTUALIZAR_RESTAURANTE = "ACTUALIZAR_RESTAURANTE";

//actualizar core
export const ACTUALIZAR_CORE = "ACTUALIZAR_CORE";

export const ACTUALIZAR_EXTERNAL = "ACTUALIZAR_EXTERNAL";

//actualizar la cena
export const ACTUALIZAR_CENA = "ACTUALIZAR_CENA";

//cosas de adicionales
export const UPDATE_POSTRE = "UPDATE_POSTRE";
export const UPDATE_BEBIDA = "UPDATE_BEBIDA";

//cosas de ordenes
export const UPDATE_ORDEN = "UPDATE_ORDEN";
export const CLEAR_ORDEN = "CLEAR_ORDEN";

export const UPDATE_LOAD_HOME = "UPDATE_LOAD_HOME";

export const COMPLETE_SURVEY = "COMPLETE_SURVEY";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
