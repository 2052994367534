import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getArrayCategoriesSlider } from '../../funciones/pasillo'
import { LazyImage } from "react-lazy-images";

class AllySlider extends Component {

    constructor(props){
        super()
        this.state={
            id:props.id
        }
    }

    scrollToHall(hall){

        var element = document.getElementById("hall"+hall.id);

        element.scrollIntoView({"block":"center"})
        //window.scrollBy(0, 300)


    }


    render() {

        const {halls} = this.props

        let slider=halls.map((value,i)=>{
            return(
                <div key={i} className="mr-2 inline-halls" onClick={()=>{this.scrollToHall(value)}}>
                    {value.name}
                </div>
            )
        })

        return (
            <div className='container-hall-s mb-display'>
                <div className="categories-slider-box my-2">

                    <div className="w-max-content">

                        {slider}

                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    aliados: state.aliado.data,
})

export default connect(
    mapStateToProps,
    { }
)(withRouter(AllySlider));