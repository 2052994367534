import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import { dataLogin } from '../../actions';
import gicon from '../../assets/images/g-icon.png'
import { cleverOnLogin, cleverRegistered } from '../../funciones/clevertap';
import { postData } from '../../servicios';
import FormError from '../common/FormError';
import regalo from '../../assets/images/regalo.png'

class MobileLogin extends Component {

    constructor(){
        super()
        this.state={
            email:'',
            name:'',
            pass:'',
            pass2:'',
            user:'',
            page:1,
            error:'',
            loading:false,
        }
    }

    handleSubmit1 = (e) => {
        e.preventDefault()
        this.setState({
            page:2
        })
    }

    handleSubmit2 = async (e) => {

        e.preventDefault()

        this.setState({
            error:'',
            loading:true,
        })

        const json={
            email:this.state.email,
            name: this.state.name,
            nickname:this.state.user,
            password:this.state.pass,
            password_confirmation:this.state.pass2,
        }

        try{
            const response= await postData("customer_auth/",json)
            this.props.dataLogin(response['data'].data,response.headers)
            cleverOnLogin(response['data'].data)
            cleverRegistered(response['data'].data)
            window.location.href="/"
        }catch(err){

            console.log(err)
            const error=err.response.data.errors.full_messages

            this.setState({
                error:error.join(),
                loading:false,
            })

        }
        
    }
    

    goBack = (e) => {
        e.preventDefault()
        this.setState({
            page:1
        })
    }
    

    handleChange= (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }
    
    render() {
        const {email,page,pass,user,error,loading}=this.state

        return (
            <div className='mobile-login-page'>


                <img src={regalo} alt="regalo" className="regalo-img"/>


                <div className="container-login-page">

                    <div className="tac login-title">
                        Bienvenid@ a fluio, la app que te cuida
                    </div>
                    <div className="tac login-title">
                        Con tu registro te regalamos un bono de
                    </div>
                    <div className="tac login-title">
                        $8.000
                    </div>
                    <div className='tac'>
                        Puedes usarlo en toda la tienda
                    </div>

                    {
                        page==1?
                        <form onSubmit={this.handleSubmit1} className='log-form-margin'>

                            <div className='log-input-h'>
                                <input type="email" name='email' className="form-control log-input" 
                                onChange={this.handleChange} placeholder='Email' value={email}
                                autoComplete='on' required/>
                                <i className='fa fa-user log-user-icon'></i>
                            </div>

                            <div className="tac mt-2">
                                <button className="btn btn-log">
                                    Continuar
                                </button>
                            </div>

                        </form>
                        :
                        <form onSubmit={this.handleSubmit2} className='log-form-margin'>

                            <div className='log-input-h'>
                                <input type="text" name='user' className="form-control log-input" 
                                onChange={this.handleChange} placeholder='Usuario' value={user}
                                autoComplete='off' required/>
                                <i className='fa fa-user log-user-icon'></i>
                            </div>

                            <div className='log-input-h mt-1'>
                                <input type="password" name='pass' className="form-control log-input" 
                                onChange={this.handleChange} placeholder='Contraseña' value={pass}
                                autoComplete='off' required/>
                                <i className='fa fa-circle log-user-icon'></i>
                            </div>

                            <div className='log-input-h mt-1'>
                                <input type="password" name='pass2' className="form-control log-input" 
                                onChange={this.handleChange} placeholder='Confirmar contraseña'
                                autoComplete='off' required/>
                                <i className='fa fa-circle log-user-icon'></i>
                            </div>

                            <div className="tac mt-2">
                                <FormError text={error} show={error}/>
                                <button className="btn btn-log" disabled={loading}>
                                    Continuar
                                </button>
                                <br/>
                                <button type='button' className="btn btn-log mt-1" onClick={this.goBack}>
                                    Atras
                                </button>
                            </div>

                        </form>
                    }

                    

                    <div className="tac mt-1">
                        <Link to='/login' className='to-log'>
                            Ya tienes una cuenta?
                        </Link>
                    </div>

                    <div className="d-flex log-separator tac my-2">
                        <hr/>
                        <span>o</span>
                        <hr/>
                    </div>

                    <div className="tac mt-2">
                        <button className="btn btn-log-social">
                            Continuar con Google
                        </button>
                        <img src={gicon} alt="gicon" className='log-social-icon gicon-size'/>
                    </div>

                    <div className="tac">
                        <button className="btn btn-log-social">
                            Continuar con Facebook
                        </button>
                        <i className='fa fa-facebook-square fb-color log-social-icon'/>
                    </div>

                </div>
                
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps, { dataLogin }
)(withRouter(MobileLogin))
