import React, { Component } from 'react'

export class Google extends Component {

    click = (e) => {

        e.preventDefault()
        this.props.click()
        
    }
    

    render() {
        return (
            <a href="#" class="google-btn social-btn" onClick={this.click}>
                <i class="fa fa-google"></i> Login con google
            </a>
        )
    }
}

export default Google
