import React, { Component } from 'react'
import logo from '../assets/images/app-logo.png'
import { withRouter } from 'react-router-dom'
import { completeSurvey } from '../actions';
import { connect } from 'react-redux';

export class HollyDayPage extends Component {

    constructor(props){
        super(props)
        this.state={
        }
    }

    render() {

        const {page}= this.state

        return (
            <div className="launch-page" /*style={{backgroundImage: 'url('+background+')'}}*/>

                <div className="tac mt-5">
                    <img src={logo} alt="logo" className="hollyday-logo"/>
                </div>

                <div className="hollyday-content tac">

                    <div className="launch-page-1-title-black">
                    ¡Volveremos el 3 de enero con muchas sorpresas!
                    </div>
                    <div className="launch-page-1-title-black">
                    ¡Te deseamos una feliz navidad y un mejor 2021!
                    </div>

                </div>
                
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

export default connect(
    mapStateToProps,
    { completeSurvey }
)(withRouter(HollyDayPage));