import React, { Component } from "react";
import { connect } from "react-redux";
import { getDeliveryCost, getTotalNormal } from "../../../services";
import * as moment from "moment";
import { getDiaMesEspanol } from "../../../funciones/momentEspañol";
import { CoreHuertaMatch } from "../../../funciones/checkout";
import { Link } from "react-router-dom";
import DetalleComentario from "../modales/DetalleComentario";
import Modal from "react-responsive-modal";
import { getTimeRange } from "../../../funciones/patrones";
import { formatNumber, getPrice } from "../../../funciones/general";

class CheckSnack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      product: null,
    };
  }

  handleAdd = (e, product) => {
    e.preventDefault();

    this.setState(
      {
        product: product,
      },
      () => {
        this.onOpenModal();
      }
    );
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { cartItems, total, symbol, delivery, entrega } = this.props;

    if (cartItems.length > 0) {
      return (
        <>
          <strong className="center70 font-25">Mercado</strong>
          <br />
          <div className="title-box">
            <div className="row">
              <div className="col-7">Producto</div>
              <div className="col-5">Total</div>
            </div>
          </div>
          <ul className="qty">
            {cartItems.map((item, index) => {
              return (
                <li key={index}>
                  <div className="row">
                    <div className="col-7">
                      {item.name} (×{item.qty})
                    </div>
                    <div className="col-5">
                      {symbol}
                      {formatNumber(getPrice(item) * item.qty)}
                      {item.if_coment == 1 ? (
                        <>
                          /
                          <Link
                            to="/#"
                            onClick={(e) => {
                              this.handleAdd(e, item);
                            }}
                            className="mt-2"
                          >
                            <i className="fa fa-comment comment-icon" />
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>

          <ul className="sub-total">
            <li>
              Entrega
              <span className="count">
                {entrega == 1
                  ? "Entrega en menos de 90 minutos"
                  : getDiaMesEspanol(entrega.fecha) +
                    " de " +
                    getTimeRange(entrega.hora)}
              </span>
            </li>
            {!CoreHuertaMatch() ? (
              <li>
                Delivery{" "}
                <span className="count">
                  {symbol}
                  {formatNumber(delivery)}
                </span>
              </li>
            ) : (
              <></>
            )}
            <li>
              Subtotal{" "}
              <span className="count">
                {symbol}
                {formatNumber(total)}
              </span>
            </li>
          </ul>

          <Modal
            classNames={{ modal: "mb-modal" }}
            open={this.state.open}
            onClose={this.onCloseModal}
          >
            <DetalleComentario
              close={this.onCloseModal}
              product={this.state.product}
            />
          </Modal>
        </>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.core.cart,
  symbol: state.data.symbol,
  total: getTotalNormal(state.core.cart, 1),
  delivery: getDeliveryCost(1),
  entrega: state.core.programacion,
});

export default connect(
  mapStateToProps,
  {}
)(CheckSnack);
