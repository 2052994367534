import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastSuccess } from '../../alertas';

export class ShareButton extends Component {
    constructor(props) {
        super(props);
    }

    onCopy = () => {
        toastSuccess('Enlace copiado');
    };

    render() {
        const { product } = this.props;
        return (
            <CopyToClipboard text={`https://fluio.com.co/producto/${product.seo}`} onCopy={this.onCopy}>
                <i className="fa fa-share share-icon pointer" />
            </CopyToClipboard>
        );
    }
}

export default ShareButton;
