import React, { Component } from 'react'
import Breadcrumb from "../common/breadcrumb";

export class TyC extends Component {
    render() {
        return (
        <div>
            <Breadcrumb title={'Terminos y condiciones'}/>
            <div className="container">
                <p>
                A continuación, presentamos los términos y condiciones aplicables a los usuarios del portal web y aplicación móvil 
                Fluio y las aplicaciones o desarrollos informáticos para proveer los servicios de la compañía Fluio (en adelante, “Fluio” 
                y/o “la Compañía”).
                </p>
                <p>
                Fluio es una compañía que busca conectar consumidores con expendedores, por medio de una plataforma virtual, constituida por una página web 
                y una aplicación móvil, con la finalidad de que los consumidores, al ingresar a la plataforma virtual, se informen sobre los productos de 
                consumo exhibidos y realicen transacciones de compra-venta directa con los expendedores, de manera electrónica. 
                </p>
                <p>
                El ecosistema facilitador que permite Fluio con su plataforma virtual, busca que el encuentro entre consumidores y expendedores se consoliden 
                en vínculos contractuales sólidos, a propósito de los bienes y servicios que se promocionan y exhiben en la plataforma virtual.
                </p>
                <p>
                Así, mediante el registro, bien como Cliente y/o Usuario, bien como Expendedor y/o Proveedor, en la página web o en la aplicación móvil de
                 Fluio, o la aceptación electrónica expresa de los presentes términos y condiciones, o el uso de los Servicios ofrecidos por la Plataforma, 
                 el Usuario manifiesta su consentimiento y aceptación de estos. Todo lo anterior, al realizar clic y/o doble clic en los enlaces que la 
                 Plataforma tienen dispuestos para los efectos de realizar el registro en el portal Web o aplicativo de Fluio.
                </p>
                <p>
                Por medio de la aceptación de estos términos y condiciones, Fluio confiere a favor de los Usuarios, una licencia de uso no exclusiva, no 
                cesible y no sublicenciable, sobre la Plataforma para que los Usuarios puedan disfrutar de los Servicios y Productos que se proveen por los 
                Expendedores, a través de la misma. Lo anterior, sin perjuicio de los demás acuerdos que existan entre las Compañías y Fluio para el uso de 
                la Plataforma. La licencia tendrá un duración equivalente al tiempo en el que el Usuario permanezca registrado en la Plataforma, o aquella 
                que sea conferida mediante otros acuerdos, contratos o documentos, incluyendo los que Fluio haya celebrado con las distintas Compañías que 
                se registren.
                </p>
                <p>
                La autorización que se extiende por medio de la licencia de uso permite que los Usuarios ingresen a la plataforma virtual, se informen sobre 
                la variedad de productos y servicios que se ofrecen por medio de la misma y la posibilidad de solicitud de adquisición de los productos y/o 
                servicios. Esta adquisición se entiende intermediada por un contrato de mandato, donde el Mandante es el consumidor, mientras que el 
                Mandatario será la persona natural que acepta realizar la gestión del encargo requerida por el Consumidor, en virtud del Contrato de Mandato.
                </p>
                <p>
                Los Usuarios manifiestan entender que Fluio, en la calidad de Operador de la Plataforma Virtual, exhibe diferentes productos y/o servicios 
                de consumo, a la vez que facilita el encuentro entre consumidores y Expendedores y entre los Consumidores y el Mandatario, quien realizará 
                la consolidación del vínculo contractual en nombre de los Usuarios. De la misma manera, Fluio permitirá el uso de la plataforma de pagos y
                 podrá servir de medio en el envío de comunicaciones entre los Consumidores y el Mandatario.
                </p>
                <p>
                La denominada consolidación de la relación contractual implica, por un lado, la obtención de un servicio y/o producto, en contraprestación 
                de un precio, el cual podrán pagar, mediante un sistema de pago electrónico o en dinero en efectivo, una vez se presente la recepción del 
                producto y/o servicio.
                </p>
                <p>
                Por su parte, el Contrato de Mandato implica un encargo del Consumidor a un Mandatario frente a la adquisición de Productos y/o Servicios, 
                con la única finalidad de satisfacer una necesidad privada, personal o familiar, pero, en ningún caso, la búsqueda de comercialización, 
                reventa o cualquier otro tipo de transacción comercial o interés sobre los productos y/o servicios adquiridos. 
                </p>
                <p>
                Al aceptar estos términos y condiciones, los Usuarios declaran que tienen capacidad jurídica y las facultades necesarias para obligarse de 
                acuerdo con los presentes términos y condiciones. En caso de que quien solicite los Productos y/o Servicios de Fluio lo haga a nombre de 
                una persona jurídica o de un tercero, dicha persona garantiza que cuenta con la autorización de dicha persona para hacerlo. En caso de no 
                estar facultado, el solicitante responderá personalmente por las obligaciones contraídas de acuerdo con los presentes términos y condiciones.
                </p>
                <strong>1.¿Quién es Fluio?</strong>
                <p>
                Fluio S.A.S. es una sociedad constituida conforme a las leyes de la República de Colombiana, con NIT. 901.323.943-5, domiciliada en la 
                ciudad de Bogotá, República de Colombia, en la carrera 18 #91-68, apto. 102, con el número de teléfono (+57) 304 3578424 y el correo 
                electrónico [•]. La sociedad, presente en Colombia, se especializa, entre otras actividades, en brindar soluciones tecnológicas para los 
                consumidores con respecto a productos y/o bienes orgánicos, saludables, dietéticos, entre otros que permitan llevar una vida saludable.
                </p>
                <strong>2.Definiciones</strong>
                <p>
                Las siguientes palabras tendrán el significado que se indica a continuación:
                </p>
                <p>
                    <strong >
                    2.1 Contrato de Mandato:
                    </strong>
                    se entiende como el acto jurídico que se perfecciona con la aceptación de los presentes términos y condiciones, en virtud del cual, 
                    el Mandante –que para todos los efectos, se entiende el Consumidor y/o Comprador– encarga a un Mandatario –que para todos los efectos, 
                    se entiende como las personas naturales que Fluio delegue y/o asigne para dichos fines–, la celebración de un contrato de compraventa o 
                    cualesquiera contratos lícitos, con el fin de adquirir Servicios y/o Productos. Este Contrato se entiende perfeccionado una vez se haga 
                    entrega del Producto o se ponga a disposición del Consumidor, los Servicios encargados.
                    <p>
                    Se entiende que los Usuarios y Mandatarios suscriben el Contrato de Mandato por medios electrónicos, conforme a las disposiciones de la 
                    ley 527 de 1999. En ningún caso, este acto jurídico muta a una relación contractual de suministro, distribución o cualesquiera tipos 
                    contractuales que le sean asimilables. 
                    </p>
                </p>
                <p>
                    <strong>
                    2.2 Cuenta de Usuario: 
                    </strong>
                    Para solicitar la adquisición de Productos y/o Servicios, los Usuarios requerirán crear una Cuenta de Usuario, donde se le solicitarán 
                    ciertos datos personales, los cuales, se entienden tratados bajo la normativa vigente y la Política de Tratamiento de Datos Personales de 
                    la Compañía. Los Consumidores, en caso de tener cuentas en las redes sociales de Facebook y/o Google+, podrán crear su Cuenta de Usuario 
                    con la utilización de las credenciales previamente definidas en estas redes. 
                </p>
                <p>
                    <strong>
                    2.3 Plataforma virtual: 
                    </strong>
                    es el sitio web, aplicación o desarrollo informático, independientemente de su denominación, que ofrecerá a los Usuarios el acceso a los 
                    Servicios y/o Productos. El nombre de la Plataforma, su formato y funcionamiento, podrá variar según lo estime conveniente Fluio. Esta 
                    plataforma permite el uso gratuito por varios medios, a saber: página web y aplicación electrónica que se puede descargar en dispositivos 
                    móviles, por medio de tiendas virtuales de aplicación, entiéndase, App Store y Google Play. La licencia de uso será gratuita y sujeta a 
                    los términos y condiciones que por este instrumento se dispongan. 
                </p>
                <p>
                    <strong>
                        2.4 Proveedor(es) y/o Expendedor(es):
                    </strong>
                    aquellos sujetos que se registran dentro de la Plataforma, con el propósito de proveer y/o expender Servicios y/o Productos a quienes 
                    lo demanden, esto es, los Consumidores y/o Compradores. Dentro de los Proveedores puede haber sociedades, personas naturales, entidades 
                    sin ánimo de lucro, y, en general, cualquier otra entidad o persona que interactúe en la comercialización y distribución de productos y/o 
                    servicios saludables. 
                </p>
                <p>
                    <strong>
                        2.5 Servicios y/o Productos:
                    </strong>
                    son los bienes y/o servicios que se ofrecen en uso de la Plataforma Virtual, dentro los cuales se encuentra principalmente, 
                    aunque no de manera limitativa, la prestación de servicios que propendan por mejorar la vida y salud de las personas, así como los 
                    Productos que se cataloguen como orgánicos o bajo denominaciones dietéticas y/o con alguna otra restricción médica en términos de dieta 
                    y consumo.
                </p>
                <p>
                    <strong>
                        2.6 Usuarios o Usuario:
                    </strong>
                    son los visitantes de la Plataforma, aun cuando estos no se hubieran registrado y que quieran utilizar la Plataforma para buscar, 
                    contactar y/o solicitar los Servicios y/o Productos de la Plataforma, así como acceder a la información dispuesta en la misma. Entre los 
                    Usuarios, se identifican los siguientes:
                    <p>
                        <strong> 2.6.1 Comprador(es) y/o Consumidor(es): </strong>
                        aquellos sujetos que se registran dentro de la Plataforma como Consumidores de Servicios y/o Productos y que suscriben, con la 
                        aceptación de estos términos y condiciones, un Contrato de Mandato las personas naturales que Fluio asigne para tales efectos,
                        con el propósito de adquirir, consecuentemente, servicios y/o productos exhibidos por medio de la Plataforma Virtual de Fluio. Los
                        Compradores pueden incluir, pero no se limitan a, compañías y/o sociedades, bajo cualquier tipo o denominación societaria, 
                        personas naturales, entidades sin ánimo de lucro y cualesquiera otras formas asociativas legítimas.
                        <p>
                        De la misma manera, el Consumidor deberá ser el destinatario final de los Productos y/o Servicios que adquiera por medio de la Plataforma Virtual.
                        </p>
                    </p>
                    <p>
                        <strong>2.6.2 Visitantes: </strong>
                        aquellos sujetos que se registran o que simplemente visitan la Plataforma, con el propósito de participar en los servicios gratuitos 
                        y de información que se ofrecen, si es que a ello hay lugar.
                    </p>
                </p>
                <strong>
                    3. Descripción de los servicios, aspectos asociados y cobertura de la Plataforma Virtual.
                </strong>
                <p>
                La Plataforma provee un ecosistema que permite a oferentes y demandantes de Productos y Servicios saludables interactuar con mayor 
                facilidad y bajo condiciones de transparencia y confianza. A continuación, se encuentra una descripción sucinta de tales servicios, los 
                aspectos asociados al Servicio y su cobertura.
                    <p>
                        <strong>3.1 Servicios de información y/o contenidos: </strong>
                        son aquellos que suministra la Plataforma Virtual a personas con acceso a ésta, y que implica exhibir información y contenidos. 
                        A modo de ejemplo, y sin que se limite a ellos, se hace referencia a los siguientes:
                        <p>
                            <strong>3.1.1 Artículos de prensa o informativos.</strong>
                        </p>
                        <p>
                            <strong>3.1.2 Artículos creados por el equipo editorial de la Plataforma Virtual o por terceros sobre bienestar, eventos y 
                                tendencias en materia de alimentación y vida saludable, especialmente, de los Productos y Servicios saludables y del uso de
                                 la Plataforma, entre otros temas.
                            </strong>
                        </p>
                        <p>
                            <strong>
                            3.1.3 Presentación de fotografías, videos y diseños gráficos.
                            </strong>
                        </p>
                        <p>
                            <strong>
                                3.1.4 Presentación de música y/o videos de distintos contenidos, que tengan una finalidad de entretenimiento y/o de educación.
                            </strong>
                        </p>
                    </p>
                    <p>
                        <strong>
                            3.2 Servicios de promoción, publicidad y alerta de los Servicios:
                        </strong>
                        son aquellos que la Plataforma, a través de cualesquiera medios, incorpora para la promoción y publicidad de los Servicios y/o 
                        Productos o de la Plataforma Virtual, o bien para cumplir con determinadas tareas que se requieren para una adecuada prestación de 
                        los mismos. A modo de ejemplo, estos servicios pueden consistir –sin que se limite a ellos– en los siguientes:
                        <p>
                            <strong>
                                3.2.1 Programa de incentivos a la adquisición de los Servicios y/o Productos, consistente en promociones o en la 
                                acumulación de beneficios o puntos para el reclamo de premios.
                            </strong>
                        </p>
                        <p>
                            <strong>
                                3.2.2 Proyecto de promoción y mercadeo de los beneficios y servicios de la Plataforma Virtual a partir de la utilización de 
                                sus signos distintivos en cualesquiera objetos publicitarios, tales como, stickers, magnetos, pinnes, entre otros; los 
                                cuales, podrán ir como elementos accesorios a los Productos y/o Servicios que permita adquirir a partir del portal web y/ 
                                aplicativo móvil de Fluio.
                            </strong>
                        </p>
                        <p>
                            <strong>
                                3.2.3 Estudios de mercado basados en la información no individual de los Compradores y del comportamiento general de 
                                las ventas en la Plataforma Virtual.
                            </strong>
                        </p>
                        <p>
                            <strong>
                                3.2.4 Sistema de envío de notificaciones y alertas a los dispositivos de hardware que los Usuarios utilicen para disfrutar de los Servicios y/o Productos, con el fin de que estos sean prestados adecuadamente.
                            </strong>
                        </p>
                    </p>
                    <p>
                        <strong>
                            3.3 Servicios de recepción o presentación de ofertas de Productos y/o Servicios: 
                        </strong>
                        son aquellos que se otorgarán a las Compradores y/o Consumidores de Servicios y/o Productos para la identificación y categorización de los servicios o bienes que requieran de los distintos Proveedores en materia de productos o servicios saludables, de tal suerte que logren adquirir cuantos Productos y/o Servicios deseen conforme a las ofertas previamente dispuestas y publicadas en la Plataforma Virtual.
                    </p>
                    <p>
                        <strong>
                            3.4 Servicios de exhibición de ofertas de Productos y/o Servicios: 
                        </strong>
                        se desarrollan en el marco de la presentación y exhibición de los Servicios y/o Productos de los distintos oferentes (Proveedores y/o Expendedores). Este servicio puede involucrar una interacción y presentación de los bienes y servicios a partir de un contacto directo por parte del Comprador con el subsitio designado al Expendedor en la Plataforma Virtual, o por medio de notificaciones PUSH que genere la plataforma misma, con la finalidad de publicitar y promocionar un Producto y/o Servicio particular de un Proveedor a un grupo de Consumidores previamente identificados a partir de algoritmos. 
                        <p>
                        Esta exhibición, bien directa, bien mediada por publicidad, servirá como referencia al Consumidor para el encargo de Compraventa, por lo que el Expendedor se encuentra obligado a presentar las características básicas y específicas del Producto y/o Servicio que se encuentra ofertando.
                        </p>
                    </p>
                    <p>
                        <strong>
                            3.5 Servicios de administración de información:
                        </strong>
                        son aquellos que se otorgarán a los Usuarios para la administración y almacenamiento de la información a partir de la cual se generan las interacciones entre los Consumidores y los Expendedores en y a partir de la Plataforma, así como aquellos que permiten adelantar la totalidad del proceso requerido por la misma para poder realizar la adquisición de los Servicios y/o Productos. Toda la información adicional relacionada con el funcionamiento de la Plataforma y el procedimiento para lograr la compra de los servicios solicitados, será informada a través de la página web www.fluio.com.co, o bien mediante cualquier otro canal de comunicación que Fluio tenga dispuesto para tales efectos.
                    </p>
                    <p>
                        <strong>
                            3.6 Servicios de atención al cliente: 
                        </strong>
                        son aquellos consistentes en la recepción, gestión y resolución de peticiones, quejas y reclamos realizadas por los Compradores y/o Expendedores, y que están relacionadas con fallas de la Plataforma. Dichos servicios de atención al cliente son prestados directamente por Fluio, en uso de diferentes canales de comunicación dispuestos en la Plataforma, tales como: correo electrónico, chat online, redes sociales y línea telefónica, entre otros. En la medida en que Fluio no participa en la cadena de valor de los Usuarios, sino que simplemente ofrece una solución tecnológica a los mismos para la prestación de un servicio, reducción de costos y mejora de la experiencia de sus Usuarios, toda reclamación, queja, petición o sugerencia relacionada con los servicios solicitados y, por ende, prestados deberán manejarse directamente entre el Comprador y el Proveedor.
                    </p>
                    <p>
                        <strong>
                        3.7 Servicios de celebración de Contrato de Compraventa.
                        </strong>
                        A partir de la suscripción del Contrato de Mandato, el Consumidor, es decir, el Mandante, encargará al Mandatario, quien para los efectos de dicho acto jurídico pueden ser cualesquiera terceros y/o personas naturales que Fluio designe para estos fines, la celebración de uno o varios contratos de compraventa de Productos y/o Servicios expuestos y dispuestos en la Plataforma Virtual; los cuales, cabe aclarar, previamente fueron seleccionados por el Consumidor a referencia. Este mandato se caracteriza por ser remunerado.
                    </p>
                    <p>
                        <strong>
                            3.8 Servicios de selección del Mandatario.
                        </strong>
                        Toda vez que Fluio tiene acceso a una importante cantidad de sujetos que realizarían y ejecutarían el encargo de los Consumidores, será la Compañía quien, en virtud de la cercanía de los Productos por entregar a la ubicación del Mandatario, entre otros factores, determinarán y designarán el Mandatario para cada transacción, sin que ello conlleve que la celebración del Contrato de Mandato se realiza directamente con Fluio o bajo su cuenta y riesgo. 
                    </p>
                    <p>
                        <strong>
                            3.9 Áreas de Cobertura. 
                        </strong>
                        El área de cobertura de los servicios referidos anteriormente es la del territorio nacional, es decir, aquellos que se celebren en el país de la República de Colombia. No obstante, tal cobertura podrá variar a voluntad de Fluio y según requerimientos de los Consumidores y/o Expendedores.
                    </p>
                </p>
                <strong>
                    4. Sobre el proceso de inscripción de los usuarios.
                </strong>
                <p>
                Para utilizar los servicios de la Plataforma, los Usuarios deberán realizar el proceso de inscripción y/o registro. El proceso de registro se regirá bajo los siguientes preceptos:
                    <p>
                        <strong>
                            4.1 Términos y condiciones generales de la inscripción 
                        </strong>
                        <p>
                            <strong>
                                4.1.1 Es obligación de los Usuarios diligenciar el formulario de inscripción en todos sus campos, con datos válidos y verídicos, para poder utilizar los servicios que presta la Plataforma. En todo caso, se podrá acceder a algunos servicios ofrecidos por la Plataforma, sin que sea necesaria la inscripción, tales como aquellos que resultan meramente informativos.
                            </strong>
                            <br/>
                            <strong>
                                4.1.2 Todo Usuario deberá completar el o los formularios pertinentes con su información personal de manera exacta, precisa y verdadera, y asume el compromiso de actualizar sus datos personales siempre que sea necesario. Para dichos efectos, y previo al recaudo de los respectivos datos personales, los Usuarios deberán leer y conocer la Política de Protección de Datos Personales de la Plataforma y autorizar el tratamiento de sus datos de conformidad con dicha política, la cual se encuentra disponible en la siguiente URL: www.fluio.com.co. 
                            </strong>
                            <br/>
                            <strong>
                                4.1.3 Los Usuarios garantizarán y serán responsables, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de los datos personales que suministren. En todo caso, la Plataforma podrá requerir, en cualquier momento, la información que considere necesaria para verificar la información suministrada.
                            </strong>
                            <br/>
                            <strong>
                                4.1.4 Los Usuarios responderán por cualquier perjuicio, daño o inconveniente generado por el suministro de información falsa, errónea e imprecisa.
                            </strong>
                            <br/>
                            <strong>
                                4.1.5 Si la información personal de los Usuarios presenta algún cambio que llegare a afectar alguno de los Servicios ofrecidos por la Plataforma, los Usuarios deberán actualizarla a través de los medios dispuestos para ello.
                            </strong>
                            <br/>
                            <strong>
                                4.1.6 Los Usuarios aceptan que las cuentas o perfiles creados podrán ser deshabilitados o limitados si la Plataforma determina que se ha suministrado información incorrecta, falsa o imprecisa por medio de la Plataforma.
                            </strong>
                            <br/>
                            <strong>
                                4.1.7 Todo lo ateniente al cumplimiento de la regulación en tratamiento de datos personales, se regirá por las Políticas de Tratamiento de Datos Personales de Fluio.
                            </strong>
                            <br/>
                            <strong>
                                4.1.8 El Usuario accederá a su cuenta personal mediante el ingreso de su seudónimo, nombre o razón social y clave de seguridad personal elegida, o bien mediante el uso de aplicaciones integrables al registro de la Plataforma, como Facebook, Instagram o correo electrónico. El Usuario se obliga a mantener la confidencialidad de su clave de seguridad y a tomar las medidas de seguridad necesarias para que no ocurra ningún incidente de seguridad respecto del manejo de su cuenta y la respectiva clave de seguridad que le asiste.
                            </strong>
                            <br/>
                            <strong>
                                4.1.9 La cuenta es personal, única e intransferible. Un mismo Usuario no podrá inscribir o poseer más de una cuenta, salvo que se postule como Consumidores y Proveedor y tenga dichas cualidades ambivalentes. Esto, independientemente de que cada Usuario pueda asociar distintas cuentas de correo electrónico e identificaciones como que sean requeridas, máxime si le asisten las calidades de Comprador y Prestador.
                            </strong>
                            <br/>
                            <strong>
                                4.1.10 El Usuario será responsable por todas las operaciones efectuadas en su cuenta, pues el acceso a la misma está restringido al ingreso y uso de su clave de seguridad, de conocimiento exclusivo del Usuario. El Usuario está obligado a notificar a la Plataforma en forma inmediata, por un medio idóneo y fehaciente, de cualquier uso no autorizado de su cuenta, así como el ingreso por parte de terceros no autorizados.
                            </strong>
                            <br/>
                            <strong>
                                4.1.11 Las cuentas de los Usuarios no podrán ser cedidas o transferidas, ni tampoco podrán estar sujetas a cualquier otra operación comercial que no sea reconocida expresamente por los presentes términos y condiciones.
                            </strong>
                        </p>
                    </p>
                    <p>
                        <strong>
                            4.2 Aspectos sobre la selección de seudónimos y/o avatares de las cuentas.
                        </strong>
                        <p>
                        Los Usuarios deberán considerar los siguientes aspectos a la hora de seleccionar los seudónimos y/o avatares que identificarán sus cuentas, al momento de realizar la inscripción, si es que optan por no adoptar su nombre o razón social:
                            <p>
                                <strong>
                                    4.2.1 Al momento de la inscripción a la Plataforma, el Usuario deberá elegir un seudónimo y/o avatar para asociarlo a su cuenta, que tendrá la finalidad de identificarlo frente a cualquier actividad que realice en la Plataforma.
                                </strong>
                                <br/>
                                <strong>
                                    4.2.2 El seudónimo y/o avatar elegido, no podrá suscitar riesgo de confusión con otros Usuarios ni mucho menos, con el personal de servicio de la Plataforma.
                                </strong>
                                <br/>
                                <strong>
                                    4.2.3 El seudónimo y/o avatar elegido, deberá respetar los derechos de propiedad intelectual de terceros, como también sus derechos de imagen.
                                </strong>
                                <br/>
                                <strong>
                                    4.2.4 El seudónimo y/o avatar elegido, deberá respetar las normas de la ética y las buenas costumbres, por lo cual no podrá contener ningún tipo de alusión a temas obscenos, ilegales, o contrarios a la moral. Este criterio será libre y discrecionalmente determinado por la Plataforma y su Administrador.
                                </strong>
                                <br/>
                                <strong>
                                    4.2.5 El seudónimo y avatar elegido no podrá contener datos personales o de contacto, links de internet, números telefónicos, o conceptos similares.
                                </strong>
                                <br/>
                                <strong>
                                    4.2.6 Esta prohibido cualquier tipo suplantación de identidad.
                                </strong>
                            </p>
                        </p>
                    </p>
                    <p>
                        <strong>
                            4.3 Causales de rechazo, suspensión y/o inhabilitación de inscripción de cuentas.
                        </strong>
                        <p>
                        Fluio podrá rechazar, cancelar, suspender o inhabilitar cualquier inscripción –sea que esté en proceso de aceptación o ya haya sido aceptada–, cuando ocurra alguno de los siguientes eventos y/o circunstancias:
                            <p>
                                <strong>
                                    4.3.1 Será rechazada toda solicitud de inscripción, donde se encuentre información falsa, incorrecta, equivocada, imprecisa, errónea o confusa.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.2 Serán canceladas todas las cuentas adicionales a la cuenta original que cree un Usuario, siempre que ello no corresponda a la calidad ambivalente de Proveedor y Comprador en cabeza de un solo sujeto y/o Usuario. De no precisarse concurrencia de calidades, la cuenta original del Usuario podrá ser suspendida o inhabilitada dependiendo del caso particular y a discreción de Fluio.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.3 Serán suspendidas las cuentas de un sujeto cuando un Usuario incumpla alguna de sus obligaciones respecto de la Plataforma. En caso de incumplir por más de tres (3) ocasiones, su cuenta será cancelada.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.4 Cuando se realice una operación ilegal o que atente contra la seguridad de bienes o personas, a través de una cuenta registrada, tal cuenta será cancelada, y de ser el caso, se avisará a las autoridades competentes de la irregularidad presentada.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.5 Será cancelada, inhabilitada o suspendida temporalmente, una cuenta, a petición del Usuario, siempre y cuando, dicha cuenta no tenga obligaciones o compromisos pendientes por cumplir, bien con otro Usuario, bien con la Plataforma Virtual o Fluio.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.6 erá suspendida temporalmente, toda cuenta que utilice un lenguaje inapropiado en el desarrollo de sus operaciones e interacciones. Por lenguaje inapropiado, se entenderá aquel que sea irrespetuoso, afecte la honra y buen nombre de las personas, o sea sexual, soez, o vulgar.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.7 La cuenta podrá ser cancelada, suspendida o inhabilitada, por la falta de cumplimiento de cualquiera de los requisitos para ser Usuario.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.8 La cuenta podrá ser cancelada, suspendida o inhabilitada, si no se realiza un uso activo, con por lo menos un ingreso semestral.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.9 La cuenta podrá ser cancelada en caso de que se presente una violación a alguna de las disposiciones referidas a la selección de seudónimos y/o avatares del presente instrumento.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.10 Será cancelada, inhabilitada o suspendida temporalmente, una cuenta, en cualquier evento o circunstancia en que se entienda como incumplida alguna de las obligaciones establecidas en los presentes términos y condiciones.
                                </strong>
                                <br/>
                                <strong>
                                    4.3.11 Cuando Fluio advierta que un Usuario es un menor de edad procederá a cancelar la cuenta.
                                </strong>
                            </p>
                            <p>
                            En cualquier caso, Fluio no será responsable de los perjuicios que se pudiesen llegar a causar, por la suspensión, cancelación o inhabilitación de una cuenta, cuando ésta ocurra por cualesquiera de las causas que se enuncian anteriormente.
                            </p>
                        </p>
                    </p>
                    <p>
                        <strong>
                            4.4 Requisitos para inscribirse como Comprador y/o Consumidor.
                        </strong>
                        <p>
                        Para registrarse y ser Comprador y/o Consumidor, se deberán cumplir con los requisitos que se describen a continuación:
                            <p>
                                <strong>
                                    4.4.1 Ser mayor de dieciocho (18) años, en caso de ser persona natural
                                </strong>
                                <br/>
                                <strong>
                                    4.4.2 Tener a disposición un medio financiero válido y reconocido por la Plataforma para el disfrute de los Servicios y/o Productos, cuando sea aplicable.
                                </strong>
                                <br/>
                                <strong>
                                    4.4.3 Cumplir con cualquiera de las obligaciones, términos y condiciones establecidas en el presente documento.
                                </strong>
                            </p>
                        </p>
                    </p>
                    <p>
                        <strong>
                            4.5 Requisitos para ser Expendedor y/o Proveedor.
                        </strong>
                        <p>
                        Para inscribirse como Proveedor y/o Prestador, se deberán cumplir con los siguientes requisitos:
                            <p>
                                <strong>
                                    4.5.1 Ser mayor de dieciocho (18) años, en caso de ser persona natural.
                                </strong>
                                <br/>
                                <strong>
                                    4.5.2 Tener a disposición un medio financiero válido y reconocido por la Plataforma para el ejercicio de sus operaciones.
                                </strong>
                                <br/>
                                <strong>
                                    4.5.3 Cumplir con cualquiera de las obligaciones, términos y condiciones establecidas en el presente documento.
                                </strong>
                                <br/>
                                <strong>
                                    4.5.4 No tener antecedentes irregulares o sanciones reportadas en las bases de datos y fuentes, tales como, bases de datos de la Policía Nacional, Contraloría, Procuraduría, Interpol, FBI, SDNT list (o “Lista Clinton”), OFAC en la forma en la que se encuentren dispuestas.
                                </strong>
                                <br/>
                                <strong>
                                    4.5.5 Presentar Copia actualizada del Certificado de Existencia y Representación Legal de Cámara de Comercio o documento equivalente, en caso de ser aplicable.
                                </strong>
                                <br/>
                                <strong>
                                    4.5.6 Presentar Copia actualizada de Registro Único Tributario.
                                </strong>
                                <br/>
                                <strong>
                                    4.5.7 Presentar certificación bancaria de una cuenta activa de la empresa, entidad o la persona natural.
                                </strong>
                            </p>
                        </p>
                    </p>
                    <p>
                        <strong>
                            4.6 Requisitos para hacer uso de la Plataforma Virtual y sus servicios asociados.
                        </strong>
                        <p>
                            <strong>
                                4.6.1 El Usuario deberá ingresar a la Plataforma Virtual, indicando el domicilio y ubicación exacta en la que requiere el Producto y/o Servicio, puesto que de ello se desprenderá (i) la recepción real y efectiva del Producto y/o Servicio encargado; y (ii) la disponibilidad del Producto y/o Servicio.
                            </strong>
                            <br/>
                            <strong>
                                4.6.2 El Usuario deberá seleccionar el lugar de entrega exacto, por medio del diligenciamiento de la dirección exacta. Esta dirección deberá encontrarse en el rango de cobertura de entrega, toda vez que, de no encontrarse, no se permitirá la finalización de la transacción. 
                            </strong>
                            <br/>
                            <strong>
                                4.6.3 Con posterioridad, el Usuario deberá indicar el Producto y/o Servicio por encargar, del cual se desprenderán las características del Producto y/o Servicio, así como el valor total del producto. Éste podrá estar acompañado de fotografías y notas de referencia; elementos indispensables para la plena individualización del Producto y/o Servicio por parte del Consumidor.
                            </strong>
                            <br/>
                            <strong>
                                4.6.4 Una vez realizada la validación del Producto y/o Servicio por parte del Consumidor, será obligación de Fluio relacionar al Consumidor, el resumen del Producto y/o Servicio, sus condiciones generales y la marca y presentación que le corresponde, así como los términos de su utilización y ejercicio, en caso de que se encargue un Servicio.
                            </strong>
                            <br/>
                            <strong>
                                4.6.5 Posterior a esta validación, el Producto y/o Servicio ingresará al carrito de compras. Con ello, el Consumidor manifiesta su voluntad inequívoca de solicitar la gestión del encargo y, por ende, se iniciará con la ejecución del Contrato de Mandato, en donde Fluio designará a una persona natural que procederá a adquirir y celebrar el contrato de compraventa sobre el Producto y/o Servicio previamente seleccionado y validado por el Consumidor, por cuenta y riesgo propio del Consumidor, en su calidad de Mandatario. Sin perjuicio de lo anterior, Fluio se reserva total autonomía para limitar el ingreso de Productos y/o Servicios al carrito de compras con ocasión o a propósito de la cantidad. 
                            </strong>
                            <br/>
                            <strong>
                                4.6.6 Dada la culminación de los pasos descritos en los numerales anteriores, el Consumidor deberá avalar el valor determinado por Fluio para realizar y ejecutar el encargo designado. Este valor comprende la remuneración del mandato y la suma a reembolsar por la gestión. En ese sentido, el valor por reembolsar corresponderá al valor total de los Productos y/o Servicios adquiridos, incluyendo los costos de transacción e impuestos. 
                            </strong>
                            <br/>
                            <strong>
                                4.6.7 Una vez finalizada la discriminación del valor, el Usuario realizará el pago directamente al Mandatario, sea éste en efectivo o a través o por medio de las plataformas virtuales de pagos que tiene contratada Fluio para tales efectos. Con esto, la orden se entiende finalizada y, por lo tanto, se acepta como realizada, una vez se entreguen los Productos o se pongan a disposición los Servicios, según la forma en cómo ellos puedan ejercerse.
                            </strong>
                            <br/>
                            <strong>
                                4.6.8 Completados estos puntos, el Usuario recibirá un resumen detallado de la transacción realizada a partir de la Plataforma Virtual, en el correo electrónico dispuesto al momento del registro o inscripción. En este resumen, se relacionará el encargo, la remuneración al Mandatario, el valor discriminado por la adquisición del Producto y/o Servicio, el Expendedor que provee los mismos, así como el servicio de atención al cliente que le corresponde y la forma en la que puede ejercer la misma.
                            </strong>
                            <br/>
                            <strong>
                                4.6.9 Al momento de la entrega, de no poder realizarse la misma por razones imputables al Consumidor, el Mandatario encargado por éste, deberá dejar constancia de ello.
                            </strong>
                            <br/>
                            <strong>
                                4.6.10 En caso de que el Producto y/o Servicio no se encuentre, el Mandatario comunicará de tal evento al Consumidor, quien tendrá la facultad de continuar con el encargo sobre los Productos y/o Servicios disponibles o de cancelar el pedido o utilizar el cumplimiento de un sustituto, aun cuando ello implique un mayor valor; precio que será asumido por el Consumidor. 
                            </strong>
                        </p>
                    </p>
                </p>
                <strong>
                    5 Obligaciones de los Usuarios
                </strong>
                <p>
                Los Usuarios están obligados a cumplir con todas las obligaciones que se expresan en los presentes términos y condiciones, al igual que con aquellas que se determinen en otros documentos, incluyendo, pero sin limitarse a, otros contratos que sean celebrados con Fluio, órdenes de compra y las Políticas de Protección de Datos Personales. Además de estas obligaciones, los Usuarios tendrán las siguientes obligaciones:
                    <p>
                        <strong>
                            5.1 Obligaciones de los Usuarios, en general. 
                        </strong>
                        <p>
                            <strong>
                                5.1.1 Dirigirse con respeto a los otros Expendedores y al personal de servicio al cliente de la Plataforma.
                            </strong>
                            <br/>
                            <strong>
                                5.1.2 Suministrar información veraz, exacta, vigente y auténtica respecto de su inscripción a la Plataforma, y respecto de su identidad.
                            </strong>
                            <br/>
                            <strong>
                                5.1.2.1 Proporcionar de manera completa y fidedigna la información que le sea solicitada para la efectiva prestación del servicio de la Plataforma.
                            </strong>
                            <br/>
                            <strong>
                                5.1.3. Actualizar la información suministrada respecto de su identidad y respecto de la inscripción a la Plataforma, cuando sea razonablemente requerido por Fluio o cuando sea necesario., bajo su consideración.
                            </strong>
                            <br/>
                            <strong>
                                5.1.4 Pagar oportunamente la suma acordada como contraprestación del mandato y del Producto y/o Servicio adquirido.
                            </strong>
                            <br/>
                            <strong>
                                5.1.5 Dar aviso a la Plataforma sobre cualquier irregularidad o ilegalidad de la que tenga conocimiento, y que haya ocurrido o esté ocurriendo en la Plataforma.
                            </strong>
                            <br/>
                            <strong>
                                5.1.6 Leer y conocer a cabalidad los presentes Términos y Condiciones y la Política de Tratamiento de Datos Personales de Fluio.
                            </strong>
                            <br/>
                            <strong>
                                5.1.7 Abstenerse de subir o distribuir cualesquiera archivos que contengan virus, archivos corruptos o cualquier otro software similar o programas que puedan perjudicar o dañar la operatividad de la Plataforma y/o sistemas operativos de los operadores y/u otros Usuarios.
                            </strong>
                            <br/>
                            <strong>
                                5.1.8 Abstenerse de modificar, desensamblar, descompilar o aplicar ingeniería inversa a la Plataforma.
                            </strong>
                            <br/>
                            <strong>
                                5.1.9 Abstenerse de probar, testear, escanear la vulnerabilidad, o tratar de evadir los mecanismos de seguridad implementados en los sitios web, servidores o redes conectadas a la Plataforma, o relacionadas con la misma.
                            </strong>
                            <br/>
                            <strong>
                                5.1.10 Abstenerse de realizar cualquier acto que imponga de manera desproporcionada o no razonable, cargas de tamaño considerable en los sitios, servidores o redes conectadas a la Plataforma.
                            </strong>
                            <br/>
                            <strong>
                                5.1.11 Abstenerse de acceder o usar la información de cualesquiera otros Usuarios o terceras personas, a través o al hacer uso de la Plataforma, sin estar autorizado debidamente para el efecto.
                            </strong>
                            <br/>
                            <strong>
                                5.1.12 Abstenerse de reducir o impedir de manera deliberada la accesibilidad, usabilidad u operatividad de la Plataforma.
                            </strong>
                            <br/>
                            <strong>
                                5.1.13 Abstenerse de utilizar la Plataforma para publicar, promover o trasmitir material o contenido ilegal, abusivo, difamador, amenazador, dañino o discriminatorio.
                            </strong>
                            <br/>
                            <strong>
                                5.1.14 Abstenerse de trasmitir, publicar o promover material o contenido que incentive cualquier conducta que pueda constituir un delito o que pueda generar cualquier tipo de responsabilidad de Fluio, de acuerdo con las leyes aplicables.
                            </strong>
                            <br/>
                            <strong>
                                5.1.15  Cumplir, a cabalidad con los requisitos para hacer uso de los servicios de la Plataforma Virtual y sus servicios asociados.
                            </strong>
                            <br/>
                            <strong>
                                5.1.16 Presentar, de forma fidedigna y veraz, la información sobre las características y condiciones de los Productos y/o Servicios que se ofrecen en calidad de Expendedores. 
                            </strong>
                        </p>
                    </p>
                    <p>
                        <strong>
                            5.2 Obligaciones de los Compradores o Consumidores
                            <p>
                                <strong>
                                    5.2.1 Generales
                                    <p>
                                        <strong>
                                            5.2.1.1 Suministrar información veraz, exacta, vigente y auténtica respecto de los datos que sean requeridos en relación con los procesos de adquisición y encargo que se inicien dentro de la Plataforma, indicando todos los detalles e información que sea razonablemente requerida para tales efectos.
                                        </strong>
                                        <br/>
                                        <strong>
                                            5.2.1.2 Actuar lealmente y observar siempre el principio de la buena fe, en todas las operaciones comerciales.
                                        </strong>
                                        <br/>
                                        <strong>
                                            5.2.1.3 Abstenerse de realizar encargos que no cumplirán. 
                                        </strong>
                                        <br/>
                                        <strong>
                                            5.2.1.4 Leer y comprender los presentes términos y condiciones.
                                        </strong>
                                        <br/>
                                        <strong>
                                            5.2.1.5 Prestar toda su colaboración y asistencia en relación con la atención de PQRS que se presenten respecto de los servicios que adquiere y/o de los procesos de licitación que publica, cuando sea necesario. Para tales efectos, se deberá dar respuesta a cualquier requerimiento de información de la Plataforma, en un término no menor a dos (2) días hábiles, salvo que se convenga un plazo distinto entre los actores y el Comprador.
                                        </strong>
                                        <br/>
                                        <strong>
                                            5.2.1.6 Generar las condiciones adecuadas para que Fluio y su personal, puedan prestar adecuadamente sus servicios.
                                        </strong>
                                        <br/>
                                        <strong>
                                            5.2.1.7 El Usuario se abstendrá de realizar conductas desleales tendientes a utilizar la Plataforma de manera parasitaria, y no reconocer las comisiones y conceptos económicos a los que tiene derecho Fluio y/o los Mandatarios.
                                        </strong>
                                        <br/>
                                        <strong>
                                            5.2.1.8 Realizar los procesos de validación requerida para el uso adecuado de los servicios que provee la Plataforma Virtual. 
                                        </strong>
                                    </p>
                                </strong>
                            </p>
                        </strong>
                    </p>
                </p>
                <strong>
                    6 Tarifas
                </strong>
                <p>
                Las tarifas de los servicios de la Plataforma serán determinadas en planes tarifarios, precios por unidad o cualquier otra forma de venta, que se ofrecerán a los Usuarios, se publicarán en la página web y podrán ser también informadas a través de los asesores de venta de Fluio. Dichas tarifas podrán ser actualizadas regularmente, dependiendo de las condiciones del mercado y a discreción de Fluio, siendo previamente informado cualquier cambio a los Usuarios. 
                </p>
                <p>
                Sin perjuicio de lo anterior, cualesquiera incrementos de los Productos y/o Servicios no comunicados en forma oportuna por los Expendedores correrán por cuenta y riesgo de los Usuarios.
                </p>
                <strong>
                    7 Facturación, cobro, impuestos y pago.
                    <p>
                        <strong>
                            7.1 Facturación. Las facturas o documentos equivalente por los servicios prestados por la Plataforma se emitirán mensualmente, si es que se opta por una membresía mes a mes, o si, por el contrario, es un servicios instantáneo, éste podrá ser facturable de manera inmediata por Fluio y/o por el Expendedor, según sea el caso.
                        </strong>
                        <br/>
                        <strong>
                            7.2 Cobro. Las facturas o documentos equivalentes serán enviadas por correo electrónico a los Usuarios, y serán cobradas a discreción de Fluio, dependiendo del caso particular y/o membresía.
                        </strong>
                        <br/>
                        <strong>
                            7.3 Tanto el Comprador, como el Expendedor, asumirán los impuestos y retenciones establecidas o que se estableciesen para los servicios de la Plataforma, a la vez que aquellos tributos que se causen por la venta y/o adquisición de los servicios en Colombia, así como los aplicables a los negocios que se realicen, entre sí, por los Usuarios.
                        </strong>
                    </p>
                </strong>
                <br/>
                <strong>
                    8 Parámetros de calidad del servicio de la plataforma.
                </strong>
                <p>
                Se consideran los siguientes parámetros, como aquellos que reflejan una adecuada calidad en la prestación del Servicio de la Plataforma:
                    <p>
                        <strong>
                            8.1 Disponibilidad del Servicio: Fluio garantiza que el Servicio sea continuo en un 98%, el cual, sólo y exclusivamente se interrumpirá por mantenimiento de la Plataforma o por cualesquiera otras razones que Fluio estime convenientes, en forma continua o acumulada. En todo caso, se dará aviso previo –de ser ello posible y previsible para Fluio– en un término razonable sobre cualquier interrupción en la prestación del servicio
                        </strong>
                        <br/>
                        <strong>
                            8.2 Causas Ajenas: Fluio no será responsable de la interrupción del servicio que tengan origen en causas imputables al Usuario, o por causas ajenas a su órbita de control, o por fuerza mayor y/o caso fortuito o cualesquiera otra causa extraña o ajena al control de. Fluio.
                        </strong>
                    </p>
                </p>
                <strong>
                    9 Peticiones, Queja, Reclamos y Sugerencias (P.Q.R.S.)
                    <p>
                    Las peticiones, quejas, reclamos y sugerencias respecto de los servicios de la Plataforma se regirán por las siguientes disposiciones:
                    </p>
                    <p>
                        <strong>
                            9.1 Las PQRS de los Usuarios podrán interponerse en las oficinas de atención de Fluio de lunes a viernes, de 8:00 AM a 5:00 PM, o por el Chat Virtual de la Plataforma o correo electrónico a info@fluio.com.co. 
                        </strong>
                        <br/>
                        <strong>
                            9.2 Servicios de Asistencia y Emergencia. El Usuario podrá presentar e interponer reclamos por desperfectos técnicos en la provisión del servicio de la Plataforma. Para estos casos, la asistencia técnica será provista por medio del correo electrónico info@fluio.com.co.
                        </strong>
                        <br/>
                        <strong>
                            9.3 Atención de Reclamaciones. Un Usuario o un tercero en su representación, previa identificación, podrá presentar sus reclamaciones directas, de forma gratuita, en las oficinas de Fluio, en el chat la Plataforma, por correo electrónico u otros medios que Fluio disponga para tales efectos. La reclamación podrá ser presentada en forma escrita o verbal. Por su parte, Fluio registrará e individualizará la reclamación, asignándole un número a su reclamación, que será puesto en conocimiento del Usuario y/o su representante, a través de una copia o, cuando menos, comunicándole el número de que le fue asignada. Fluio deberá emitir la respuesta al reclamo dentro de los siguientes quince (15) días hábiles a la fecha de recepción de la reclamación. 
                        </strong>
                    </p>
                </strong>
                <p></p>
                <strong>
                    10 Deberes de Fluio. 
                </strong>
                <p>
                En virtud de los presentes términos y Condiciones, Fluio se obliga a: 
                    <p>
                        <strong>
                            10.1 Suministrar información cierta, fidedigna, suficiente, clara y actualizada respecto de los Productos y/o Servicios que exhibe en su Plataforma Virtual;
                        </strong>
                        <br/>
                        <strong>
                            10.2 Indicar las características generales del producto para que sirvan de referencia a los Consumidores, dentro de los que se encuentra, la marca, presentación, entre otros;
                        </strong>
                        <br/>
                        <strong>
                            10.3 Informar suficientemente sobre los medios habilitados para que los Consumidores realicen el pago;
                        </strong>
                        <br/>
                        <strong>
                            10.4 Informar en el momento indicado y con suficiencia los datos de los Mandatarios con los cuales los Consumidores han de celebrar el contrato de mandato;
                        </strong>
                        <br/>
                        <strong>
                            10.5  Enviar al correo electrónico suministrado por el Consumidor resumen del encargo y constancia de la transacción;
                        </strong>
                        <br/>
                        <strong>
                            10.6 Poner a disposición de los Consumidores los términos y condiciones de uso de la Plataforma Virtual de forma actualizada;
                        </strong>
                        <br/>
                        <strong>
                            10.7 Utilizar la información únicamente para los fines establecidos en los presentes términos;
                        </strong>
                        <br/>
                        <strong>
                            10.8 Utilizar mecanismos de información y validación durante la transacción como ventanas emergentes (Pop Ups), que permitan al Consumidor aceptar, o no, cada paso del proceso de compra que se describe en los requisitos para el uso de los servicios de la Plataforma Virtual y sus servicios asociados.
                        </strong>
                    </p>
                </p>
                <strong>
                     11 Exenciones de responsabilidad.
                </strong>
                <p>
                Fluio, aleatoriamente, realizará revisiones y chequeos de los aspectos relacionados con la Plataforma, sin embargo, informa a los Usuarios que quedará exenta de responsabilidad cuando:
                    <p>
                        <strong>
                            11.1 El Usuario proporcione datos de identidad o información incorrectos, inexactos, no actualizados o falsos, y/o permita el acceso de otros usuarios a su cuenta y en particular, aunque de modo no exclusivo ni limitativo, por daños y perjuicios de cualquier naturaleza que se puedan deber a suplantación de personalidad de un tercero, efectuada por un Usuario en cualquier clase de comunicación o transacción realizada a través del servicio de la Plataforma.
                        </strong>
                        <br/>
                        <strong>
                            11.2 Por consecuencias derivadas del contenido de la información entregada por el Usuario, en la máxima medida permitida por las leyes aplicables.
                        </strong>
                        <br/>
                        <strong>
                            11.3 Cuando se produzca suspensión, interrupción o corte del servicio debido a mantenimiento correctivo o preventivo.
                        </strong>
                        <br/>
                        <strong>
                            11.4 Cuando se deban realizar modificaciones urgentes que sean necesarias en sus instalaciones o en la Plataforma, por causas ajenas a la voluntad de Fluio. 
                        </strong>
                        <br/>
                        <strong>
                            11.5 En casos de congestionamiento de magnitud en las líneas o señales, casos de fuerza mayor o fortuitos atribuibles a la naturaleza, a las condiciones atmosféricas, a altas o bajas en el voltaje del sistema eléctrico y otros de similar naturaleza.
                        </strong>
                        <br/>
                        <strong>
                            11.6 Por virus importados a través de la red o cualquiera que sea su origen.
                        </strong>
                        <br/>
                        <strong>
                            11.7 Por el no uso del servicio por parte del Usuario, ya sea por desconocimiento del uso de equipos, mala configuración de estos, fallas técnicas de acceso u otras similares en los equipos de propiedad del Usuario.
                        </strong>
                        <br/>
                        <strong>
                            11.8 Por fallas eventuales o cualquier problema técnico presente en los equipamientos de propiedad del Usuario. 
                        </strong>
                        <br/>
                        <strong>
                            11.9 Por falta de conocimiento del uso de su equipo terminal o de la red local del Usuario o cualquiera de sus herramientas.
                        </strong>
                        <br/>
                        <strong>
                            11.10 Por pérdidas de ganancias por negocios o acuerdos no realizados o celebrados por el Usuario, lucro cesante, pérdidas pecuniarias o pérdidas de información o datos, ya sea por la imposibilidad de habilitar el servicio a tiempo o por cualquier tipo de falla en el servicio. 
                        </strong>
                        <br/>
                        <strong>
                            11.11 Por cualquier pérdida económica resultante de bloqueos dentro del marco legal u otra limitación de acceso debidamente justificada.
                        </strong>
                        <br/>
                        <strong>
                            11.12 Por cualquier costo, perjuicio o daño que sea causado al Usuario o a terceros como consecuencia de la utilización indebida del servicio por personas ajenas a Fluio.
                        </strong>
                        <br/>
                        <strong>
                            11.13 Por cualquier declaración que sea injuriante y/o busque calumniar a un Usuario por otro Usuario.
                        </strong>
                        <br/>
                        <strong>
                            11.14 Por las infracciones de derecho efectuadas por terceros, quienes se atribuyan la condición de derechohabientes, ya sea como titulares o licenciatarios de tales derechos, o quienes presuman contar con cualquier tipo de autorización para su uso.
                        </strong>
                        <br/>
                        <strong>
                            11.15. Los Usuarios aceptan y reconocen que Fluio no es productos, proveedor, expendedor, agente, distribuidor y, en general, ningún tipo de comercializador de los Productos y/o Servicios que exhibe en su Plataforma Virtual y, por lo tanto, no responderá por los vicios que presenten los bienes adquiridos ni los servicios ofertados. 
Fluio no ofrece garantías sobre la veracidad o exactitud de la información suministrada o transmitida, a través de la Plataforma por los Usuarios. Sin perjuicio de lo anterior, Fluio realizará estudios aleatorios sobre la veracidad y exactitud de la información. Así, los únicos que garantizar y atenderán las reclamaciones serán los Mandatarios y/o los Expendedores directamente. 
                        </strong>
                    </p>
                </p>
                <strong>
                    12 Aspectos de propiedad intelectual de la licencia de uso.
                </strong>
                <p>
                    <strong>
                    12.1 La utilización de los aplicativos y del software de la Plataforma de Fluio, por parte de los Usuarios, no implica cesión alguna de los derechos de propiedad industrial y de derechos de autor de Fluio sobre tales contenidos ni sobre los servicios atados a la Plataforma. Fluio únicamente concede una licencia de uso, bajo las características expresadas en los términos y condiciones prescritos en este instrumento, sobre sus derechos de propiedad intelectual a los Usuarios que estará referida exclusivamente a los aplicativos y el software de la Plataforma. Los usos autorizados estarán delimitados por los presentes términos y condiciones, la finalidad natural y funcional de los aplicativos y del software de la Plataforma, y la relación comercial del Usuario con Fluio. Los usos conferidos deben interpretarse de manera restrictiva y a favor de quien ostenta la propiedad de los derechos de propiedad intelectual
                    </strong>
                </p>
                <strong>
                    13. Derecho de retracto. 
                </strong>
                <p>
                En virtud de que los Productos que se comercializan a través de la Plataforma Virtual son productos perecederos, Fluio informa a los Consumidores que los Mandatarios no se encontrarán en la obligación de otorgar el derecho de retracto, consagrado en el artículo 47 de la ley 1480 de 2011.
                </p>
                <p>
                Ahora bien, con respecto a todos los demás Productos y/o Servicios en los que sea aplicable, el Mandatario será quien deba responder ante el Consumidor conforme a la legislación vigente y en las condiciones para ello previstas. 
                </p>
                <strong>
                    14 Aceptación de los Términos y Condiciones
                </strong>
                <p>
                    <strong>
                        14.1 Al registrarse o utilizar los servicios de Fluio, a través de la Plataforma y/o aplicativos, los Usuarios declaran conocer, aceptar y sujetarse a los presentes términos y condiciones, y cumplir con los requisitos legales que los habilitan para acceder a la contratación de los Servicios en el marco de la normatividad vigente.
                    </strong>
                    <br/>
                    <strong>
                        14.2 El uso de las funcionalidades incluidas en la Plataforma por parte de los Usuarios, implica la aceptación de los presentes términos y condiciones. Si cualquier Usuario está en desacuerdo con alguno de los elementos contenidos en los términos y condiciones, deberá dejar de utilizar la Plataforma o cancelar el Servicio.
                    </strong>
                    <br/>
                    <strong>
                        14.3 Los presentes términos y condiciones constituyen un acuerdo legalmente vinculante ente los Usuarios y Fluio, lo que no obsta para la celebración de actos posteriores, distintos e independientes entre estos.
                    </strong>
                    <br/>
                    <strong>
                        14.4 Fluio podrá realizar actualizaciones y cambios a los presentes términos y condiciones, tanto en su apariencia como en sus características, y estos podrán ser cambiado o modificado en la medida en que se desarrollen nuevas funciones o características y a las condiciones de acceso en cualquier momento, sin que esto signifique compromiso adicional a los acá establecidos, por parte de Fluio, ni que se pueda alegar algún tipo de perjuicio por la modificación. En caso de que los Usuarios continúen usando los Servicios, después de modificados los términos y condiciones, se entenderá que han aceptado dichas modificaciones. Ahora, en el evento en que algún Usuario no esté de acuerdo con las modificaciones a los términos y condiciones, podrá solicitar la cancelación de sus servicios e incluso de su cuenta.
                    </strong>
                    <br/>
                    <strong>
                        14.5 Fluio publicará las actualizaciones que se hagan en la Plataforma.
                    </strong>
                </p>
                <strong>
                    15 Legislación aplicables a la Plataforma
                </strong>
                <p>
                Tanto la Plataforma, como los servicios ofrecidos por Fluio, se encuentran regulados por las leyes de la República de Colombia y cualquier diferencia y/o disputa entre las partes será resuelta por la jurisdicción ordinaria colombiana.
                </p>
                <strong>
                    16 Hipervínculos a otros sitios web y cookies
                </strong>
                <p>
                La Plataforma podrá contener cuantos hipervínculos a sitios web de terceros Fluio estime convenientes. No obstante, el Usuario declara entender que Fluio no es responsable por el contenido de ninguno de los sitios web a los que se dirigen y/o re-direccionen dichos vínculos. Fluio suministra estos hipervínculos para conveniencia de sus Usuarios y no respalda a las empresas ni los contenidos de ninguno de los sitios a los que se dirigen dichos hipervínculos.
                </p>
                <p>
                La Plataforma utiliza cookies. Mediante el uso de la Plataforma, el Usuario autoriza el uso de cookies. Las cookies son archivos enviados por los servidores web a los navegadores web, los cuales se entienden almacenados en el sistema del Usuario. La información se envía de vuelta al servidor cada vez que el navegador solicita una página del servidor. Esto permite a un servidor web identificar y realizar un seguimiento de los navegadores web. Hay dos tipos principales de cookies. Por un lado, existen las cookies de sesión y, por el otro, las cookies permanentes. Las cookies de sesión se borran de su computadora cuando usted cierra su navegador, mientras que las cookies permanentes permanecen almacenadas en el ordenador hasta que se elimine, o hasta que lleguen a su fecha de caducidad. 
                </p>
                <p>
                Para mayor información sobre el uso de cookies por parte de Fluio, por favor consultar la siguiente URL: www.fluio.com.co
                </p>
                <strong>
                    17 Tratamiento de Datos Personales
                </strong>
                <p>
                Con la aceptación de los presentes términos y condiciones, Fluio queda autorizado para la recopilación, uso y tratamiento de los datos personales contenidos en él y aquellos que se llegaren a suministrar o recopilar en el futuro, para las finalidades y en los términos descritos en la Política de Tratamiento de Datos Personales de Fluio, la cual se encuentra disponible en www.fluio.com.co . 
                </p>
                <div>
                    <a href="/politica-de-tratamiento" >
                        Políticas de tratamiento de datos personales
                    </a>
                </div>
            </div>
        </div>
        )
    }
}

export default TyC
