import React, { Component } from 'react'
import FormError from '../common/FormError'
import { postData } from '../../servicios'
import { toastSuccess } from '../../alertas'

export class ForgottenForm extends Component {

    constructor(props){
        super(props)
        this.state={
            email:'',
            error:false,
            isEmail:false,
            loading:false,
            send:false
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        this.setState({
            loading:true,
            error:false,
            isEmail:false
        })

        let json={
            email:this.state.email
        }

        try {

            const req=await postData("recover-password",json)
            this.setState({
                loading:false,
                send:true
            })
            
            
        } catch (error) {

            console.log(error)
        
            if(error.response){
                if(error.response.status==401){
                    this.setState({
                        isEmail:true,
                        loading:false
                    })
                }
            }else{
                this.setState({
                    error:true,
                    loading:false
                })
            }

        }
        
    }

    handleChange= (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    openModal = (e) => {
        e.preventDefault()
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };


    render() {

        const {error,isEmail,loading,send}=this.state

        return (

            <div>
                {
                    loading ?

                        <div className="row h-196">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                <h3 >Enviando mensaje</h3>
                            </div>
                        </div>

                    :

                        send ?
                        <div className="row ">
                            <div className="col-sm-12 text-center" >
                                <h3 >Tu nueva contraseña fue enviada a tu correo</h3>
                            </div>
                        </div>
                        :
                        <form className="theme-form" onSubmit={this.handleSubmit} >
                            <strong>Solicita una nueva contraseña</strong>
                            <div className="form-group">
                                <label htmlFor="email">Ingresa el correo de tu cuenta</label>
                                <input type="email" className="form-control" name="email" placeholder="Correo" onChange={this.handleChange} required />
                                <FormError text="Ocurrio un problema intenta de nuevo" show={error}/>
                                <FormError text="El correo ingresado no se encuentra registrado" show={isEmail}/>
                            </div>
                            <div className="form-group">
                                <button type="submit" className="btn btn-solid ttn">Solicitar nueva contraseña</button>
                            </div>
                            
                        </form>
                 }
            </div>
        )

            

            
    }
}

export default ForgottenForm
