import * as types from "../constants/ActionTypes";

const deliveryReducerDefaultState = {
  core: 5500,
  snack: 5500,
  huerta: 5500,
  congelado: 5500,
  restaurante: 5500,
  plan: 1500,
  core_huerta: 7500,
};

const deliveryReducer = (state = deliveryReducerDefaultState, action) => {
  switch (action.type) {
    case types.UPDATE_DELIVERY:
      return {
        ...state,
        core: action.costo.core,
        snack: action.costo.snack,
        huerta: action.costo.huerta,
        congelado: action.costo.congelado,
        restaurante: action.costo.restaurante,
        plan: action.costo.plan,
        core_huerta: action.costo.core_huerta,
        chia: action.costo.chia,
      };
    default:
      return state;
  }
};

export default deliveryReducer;
