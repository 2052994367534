import React, {Component} from 'react';
import { cargarOrders, updateSaldo } from '../../services';
import Banner from '../common/Banner';


class orderSuccess extends Component {

    constructor (props) {
        super (props)

    }

    componentDidMount(){
        cargarOrders()
        updateSaldo()
    }

    render (){

        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var current = new Date();
        var next5days = new Date(Date.now() + 5 * 86400000);
        let CheckDate = current.toLocaleDateString("en-US", options).toString()
        let deliveryDate = next5days.toLocaleDateString("en-US", options).toString()

        return (
            this.props.match.params.code=="201" ?
            <div>

                <Banner/>

                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="success-text">
                                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                                    <h3 className="bold-text-black">Gracias Por Tu Compra</h3>
                                    <p>{this.props.match.params.text}</p>
                                    <p>Te notificaremos por SMS sobre el estado de tu pedido</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                
            </div>
            :
            <section className="section-b-space light-layout">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="success-text">
                                <i className="fa fa-times-circle error-text" aria-hidden="true"></i>
                                <h3 className="bold-text-black">Hubo Un Problema Con Tu Pago</h3>
                                <p>{this.props.match.params.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default orderSuccess