import React, { Component } from 'react'
import { connect } from 'react-redux'
import Breadcrumb from "../common/breadcrumb";
import {filterCategory} from '../../actions/index'
import ProductListingInPasilloTag from './common/product-listing-in-pasillo-tag';
import Banner from '../common/Banner';


class CollectionInPasilloTag extends Component {

    state = {
        layoutColumns:3
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    constructor(props){
        super(props)
        this.state={
            hall:this.props.match.params.pasillo,
            productos: [],
        }
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = (e) => {
		e.preventDefault()
		this.props.history.push("/productos/"+this.state.text)
    }
    

    render (){

        const {pasillo,categories,loading_products} = this.props
        const hall_id=this.props.match.params.pasillo
        const category_id=this.props.match.params.category

        let data_pasillo=pasillo.find((value)=>value.seo==hall_id)
        let data_category=categories.find((value)=>value.seo==category_id)

        return (
            <div>

                <Banner/>

                <div>
                    <h3 className="center mb-center90 ttn">{data_category?data_category.name:''}</h3>
                </div>

                <Breadcrumb title={data_pasillo?data_pasillo.name:''}/>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">

                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">


                                                            </div>
                                                        </div>

                                                        {
                                                            loading_products?
                                                            <div className="row">
                                                                <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                                                    <h3>Cargando</h3>
                                                                </div>
                                                            </div>
                                                            :
                                                            <ProductListingInPasilloTag 
                                                                colSize={3} 
                                                                pasillo={data_pasillo.id} 
                                                                categoria={data_category.id}
                                                                hallValue={data_pasillo}
                                                            />

                                                        }

                                                        {/*Products Listing Component*/}
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    products: state.data.products,
    aliado: state.aliado.data,
    pasillo: state.halls.halls,
    categories: state.categories.categories,
    loading_products:state.data.loading_products
})

export default connect(
    mapStateToProps,
    {filterCategory}
)(CollectionInPasilloTag)