import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import Modal from 'react-responsive-modal';
import {getCartIndividual,getTotalGeneral, getTipoCategoria, validatePlan} from "../../../services";
import {removeFromCart, incrementQty, decrementQty} from '../../../actions'
import Normal from '../programacion/Normal';
import ProgramableNormal from '../programacion/ProgramableNormal';
import ProgramableSemanal from '../programacion/ProgramableSemanal';
import ProgramableQuincenal from '../programacion/ProgramableQuincenal';
import ProgramableMensual from '../programacion/ProgramableMensual';
import { getCartLength } from '../../../servicios';
import { toastError } from '../../../alertas';

class CartPlan extends Component {

    constructor (props) {
        super (props)
        this.state={
            openNormal:false,
            openProgramableNormal: false,
            openProgramableSemanal: false,
            openProgramableQuincenal: false,
            openProgramableMensual: false,
            product:null
        }
    }

    handleModal = (e,product) => {
        e.preventDefault()

        this.setState({
            product:product
        },()=>{

            if(product.tipo_cate==3){

                if(product.tipo_hall==1){
                    this.openProgramableSemanal()
                }

                if(product.tipo_hall==2){
                    this.openProgramableQuincenal()
                }

                if(product.tipo_hall==4){
                    this.openProgramableMensual()
                }
    
            }else{
                
                if(product.tipo_cate==2){
                    this.openNormal()
                }
    
            }

        })
        
    }

    //open y close de los 4 tipos de modales que existiran

    openNormal = () => {
        this.setState({
            openNormal: true
        })
    }

    closeNormal = () => {
        this.setState({
            openNormal: false
        })
    }

    openNormalProgramable = () => {
        this.setState({
            openProgramableNormal: true
        })
    }

    closeNormalProgramable = () => {
        this.setState({
            openProgramableNormal: false
        })
    }

    openProgramableSemanal = () => {
        this.setState({
            openProgramableSemanal: true
        })
    }

    closeProgramableSemanal = () => {
        this.setState({
            openProgramableSemanal: false
        })
    }

    openProgramableQuincenal = () => {
        this.setState({
            openProgramableQuincenal: true
        })
    }

    closeProgramableQuincenal = () => {
        this.setState({
            openProgramableQuincenal: false
        })
    }

    openProgramableMensual = () => {
        this.setState({
            openProgramableMensual: true
        })
    }

    closeProgramableMensual = () => {
        this.setState({
            openProgramableMensual: false
        })
    }


    //algoritmo para saber el costo de los planes
    getCostoPlan = (product) => {

        if(product.tipo_hall==1){

            return (product.price)*(product.programacion.semana1.length)
        }
        if(product.tipo_hall==2){

            return (product.price)*(product.programacion.semana1.length+product.programacion.semana2.length)
        }

        if(product.tipo_hall==4){

            const dias_semanas=product.programacion.semana1.length+product.programacion.semana2.length+
            product.programacion.semana3.length+product.programacion.semana4.length

            return (product.price)*(dias_semanas)
        }
        
    }

    getCostoDelivery = (product) => {

        const {delivery}=this.props

        switch (product.tipo_cate) {
            case 2:
                return delivery.restaurante
            case 3:
                return delivery.plan
            default:
                return 0;
        }
        
    }

    getNumberDelivery = (product) => {

        switch (product.tipo_cate) {
            case 2:
                return 1
            case 3:

                if(product.tipo_hall==1){

                    return (product.programacion.semana1.length)
                }
                if(product.tipo_hall==2){
        
                    return (product.programacion.semana1.length+product.programacion.semana2.length)
                }
        
                if(product.tipo_hall==4){
        
                    const dias_semanas=product.programacion.semana1.length+product.programacion.semana2.length+
                    product.programacion.semana3.length+product.programacion.semana4.length
        
                    return (dias_semanas)
                }
                
            default:
                return 0;
        }
        
    }

    agregar = (item) => {

        if(validatePlan(1)){
            this.props.incrementQty(item, 1)
        }else{
            toastError("Limite de planes superado")
        }

    }

    render (){

        const {cartItems, symbol, total,tipo_categoria} = this.props;

        const img_categorias=tipo_categoria.map((value,index)=>{
            return(
                <img src={value.image} alt="" key={index} className="mb-cate-cart" />
            )
        })

        return (
            <div>
                
                {cartItems.length>0?

                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12" id="accordion">

                                    {
                                        cartItems.length>=3 ? 
                                        <div className="card-header" id="headingOne">
                                            <div className="row">

                                                <div className="col-md-6 col-sm-12">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link theme-color wrap-buttom mt-1" 
                                                        data-toggle="collapse" data-target="#collapsePlan" aria-expanded="true" aria-controls="collapseOne">
                                                            {img_categorias}
                                                        </button>
                                                    </h5>
                                                </div>


                                            </div>
                                                                            
                                        </div>

                                        : 
                                        <div className="row">

                                            <div className="col-sm-6 col-8">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link theme-color wrap-buttom mt-1">
                                                        {img_categorias}
                                                    </button>
                                                </h5>
                                            </div>


                                            <div className="col-4 col-sm-6 center flr">

                                            </div>
                                        </div>
                                    }

                                    <div id="collapsePlan" className={cartItems.length>=3 ? "collapse show" : ''} aria-labelledby="headingOne" data-parent="#accordion">

                                        <br/>
                                        <br className="mb-none"/>
                                        <table className="table cart-table table-responsive-xs fix-table-cart-plan">
                                            <thead className="thead">
                                            <tr className="table-head">
                                                <th scope="col">
                                                    <strong className="mb-none">Imagen</strong>
                                                    <strong className="mb-display">Producto</strong>
                                                </th>
                                                <th scope="col">
                                                    <strong className="mb-none">Nombre</strong>
                                                    <strong className="mb-display">Acciones</strong>
                                                </th>
                                                <th scope="col">
                                                    <strong className="mb-none">Precio</strong>
                                                    <strong className="mb-display">Precios</strong>
                                                </th>
                                                <th scope="col">Cantidad</th>
                                                <th scope="col">Envío</th>
                                                <th scope="col">Borrar</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                            </thead>
                                            {cartItems.map((item, index) => {


                                                return (
                                                    <tbody key={index} className="tbody">
                                                        <tr>
                                                            <td>
                                                                <Link to="#" onClick={(e)=>e.preventDefault()}>
                                                                    <img src={item.variants?
                                                                            item.variants[0].images
                                                                            :item.pictures[0]} alt="" />
                                                                </Link>
                                                                <Link to="#" className="mb-display" onClick={(e)=>e.preventDefault()}>{item.name}</Link>
                                                            </td>
                                                            <td className="mb-p0"><Link className="mb-none" to="#" onClick={(e)=>e.preventDefault()}>{item.name}</Link>

                                                                {/*este bloque se utiliza para el responsive cuando la pantlla es pequeña*/}
                                                                <div className="mobile-cart-content row">
                                                                    <div className="col-12">
                                                                        <div className="qty-box">
                                                                            <div className="input-group">

                                                                                <span className="input-group-prepend">
                                                                                    <button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementQty(item.id)} data-type="minus" data-field="">
                                                                                        <i className="fa fa-angle-left"></i>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" name="quantity" value={item.qty} readOnly={true} className="form-control input-number" />

                                                                                <span className="input-group-prepend">
                                                                                    <button className="btn quantity-right-plus" onClick={() => this.agregar(item)}  data-type="plus" disabled={(item.qty >= 5)? true : false}>
                                                                                        <i className="fa fa-angle-right"></i>
                                                                                    </button>
                                                                                </span>

                                                                            </div>
                                                                            
                                                                        </div>
                                                
                                                                    </div>
                                                                    <div className="col-xs-3">
                                                                        <i className="fa fa-clock-o pointer clock-icon" onClick={(e)=>{this.handleModal(e,item)}} />
                                                                    </div>
                                                                    <div className="col-xs-3 mt-2">
                                                                        <h2 className="td-color">
                                                                            <span className="icon pointer" onClick={() => this.props.removeFromCart(item)}>
                                                                                <i className="icon-close mobile-x"></i>
                                                                            </span>
                                                                        </h2>
                                                                    </div>
                                                                </div>

                                                            </td>

                                                            
                                                            <td className="mb-display">
                                                                <div className="col-xs-3 mt-4">
                                                                    <h2 className="td-color price-plan">{symbol}{(item.price)}</h2>
                                                                </div>
                                                                <div>
                                                                    <Link className="price-plan" to="#" onClick={(e)=>e.preventDefault()}>
                                                                        Delivery:{symbol}{this.getCostoDelivery(item)}(x{this.getNumberDelivery(item)})
                                                                    </Link>
                                                                </div>
                
                                                                <h2 className="td-color price-plan">
                                                                    Total:
                                                                    {symbol}
                                                                    {item.tipo_cate!==3 ? (item.price*item.qty)+(this.getCostoDelivery(item)*(this.getNumberDelivery(item))) : 
                                                                    this.getCostoPlan(item)*item.qty+(this.getCostoDelivery(item)*this.getNumberDelivery(item))}
                                                                </h2>
                                                                
                                                            </td>
                                                            

                                                            {/*este bloque se utiliza para el responsive cuando la pantlla es "normal" */}

                                                            <td>
                                                                <h2>{symbol}{(item.price)}</h2>
                                                                <div>
                                                                    <Link to="#" onClick={(e)=>e.preventDefault()}>
                                                                        Delivery:{symbol}{this.getCostoDelivery(item)}(x{this.getNumberDelivery(item)})
                                                                    </Link>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="qty-box">

                                                                    <div className="input-group">

                                                                        <span className="input-group-prepend">
                                                                            <button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementQty(item.id)} data-type="minus" data-field="">
                                                                                <i className="fa fa-angle-left"></i>
                                                                            </button>
                                                                        </span>
                                                                        <input type="text" name="quantity" value={item.qty} readOnly={true} className="form-control input-number" />

                                                                        <span className="input-group-prepend">
                                                                            <button className="btn quantity-right-plus" onClick={() => this.agregar(item)}  data-type="plus" disabled={(item.qty >= 5)? true : false}>
                                                                                <i className="fa fa-angle-right"></i>
                                                                            </button>
                                                                        </span>

                                                                    </div>
                                                                </div>
                                                                {(item.qty >= item.stock)? 'Se alcanzo el límite' : ''}

                                                            </td>
                                                            <td>
                                                                <i className="fa fa-clock-o pointer clock-icon" onClick={(e)=>{this.handleModal(e,item)}} />
                                                            </td>
                                                            <td>
                                                                <span className="icon pointer" onClick={() => this.props.removeFromCart(item)}>
                                                                    <i className="fa fa-times"></i>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <h2 className="td-color">
                                                                    {symbol}
                                                                    {item.tipo_cate!==3 ? (item.price*item.qty)+(this.getCostoDelivery(item)*(this.getNumberDelivery(item))) : 
                                                                    this.getCostoPlan(item)*item.qty+(this.getCostoDelivery(item)*this.getNumberDelivery(item))}
                                                                </h2>
                                                            </td>
                                                        </tr>

                                                        {/*
                                                        <tr >
                                                            <td>
                                                                Hora de entrega: 14:30
                                                                Del 24/02/2020 al 29/02/2020
                                                                Dias: Lunes,Martes

                                                            </td>
                                                        </tr>
                                                        */}

                                                        


                                                    </tbody>                                

                                                )
                                                
                                            })}
                                        </table>
                                        <div>
                                            <table className="table cart-table table-responsive-md sub-fix-plan">
                                                <tfoot>
                                                <tr>
                                                    <td>Sub total :</td>
                                                    <td>
                                                        <h2 className="mb-mt-1">
                                                            {symbol+total} 
                                                        </h2>
                                                    </td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>



                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </section>


                :
                null}
                        
                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openNormal} onClose={this.closeNormal} >
                    <Normal close={this.closeNormal} product={this.state.product} />
                </Modal>

                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openProgramableNormal} onClose={this.closeNormalProgramable} >
                    <ProgramableNormal close={this.closeNormalProgramable} product={this.state.product} />
                </Modal>

                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openProgramableSemanal} onClose={this.closeProgramableSemanal} >
                    <ProgramableSemanal close={this.closeProgramableSemanal} product={this.state.product} />
                </Modal>

                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openProgramableQuincenal} onClose={this.closeProgramableQuincenal} >
                    <ProgramableQuincenal close={this.closeProgramableQuincenal} product={this.state.product} />
                </Modal>

                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openProgramableMensual} onClose={this.closeProgramableMensual} >
                    <ProgramableMensual close={this.closeProgramableMensual} product={this.state.product}/>
                </Modal>

            </div>

            
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartIndividual(state.cartList.cart),
    general_total:getTotalGeneral(),
    tamaño: getCartLength(),
    delivery:state.delivery,
    tipo_categoria: getTipoCategoria(3)
})

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty}
)(CartPlan)