import React, { Component } from 'react'
import { connect } from 'react-redux'




class DetalleAdicional extends Component {

    constructor(props){
        super(props)
        this.state={
            product: props.product
        }
    }

    getPostre(id_add){
        const {add_postre}=this.props

        return add_postre.find((value)=>
            value.id==id_add
        )

    }

    getBebida(id_add){
        const {add_bebida}=this.props

        return add_bebida.find((value)=>
            value.id==id_add
        )

    }

    render() {

        const {product}= this.state
        const {symbol}= this.props
        
        return (
            <div className="modal-dialog  modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <strong className="font-20" >Extras en el pedido</strong>
                    </div>
                    <br/>

                    <div className="modal-body">
                        <div className="row">

                            <div className="col-md-12">
                                <form onSubmit={this.submit}>

                                    <div>
                                        <strong>Producto:</strong>
                                        {product.name}
                                    </div>
        
                                    <div>
                                        <strong>Costo unitario:</strong>
                                        {symbol}{product.price}
                                    </div>

                                    {
                                        product.comment ?
                                        <div>
                                            <strong>Comentario:</strong>
                                            {product.comment}
                                        </div>
                                        :''
                                    }
                                    

                                </form>

                            </div>

                            <div className="center col-12">
                                <strong>Extras</strong>
                            </div>

                            {
                                product.postre?

                                <fieldset className={"form-group col-12 "  + (product.bebida ? "col-sm-6": "" )}>
                                    <div className="">
                                    
                                        <>
                                            <div>
                                                <strong>Postre:</strong>
                                                {this.getPostre(product.postre).name}
                                            </div>
                                            <div>
                                                <strong>Precio:</strong>
                                                {this.getPostre(product.postre).price}
                                            </div>

                                            <div>
                                                <strong>Cantidad:</strong>
                                                {product.num_postre}
                                            </div>
                                        </>
                                                                                                        
                                    </div>
                                </fieldset>

                                :''

                            }

                            
                            {
                                product.bebida?

                                    <fieldset className={"form-group col-12 "  + (product.postre ? "col-sm-6": "" )}>
                                        <div className="">

                                            
                                                <>
                                                    <div>
                                                        <strong>Bebida:</strong>
                                                        {this.getBebida(product.bebida).name}
                                                    </div>
                                                    <div>
                                                        <strong>Precio:</strong>
                                                        {this.getBebida(product.bebida).price}
                                                    </div>
                                                    <div>
                                                    <strong>Cantidad:</strong>
                                                        {product.num_bebida}
                                                    </div>                                  
                                                </>
                                            
                                        
                                        </div>
                                    </fieldset>

                                :''

                            }

                            {
                                !product.bebida && !product.postre ?
                                <div className="tac w100">
                                    <strong>No se agregaron extras a este pedido</strong>
                                </div>
                                :''
                            }
                  
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    delivery: state.delivery.plan,
    add_postre: state.data.postres,
    add_bebida: state.data.bebidas,
})

export default connect(
    mapStateToProps
)(DetalleAdicional)