import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Cities, Countries } from '../../constants/data';
import { getInitialCity, getInitialCountry, isActualDirectionLocal, isDirectionLocal } from '../../funciones/cities';
import { updateLocation } from '../../actions/index';
import { limpiarCarrito } from '../../services';

export class LocationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            country: getInitialCountry(),
            city: getInitialCity(),
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { city, country } = this.state;
        const actual = isActualDirectionLocal() ? 'local' : 'external';
        const next = isDirectionLocal(city) ? 'local' : 'external';

        if (actual != next) {
            limpiarCarrito();
        }

        this.props.updateLocation(city, country);
        this.props.close();
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    render() {
        const listCountry = Countries.map((value, index) => {
            return (
                <option value={value} key={index}>
                    {value}
                </option>
            );
        });

        const listCity = Cities.map((value, index) => {
            return (
                <option value={value} key={index}>
                    {value}
                </option>
            );
        });

        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal fluio-font">
                    <div>
                        <div className="header-title tac-desk">¿Dónde te encuentras?</div>

                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row margin-zero">
                                <div className="form-group col-12 pl-0">
                                    <select
                                        name="country"
                                        onChange={this.handleChange}
                                        value={this.state.country || ''}
                                        className="form-control input-style"
                                        required
                                    >
                                        {listCountry}
                                    </select>
                                </div>
                                <div className="form-group col-12 pl-0">
                                    <select
                                        name="city"
                                        onChange={this.handleChange}
                                        value={this.state.city || ''}
                                        className="form-control input-style"
                                        required
                                    >
                                        {listCity}
                                    </select>
                                </div>
                            </div>
                            <div className="container-button-prod">
                                <button className="btn modal-btn-add ttn" style={{ width: '100%' }}>
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(
    mapStateToProps,
    { updateLocation }
)(withRouter(LocationForm));
