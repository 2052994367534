import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual';
import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations';

// Layouts
import Vegetables from './components/layouts/vegetables/main';

//Collection Pages
import CollectionLeftSidebar from './components/collection/collection-left-sidebar';
import CollectionPasillos from './components/collection/collection-pasillos';

// Features
import Layout from './components/app';
import Cart from './components/cart';
import checkOut from './components/checkout';
import orderSuccess from './components/checkout/success-page';

// Extra Pages
import PageNotFound from './components/pages/404';
import Login from './components/pages/login';
import Register from './components/pages/register';
import Home from './components/Home';

//proteccion de rutas
import { NotAuthRoute } from './AuthGuard/NotAutRoute';
import { AuthRoute } from './AuthGuard/AuthRoute';
import collectionInPasillo from './components/collection/collection-in-pasillo';
import Perfil from './components/pages/perfil';
import Orders from './components/pages/Ordenes/orders';
import CollectionAliado from './components/collection/collection-aliado';
import { cargarStorage, setDeliverys, loadUserCoor } from './funciones/cargarDatos';
import CollectionPasillosAliado from './components/collection/collection-pasillos-aliado';
import CollectionInAliado from './components/collection/collection-in-aliado';
import CollectionAllAliados from './components/collection/collection-all-aliados';
import CollectionPasilloAllAliado from './components/collection/collection-pasillo-all-aliado';
import CollectionInAllAliado from './components/collection/collection-in-all-aliado';
import CollectionPasilloTag from './components/collection/collection-pasillo-tag';
import CollectionInPasilloTag from './components/collection/collection-in-pasillo-tag';
import { CoreHuertaMatch } from './funciones/checkout';
import PseSuccess from './components/pages/PseSuccess';
import TyC from './components/pages/TyC';
import PdT from './components/pages/PdT';
import CheckoutPse from './components/checkout/CheckoutPse';
import SubHuerta from './components/pages/SubHuerta';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import CollectionSugeridos from './components/collection/collection-sugeridos';
import { cleverAddToCart, cleverSetUser } from './funciones/clevertap';
import { getToday, reduceObjectArray, validAllies } from './funciones/general';
import { checkProgram } from './funciones/patrones';
import LaunchPage from './components/LaunchPage';
import Product from './components/pages/Product';
import MobileLogin from './components/pages/MobileLogin';
import HomeShop from './components/HomeShop';
import SubHuertaTest from './components/pages/SubHuertaTest';

/** prod
 
const firebaseConfig = {
    apiKey: "AIzaSyCEPOvpIhRwERl8IzxjtnaZppYrYbQTiec",
    authDomain: "fluio-shop.firebaseapp.com",
    databaseURL: "https://fluio-shop.firebaseio.com",
    projectId: "fluio-shop",
    storageBucket: "fluio-shop.appspot.com",
    messagingSenderId: "255430351130",
    appId: "1:255430351130:web:33eaecc07b9442c4a934a3",
    measurementId: "G-RLJC6MPCJN"
  };

*/

const firebaseConfig = {
    apiKey: 'AIzaSyB61LBz1EQNPdlO1-anEsRTs7L64iXIlt8',
    authDomain: 'fluio-test.firebaseapp.com',
    databaseURL: 'https://fluio-test.firebaseio.com',
    projectId: 'fluio-test',
    storageBucket: 'fluio-test.appspot.com',
    messagingSenderId: '834931329676',
    appId: '1:834931329676:web:cfe156330e534de188c9a9',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

class Root extends React.Component {
    render() {
        console.log(JSON.parse(localStorage.getItem('state')));
        cargarStorage();
        setDeliverys();
        cleverSetUser();
        //loadUserCoor()

        return (
            <Provider store={store}>
                <IntlProvider translations={translations} locale="en">
                    <BrowserRouter basename={'/'}>
                        <ScrollContext>
                            <Switch>
                                <Layout>
                                    <Switch>
                                        <NotAuthRoute
                                            exact
                                            path={`${process.env.PUBLIC_URL}/login/:url?`}
                                            component={Login}
                                        />

                                        <NotAuthRoute
                                            exact
                                            path={`${process.env.PUBLIC_URL}/mobile-login/:url?`}
                                            component={MobileLogin}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/producto/:seo`}
                                            component={Product}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/productos/:text?`}
                                            component={CollectionLeftSidebar}
                                        />
                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/categoria/:category`}
                                            component={CollectionPasillos}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/aliado/:ally/:category`}
                                            component={CollectionPasillosAliado}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/tag/:category`}
                                            component={CollectionPasilloTag}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/pasillo/:pasillo/:category/:ally`}
                                            component={CollectionInAliado}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/pasillo/:pasillo`}
                                            component={collectionInPasillo}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/tag/:category/:pasillo`}
                                            component={CollectionInPasilloTag}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/aliados/:category`}
                                            component={CollectionAliado}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/:ally/:pasillo`}
                                            component={CollectionInAllAliado}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/nuestros-aliados`}
                                            component={CollectionAllAliados}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/productos-sugeridos`}
                                            component={CollectionSugeridos}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/menu-huerta`}
                                            component={SubHuertaTest}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/menu-huerta-test`}
                                            component={SubHuertaTest}
                                        />

                                        <Route exact path={`${process.env.PUBLIC_URL}/launch`} component={LaunchPage} />
                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/terminos-y-condiciones`}
                                            component={TyC}
                                        />
                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/politica-de-tratamiento`}
                                            component={PdT}
                                        />
                                        <Route exact path={`${process.env.PUBLIC_URL}/cart`} component={Cart} />
                                        <AuthRoute path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut} />
                                        <AuthRoute path={`${process.env.PUBLIC_URL}/profile`} component={Perfil} />
                                        <AuthRoute path={`${process.env.PUBLIC_URL}/orders`} component={Orders} />

                                        <Route
                                            path={`${process.env.PUBLIC_URL}/confirm/:code/:text`}
                                            component={orderSuccess}
                                        />
                                        <Route
                                            path={`${process.env.PUBLIC_URL}/pse-confirmation`}
                                            component={PseSuccess}
                                        />

                                        <AuthRoute
                                            path={`${process.env.PUBLIC_URL}/checkout-pse-test`}
                                            component={CheckoutPse}
                                        />

                                        <NotAuthRoute
                                            exact
                                            path={`${process.env.PUBLIC_URL}/register`}
                                            component={Register}
                                        />

                                        <Route
                                            exact
                                            path={`${process.env.PUBLIC_URL}/:ally`}
                                            component={CollectionPasilloAllAliado}
                                        />

                                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeShop} />

                                        <Route path="/not-found/404" component={PageNotFound} />
                                        <Route path="*">
                                            <Redirect to="/not-found/404" />
                                        </Route>
                                    </Switch>
                                </Layout>
                            </Switch>
                        </ScrollContext>
                    </BrowserRouter>
                </IntlProvider>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
