import React, { Component } from 'react'
import { formatNumber } from '../../../funciones/general'

export class PriceListingSugerido extends Component {
    render() {
        const {product}=this.props
        return (
            <>
            {
                product.if_offer?
                <h5 className="pricing-sugerido">
                    ${formatNumber(product.price_offer)}
                    <del className="old-price-space">
                    ${formatNumber(product.price)}
                    </del>
                </h5>
                :
                <h5 className="pricing-sugerido">
                    ${formatNumber(product.price)}
                </h5>
            }
            </>
        )
    }
}

export default PriceListingSugerido