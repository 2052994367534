import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import FormError from '../common/FormError';
import 'react-toastify/dist/ReactToastify.min.css';
import { toastSuccess,toastError } from '../../alertas';
import { withRouter } from 'react-router-dom';
import { postData } from '../../servicios';
import { connect } from 'react-redux';
import { updateHeaders,updateUser } from '../../actions';
import { updateSaldo } from '../../services';
import Modal from 'react-responsive-modal';
import PasswordForm from './PasswordForm';
import MapTool from '../common/MapTool'
import BonoForm from './BonoForm';

class Perfil extends Component {

    constructor (props) {
        super (props)
        const {user}= this.props

        if(user.cards.length>0){

            this.state={
                name:user.name,
                email:user.email,
                tel:user.tel,
                ci:user.ci,
                nickname:user.nickname,
                //dir:user.addresses[0].dir,

                cname:user.cards[0].name,
                cnum:user.cards[0].number,
                mes:user.cards[0].month,
                anno:user.cards[0].year,
                cvc:user.cards[0].cvc,
                bool:false,
                open:false,
                openBono:false,
                openMap:false,
                //lon:user.addresses[0].lon,
                //lat:user.addresses[0].lat,
                directions:user.directions,

                bool_directions:false,

                //boolpass:false,
                //boolemail:false
            }

        }else{

            this.state={
                name:user.name,
                email:user.email,
                tel:user.tel,
                ci:user.ci,
                nickname:user.nickname,
                //dir:user.addresses[0].dir,

                cname:'',
                cnum:'',
                mes:'',
                anno:'',
                cvc:'',
                bool:false,
                open:false,
                openBono:false,
                openMap:false,
                //lon:user.addresses[0].lon,
                //lat:user.addresses[0].lat,
                directions:user.directions,

                bool_directions:false,

                //boolpass:false,
                //boolemail:false
            }

        }



    }

    update = async (e) => {
        e.preventDefault()


        //reinicia errores que pueden o no haber sido corregidos
        this.setState({
            boolemail:false,
            boolpass:false,
            bool_directions:false,
            bool: true
        })

        if(this.state.directions.length==0){
            this.setState({
                bool_directions:true,
                bool:false
            })
            return
        }

        //validacion de que las contraseñas coincidan


        const json={
            customer:{
                nickname:this.state.nickname,
                ci:this.state.ci,
                tel:this.state.tel,
                directions:this.state.directions
                /**
                addresses_attributes:[{
                    dir: this.state.dir
                }],
                */


            }
        }

        if(this.state.cname && this.state.cnum && this.state.mes && this.state.anno && this.state.cvc){
            json.customer.cards_attributes=[{
                name:this.state.cname,
                number:this.state.cnum,
                month:this.state.mes,
                year: this.state.anno,
                cvc: this.state.cvc,
                df: true
            }]
        }

        if(this.state.lat){
            json.customer.addresses_attributes=[{
                dir: this.state.dir,
                lat:this.state.lat,
                lon:this.state.lon
            }]
        }

        //console.log(json)


        this.setState({
            bool:true
        }, async ()=>{

            try{
                const config={
                    headers: this.props.headers
                }
                const response= await postData("edit-user/",json,config)
                console.log(response)
                this.props.updateHeaders(response.headers)
                this.props.updateUser(response.data)
                toastSuccess("Datos actualizados")
                setTimeout(()=>{
                    window.location.reload();
                },2000)


            }catch(err){
                console.log(err)
                toastError("Error, no se pudo actualizar")
                this.setState({
                    bool:false
                })
            }

        })

    }

    openModal = (e) => {
        e.preventDefault()
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    openModalBono = (e) => {
        e.preventDefault()
        this.setState({ openBono: true });
    };

    closeModalBono = () => {
        this.setState({ openBono: false });
    };

    openMap = (e) => {
        e.preventDefault()
        this.setState({ openMap: true });
    };

    closeMap = () => {
        this.setState({ openMap: false });
    };



    handleChange= (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    componentDidMount(){
        updateSaldo()
    }

    setCordenates = (lon,lat) => {

        this.setState({
            lon:lon,
            lat:lat,
            openMap:false
        })
        
    }

    handleDirectionChange = (e,index) => {
        
        const { name, value,type } = e.target;
        const {directions}=this.state

        const list = [...directions];
        list[index] = value;
        this.setState({
            directions:list
        })
        
    }

    handleAdd = (e) => {
        e.preventDefault()
        const {directions}=this.state
        this.setState({
            directions:[...directions,'']
        })
    };

    handleRemove = (e,i) => {
        e.preventDefault()
        const {directions}=this.state

        const list = [...directions];
        list.splice(i, 1);
        this.setState({
            directions:list
        })

    }

    render (){

        const {boolpass,boolemail,bool,open,directions,bool_directions,openBono} =this.state


        const list_directions=directions.map((x, i) => {

            return (
              <div className="form-row mt-2" key={i}>
                <div className="col-sm-12 form-group margin-zero">
                    <label htmlFor="name">Dirección</label>
                    <input
                        type="text"
                        name="direction"
                        value={x}
                        className="form-control mb-2"
                        required
                        onChange={e => this.handleDirectionChange(e, i)}
                    />
                </div>

                <div className="btn-box dinam-button-box mb-2">
                  {directions.length !== 0 && <button className="btn btn-cart" onClick={(e)=>{this.handleRemove(e,i)}}>-</button>}
                  {directions.length - 1 === i && <button className="btn btn-cart" onClick={this.handleAdd}>+</button>}
                </div>
                
              </div>
            );
        })
        

        return (

            <div>
                <Breadcrumb title={'Editar Perfil'}/>


                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="ttn">Datos de la cuenta</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.update}>

                                        <h4 className="tac">Datos personales</h4>

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="name">Nombre</label>
                                                <input type="text" className="form-control" name="name" placeholder="Nombre completo" value={this.state.name} onChange={this.handleChange} disabled required />
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="email">Correo</label>
                                                <input type="email" className="form-control" value={this.state.email} name="email" placeholder="Correo electrónico" onChange={this.handleChange} disabled required />
                                                <FormError text="El correo indicado ya esta en uso" show={boolemail}/>
                                            </div>

                                        </div>

                                        {/*

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="password">Contraseña</label>
                                                <input type="password" className="form-control" name="password" placeholder="Contraseña" onChange={this.handleChange} required />
                                                <FormError text="Las contraseñas no coinciden" show={boolpass}/>
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="password_confirmation" className="ttn">Confirmar contraseña</label>
                                                <input type="password" className="form-control" name="password_confirmation" placeholder="Confirma la contraseña" onChange={this.handleChange} required />
                                            </div>

                                        </div>

                                        */}

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="tel">Celular</label>
                                                <input type="text" className="form-control" name="tel" value={this.state.tel} placeholder="Celular" onChange={this.handleChange} required />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="ci">Cédula</label>
                                                <input type="text" className="form-control" name="ci" value={this.state.ci} placeholder="Cédula" onChange={this.handleChange} />
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="nickname">Usuario</label>
                                                <input type="text" className="form-control" name="nickname" value={this.state.nickname} placeholder="Nombre de usuario" onChange={this.handleChange} required />
                                            </div>

                                            {/*

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="dir" className="ttn">Direccíon de envío</label>
                                                <input type="text" className="form-control" name="dir" placeholder="Dirección" value={this.state.dir} onChange={this.handleChange} required />
                                            </div>

                                            */}

                                            <div className="col-md-6 form-group">
                                                <label htmlFor="saldo">Saldo</label>
                                                <input type="text" className="form-control" name="saldo" value={this.props.user.saldo}  disabled />
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            

                                            {/*
                                            
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="lat">Ubicacion</label>
                                                <input type="text" className="form-control" name="lat" 
                                                value={this.state.lat?"Lat:"+this.state.lat+" "+"Lon:"+this.state.lon:"Sin ubicación"}  
                                                disabled />
                                            </div>


                                            */}
                                     
                                        </div>

                                        <div className="form-row">

                                            <div className="col-6 form-group">
                                                <button type="button" className="btn btn-solid btn-center ttn" onClick={this.openModal}>Cambiar contraseña</button>
                                            </div>

                                            {/*

                                            <div className="col-6 form-group">

                                                <button className="btn btn-solid btn-center ttn" onClick={this.openMap}>
                                                    Ver mapa
                                                    <i className="fa fa-map-marker map-icon"/>
                                                </button>  

                                            </div>

                                            */}
                                          
                                        </div>

                                        <h4 className="tac">Direcciones</h4>

                                        {list_directions}

                                        {
                                            list_directions.length==0?
                                            <div className="btn-box">
                                                <button className="btn btn-cart" onClick={this.handleAdd}>+</button>
                                            </div>
                                            :''
                                        }

                                        <div className="mt-2">
                                            <FormError text="Debes incluir al menos 1 dirección" show={bool_directions}/>
                                        </div>   

                                        <h4 className="tac mt-1 ttn">(Opcional) Tarjeta de crédito, registrala si quieres usar este metodo de pago </h4>
                                        <h5 className="tac font-10 ttn">
                                            - Toda tu informacion esta protegida por medio de la pasarela de pago Kushki (https://www.kushkipagos.com)
                                            más informacion -
                                        </h5>
                                    
                                        <div className="form-row">

                                            <div className="col-sm-6 form-group">
                                                <label htmlFor="cname">Nombre</label>
                                                <input type="text" value={this.state.cname} className="form-control" name="cname" placeholder="Nombre" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-6 form-group">
                                                <label htmlFor="cnum" className="ttn">Número de tarjeta</label>
                                                <input type="num" value={this.state.cnum} className="form-control" name="cnum" placeholder="Numero" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-3 form-group">
                                                <label htmlFor="mes">MM</label>
                                                <input type="num" className="form-control" value={this.state.mes} min={1} max={12} name="mes" placeholder="MM" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-3 form-group">
                                                <label htmlFor="anno">AA</label>
                                                <input type="num" value={this.state.anno} className="form-control" min={0} max={99} name="anno" placeholder="AA" onChange={this.handleChange} />
                                            </div>

                                            <div className="col-sm-6 form-group">
                                                <label htmlFor="cvc">CVC</label>
                                                <input type="num" className="form-control" value={this.state.cvc} min={0} name="cvc" placeholder="CVC" onChange={this.handleChange} />
                                            </div>

                                        </div>

                                        <div className="form-row">

                                            <div className="col-md-6 form-group">
                                                <button onClick={this.openModalBono} type="button" className="btn btn-solid btn-center mt-26px ttn" disabled={bool}>Canjear bono</button>
                                            </div>

                                            <div className="col-md-6 form-group">
                                                <button type="submit" className="btn btn-solid btn-center mt-26px ttn" disabled={bool}>Actualizar Datos</button>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal classNames={{'modal': 'mb-modal'}} open={open} onClose={this.closeModal} classNames={{closeButton: "close-forgotten"}} center>
                        
                    <PasswordForm close={this.closeModal} />

                </Modal>

                <Modal  
                    open={openBono} onClose={this.closeModalBono}
                    classNames={{closeButton: "close-forgotten",'modal': 'mb-modal'}} 
                    center
                >
                        
                    <BonoForm close={this.closeModalBono} />

                </Modal>

                <Modal open={this.state.openMap} onClose={this.closeMap} center classNames={{'modal': 'map-mb',closeButton: "close-forgotten"}} >
                        <div className="modal-dialog modal-dialog-centered w-vista-r map-dialog" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <MapTool  
                                        submit={this.setCordenates} 
                                        lat={this.state.lat} 
                                        lon={this.state.lon}
                                    />
                                </div>
                            </div>
                        </div>
                </Modal>

            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    headers: state.customer.headers
})

export default connect(
    mapStateToProps, { updateHeaders,updateUser }
)(withRouter(Perfil))
