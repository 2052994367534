import React, { Component } from 'react'
import { connect } from 'react-redux'
import horas from '../../../api/horarios/tipoSnack.json'
import { updateSnack } from '../../../actions/index.js'
import * as moment from 'moment';
import { detFechaPatronSnack, detRadioPatronSnack, getArrayPatronSnack, initialHourSnack, getMinDateCore, getArrayDateCore } from '../../../funciones/patrones';
import { isUnaHoraUntil, isAfterHora } from '../../../funciones/general';
import { getDiaMesEspanol } from '../../../funciones/momentEspañol';
import { getDiffHoraria } from '../../../servicios';

class ProgramableSnack extends Component {

    constructor(props){
        super(props)

        this.state={
            hora:'',
            fecha:detFechaPatronSnack(horas),
            radio:detRadioPatronSnack(horas),
            listado:getArrayPatronSnack(horas)
        }
    }

    submit = (e) => {
        e.preventDefault()
        const {updateSnack}= this.props
        const {radio,hora,fecha}=this.state
        let programacion

        if(radio==1){
            programacion=1
        }else{
            programacion={
                hora:hora,
                fecha:fecha
            }
        }

        updateSnack(programacion)
        this.props.close()
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    handleRadio = (event) => {
        const { value}= event.target
        this.setState({
            radio: value
        })

    }

    //metodo para cargar las horas
    handleHora = (e) => {

        const {value}= e.target
        const today=moment().format('YYYY-MM-DD')

        //caso mismo dia
        if(today==value){

            const list_horas=horas.filter((value,index)=>{
                return (getDiffHoraria(value.hora)>0)
            })

            //para llenar los horas al escoger una fecha
            if(list_horas.length>0){
                this.setState({
                    listado:list_horas,
                    hora:list_horas[0].hora
                })
            //caso que deja la lista vacio si no hay horas disponibles
            }else{
                this.setState({
                    listado:list_horas
                })
            }

        //casi un dia programado en el futuro
        }else{
            this.setState({
                listado:horas,
                hora:horas[0].hora
            })

        }
        
    }

    componentDidMount(){
        this.setState({
            hora:initialHourSnack(horas,this.state.fecha)
        })
    }
    
    
    

    render() {

        const {listado}=this.state

        const list_horas=listado.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_fechas=getArrayDateCore(horas).map((value,index)=>{

            return(
                <option  value={value} key={index}>{getDiaMesEspanol(value)}</option>
            )

        })
        
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title" >Programar envío(Snacks)</h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submit}>

                            <div className="form-group">

                                {
                                    isUnaHoraUntil("18:30") && isAfterHora("9:00") ? 

                                        <label className="radio-inline">
                                            <input type="radio"  value={1} onChange={this.handleRadio} checked={this.state.radio == 1} />Entrega en menos de 90 minutos
                                        </label>
                                    
                                    :''
                                }

                                <label className="ml-2 radio-inline">
                                    <input type="radio"  value={2} onChange={this.handleRadio} checked={this.state.radio == 2} />Envío programado
                                </label>

                            </div>

                            {this.state.radio==2 ?

                            <div className="form-row">
                                <div className="form-group col-7">
                                    <label htmlFor="fecha" className="col-form-label" >Fecha:</label>
                                    <select name="fecha" onChange={(e)=>{
                                            this.handleChange(e)
                                            this.handleHora(e)
                                    }} value={this.state.fecha || ''} className="form-control" required>
                                        {list_fechas}
                                    </select>
                                </div>

                                <div className="form-group col-5">
                                    <label htmlFor="hora" className="col-form-label" >Hora</label>
                                    <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                        {list_horas}
                                    </select>
                                </div>

                            </div>
                            : '' }

                            <button className="btn btn-solid"> Guardar</button>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    snack: state.snack
})

export default connect(
    mapStateToProps,{updateSnack}
)(ProgramableSnack)