import React, { Component } from 'react'
import { connect } from 'react-redux'
import horas from '../../../api/horarios/cenaSemana.json'
import horas_fin from '../../../api/horarios/cenaFin.json'
import { updateCena } from '../../../actions/index.js'
import store from '../../../store/index.js'
import * as moment from 'moment';
import { getDiffHoraria, getArrayPatronSemanaYFin, getDia } from '../../../servicios.js'

class ProgramarCena extends Component {

    constructor(props){
        super(props)
        this.state={
            hora: props.hora
        }
    }

    submit = (e) => {
        e.preventDefault()
        const hora=this.state.hora
        this.props.updateCena(hora)
        this.props.close()
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    notWeekend = () => {

        let dia=moment().isoWeekday()

        if(dia==6 || dia==7){
            return false
        }else{
            return true
        }
        
    }



    getHoras = () => {

        return getArrayPatronSemanaYFin(horas,horas_fin).map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

    }

    setHoraDefault = () => {

        const list_horas=getArrayPatronSemanaYFin(horas,horas_fin)

        //caso en que existe al menos una hora
        if(list_horas.length>0){
            this.setState({
                hora:list_horas[0].hora
            })
        }
        
    }

    componentDidMount(){

        //algorimo para validar hora seleccionada
        const {hora} =this.props
        const lista_horas=getArrayPatronSemanaYFin(horas,horas_fin)

        // caso existe una hora de entrega
        if(hora){
            /**condicion para saber si 
             * la hora almacenada es valida
             */
            if(!lista_horas.some((value,index)=>
                value.hora==hora
            )){

                this.setHoraDefault()

            }

        //caso no hay hora de entrega
        }else{

            this.setHoraDefault()

        }

    }

    render() {

        const list_horas=this.getHoras()
        
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title" >Programar envío(Restaurantes)</h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submit}>


                                <div className="form-group">
                                    <label htmlFor="hora" className="col-form-label" >{"Selecciona la hora a la que deseas recibir "+getDia(horas,horas_fin)+" tus productos"}</label>
                                    <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                        {list_horas}
                                    </select>
                                </div>


                            <button className="btn btn-solid"> Guardar</button>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    hora: state.cena.hora_entrega
})

export default connect(
    mapStateToProps,{updateCena}
)(ProgramarCena)