import React, { Component } from 'react'
import { connect } from 'react-redux'
import { calcularRestaurante, getTotalRestaurante } from '../../../services'
import { Link } from 'react-router-dom'
import Modal from 'react-responsive-modal';
import DetalleAdicional from '../modales/DetalleAdicional';


class CheckRestaurante extends Component {

    constructor (props) {
        super (props)
        this.state={
            open:false,
            product:null
        }
    }

    handleAdd = (e,product) => {
        e.preventDefault()

        this.setState({
            product:product
        },()=>{
            this.onOpenModal()
        })
        
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };
    

    render() {
        const{cartItems,total,symbol,delivery,entrega}=this.props

        if(cartItems.length>0){
            return (
                <>                   
                    <strong className="center70 font-25">Restaurantes</strong>
                    <br/>
                    <div className="title-box">
                        <div className="row">
                            <div className="col-7">
                                Producto
                            </div>
                            <div className="col-5">
                                Total
                            </div>
                        </div>
                    </div>
                    <ul className="qty">
                        {
                            cartItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div className="row">
                                            <div className="col-7">
                                                {item.name} (×{item.qty})  
                                            </div>
                                            <div className="col-5">
                                                {symbol}{calcularRestaurante(item)}
                                                {" / "}
                                                <Link to="/#" onClick={(e)=>{this.handleAdd(e,item)}} className="mt-2">
                                                    <img src="/assets/images/adicionales.png" className="image-30" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>

                    <ul className="sub-total">
                        <li>Hora de entrega
                            <span className="count">
                                {entrega}
                            </span>
                        </li>
                        <li>Delivery <span className="count">{symbol}{delivery}</span></li>
                        <li>Subtotal <span className="count">{symbol}{total}</span></li>
                    </ul>

                    <Modal classNames={{'modal': 'mb-modal'}} open={this.state.open} onClose={this.onCloseModal} >
                        <DetalleAdicional close={this.onCloseModal} product={this.state.product} />
                    </Modal>

                </>
            )
        }else{
            return ('')
        }
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.restaurante.cart,
    symbol: state.data.symbol,
    total: getTotalRestaurante(state.restaurante.cart),
    delivery:state.delivery.restaurante,
    entrega:state.restaurante.hora_entrega
})

export default connect(
    mapStateToProps,
    {}
)(CheckRestaurante)
