import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getArrayCategoriesSlider } from '../../funciones/pasillo'
import { LazyImage } from "react-lazy-images";
import CategoriesHomeSlider1 from './CategoriesHomeSlider1';
import CategoriesHomeSection1 from './CategoriesHomeSection1';
import CategoriesHomeSlider2 from './CategoriesHomeSlider2';

class HomeCategories extends Component {

    constructor(props){
        super()
        this.state={
        }
    }

    goBycategory(category){

        if(category.tipo==2 || category.tipo==6){
            this.props.history.push("/aliados/"+category.seo)
        }else if(category.jerarquia==1){
            this.props.history.push("/menu-huerta")
        }else{
            this.props.history.push("/categoria/"+category.seo)
        }

    }



    render() {

        const {categories}=this.props

        const list_categories_desk=categories.filter((value)=>value.pri<77 && value.jerarquia!=2).map((value,index)=>{

            return(
                <div className={"mb-padding col-sm-4 col-lg-3 col-xl-3 "+ ( index>3? 'col-4' : 'col-6 big-cate-img') } key={index} >
                    <div className={"cate-banner box400 bg-cate mb-5 mb-cate-box " +( index>3 ? 'mb-min-cate': '')} style={{backgroundColor: value.color}} >

                        <a  onClick={()=>this.goBycategory(value)}>
                            <LazyImage
                                src={value.image}
                                alt={value.name}
                                placeholder={({ imageProps, ref }) => (
                                    <img
                                        className={"home-loader img-fluid bg-img up-image "+ (index>3 ? '' : 'mb-home-loader')}
                                        
                                        ref={ref} 
                                        src={`${process.env.PUBLIC_URL}/assets/images/loading5.gif`}
                                        alt={imageProps.alt}
                                    />
                                )}
                                actual={({ imageProps }) => 
                                    <img {...imageProps} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')}  />
                                }
                            />
                            {/*
                            <img src={value.image} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')} alt="" />
                            */}
                            <img className="mb-oval"></img>
                        </a>                          
                        
                        <div className={"category-box "+(index>3 ? "mb-cate-box-test mb-cate-banner-test" : '')}>                       
                            <a onClick={()=>this.goBycategory(value)} className="pointer" >
                                <h2 className={"home-cate mb-font-10  mb-cate-p cate-lbl "+ (index>3 ? 'mb-min-cate-style': 'mb-mb-100')}>{value.name}</h2>
                            </a>
                        </div>
                    </div>
                </div>
            )
        })

        return (
            <>
            <div className="bg-white mb-none">

                <section className="p-0 ratio2_1 bg-white sandwitch10">

                    <div>
                        
                        <div className="row">
                            {list_categories_desk}
                        </div>
                    </div>

                </section>

            </div>

            <CategoriesHomeSlider1/>

            <CategoriesHomeSection1/>

            <CategoriesHomeSlider2/>

            </>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.categories.categories,
})

export default connect(
    mapStateToProps,
    { }
)(withRouter(HomeCategories));