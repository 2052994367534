import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastError, toastSuccess } from '../../alertas';
import { postData } from '../../servicios';
import FormError from '../common/FormError';
import { updateHeaders, updateUser } from '../../actions/index';

export class AddressForm extends Component {
    constructor(props) {
        super(props);
        const { user } = this.props;

        this.state = {
            directions: user.directions,
            bool_directions: false,
            loading: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    handleDirectionChange = (e, index) => {
        const { value } = e.target;
        const { directions } = this.state;

        const list = [...directions];
        list[index] = value;
        this.setState({
            directions: list,
        });
    };

    handleAdd = (e) => {
        e.preventDefault();
        const { directions } = this.state;
        this.setState({
            directions: [...directions, ''],
        });
    };

    handleRemove = (e, i) => {
        e.preventDefault();
        const { directions } = this.state;

        const list = [...directions];
        list.splice(i, 1);
        this.setState({
            directions: list,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const { user } = this.props;

        if (this.state.directions.length == 0) {
            this.setState({
                bool_directions: true,
            });
            return;
        }

        this.setState({
            bool_directions: false,
            loading: true,
        });

        const json = {
            customer: {
                nickname: user.nickname,
                ci: user.ci,
                tel: user.tel,
                directions: this.state.directions,
            },
        };

        console.log(json);

        try {
            const config = {
                headers: this.props.headers,
            };
            const response = await postData('edit-user/', json, config);
            this.props.updateHeaders(response.headers);
            this.props.updateUser(response.data);
            toastSuccess('Datos actualizados');
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (err) {
            console.log(err);
            toastError('Error, no se pudo actualizar');
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        const { directions, loading, bool_directions } = this.state;

        const list_directions = directions.map((x, i) => {
            return (
                <div className="form-row mt-2" key={i}>
                    <div className="col-sm-12 form-group margin-zero">
                        <label htmlFor="name">Dirección</label>
                        <input
                            type="text"
                            name="direction"
                            value={x}
                            className="form-control mb-2"
                            required
                            onChange={(e) => this.handleDirectionChange(e, i)}
                        />
                    </div>

                    <div className="btn-box dinam-button-box mb-2">
                        {directions.length !== 0 && (
                            <button
                                className="btn btn-cart"
                                onClick={(e) => {
                                    this.handleRemove(e, i);
                                }}
                            >
                                -
                            </button>
                        )}
                        {directions.length - 1 === i && (
                            <button className="btn btn-cart" onClick={this.handleAdd}>
                                +
                            </button>
                        )}
                    </div>
                </div>
            );
        });
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal fluio-font">
                    <div>
                        <div className="header-title tac-desk">Libreta de direcciones</div>

                        {loading ? (
                            <div className="row m-0">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`}
                                        className="img-fluid mb-4"
                                    />
                                    <h3>Cargando</h3>
                                </div>
                            </div>
                        ) : (
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-row margin-zero" />
                                <div className="container-button-prod">
                                    {list_directions}

                                    {list_directions.length == 0 ? (
                                        <div className="btn-box">
                                            <button className="btn btn-cart" onClick={this.handleAdd}>
                                                +
                                            </button>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div className="mt-2">
                                        <FormError text="Debes incluir al menos 1 dirección" show={bool_directions} />
                                    </div>
                                    <button className="btn modal-btn-add ttn" style={{ width: '100%' }}>
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    headers: state.customer.headers,
});

export default connect(
    mapStateToProps,
    { updateHeaders, updateUser }
)(withRouter(AddressForm));
