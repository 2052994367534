import React, { Component } from 'react';
import { getArrayByTipo, isOrdenLocal } from '../../../../servicios';
import OrdenRestaurante from './secciones/OrdenRestaurante';
import OrdenHuerta from './secciones/OrdenHuerta';
import OrdenPlan from './secciones/OrdenPlan';
import OrdenCore from './secciones/OrdenCore';
import OrdenSnack from './secciones/OrdenSnack';
import OrdenCongelado from './secciones/OrdenCongelado';
import OrdenCena from './secciones/OrdenCena';
import { HuertaCoreMatchOrden } from '../../../../funciones/Ordenes';
import { formatNumber, getStatusText } from '../../../../funciones/general';
import OrdenExternal from './secciones/OrdenExternal';
import { isLocation } from '../../../../funciones/cities';
import ordenReducer from '../../../../reducers/orden';

export class ModalOrder extends Component {
    render() {
        const { orden } = this.props;
        const productos = orden.productos;
        const snack = getArrayByTipo(productos, 0);
        const core = getArrayByTipo(productos, 1);
        const restaurante = getArrayByTipo(productos, 2);
        const plan = getArrayByTipo(productos, 3);
        const huerta = getArrayByTipo(productos, 4);
        const congelado = getArrayByTipo(productos, 5);
        const cena = getArrayByTipo(productos, 6);

        const match = HuertaCoreMatchOrden(huerta, core);
        const isLocal = isOrdenLocal(orden.ext);

        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title tac bold">Detalles del pedido</h5>
                    </div>

                    <div className="modal-body">
                        <div className="row">
                            <div className="col-4">
                                <strong>Nombre:</strong>
                            </div>
                            <div className="col-8">
                                <strong>{orden.cname}</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <strong>Teléfono:</strong>
                            </div>
                            <div className="col-8">
                                <strong>{orden.tel_c}</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <strong>Dirección:</strong>
                            </div>
                            <div className="col-8">
                                <strong>{`${orden.city} - ${orden.dir}`}</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <strong>Email:</strong>
                            </div>
                            <div className="col-8">
                                <strong>{orden.email}</strong>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <strong>Cédula:</strong>
                            </div>
                            <div className="col-8">
                                <strong>{orden.ci}</strong>
                            </div>
                        </div>
                        <br />

                        {isLocal ? (
                            <>
                                <OrdenSnack productos={snack} orden={orden} />
                                <OrdenCore productos={core} orden={orden} match={match} />
                                <OrdenRestaurante productos={restaurante} orden={orden} />
                                <OrdenPlan productos={plan} orden={orden} />
                                <OrdenHuerta productos={huerta} orden={orden} match={match} />
                                <OrdenCongelado productos={congelado} orden={orden} />
                                <OrdenCena productos={cena} orden={orden} />
                            </>
                        ) : (
                            <OrdenExternal productos={productos} orden={orden} />
                        )}

                        {orden.desc && (
                            <div className="row mb-2">
                                <div className="col-4">
                                    <strong>Comentario adicional:</strong>
                                </div>
                                <div className="col-8">{orden.desc}</div>
                            </div>
                        )}

                        {match && isLocal ? (
                            <div className="row">
                                <div className="col-4">
                                    <strong>Delivery(Mercado,huerta y congelados):</strong>
                                </div>
                                <div className="col-4">${formatNumber(core[0].delivery)}</div>
                            </div>
                        ) : (
                            ''
                        )}

                        {parseFloat(orden.discount) > 0 ? (
                            <>
                                <div className="row">
                                    <div className="col-4">
                                        <strong>Valor en carrito:</strong>
                                    </div>
                                    <div className="col-4">
                                        ${formatNumber(parseFloat(orden.amount) + parseFloat(orden.discount))}
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <strong>Descuento por saldo:</strong>
                                    </div>
                                    <div className="col-4">${formatNumber(orden.discount)}</div>
                                </div>
                            </>
                        ) : (
                            ''
                        )}

                        <div className="row">
                            <div className="col-4">
                                <strong>Total pagado:</strong>
                            </div>
                            <div className="col-4">${formatNumber(orden.amount)}</div>
                        </div>

                        {orden.pay_method == 3 ? (
                            <div className="row mt-2">
                                <div className="col-4">
                                    <strong>Transacción PSE:</strong>
                                </div>

                                <div className="col-4">{getStatusText(orden.mensaje)}</div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalOrder;
