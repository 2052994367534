/**
 * Mocking client-server processing
 */
import {getData} from '../servicios'
import store from '../store'
import { removeFromCart,
        addToCart,
        addToHuerta,
        removeFromHuerta,
        addToCongelado,
        removeFromCongelado,
        addToCore,
        removeFromCore,
        addToSnack,
        removeFromSnack,
        addToRestaurante,
        removeFromRestaurante,
        addToCena,
        removeFromCena } from '../actions'
import { validateActiveInCart } from '../funciones/validateCart'

const TIMEOUT = 100

export default {
    
    getProducts: async (cb, timeout) => {
        const response=await getData("products")
        const json=response['data']
        const carrito=store.getState().cartList.cart
        const carrito_huerta=store.getState().huerta.cart
        const carrito_congelado=store.getState().congelado.cart
        const carrito_core=store.getState().core.cart
        const carrito_snack=store.getState().snack.cart
        const carrito_restaurante=store.getState().restaurante.cart
        const carrito_cena=store.getState().cena.cart


        let Productos = [];
        for (let i = 0; i < json.length; i++) {
            let datos = {
                id: json[i].id,
                name: json[i].name,
                price: parseInt(json[i].price),
                salePrice: 200.00,
                discount: 10,
                pictures:[json[i].image],
                description: json[i].desc,
                shortDetails: "lorem",
                stock: 15,
                new: false,
                sale: false,
                category: json[i].category_id,
                tipo_cate:json[i].category_type,
                tipo_hall:json[i].hall_type,
                hall:json[i].hall_id,
                aliado: json[i].aliado,
                activo: json[i].activo,
                activo_hall: json[i].activo_hall,
                ally:json[i].ally_id
            };
            Productos.push(datos);
        }


        /** 
         * variable en la que se guardaran los productos 
         * del array Productos que vienen de api
         * que ya esten en el carrito para las siguientes 
         * validaciones, es posible que un producto
         */

        const productos_carrito=Productos.filter(producto=>{
            return carrito.some(item=>{
                return item.id==producto.id
            })
        })


        carrito.forEach(value=>{

            /**
             * variable para saber si 
             * el producto realmente esta en el carrito
             */
            let bool=true

            //algoritmo par actualizar datos del carrito
            productos_carrito.forEach(item=>{

                if(value.id==item.id){
                    
                    bool=false

                    //actualizacion de programacion segun tipo de articulo

                    //caso tipo restaurante
                    if(item.tipo_cate==2 || item.tipo_cate==3){
                        
                        var programacion=value.programacion
                        
                    }


                    if(value.qty>item.stock){
                        store.dispatch(removeFromCart(value))
                        store.dispatch(addToCart(item,programacion,item.stock))
                    }else{
                        store.dispatch(removeFromCart(value))
                        store.dispatch(addToCart(item,programacion,value.qty))
                    }

                }
            
            })

            if(bool){
                /**
                 * algoritmo para elminar del carrito ya que
                 * es posible que algun producto que estaba en el carrito
                 * se quedo sin stock
                 */
                store.dispatch(removeFromCart(value))

            }

        })

        //mismo algoritmo para caso carrito de huerta

        const productos_carrito_huerta=Productos.filter(producto=>{
            return carrito_huerta.some(item=>{
                return item.id==producto.id
            })
        })


        carrito_huerta.forEach(value=>{

            /**
             * variable para saber si 
             * el producto realmente esta en el carrito
             */
            let bool_huerta=true

            //algoritmo par actualizar datos del carrito
            productos_carrito_huerta.forEach(item=>{

                if(value.id==item.id){
                    
                    bool_huerta=false

                    if(value.qty>item.stock){
                        store.dispatch(removeFromHuerta(value))
                        store.dispatch(addToHuerta(item,store.getState().huerta.programacion,item.stock))
                    }else{
                        store.dispatch(removeFromHuerta(value))
                        store.dispatch(addToHuerta(item,store.getState().huerta.programacion,value.qty))
                        
                    }

                }
            
            })

            if(bool_huerta){
                /**
                 * algoritmo para elminar del carrito ya que
                 * es posible que algun producto que estaba en el carrito
                 * se quedo sin stock
                 */
                store.dispatch(removeFromHuerta(value))

            }

        })

        //mismo algoritmo para caso carrito de congelados

        const productos_carrito_congelado=Productos.filter(producto=>{
            return carrito_congelado.some(item=>{
                return item.id==producto.id
            })
        })


        carrito_congelado.forEach(value=>{

            /**
             * variable para saber si 
             * el producto realmente esta en el carrito
             */
            let bool_congelado=true

            //algoritmo par actualizar datos del carrito
            productos_carrito_congelado.forEach(item=>{

                if(value.id==item.id){
                    
                    bool_congelado=false

                    if(value.qty>item.stock){
                        store.dispatch(removeFromCongelado(value))
                        store.dispatch(addToCongelado(item,store.getState().congelado.programacion,item.stock))
                    }else{
                        store.dispatch(removeFromCongelado(value))
                        store.dispatch(addToCongelado(item,store.getState().congelado.programacion,value.qty))
                        
                    }

                }
            
            })

            if(bool_congelado){
                /**
                 * algoritmo para elminar del carrito ya que
                 * es posible que algun producto que estaba en el carrito
                 * se quedo sin stock
                 */
                store.dispatch(removeFromCongelado(value))

            }

        })


        //mismo algoritmo para caso carrito de core

        const productos_carrito_core=Productos.filter(producto=>{
            return carrito_core.some(item=>{
                return item.id==producto.id
            })
        })


        carrito_core.forEach(value=>{

            /**
             * variable para saber si 
             * el producto realmente esta en el carrito
             */
            let bool_core=true

            //algoritmo par actualizar datos del carrito
            productos_carrito_core.forEach(item=>{

                if(value.id==item.id){
                    
                    bool_core=false

                    if(value.qty>item.stock){
                        store.dispatch(removeFromCore(value))
                        store.dispatch(addToCore(item,store.getState().core.programacion,item.stock))
                    }else{
                        store.dispatch(removeFromCore(value))
                        store.dispatch(addToCore(item,store.getState().core.programacion,value.qty))
                    }

                }
            
            })

            if(bool_core){
                /**
                 * algoritmo para elminar del carrito ya que
                 * es posible que algun producto que estaba en el carrito
                 * se quedo sin stock
                 */
                store.dispatch(removeFromCore(value))

            }

        })



        //mismo algoritmo para caso carrito de snack

        const productos_carrito_snack=Productos.filter(producto=>{
            return carrito_snack.some(item=>{
                return item.id==producto.id
            })
        })


        carrito_snack.forEach(value=>{

            /**
             * variable para saber si 
             * el producto realmente esta en el carrito
             */
            let bool_snack=true

            //algoritmo par actualizar datos del carrito
            productos_carrito_snack.forEach(item=>{

                if(value.id==item.id){
                    
                    bool_snack=false

                    if(value.qty>item.stock){
                        store.dispatch(removeFromSnack(value))
                        store.dispatch(addToSnack(item,store.getState().snack.programacion,item.stock))
                    }else{
                        store.dispatch(removeFromSnack(value))
                        store.dispatch(addToSnack(item,store.getState().snack.programacion,value.qty))
                    }

                }
            
            })

            if(bool_snack){
                /**
                 * algoritmo para elminar del carrito ya que
                 * es posible que algun producto que estaba en el carrito
                 * se quedo sin stock
                 */
                store.dispatch(removeFromSnack(value))

            }

        })

        //mismo algoritmo para caso carrito de restaurante

        const productos_carrito_restaurante=Productos.filter(producto=>{
            return carrito_restaurante.some(item=>{
                return item.id==producto.id
            })
        })


        carrito_restaurante.forEach(value=>{

            /**
             * variable para saber si 
             * el producto realmente esta en el carrito
             */
            let bool_restaurante=true

            //algoritmo par actualizar datos del carrito
            productos_carrito_restaurante.forEach(item=>{

                if(value.id==item.id){
                    
                    bool_restaurante=false

                    item.bebida=value.bebida
                    item.postre=value.postre

                    item.num_bebida=value.num_bebida
                    item.num_postre=value.num_postre
                    item.comment=value.comment

                    item.hall=value.hall

                    if(value.qty>item.stock){
                        store.dispatch(removeFromRestaurante(value))
                        store.dispatch(addToRestaurante(item,store.getState().restaurante.hora_entrega,item.stock))
                    }else{
                        store.dispatch(removeFromRestaurante(value))
                        store.dispatch(addToRestaurante(item,store.getState().restaurante.hora_entrega,value.qty))
                    }

                }
            
            })

            if(bool_restaurante){
                /**
                 * algoritmo para elminar del carrito ya que
                 * es posible que algun producto que estaba en el carrito
                 * se quedo sin stock
                 */
                store.dispatch(removeFromRestaurante(value))

            }

        })

        
        //mismo algoritmo para caso carrito de cenas

        const productos_carrito_cena=Productos.filter(producto=>{
            return carrito_cena.some(item=>{
                return item.id==producto.id
            })
        })


        carrito_cena.forEach(value=>{

            /**
             * variable para saber si 
             * el producto realmente esta en el carrito
             */
            let bool_cena=true

            //algoritmo par actualizar datos del carrito
            productos_carrito_cena.forEach(item=>{

                if(value.id==item.id){
                    
                    bool_cena=false

                    item.bebida=value.bebida
                    item.postre=value.postre

                    item.num_bebida=value.num_bebida
                    item.num_postre=value.num_postre
                    item.comment=value.comment
                    
                    item.hall=value.hall

                    if(value.qty>item.stock){
                        store.dispatch(removeFromCena(value))
                        store.dispatch(addToCena(item,store.getState().cena.hora_entrega,item.stock))
                    }else{
                        store.dispatch(removeFromCena(value))
                        store.dispatch(addToCena(item,store.getState().cena.hora_entrega,value.qty))
                    }

                }
            
            })

            if(bool_cena){
                /**
                 * algoritmo para elminar del carrito ya que
                 * es posible que algun producto que estaba en el carrito
                 * se quedo sin stock
                 */
                store.dispatch(removeFromCena(value))

            }

        })

        validateActiveInCart()


        setTimeout(() => cb(Productos), timeout || TIMEOUT)
    },
    buyProducts: (payload, cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT)
}
