import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Breadcrumb from '../common/breadcrumb'

class SubHuertaTest extends Component {

    constructor(props){
        super(props)
    }

    goBycategory(category){

        this.props.history.push("/categoria/"+category.seo)

    }

    render() {

        const {categories}=this.props

        const list_categories=categories.filter((value)=>value.jerarquia==1 || value.jerarquia==2).map((value,i)=>

            <div className="col-12 col-sm-6 mt-3 pointer" onClick={()=>this.goBycategory(value)} key={i}>
                <div className="subcategory-card2">
                    <div className="container">
                        <div className="row">

                            <div className="col-12 p-0 c-margin">
                                <div className="container" style={{width:'100%'}}>
                                    <h3 className="tac bold text-dark" style={{paddingTop: '20px'}}>{value.name}</h3>
                                    <p className="tac mt-2">
                                        {
                                            value.tipo==1?
                                                "Entrega en menos de 90 minutos"
                                            :
                                                'Entregas los martes, jueves y sábados'
                                        }
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )

        return (

            <div className="mb-5">
                <Breadcrumb title={'Escoge una opción'}/>
                <div className="container ">

                    <div className='menu-huerta'>

                        <div className="row center-huerta">

                        {list_categories}

                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categories: state.categories.categories,
})

export default connect(
    mapStateToProps, { }
)(withRouter(SubHuertaTest))
