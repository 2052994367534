import { isActualDirectionLocal, isDirectionLocal } from './cities';

export const defineProducts = (data) => {
    let Productos = [];
    for (let i = 0; i < data.length; i++) {
        let datos = {
            id: data[i].id,
            name: data[i].name,
            price: parseInt(data[i].price),
            salePrice: 200.0,
            discount: 10,
            pictures: [data[i].image],
            description: data[i].desc,
            shortDetails: 'lorem',
            stock: parseInt(data[i].stock),
            new: false,
            sale: false,
            category: data[i].category_id,
            tipo_cate: data[i].category_type,
            tipo_hall: data[i].hall_type,
            hall: data[i].hall_id,
            aliado: data[i].aliado,
            activo: data[i].activo,
            activo_hall: data[i].activo_hall,
            ally: data[i].ally_id,
            pri: data[i].pri,
            tags: data[i].tags_id,
            if_coment: data[i].if_coment,
            category_name: data[i].category_name,
            hall_name: data[i].hall_name,
            if_offer: data[i].if_offer,
            price_offer: parseInt(data[i].price_offer),
            hallsId: data[i].halls,
            patrones: data[i].patrones,
            patron_default: data[i].patron_default,
            seo: data[i].seo,
            on_search: data[i].on_search,
            ext: data[i].ext,
        };

        if (isActualDirectionLocal()) {
            Productos.push(datos);
        } else {
            if (data[i].ext === 0) {
                Productos.push(datos);
            }
        }
    }

    return Productos;
};
