import store from "../store"
import horasCenaFin from "../api/horarios/cenaFin.json"
import horasCenaSem from "../api/horarios/cenaSemana.json"
import horasCore from "../api/horarios/tipoCore.json"
import horasSnack from "../api/horarios/tipoSnack.json"
import { getArrayPatronSemanaYFin } from "../servicios"
import { isUnaHoraUntil, isToday, isAfterHora } from "./general"
import { getArrayPatronCore, getArrayPatronSnack } from "./patrones"


/** 
 * metodo para determinar que productos de un pedido viejo estan
 * disponiblea aun para poder repetirlos
*/
export const getArrayForRepeat = (data) => {

    const productos=store.getState().data.products

    const new_array=productos.filter((vprod)=>

        data.some((vdata)=>
            vdata.product_id==vprod.id
        )

        &&

        vprod.activo==1

        &&

        vprod.activo_hall==1

    )

    return new_array
    
}

export const getDataOfPedido = (product,list) => {

    return list.find((value)=>
        value.product_id==product.id
    )

    
}

export const isProgramCenaValid = () => {

    const cena=store.getState().cena
    const horas=getArrayPatronSemanaYFin(horasCenaSem,horasCenaFin)

    if(cena.cart.length==0){
        return true
    }

    if(horas.some((value)=>value.hora==cena.hora_entrega)){
        return true
    }else{
        return false
    }
    
}

export const isProgramCoreValid=()=>{

    const core=store.getState().core
    const horas=getArrayPatronCore(horasCore)

    if(core.cart.length==0){
        return true
    }

    if(core.programacion==1){

        if(isUnaHoraUntil("18:30") && isAfterHora("9:00")){
            return true
        }else{
            return false
        }

    }else{

        const fecha=core.programacion.fecha+"T23:59:59"

        if(new Date(fecha) < new Date()){
            return false
        }else if(isToday(new Date(fecha))){
            
            if(isUnaHoraUntil("18:30")){

                if(horas.some((value)=>value.hora==core.programacion.hora)){
                    return true
                }else{
                    return false
                }

            }else{
                return false
            }
            
        }else{
            return true
        }

    }
    
}


export const isProgramSnackValid=()=>{

    const snack=store.getState().snack
    const horas=getArrayPatronSnack(horasSnack)


    if(snack.cart.length==0){
        return true
    }

    if(snack.programacion==1){

        if(isUnaHoraUntil("18:30") && isAfterHora("9:00")){
            return true
        }else{
            return false
        }

    }else{

        const fecha=snack.programacion.fecha+"T23:59:59"

        if(new Date(fecha) < new Date()){
            return false
        }else if(isToday(new Date(fecha))){
            
            if(isUnaHoraUntil("18:30")){

                if(horas.some((value)=>value.hora==snack.programacion.hora)){
                    return true
                }else{
                    return false
                }

            }else{
                return false
            }
            
        }else{
            return true
        }

    }
    
}


