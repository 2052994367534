import React, { Component } from 'react'
import background from '../assets/images/launch-background.png'
import logo from '../assets/images/logo-square.jpg'
import celi from '../assets/images/Celíacos.png'
import diab from '../assets/images/Diabéticos.png'
import orga from '../assets/images/Orgánico.png'
import vega from '../assets/images/Veganos.png'
import { withRouter } from 'react-router-dom'
import { completeSurvey } from '../actions';
import { connect } from 'react-redux';
import { cleverSurvey } from '../funciones/clevertap';

export class LaunchPage extends Component {

    constructor(props){
        super(props)
        this.state={
            page:1,
            programing:'',
            checkVegano:false,
            checkOrganico:false,
            checkCeliaco:false,
            checkDiabetico:false,
            checkCarne:false,
            checkNatural:false,
            checkComercio:false,
            checkColombia:false,
            checkResiduo:false
        }
    }

    nextPage = (e) => {

        const {page} = this.state

        this.setState({
            page: page+1
        })
        
    }

    handleOmitir = (e) => {

        this.props.completeSurvey()
        this.props.history.push("/")

    }
    
    programingNext = (e) => {

        const {id} = e.target
        const {page} = this.state

        if(id=="90"){
            this.setState({
                page: page+1,
                programing: '90 minutos',
            })
        }else{
            this.setState({
                page: page+1,
                programing: 'Programado',
            })
        }
        
    }

    handleCheck= (e) => {
        const {name,checked}= e.target
        this.setState({
            [name]: checked
        })
    }
    

    surveySubmit = () => {

        let json={
            "Vegano":this.state.checkVegano,
            "Organico":this.state.checkOrganico,
            "Apto-Celiaco":this.state.checkCeliaco,
            "Apto-Diabetico": this.state.checkDiabetico,
            "Carnes y pescados": this.state.checkCarne,
            "100% Natural": this.state.checkNatural,
            "Residuo Cero": this.state.checkResiduo,
            "Comercio Justo": this.state.checkComercio,
            "Hecho en colombia":this.state.checkColombia,
        }

        cleverSurvey(json)
        this.props.completeSurvey()
        this.props.history.push("/")
        
    }
    

    render() {

        const {page}= this.state

        return (
            <div className="launch-page" /*style={{backgroundImage: 'url('+background+')'}}*/>

                <div className="launch-bar"></div>

                <div className="tac mt-5">
                    <img src={logo} alt="logo" className="launch-logo"/>
                </div>

                

                {
                    page==1?

                    <div className="launch-page-1 tac">

                        <div className="launch-page-1-title-black">
                            ¡Bienvenid@
                        </div>
                        <div className="launch-page-1-title-black">
                            a FLUIO!
                        </div>

                        <div className="launch-page-1-section-1">
                            <div className="launch-page-1-text">
                                Empieza ya
                            </div>
                            <div className="launch-page-1-text">
                                una vida
                            </div>
                            <div className="launch-page-1-text">
                                saludable
                            </div>
                            <div className="launch-page-1-text">
                                y consciente
                            </div>
                        </div>


                        <div>

                            <div className="tac launch-next-1-space">
                                <button className="btn launch-next-button" onClick={this.nextPage}>Siguiente</button>
                            </div>

                        </div>


                    </div>

                    :<></>
                }

                {
                    page==2?
                    <div className="launch-page-2 tac">

                        <div>

                            <div className="mb-2">

                                <img src={orga} alt="organico" className="launch-tag-img"/>

                                <img src={vega} alt="vegano" className="launch-tag-img"/>

                                <img src={celi} alt="celiaco" className="launch-tag-img"/>

                                <img src={diab} alt="diabetico" className="launch-tag-img"/>

                            </div>


                            <div className="launch-page-2-text">
                                + 1.500 productos
                            </div>
                            <div className="launch-page-2-text">
                                saludables,
                            </div>
                            <div className="launch-page-2-text">
                                éticos, locales y
                            </div>
                            <div className="launch-page-2-text">
                                sostenibles.
                            </div>

                            

                        </div>

                        <hr class="hr-check my-5"></hr>

                        <div className="mt-4">

                            <div className="launch-page-2-text">
                                <i className="fa fa-truck launch-truck"></i>
                            </div>

                            <div className="launch-page-2-text">
                                Envíos en menos de 90 minutos o programados
                            </div>

                        </div>

                        <div>

                            <div className="tac launch-next-2-space">
                                <button className="btn launch-next-button" onClick={this.nextPage}>Siguiente</button>
                            </div>

                        </div>
                        

                    </div>
                    :<></>
                }

                {
                    page==3?
                    <div className="launch-page-3">

                        <div>

                            <div className="launch-page-3-text">
                                ¿Qué te interesa
                            </div>
                            <div className="launch-page-3-text">
                                encontrar?
                            </div>

                        </div>

                        <div className="mt-3">
                            
                            <div className="row">

                                <div className="col-10">

                                    <div className="launch-page-3-label launch-margin-label">
                                        Vegano
                                    </div>

                                    <div className="launch-page-3-label launch-margin-label">
                                        Orgánico
                                    </div>

                                    <div className="launch-page-3-label launch-margin-label">
                                        Apto-celíaco
                                    </div>

                                    <div className="launch-page-3-label launch-margin-label">
                                        Apto-diabético
                                    </div>

                                    <div className="launch-page-3-label launch-margin-label">
                                        Carnes y pescados
                                    </div>

                                    <div className="launch-page-3-label launch-margin-label">
                                        100% Natural
                                    </div>


                                    <div className="launch-page-3-label launch-margin-label">
                                        Residuo cero
                                    </div>

                                    <div className="launch-page-3-label launch-margin-label">
                                        Comercio justo
                                    </div>

                                    <div className="launch-page-3-label launch-margin-label">
                                        Hecho en Colombia
                                    </div>

                                </div>

                                <div className="col-2">

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check">
                                            <input 
                                                type="checkbox" 
                                                name="checkVegano"
                                                checked={this.state.checkVegano}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check">
                                            <input 
                                                type="checkbox" 
                                                name="checkOrganico"
                                                checked={this.state.checkOrganico}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check">
                                            <input 
                                                type="checkbox" 
                                                name="checkCeliaco"
                                                checked={this.state.checkCelicaco}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check">
                                            <input 
                                                type="checkbox" 
                                                name="checkDiabetico"
                                                checked={this.state.checkDiabetico}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check">
                                            <input 
                                                type="checkbox" 
                                                name="checkCarne"
                                                checked={this.state.checkCarne}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check">
                                            <input 
                                                type="checkbox" 
                                                name="checkNatural"
                                                checked={this.state.checkNatural}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check-2">
                                            <input 
                                                type="checkbox" 
                                                name="checkResiduo"
                                                checked={this.state.checkResiduo}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check-2">
                                            <input 
                                                type="checkbox" 
                                                name="checkComercio"
                                                checked={this.state.checkComercio}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="">
                                        <label class="container-launch-check launch-margin-check-2">
                                            <input 
                                                type="checkbox" 
                                                name="checkColombia"
                                                checked={this.state.checkColombia}
                                                onChange={this.handleCheck}
                                            />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                                                 
                                </div>
                            </div>

                        </div>

                        <div>

                            <div className="tac mt-5">
                                <button className="btn launch-next-button" onClick={this.surveySubmit}>Comenzar</button>
                            </div>


                        </div>

                    </div>

                    
                    :<></>
                }

                <span className="omitir-label" onClick={this.handleOmitir}>Omitir</span>
                
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

export default connect(
    mapStateToProps,
    { completeSurvey }
)(withRouter(LaunchPage));
