import React, {Component} from 'react';
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal';
import Breadcrumb from "../common/breadcrumb";
import {removeFromWishlist, updateEnvio, updateHeaders} from '../../actions'
import {ValidatePago, getTotalGeneral, limpiarCarrito, getCartIndividual,cargarOrders} from "../../services";
import PayForm from './PayForm';
import CheckRestaurante from './secciones/CheckRestaurante';
import CheckSnack from './secciones/CheckSnack';
import CheckCore from './secciones/CheckCore';
import CheckHuerta from './secciones/CheckHuerta';
import CheckCongelado from './secciones/CheckCongelado';
import { withRouter } from 'react-router-dom';
import { postData, getData} from '../../servicios';
import CheckCena from './secciones/CheckCena';
import { validateActiveInCart } from '../../funciones/validateCart';
import { getDescuento, getArrayProductosOrder, tokenRequetError, getPaybuttonText, CoreHuertaMatch } from '../../funciones/checkout';
import CheckPlan from './secciones/CheckPlan';
import PseForm from './PseForm';
import ContactIcons from '../common/ContactIcons'
import MapTool from '../common/MapTool';
import Swal from 'sweetalert2';
import { sweetQuestion } from '../../funciones/general';
import BotonPse from '../../assets/images/BotonPSE.png'

class checkoutPSE extends Component {

    constructor (props) {
        super (props)

        let dir
        let lon 
        let lat

        if(props.usuario.addresses[0]){
            dir=props.usuario.addresses[0].dir
            lon=props.usuario.addresses[0].lon
            lat=props.usuario.addresses[0].lat
        }

        this.state = {
            first_name:props.usuario.name,
            phone:props.usuario.tel,
            email:props.usuario.email,
            address:dir,
            create_account: '',
            ci:props.usuario.ci,
            radio:'tdc',
            open: false,
            openRestaurante:false,
            openSemanal: false,
            openQuincenal: false,
            openMensual: false,
            product:null,
            pago:false,
            load: false,
            openPse: false,
            openDelivery:false,
            openMap:false,
            lon:lon,
            lat:lat
        }

    }

    setStateFromInput = (event) => {
        const { name,value}= event.target
        this.setState({
            [name]: value
        })

    }

    handleRadio = (event) => {
        const { value}= event.target
        this.setState({
            radio: value
        })

    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    onOpenPse = () => {
        this.setState({ openPse: true });
    };

    onClosePse = () => {
        this.setState({ openPse: false });
    };

    doSubmit = () => {

        const {general_total,descuento}=this.props

        this.setState({
            load:false
        })

        if(general_total-descuento==0){
            this.postOrderNoCost()
            this.setState({
                pago:true,
                load: true
            })
            return
        }

        if(this.state.radio=="tdc"){
            //this.postOrderCash()
            this.onOpenModal()
        }else if(this.state.radio=="pse"){

            this.onOpenPse()
        }else{
            this.postOrderCash()
            this.setState({
                pago:true,
                load: true
            })
        }
        
    }
    


    handleSubmit =async  (e) => {
        e.preventDefault()

        this.doSubmit()

        /** 

        this.setState({
            load:true
        })

        if(this.state.lat){

            try {

                const req=await getData('is-bog?lat='+this.state.lat+"&lon="+this.state.lon)
                if(req.data.isBogota=="true"){
                    this.doSubmit()
                }else{

                    const question=await sweetQuestion(
                        `Advertencia`,
                        `Parece que la ubicacion que ingresaste no se encuentra en bogota,continua con 
                        tu pedido solo si puedes recibir el domicilio en alguna zona dentro de bogota
                        <br/>
                        <b>¿Deseas continuar?<b/>`
                    )
            
                    if(question.value){
                        this.doSubmit()
                    }else{
                        this.setState({
                            load:false
                        })
                    }

                }

                
            } catch (error) {
                console.log(error)
                const question=await sweetQuestion(
                    `Advertencia`,
                    `No pudimos determinar si tu ubicacion se encuentra en bogota,continua con 
                    tu pedido solo si puedes recibir el domicilio en alguna zona dentro de bogota
                    <br/>
                    <b>¿Deseas continuar?<b/>`
                )
        
                if(question.value){
                    this.doSubmit()
                }else{
                    this.setState({
                        load:false
                    })
                }
            }
            
        }else{

            const question=await sweetQuestion(
                `Advertencia`,
                `Parece que no ingresaste ninguna ubicacion,continua con 
                tu pedido solo si puedes recibir el domicilio en alguna zona dentro de bogota
                <br/>
                <b>¿Deseas continuar?<b/>`
            )
    
            if(question.value){
                this.doSubmit()
            }else{
                this.setState({
                    load:false
                })
            }

        }

        */


    }

    openDelivery = (e) => {
        e.preventDefault()
        this.setState({ openDelivery: true });
    };

    closeDelivery = () => {
        this.setState({ openDelivery: false });
    };

    openMap = (e) => {
        e.preventDefault()
        this.setState({ openMap: true });
    };

    closeMap = () => {
        this.setState({ openMap: false });
    };

    setCordenates = (lon,lat) => {

        this.setState({
            lon:lon,
            lat:lat,
            openMap:false
        })
        
    }
    





    postOrderTDC =async (token) => {

        const {usuario,general_total,descuento}=this.props

        let json={

            order:{
                customer_id:usuario.id,
                pay_cod:token,
                status: 0,
                dir:this.state.address,
                amount: general_total-descuento,
                cname: this.state.first_name,
                pay_method:1,
                tel_c:this.state.phone,
                email:this.state.email,
                ci:this.state.ci,
                details_attributes:getArrayProductosOrder(),
                amount_t:general_total,
                discount:descuento
            }

        }

        try{

            const config={
                headers: this.props.headers
            }
            const response= await postData("orders",json,config)
            this.props.updateHeaders(response.headers)
            if(response.data.codigo=="201"){
                limpiarCarrito()
            }
            this.props.history.push("/confirm/"+response.data.codigo+"/"+response.data.mensaje)
        }catch(error){
            console.log(error.response)
            if (error.response) {
                tokenRequetError("Estatus:"+error.response.status)
            } else {
                tokenRequetError(error.message)
            }
        }

    }

    postOrderPSE =async (token,bank) => {

        const {usuario,general_total,descuento}=this.props

        let json={

            order:{
                customer_id:usuario.id,
                bankId:bank,
                pay_cod:token,
                status: 0,
                dir:this.state.address,
                amount: general_total-descuento,
                cname: this.state.first_name,
                pay_method:3,
                tel_c:this.state.phone,
                email:this.state.email,
                ci:this.state.ci,
                details_attributes:getArrayProductosOrder(),
                amount_t:general_total,
                discount:descuento,
                url:"http://www.mylanderpages.com/kushki/TestSold"
            }

        }

        try{

            const config={
                headers: this.props.headers
            }
            const response= await postData("orders",json,config)
            this.props.updateHeaders(response.headers)
            if(response.data.codigo=="201"){
                limpiarCarrito()
                window.location.href=response.data.url_r
            }else{
                tokenRequetError("Ocurrio un problema:"+response.data.mensaje)
            }
            //this.props.history.push("/confirm/"+response.data.codigo+"/"+response.data.mensaje)
            
        }catch(error){
            console.log(error.response)
            if (error.response) {
                tokenRequetError("Estatus:"+error.response.status)
            } else {
                tokenRequetError(error.message)
            }
        }

    }


    postOrderCash =async () => {

        const {usuario,general_total,descuento}=this.props

        let json={

            order:{
                customer_id:usuario.id,
                status: 0,
                dir:this.state.address,
                amount: general_total-descuento,
                cname: this.state.first_name,
                pay_method:0,
                tel_c:this.state.phone,
                email:this.state.email,
                ci:this.state.ci,
                details_attributes:getArrayProductosOrder(),
                amount_t:general_total,
                discount:descuento
            }

        }

        try{
            const config={
                headers: this.props.headers
            }
            const response= await postData("orders",json,config)
            this.props.updateHeaders(response.headers)
            limpiarCarrito()
            this.props.history.push("/confirm/201/Pedido Realizado")
        }catch(error){

            if (error.response) {
                tokenRequetError("Estatus:"+error.response.status)
            } else {
                tokenRequetError(error.message)
            }
            
        }

    }

    postOrderNoCost =async () => {

        const {usuario,general_total,descuento}=this.props

        let json={

            order:{
                customer_id:usuario.id,
                status: 0,
                dir:this.state.address,
                amount: general_total-descuento,
                cname: this.state.first_name,
                pay_method:2,
                tel_c:this.state.phone,
                email:this.state.email,
                ci:this.state.ci,
                details_attributes:getArrayProductosOrder(),
                amount_t:general_total,
                discount:descuento
            }

        }

        try{
            const config={
                headers: this.props.headers
            }
            const response= await postData("orders",json,config)
            this.props.updateHeaders(response.headers)
            limpiarCarrito()
            this.props.history.push("/confirm/201/Pedido Realizado")
        }catch(error){

            if (error.response) {
                tokenRequetError("Estatus:"+error.response.status)
            } else {
                tokenRequetError(error.message)
            }
            
        }

    }


    componentDidMount(){
        validateActiveInCart()
        cargarOrders()
    }


    render (){

        const {symbol,general_total,descuento,delivery} = this.props;
        const {pago}=this.state

        return (

            <div>

                <Breadcrumb  title={'Pago'}/>

                <section className="section-b-space">
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="checkout row">
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-title">
                                                <h3>Detalles de envío</h3>
                                            </div>
                                            <div className="row check-out">
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Nombre</div>
                                                    <input type="text" name="first_name" value={this.state.first_name} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Cédula</div>
                                                    <input type="text" name="ci" value={this.state.ci} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Celular</div>
                                                    <input type="text" name="phone"  value={this.state.phone} onChange={this.setStateFromInput} required/>
                                                </div>
                                                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="field-label">Correo electrónico</div>
                                                    <input type="email" name="email" value={this.state.email} onChange={this.setStateFromInput} required />
                                                </div>
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label">Dirección</div>
                                                    <input type="text" name="address" value={this.state.address} onChange={this.setStateFromInput} required />
                                                </div>
                                                {/*                                    
                                                <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                    <div className="field-label map-form">
                                                        <span  >
                                                            Ubicación: 
                                                            {
                                                                this.state.lat?
                                                                "Latitud:"+this.state.lat+" , "+"Longitud:"+this.state.lon
                                                                :'No se ha seleccionado una ubicación'
                                                            }
                                                        </span>
                                                        <br/>
                                                        <button className="btn btn-cart margin-zero" onClick={this.openMap}>
                                                            Ver mapa
                                                            <i className="fa fa-map-marker map-icon"/>
                                                        </button>                                                       
                                                    </div>                                             
                                                </div>
                                                */}    
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12">
                                            <div className="checkout-details">
                                                
                                                <div className="order-box">
                                                    
                                                    <CheckPlan/>

                                                    <CheckSnack/>

                                                    <CheckCore/>

                                                    <CheckRestaurante/>

                                                    <CheckHuerta/>

                                                    <CheckCongelado/>

                                                    <CheckCena/>

                                                    

                                                    <ul className="sub-total">

                                                        {
                                                            general_total-descuento>0?

                                                            <li>Forma De Pago
                                                                <div className="shipping">

                                                                    <div className="radio">
                                                                        <label><input type="radio"  value="tdc" onChange={this.handleRadio} checked={this.state.radio === "tdc"} />Tarjeta de crédito</label>
                                                                    </div>

                                                                    {/**/}
                                                                    <div className="radio">
                                                                        <label><input type="radio"  value="pse" onChange={this.handleRadio} checked={this.state.radio === "pse"} />Débito PSE
                                                                        <img src={BotonPse} className="pse-button-order" alt={"boton pse"}/>
                                                                        </label>
                                                                    </div>
                                                                    
                                                                    

                                                                    {
                                                                        ValidatePago() ?
                                                                            <div className="radio">
                                                                                <label><input type="radio"  value="cash" onChange={this.handleRadio} checked={this.state.radio === "cash"} />Efectivo</label>
                                                                            </div>
                                                                        : ''
                                                                    }

                                                                </div>
                                                            </li>
                                                            :''

                                                        }
                                                        
                                                        {
                                                            CoreHuertaMatch()?
                                                                <li>Delivery<i onClick={this.openDelivery} className="fa fa-question-circle match-delivery-icon"/>: <span className="count">{symbol}{delivery.core_huerta}</span></li>
                                                            :<></>
                                                        }                                                     
                                                        {
                                                            descuento>0?
                                                            <>
                                                                <li>Total en carrito; <span className="count">{symbol}{general_total}</span></li>
                                                                <li>Descuento por saldo; <span className="count">-{symbol}{descuento}</span></li>
                                                            </>                                                           
                                                            :''
                                                        }                                                    
                                                        
                                                        <li>Total a pagar: <span className="count">{symbol}{general_total-descuento}</span></li>
                                                    </ul>
                                                </div>

                                                <div className="payment-box">
                                                    {(general_total !== 0)?
                                                    <div className="text-right">

                                                        <div className="d-flex flex-row justify-content-center">


                                                            {
                                                                this.state.load ?
                                                                <div className="col-md-6 text-center section-b-space mt-5 no-found" >
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                                                    <h3>Cargando</h3>
                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="p-4">
                                                                        <button 
                                                                            type="submit" 
                                                                            className="btn-solid btn" 
                                                                            disabled={pago}>{getPaybuttonText(this.state.radio,general_total-descuento)}
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }


                                                        </div>
                                                    </div>
                                                    : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <ContactIcons/>

                <Modal open={this.state.open} onClose={this.onCloseModal} center classNames={{'modal': 'mb-modal'}}>
                        <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <PayForm amount={general_total-descuento} pay={this.postOrderTDC} />
                                </div>
                            </div>
                        </div>
                </Modal>

                <Modal open={this.state.openPse} onClose={this.onClosePse} center classNames={{'modal': 'mb-modal',closeButton: "close-forgotten"}} >
                        <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <PseForm amount={general_total-descuento} pay={this.postOrderPSE} />
                                </div>
                            </div>
                        </div>
                </Modal>

                <Modal open={this.state.openDelivery} onClose={this.closeDelivery} center classNames={{'modal': 'mb-modal',closeButton: "close-forgotten"}} >
                        <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                la fecha y hora de entrega de tus productos de mercado y huerta orgánica programada coinciden, 
                                por lo que te cobraremos un único delivery de $7500
                                </div>
                            </div>
                        </div>
                </Modal>

                <Modal open={this.state.openMap} onClose={this.closeMap} center classNames={{'modal': 'map-mb',closeButton: "close-forgotten"}} >
                        <div className="modal-dialog modal-dialog-centered w-vista-r map-dialog" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <MapTool 
                                        submit={this.setCordenates} 
                                        lat={this.state.lat} 
                                        lon={this.state.lon}
                                        profile={false}
                                    />
                                </div>
                            </div>
                        </div>
                </Modal>

            </div>

        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    delivery:state.delivery,
    general_total:getTotalGeneral(),
    usuario:state.customer.data,
    envio:state.customer.envio,
    huerta: state.huerta,
    congelado: state.congelado,
    restaurante: state.restaurante,
    core: state.core,
    snack: state.snack,
    cena: state.cena,
    total: getCartIndividual(state.cartList.cart),
    headers: state.customer.headers,
    descuento: getDescuento()
})

export default connect(
    mapStateToProps,
    {removeFromWishlist,updateEnvio,updateHeaders}
)(withRouter(checkoutPSE))