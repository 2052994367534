import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getArrayCategoriesSlider } from '../../funciones/pasillo';
import { LazyImage } from 'react-lazy-images';
import { isAppColor, isPair } from '../../funciones/general';
import { getLocationCategories } from '../../funciones/cities';

class CategoriesHomeSlider1 extends Component {
    constructor(props) {
        super();
        this.state = {
            shuffle_array: getArrayCategoriesSlider(props.categories, props.categoria_id),
            id: props.categoria_id,
        };
    }

    goBycategory(category) {
        if (category.tipo == 2 || category.tipo == 6) {
            this.props.history.push('/aliados/' + category.seo);
        } else if (category.jerarquia == 1) {
            this.props.history.push('/menu-huerta');
        } else {
            this.props.history.push('/categoria/' + category.seo);
        }
    }

    render() {
        const { shuffle_array } = this.state;
        const { categories } = this.props;

        let slider_categories = categories
            .filter((value) => value.pri < 77 && value.jerarquia != 2)
            .slice(0, 2)
            .map((value, i) => {
                return (
                    <div
                        key={i}
                        className={'col-6 ' + (i == 0 ? 'pl-0 pr-1' : 'pl-1 pr-0')}
                        onClick={() => {
                            this.goBycategory(value);
                        }}
                    >
                        <div>
                            <div
                                className={'category-home-b2 ' + (isAppColor(value.back_on) ? 'no-border' : '')}
                                style={{ backgroundColor: isAppColor(value.back_on) ? value.color : 'white' }}
                            >
                                <div className="container">
                                    <div className="row">
                                        {isPair(i) ? (
                                            <>
                                                <div className="col-7 p-0">
                                                    <div
                                                        className="item-vertical-center container"
                                                        style={{ width: '100%' }}
                                                    >
                                                        <p className="category-home-card-label">{value.name}</p>
                                                    </div>
                                                </div>

                                                <div className="col-5 p-0">
                                                    <div className="my-3 subcategory-home-image-box">
                                                        <img src={value.image} alt={value.name} />
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="col-5 p-0">
                                                    <div className="my-3 subcategory-home-image-box">
                                                        <img src={value.image} alt={value.name} />
                                                    </div>
                                                </div>

                                                <div className="col-7 p-0">
                                                    <div
                                                        className="item-vertical-center container"
                                                        style={{ width: '100%' }}
                                                    >
                                                        <p className="category-home-card-label-odd">{value.name}</p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

        return (
            <>
                <div className="categories-home-slider-box content-home-m1 mb-display">
                    <div className="row m-0">{slider_categories}</div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    categories: getLocationCategories(),
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(CategoriesHomeSlider1));
