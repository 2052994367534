import Swal from 'sweetalert2';

const toastSuccess = (mensaje) => {
    Swal.fire({
        toast: true,
        icon: 'success',
        titleText: mensaje,
        showConfirmButton: false,
        timer: 3000,
        position: 'top-right'
    });
}

const toastError = (mensaje) => {
    Swal.fire({
        toast: true,
        icon: 'error',
        titleText: mensaje,
        showConfirmButton: false,
        timer: 3000,
        position: 'top-right'
    });
}

export { toastSuccess, toastError }