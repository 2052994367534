import React, {Component} from 'react';
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component';
import { addToCart, addToWishlist, addToCompare } from '../../../actions'
import {getProductosVisibles} from '../../../services';
import ProductListItem from "./product-list-item";
import { noAccent } from '../../../funciones/general';
import ProductListItemSearch from './ProductListItemSearch';
import { cleverFailSearch } from '../../../funciones/clevertap';
import { getData } from '../../../servicios';
import { defineProducts } from '../../../funciones/busquedas';

class ProductListing extends Component {

    constructor (props) {
        super (props)

        this.state = { 
            limit: 12, 
            hasMoreItems: true ,
            loading:true,
            data:[],
            old_texto:props.texto
        };

    }

    componentWillMount(){

        this.fetchMoreItems();
    }

    async componentDidMount(){

        const {texto,products}=this.props

        try {
            let data=[]

            const req=await getData('buscador?search='+texto)

            if(!req.data.message){

                data=defineProducts(req.data)

            }

            this.setState({
                data:data,
                loading:false,
            })
            
        } catch (error) {
            console.log(error)
        }

    }


    fetchMoreItems = () => {

        if (this.state.limit >= this.props.total.length) {
            this.setState({ hasMoreItems: false });
            return;
        }
        // a fake async api call
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 12
            });
        }, 3000);
    }

    async componentDidUpdate(prevProps,prevState){

        const {texto,products}=this.props

        if(texto!=this.state.old_texto){

            this.setState({
                old_texto:texto,
                loading:true
            })

            try {
                let data=[]
    
                const req=await getData('buscador?search='+texto)
    
                if(!req.data.message){
    
                    data=defineProducts(req.data)
    
                }
    
                this.setState({
                    data:data,
                    loading:false,
                })
                
            } catch (error) {
                console.log(error)
            }

        }

        
        

        /*

        try {
            let data=[]

            const req=await getData('buscador?search='+texto)

            if(!req.data.message){

                data=defineProducts(req.data)

            }

            this.setState({
                data:data,
                loading:false,
            })
            
        } catch (error) {
            console.log(error)
        }

         */

    }



    render (){
        const {products, addToCart, symbol, addToWishlist, addToCompare,texto} = this.props;
        const {loading,data}=this.state

        const dataProd=[...products]

        const list_product=data.filter((val)=> 
            dataProd.some((p)=>p.id==val.id && val.on_search) 
        )

        let searchProducts=[]

        list_product.map((val)=>{

            let prod=val

            if(val.patrones.length>0){

                val.patrones.map((p)=>{

                    let clone = JSON.parse(JSON.stringify(val));

                    clone.patron=p

                    if(val.patrones.length>1){
                        clone.showTag=true
                    }

                    searchProducts.push(clone)

                })

                

            }

        })


        return (
            <div>
                {
                    loading?
                    <div className="row">
                        <div className="col-md-12 text-center section-b-space mt-5 no-found" >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                            <h3>Cargando</h3>
                        </div>
                    </div>
                    :
                    <div className="product-wrapper-grid">
                        <div className="container-fluid">
                            {searchProducts.length > 0 ?
                                <>

                                    <InfiniteScroll
                                        dataLength={this.state.limit} //This is important field to render the next data
                                        next={this.fetchMoreItems}
                                        hasMore={this.state.hasMoreItems}
                                        endMessage={
                                            <p className="seen-cls seen-it-cls">
                                                <b>Ya no hay mas productos!</b>
                                            </p>
                                        }
                                    >
                                        {texto!=undefined ?
                                        <div className="row">
                                            {   
                                                searchProducts.map((product, index)=>{

                                                    return(
                                                        <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-6':'col-lg-'+this.props.colSize}`} key={index}>
                                                        <ProductListItemSearch product={product} symbol={symbol}
                                                                            onAddToCompareClicked={() => addToCompare(product)}
                                                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                                                            onAddToCartClicked={addToCart} key={index}/>
                                                        </div>                                                                                                                                     
                                                    )

                                                })

                                            }
                                            </div>                             
                                        :
                                            <div className="row">
                                                { searchProducts.map((product, index) =>
                                                    <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-6':'col-lg-'+this.props.colSize}`} key={index}>
                                                    <ProductListItemSearch product={product} symbol={symbol}
                                                                        onAddToCompareClicked={() => addToCompare(product)}
                                                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                                                        onAddToCartClicked={addToCart} key={index}/>
                                                    </div>)
                                                }
                                            </div>
                                        }
                                        
                                    </InfiniteScroll>
                                </>
                                :
                                <div className="row">
                                    {
                                        cleverFailSearch(texto)
                                    }
                                    <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                        <h3>No hay resultados en tu busqueda</h3>                            
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }               
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    symbol: state.data.symbol,
    products: getProductosVisibles(state.data, state.filters),
    total: state.data.products
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare}
)(ProductListing)