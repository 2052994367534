import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getArrayCategoriesSlider } from '../../funciones/pasillo';
import { LazyImage } from 'react-lazy-images';
import CategoriesHomeSlider1 from './CategoriesHomeSlider1';
import CategoriesHomeSection1 from './CategoriesHomeSection1';
import CategoriesHomeSlider2 from './CategoriesHomeSlider2';
import { getLocationCategories } from '../../funciones/cities';

class HomeCategoriesApp extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    goBycategory(category) {
        if (category.tipo == 2 || category.tipo == 6) {
            this.props.history.push('/aliados/' + category.seo);
        } else if (category.jerarquia == 1) {
            this.props.history.push('/menu-huerta');
        } else {
            this.props.history.push('/categoria/' + category.seo);
        }
    }

    render() {
        const { categories } = this.props;

        const list_categories_desk = categories
            .filter((value) => value.pri < 77 && value.jerarquia != 2)
            .slice(0, 4)
            .map((value, index) => {
                return (
                    <div className={'shop-cate-p col-3 '} key={index}>
                        <div className={'cate-banner bg-cate shop-cate-box '} style={{ backgroundColor: value.color }}>
                            <a onClick={() => this.goBycategory(value)}>
                                <LazyImage
                                    src={value.image}
                                    alt={value.name}
                                    placeholder={({ imageProps, ref }) => (
                                        <img
                                            className={
                                                'home-loader img-fluid bg-img up-image ' +
                                                (index > 3 ? '' : 'mb-home-loader')
                                            }
                                            ref={ref}
                                            src={`${process.env.PUBLIC_URL}/assets/images/loading5.gif`}
                                            alt={imageProps.alt}
                                        />
                                    )}
                                    actual={({ imageProps }) => (
                                        <img
                                            {...imageProps}
                                            className={
                                                'shop-cate-img img-fluid bg-img up-image ' +
                                                (index > 3 ? '' : 'big-cate-img')
                                            }
                                        />
                                    )}
                                />
                                {/*
                            <img src={value.image} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')} alt="" />
                            */}
                                <img className="mb-oval" />
                            </a>

                            <div
                                className={'shop-cate-lbl ' + (index > 3 ? 'mb-cate-box-test mb-cate-banner-test' : '')}
                            >
                                <a onClick={() => this.goBycategory(value)} className="pointer">
                                    <h2 className={'mb-font-10  ' + (index > 3 ? 'mb-min-cate-style' : 'mb-mb-100')}>
                                        {value.name}
                                    </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            });

        const list_categories_desk2 = categories
            .filter((value) => value.pri < 77 && value.jerarquia != 2)
            .slice(4)
            .map((value, index) => {
                return (
                    <div className={'shop-cate-p col-3 mb-4'} key={index}>
                        <div className={'cate-banner bg-cate shop-cate-box '} style={{ backgroundColor: value.color }}>
                            <a onClick={() => this.goBycategory(value)}>
                                <LazyImage
                                    src={value.image}
                                    alt={value.name}
                                    placeholder={({ imageProps, ref }) => (
                                        <img
                                            className={
                                                'home-loader img-fluid bg-img up-image ' +
                                                (index > 3 ? '' : 'mb-home-loader')
                                            }
                                            ref={ref}
                                            src={`${process.env.PUBLIC_URL}/assets/images/loading5.gif`}
                                            alt={imageProps.alt}
                                        />
                                    )}
                                    actual={({ imageProps }) => (
                                        <img
                                            {...imageProps}
                                            className={
                                                'shop-cate-img img-fluid bg-img up-image ' +
                                                (index > 3 ? '' : 'big-cate-img')
                                            }
                                        />
                                    )}
                                />
                                {/*
                            <img src={value.image} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')} alt="" />
                            */}
                                <img className="mb-oval" />
                            </a>

                            <div
                                className={'shop-cate-lbl ' + (index > 3 ? 'mb-cate-box-test mb-cate-banner-test' : '')}
                            >
                                <a onClick={() => this.goBycategory(value)} className="pointer">
                                    <h2 className={'mb-font-10  ' + (index > 3 ? 'mb-min-cate-style' : 'mb-mb-100')}>
                                        {value.name}
                                    </h2>
                                </a>
                            </div>
                        </div>
                    </div>
                );
            });

        return (
            <>
                <div className="bg-white mb-none">
                    <div className="home-title-label">Categorías</div>

                    <section className="p-0 ratio2_1 bg-white cate-sand">
                        <div>
                            <div className="row">{list_categories_desk}</div>
                        </div>
                    </section>

                    <div className="home-hr1">
                        <hr />
                    </div>

                    <div className="home-title-label2">¿Por que escogernos?</div>

                    <div className="home-text1">
                        <p>
                            Te enviamos tu pedido en menos de una hora y garantizamos precios justos y asequibles, para
                            que consumir sano y de calidad no te resulte tan costoso.
                        </p>
                        <p>Queremos hacerte la vida más fácil y también más saludable, armónica y equilibrada.</p>
                    </div>

                    <div className="home-hr2">
                        <hr />
                    </div>

                    <div className="home-title-label3">Mas categorías</div>

                    <section className="p-0 ratio2_1 bg-white cate-sand">
                        <div>
                            <div className="row">{list_categories_desk2}</div>
                        </div>
                    </section>

                    <div className="home-hr1">
                        <hr />
                    </div>
                </div>

                <CategoriesHomeSlider1 />

                <CategoriesHomeSection1 />

                <CategoriesHomeSlider2 />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    categories: getLocationCategories(),
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(HomeCategoriesApp));
