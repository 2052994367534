import React, { Component } from 'react'
import { connect } from 'react-redux'
import { postData } from '../servicios'
import { toastError } from '../alertas'
import { cleverItemNotFound } from '../funciones/clevertap'

class Sugerencias extends Component {

    constructor(props){
        super(props)
        this.state={
            marca:'',
            descripcion:'',
            load: false,
            product: '',
            email: props.user?props.user.email:'',
        }
    }

    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        this.setState({
            load:true
        })

        const json={
            quote:{
                email: this.state.email, 
                producto: this.state.product,
                marca: this.state.marca,
                com: this.state.descripcion
            }
        }

        const cleverData={
            email: this.state.email, 
            producto: this.state.product,
            marca: this.state.marca,
            comentario: this.state.descripcion
        }


        try {
            const req=await postData("quotes",json,{})
            cleverItemNotFound(cleverData)
            this.props.close()
        } catch (error) {
            console.log(error)
            toastError("No se pudo enviar el mensaje")
            this.state({
                load:false
            })
        }
        
    }
    

    render() {
        const {load,marca,descripcion,product,email}=this.state
        return (
            <div>
                {
                    load?

                        <div className="row h-196 margin-zero">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                <h3>Enviando mensaje</h3>
                            </div>
                        </div>

                    :
                    <>
                        <h3 className="tac">¿Que te gustaría conseguir en fluio?</h3>
                        <form onSubmit={this.handleSubmit}>

                            <div className="form-group">
                                <label htmlFor="email" >Email:</label>
                                <input type="email" className="form-control" placeholder="Email" name="email" value={email || ''} onChange={this.handleChange} required/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="product" >Producto:</label>
                                <input type="text" className="form-control" placeholder="Producto" name="product" value={product || ''} onChange={this.handleChange} required/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="marca" >Marca</label>
                                <input type="text" className="form-control" placeholder="Marca" name="marca" value={marca || ''} onChange={this.handleChange} />
                            </div>

                            <div className="form-group">
                                <textarea 
                                    rows={3} 
                                    type="text" 
                                    className="form-control" 
                                    name="descripcion" 
                                    value={descripcion || ''} 
                                    onChange={this.handleChange}
                                    placeholder="Cuentanos sobre los productos que te gustaría conseguir en nuestra página"
                                    />
                            </div>


                            <button type="submit" className="btn w-100 btn-pay">
                                Enviar
                            </button>
                            
                        </form>
                    </>
                }
                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user:state.customer.data,
})

export default connect(
    mapStateToProps,
)(Sugerencias);
