import React, { Component } from 'react'
import Breadcrumb from "../common/breadcrumb";
import { PDFReader } from 'reactjs-pdf-reader';

export class PdT extends Component {
    render() {
        return (
            <div>
            <Breadcrumb title={'Política de tratamiento de datos'}/>
                <div className="container">
                    <p>
                    Fluio S.A.S (en adelante, “Fluio” o “la Compañía”) es respetuosa de los datos personales e información que le suministran sus clientes actuales, pasados y potenciales, aliados comerciales y posibles interesados en los productos de la compañía (en adelante, “los Titulares”). En la presente Política de Tratamiento de Datos Personales y Privacidad se establecen las finalidades, medidas y procedimientos de nuestras bases de datos, así como los mecanismos con los que cuentan los Titulares para conocer, actualizar y/o rectificar, suprimir los datos suministrados o revocar la autorización que se otorga con la aceptación de la presente Política. La adquisición de los productos y/o servicios ofrecidos en las plataformas virtuales que diseñemos (en adelante, “los Productos y/o Servicios”) y/o la aceptación expresa o inequívoca de las presentes Políticas, implica la aceptación de los Titulares de la presente Política y su autorización para los usos y otros tratamientos que aquí se describen.
                    </p>
                    <strong>
                    Responsable y Encargado del Tratamiento de Datos.
                    </strong>
                    <p>
                    El responsable del tratamiento de datos personales y otra información de los Titulares es la sociedad Fluio S.A.S., sociedad constituida conforme a las leyes de la República de Colombia, identificada con NIT. 901.323.943-5, domiciliada en Bogotá D.C., Colombia, en la Carrera 18 #91-68, apto. 102, con el número de teléfono (+57) 3107139466 y el correo electrónico info@fluio.com.co1.
                    </p>
                    <strong>
                        Definiciones
                    </strong>
                    <p>
                    Sin perjuicio de las definiciones que se desarrollen en este instrumento con respecto a un contexto particular, los términos que se enuncian a continuación se entenderán bajo las siguientes acepciones:
                    </p>
                    <br/>
                    <p>
                        (a)Dato personal: Cualquier información concerniente o vinculada a personas naturales determinadas o determinables.
                    </p>
                    <p>
                        (b)Titular del dato personal: Persona natural cuyos datos son objeto de tratamiento. En el contexto de la presente política de tratamiento de datos personales los titulares podrán ser: (i) suscriptores/Clientes de la plataforma; (ii) proveedores; (iii) empleados y/o ex empleados; (iv) todas aquellas personas no vinculadas a Fluio S.A.S de quien se haga tratamiento de los datos personales; (v) Contratistas de Fluio S.A.S. 
                    </p>
                    <p>
                        (c)Base de datos personales: Conjunto organizado de datos personales que son objeto de tratamiento por una persona natural o jurídica.
                    </p>
                    <p>
                        (d)Dato sensible: Es aquel dato personal que afecta la intimidad del Titular y cuyo uso incorrecto podría generar discriminación. Son considerados datos sensibles entre otros, los datos de salud, los datos de orientación sexual, origen racial y étnico, opiniones políticas, convicciones religiosas, filosóficas o morales.
                    </p>
                    <p>
                        (e)Dato privado: Es aquel dato personal que por su carácter íntimo o reservado es relevante para el Titular.
                    </p>
                    <p>
                        (f)Dato Semiprivado: Es aquel dato personal conocido y de interés tanto para el titular como para un determinado sector de personas o para la sociedad en general, por lo que no es de carácter íntimo, reservado o público.
                    </p>
                    <p>
                        (g)Dato público: Es aquel dato personal calificado como tal según la Constitución y la ley, y que no se ha clasificado como dato personal privado o semiprivado.
                    </p>
                    <p>
                        (h)Habeas data: Es la facultad que tiene el Titular de los datos personales de exigir de las administradoras de los mismos el acceso, inclusión, exclusión, corrección, adición, actualización y certificación de los datos, así como la limitación en su divulgación, publicación o cesión.
                    </p>
                    <p>
                        (i)Autorización: Consentimiento previo, expreso e informado del Titular para llevar a cabo el tratamiento de datos personales.
                    </p>
                    <p>
                        (j)Aviso de privacidad: Comunicación verbal o escrita dirigida a los Titulares de los datos personales que están siendo tratados por la empresa, en la cual se le informa acerca de la existencia de las políticas de tratamiento de datos personales que le serán aplicadas, la forma de acceder a la mismas, y las finalidades para las cuales serán usados sus datos personales.
                    </p>
                    <p>
                        (k)Responsable del tratamiento: Persona natural o jurídica de carácter público o privado que por sí misma o en asocio con otro u otros decide sobre el tratamiento de datos personales.
                    </p>
                    <p>
                        (l)Encargado del tratamiento: Persona natural o jurídica, de carácter público o privado que por sí misma o en asocio con otros, realiza el tratamiento de datos personales por cuenta del responsable.
                    </p>
                    <p>
                        (m)Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.
                    </p>
                    <p>
                        (n)Custodio de las bases de datos: Persona natural, dentro de la empresa, que custodia las bases de datos personales.
                    </p>
                    <p>
                        (o)Transmisión: Es la comunicación de los datos personales cuando tenga por objeto la realización de un tratamiento por parte del encargado por cuenta del responsable.
                    </p>
                    <p>
                        (p)Transferencia: Tiene lugar cuando el responsable y/o encargado del tratamiento de datos personales envía la información o los datos personales a un receptor, que a su vez es responsable del tratamiento y se encuentra dentro o fuera del país.
                    </p>
                    <p>
                        (q)Violación de datos personales: Es el delito tipificado en el artículo 269 del Código Penal. El cual establece: El que, sin estar facultado para ello, con provecho propio o de un tercero, obtenga, compile, sustraiga, ofrezca, venda, intercambie, envíe, compre, intercepte, divulgue, modifique o emplee códigos personales, datos personales contenidos en ficheros, archivos, bases de datos o medios semejantes, incurrirá en pena de prisión de cuarenta y ocho (48) a noventa y seis (96) meses y en multa de 100 a 1000 salarios mínimos legales mensuales vigentes.
                    </p>
                    <p>
                        (r)Formas de recabar los datos personales: Fluio S.A.S podrá conocer, recolectar, almacenar, administrar la información del titular de la información de conformidad con la política de uso de datos contenida en el presente documento a través de los siguientes medios: (i) Inscripción y uso de la aplicación móvil de Fluio S.A.S; (ii) Inscripción y uso de la página web de Fluio S.A.S; (iii) suscripción de cualquier tipo de contrato, alianza y/o convenio con Fluio S.A.S; (iv) Inscripción como proveedor de Fluio S.A.S.
                    </p>
                    <strong>Principios Rectores para el Trtamiento de Datos Personales</strong>
                    <p>
                    Serán principios rectores para el tratamiento de datos personales, aquellos establecidos en el Título II de la Ley Estatutaria 1581 de 2012. 
                    </p>
                    <strong>
                    Finalidad de la Base de Datos.
                    </strong>
                    <p>
                    El tratamiento de las bases de datos de Fluio tiene las siguientes finalidades:
                    </p>
                    <strong>
                        A. En relación con la base de datos de empleados, contratistas y aspirantes a empleados y ex empleados de Fluio:
                    </strong>
                    <p>
                        (i)Conservar y administrar la información de la relación laboral o comercial con los Titulares.
                    </p>
                    <p>
                        (ii)El cumplimiento de deberes legales, contables, comerciales y regulatorios. 
                    </p>
                    <p>
                        (iii)El control y la preservación de la seguridad de las personas, bienes e información de la compañía.
                    </p>
                    <p>
                        (iv)Cumplir el objeto de la relación laboral o civil que se hubiere adquirido con los Titulares.
                    </p>
                    <p>
                        (v)Proteger la salud de los empleados y contratistas de la compañía. 
                    </p>
                    <p>
                        (vi)Prevenir y constatar la comisión de delitos o conductas delictivas por parte de los empleados, contratistas y aspirantes, para lo cual se podrán consultar distintas bases de datos y fuentes, tales como, bases de datos de la Policía Nacional, Contraloría, Interpol, FBI, SDNT list (o “Lista Clinton”), SARLAFT, así como las redes sociales correspondientes, en la forma en la que se encuentren dispuestas. 
                    </p>
                    <p>
                        (vii)Mantener comunicación directa con los Titulares para temas relacionados con su relación laboral o comercial.
                    </p>
                    <p>
                        (viii)Selección de personal, administración de contrataciones, manejo de relaciones laborales y cumplimiento de las obligaciones derivadas de la misma, otorgamiento de beneficios a sus empleados por sí mismo o a través de terceros, así como permitir el acceso de los empleados a los recursos informáticos de la Compañía.
                    </p>
                    <p>
                        (ix)Llevar un registro de las sanciones disciplinarias impuestas a contratistas y empleados de la compañía. 
                    </p>
                    <p>
                        (x)La realización de análisis estadísticos, comerciales, financieros, sociales y técnicos. 
                    </p>
                    <p>
                        (xi)La comunicación con los titulares para efectos contractuales, informativos y comerciales.
                    </p>
                    <p>
                        (xii)Comprobación y verificación de la identidad y antecedentes penales, disciplinarios financieros y crediticios de los titulares.
                    </p>
                    <p>
                        (xiii)Transmitir, transferir y suministrar la información y datos personales de los Titulares a aquellos terceros encargados de administrar el sistema de seguridad social en Colombia, así como a compañías aseguradoras. 
                    </p>
                    <p>
                        (xiv) Transmitir, transferir y suministrar la información y datos personales de los Titulares a terceros, en aquellos casos en que se presente sustitución patronal o en aquellos casos en que la Compañía ceda su posición contractual. 
                    </p>
                    <p>
                        (xv)Transmitir, transferir y suministrar la información y datos personales de los Titulares a terceros, con el fin de dar referencias laborales y/o profesionales sobre los Titulares. 
                    </p>
                    <p>
                        (xvi)Guardar la memoria histórica  y antecedentes de los ex empleados.
                    </p>
                    <strong>
                        (b)En relación con la base de datos de clientes, proveedores y aliados comerciales.
                    </strong>
                    <p>
                        (i)El cumplimiento del objeto social de la compañía y del portal web, los cuales serán publicados en la plataforma virtual de Fluio. 
                    </p>
                    <p>
                        (ii)El desarrollo, ejecución y cumplimiento de la relación contractual que el titular tenga con la compañía. 
                    </p>
                    <p>
                        (iii)Cumplir con la propuesta de valor y el nivel de servicio ofrecido a cada segmento de clientes y proveedores. 
                    </p>
                    <p>
                        (iv)El cumplimiento de deberes legales, contables, comerciales y regulatorios. 
                    </p>
                    <p>
                        (v)La realización de análisis estadísticos, comerciales, estratégicos, financieros, sociales y técnicos. 
                    </p>
                    <p>
                        (vi)La comunicación con los titulares para efectos contractuales, informativos y comerciales. 
                    </p>
                    <p>
                        (vii)El control y la preservación de la seguridad de las personas, bienes e información de la compañía, para lo cual se podrán consultar distintas bases de datos y fuentes, tales como, bases de datos de la Policía Nacional, Contraloría, Interpol, FBI, SDNT list (o “Lista Clinton”), SARLAFT, centrales de riesgo crediticio, así como las redes sociales del Titular, en la forma en la que se encuentren dispuestas.
                    </p>
                    <p>
                        (viii)Comprobación y verificación de la identidad y antecedentes penales, disciplinarios financieros y crediticios de los titulares.
                    </p>
                    <p>
                        (ix) Transmitir, transferir y suministrar la información y datos personales de los Titulares a las sociedades subsidiarias, filiales o afiliadas a la compañía, aliados comerciales o a otras sociedades o personas nacionales y/o internacionales que la compañía encargue para realizar el tratamiento de la información y cumplir con las finalidades descritas en la presente Política y el objeto de la relación comercial o civil con los Titulares, o para que dichos terceros asuman la posición de Responsables. 
                    </p>
                    <p>
                        (x) Transmitir, transferir y suministrar, a título gratuito u oneroso, la información y datos personales de los Titulares a aliados comerciales nacionales y/o internacionales para que estos contacten a los Titulares para ofrecerles sus productos, información o servicios que a juicio de compañía puedan ser de interés del Titular. 
                    </p>
                    <p>
                        (xi) Transmitir, transferir y suministrar la información y datos personales de los Titulares a terceros nacionales y/o internacionales, en aquellos casos en que la compañía participe en procesos de fusión, integración, escisión, liquidación y/o enajenación de activos. 
                    </p>
                    <p>
                        (xii)Realizar actividades de mercadeo, como lo son estudios de mercado, y realizar actos de promoción de productos y servicios, entre otros conceptos similares.
                    </p>
                    <p>
                        (xiii)Ordenar, catalogar, clasificar, dividir o separar y almacenar los datos personales dentro de los sistemas y archivos de Fluio, siempre que ello se presente con las medidas de seguridad pertinentes y propias de la protección de los mismos. 
                    </p>
                    <p>
                        (xiv)Creación y administración de la cuenta del usuario, en caso de que exista necesidad comercial o tecnológica.
                    </p>
                    <p>
                        (xv)restar el mantenimiento, desarrollo y/o control de la relación comercial entre el Titular del dato personal y Fluio.
                    </p>
                    <p>
                        (xvi) Proveer a los usuarios, la información necesaria, a través de la página o portal Web y/o la aplicación móvil, sobre los Productos y/o Servicios de los oferentes, para formalizar la relación de consumo de dichos productos, entre ambas partes.
                    </p>
                    <p>
                        (xvii) Realizar procesos al interior de la empresa, con fines de desarrollo operativo y/o de administración de sistemas.
                    </p>
                    <p>
                        (xviii) Prestar los servicios de la empresa y realizar el seguimiento de acuerdo con las necesidades particulares del usuario, con el fin de brindar los servicios y productos adecuados para cubrir sus necesidades específicas.
                    </p>
                    <p>
                    (xix) Realizar el envío de información de novedades, noticias, boletines, foros de educación, publicidad o marketing, ventas a distancia, en uso de medios tales como: correo electrónico, notificaciones PUSH, mensajes de texto (SMS), ofertas de productos y/o servicios encontrados en la página web y/o la aplicación. 
                    </p>
                    <p>
                        (xx) Llevar un registro histórico de la información, en aras de satisfacer las necesidades cambiantes del usuario o Cliente, desarrollando análisis sobre los intereses actuales y brindando de esta manera un mejor servicio. 
                    </p>
                    <p>
                        (xxi) Realizar estrategias de mercado mediante el estudio del comportamiento del usuario frente a las ofertas que se envían o a las que tiene acceso y, con ello, mejorar en su contenido particular y personalizado.
                    </p>
                    <p>
                        (xxii)Proyección de prospecciones comerciales y segmentación de mercados
                    </p>
                    <p>
                        (xxiii) Realizar encuestas de satisfacción.
                    </p>
                    <p>
                        (xxiv) Ofrecer o reconocer beneficios propios de nuestro programa de lealtad y servicio postventa, para calificar el servicio y la atención al usuario, utilizando los canales dispuestos para ello. 
                    </p>
                    <p>
                        (xxv) Presentar reportes ante las autoridades de inspección, vigilancia y control, y tramitar los requerimientos realizados por entidades administrativas o judiciales.
                    </p>
                    <p>
                        (xxvi) Gestión contable, económica, fiscal y administrativa de clientes.
                    </p>
                    <p>
                        (xxvii) Tener acceso a centrales de riesgo para conocer los estados financieros de los clientes.
                    </p>
                    <p>
                        (xxviii)Transferencia o transmisión de datos nacional o internacionalmente a proveedores con los que la Compañía desarrolle actividades en cumplimiento de su objeto social. Asimismo, se podrá hacer transferencia a los aliados estratégicos de la empresa para que ejecuten actividades de marketing, publicidad y promociones asociadas con el objeto social; todo de acuerdo con las disposiciones de la normativa colombiana. 
                    </p>
                    <p>
                        (xxix) Remitir información a los Encargados del tratamiento para facilitar y mejorar la calidad del servicio de Fluio.
                    </p>
                    <p>
                        (xxx) Reportes a centrales de riesgo por incumplimiento de las obligaciones financieras derivadas de la relación comercial
                    </p>
                    <p>
                        (xxxi) Solicitar la autorización de cobro ante las entidades definidas y autorizadas para ello.
                    </p>
                    <strong>c. En relación con la base de datos de clientes finales potenciales.</strong>
                    <p>
                        (i) El cumplimiento del objeto social de la compañía y del portal web, los cuales serán publicados en la plataforma virtual de Fluio. 
                    </p>
                    <p>
                        (ii) La realización de análisis estadísticos, comerciales, estratégicos, financieros, sociales y técnicos. 
                    </p>
                    <p>
                        (iii) Realizar actividades de mercadeo, como lo son estudios de mercado, y realizar actos de promoción de productos y servicios, entre otros conceptos similares. 
                    </p>
                    <p>
                        (iv) La comunicación con los Titulares para efectos comerciales. 
                    </p>
                    <strong>
                        d. En relación con la base de datos de proveedores independientes potenciales.
                    </strong>
                    <p>
                        (i)El cumplimiento del objeto social de la compañía y del portal web, los cuales serán publicados en la plataforma virtual de Fluio. 
                    </p>
                    <p>
                        (ii) La realización de análisis estadísticos, comerciales, estratégicos, financieros, sociales y técnicos. 
                    </p>
                    <p>
                        (iii)Realizar actividades de mercadeo, como lo son estudios de mercado, y realizar actos de promoción de productos y servicios, entre otros conceptos similares. 
                    </p>
                    <p>
                        (iv) La comunicación con los titulares para efectos comerciales. 
                    </p>
                    <strong>
                    Datos Personales suministrados y forma de obtención.
                    </strong>
                    <p>
                    La Compañía podrá, o bien solicitar expresamente a los Titulares los datos que sean necesarios para cumplir la finalidad de las Bases de Datos descritas en el acápite inmediatamente anterior, o bien recolectar, a partir de su comportamiento, los mismos. Los Datos Personales suministrados pueden variar, conforme a la calidad particular del Titular, así como también lo puede hacer la forma de su obtención, conforme a las disposiciones que se sostienen a continuación: 
                    </p>
                    <strong>
                        a. En relación con la base de datos de empleados y contratistas: 
                    </strong>
                    <p>
                    Nombre y apellidos, nacionalidad, estado civil, número de identificación, libreta militar, tarjeta profesional, huella dactilar, caligrafía, fecha y lugar de nacimiento, estado civil, dirección de correspondencia, teléfono de contacto, correo electrónico, historial laboral, clínico o de salud, académico y patrimonial, referencias, antecedentes comerciales o información financiera, judiciales, disciplinarios y familiares con otras compañías o con entidades públicas, fotografías recientes, imágenes en cámaras de vigilancia; historia clínica ocupacional. 
                    </p>
                    <p>
                    Nombre, número de identificación, teléfono, sexo, fecha y lugar de nacimiento, lugar de trabajo cargo o profesión del cónyuge o compañero permanente de empleados y contratistas y de sus parientes hasta el cuarto grado de consanguinidad, segundo de afinidad y/o primero civil, y cualquier otro dato que fuere necesario para lograr las finalidades descritas. 
                    </p>
                    <p>
                    La obtención de estos datos puede darse por medio de las hojas de vida, contratos de trabajo o prestación de servicios, formato de ingreso o de solicitud de empleo, entrevistas de trabajo, mediante formatos de afiliación a las distintas entidades de Seguridad Social, entre otros actos que permitan el acceso a esta información y que se encuentren previamente validados por el empleado y/o contratista.
                    </p>
                    <strong>
                        b. En relación con las bases de datos de clientes, proveedores, aliados comerciales:
                    </strong>
                    <p>
                    Nombre y apellidos, número de identificación, fecha de nacimiento, dirección de correspondencia, teléfono de contacto, correo electrónico, fotografías publicadas en su perfil, nombres, raza, edad, hábitos de consumo , historial de navegación en el sitio,  historial de compras, historial de casos de abiertos con servicio al cliente, contenido favorito, intención de asistencia a eventos, historial de preguntas a expertos en el site, antecedentes comerciales, judiciales, relaciones comerciales y familiares con otras compañías o con entidades públicas, necesidades e intereses, lugar de trabajo, contenido creado para su publicación en el site, razón social de la empresa, identificación tributaria y/o mercantil, descripción, fotografías y videos de la empresa, descripción, fotografías y videos del portafolio de servicios y productos inscritos para la venta, precios de productos y servicios, inventarios de los productos inscritos, agenda disponible de los servicios, historial de pedidos recibidos, calificación de satisfacción recibida, indicadores de desempeño operativo, comentarios de clientes recibidos, inversión promocional, incentivos a la compra causados, premios entregados.
                    </p>
                    <strong>
                        c. En relación con la base de datos de clientes finales potenciales compradas a terceros, conseguidas en el contexto de alianza comercial o conformada por usuarios del sitio web que aún no han comprado en el mismo.
                    </strong>
                    <p>
                    Nombre y apellidos, número de identificación, fecha de nacimiento, dirección de correspondencia, teléfono de contacto, correo electrónico, fotografías publicadas en su perfil, nombres, raza, edad, hábitos de consumo, historial de navegación en el sitio, contenido favorito intención de asistencia a eventos, historial de preguntas a expertos en el site, contenido creado para su publicación en el site.
                    </p>
                    <strong>
                        d. En relación con la base de datos de proveedores independientes potenciales compradas a terceros, conseguidas en el contexto de alianza comercial o conformada por usuarios del sitio web que aún no han aplicado para vender en el mismo.
                    </strong>
                    <p>
                    Razón social la empresa, identificación tributaria y/o mercantil, dirección de correspondencia, teléfono de contacto, correo electrónico, nombre y apellidos de contacto, número de identificación del contacto, antecedentes comerciales, judiciales, relaciones comerciales y familiares con otras compañías o con entidades públicas, necesidades e intereses, historial de casos de abiertos con servicio al cliente.
                    </p>
                    <strong>
                        e. En relación con datos de carácter sensible y su tratamiento.
                    </strong>
                    <p>
                    De acuerdo con lo dispuesto en la ley 1581 de 2012, son considerados datos de carácter sensible los siguientes: origen racial o étnico, orientación política, convicciones religiosas o filosóficas, pertenencia a sindicatos, organizaciones sociales, datos relacionados con el estado de salud, la vida sexual y los datos biométricos. 
                    </p>
                    <p>
                    Teniendo en cuenta las características de la Compañía, las actividades que desarrolla, y las finalidades descritas en la presente Política, la Compañía requiere realizar tratamiento de algunos datos sensibles, en la forma y condiciones que se indican a continuación. 
                    </p>
                    <strong>
                        i. Información sensible relacionada con el estado de salud.  
                    </strong>
                    <p>
                    La Compañía recopilará y hará tratamiento de los datos de salud de sus empleados y contratistas. Estos datos son de carácter sensible, por lo cual, de conformidad con lo dispuesto en las normas vigentes, se informa a los Titulares que no están obligados a suministrar los referidos datos o a autorizar su tratamiento. Una vez suministrados dichos datos y otorgado el correspondiente consentimiento, dichos datos serán recopilados y tratados únicamente para preservar y garantizar el bienestar de los Titulares. 
                    </p>
                    <strong>
                        ii. Datos biométricos. 
                    </strong>
                    <p>
                    La Compañía recopilará datos biométricos de sus empleados, contratistas y visitantes, tales como la huella dactilar. Al igual que en el caso de la información sobre el estado de salud, estos datos son de carácter sensible, y, por ello, los Titulares no están obligados a suministrarlos. La Compañía garantizará que el tratamiento de estos datos se hará conforme a la ley y bajo estrictas medidas de seguridad. 
                    </p>
                    <strong>
                        iii. Datos relacionados con menores de edad. 
                    </strong>
                    <p>
                    La Compañía solamente usará, almacenará y realizará tratamiento de datos personales de menores de edad que sean hijos, descendientes o que dependan o estén a cargo de los empleados o contratistas de la compañía, y que respondan a datos personales de naturaleza pública y/o aquellos que se requieran para garantizar su bienestar. La finalidad de dicho tratamiento será únicamente la de planear y realizar actividades relacionadas con el bienestar personal y familiar de los empleados y los menores. 
                    </p>
                    <p>
                    En el mismo sentido, y únicamente bajo autorización expresa de los padres o quienes ostenten la potestad para otorgarla, la Compañía dará tratamiento a los datos menores de las bases de datos de clientes y/o aliados estratégicos, para asuntos de actividades recreativas y/o de entretenimiento, enfocadas exclusivamente a su bienestar. 
                    </p>
                    <p>
                    Para tales efectos, la compañía tendrá en cuenta el respeto y prevalencia de los derechos de los menores, su interés superior y sus derechos fundamentales.
                    </p>
                    <strong>
                        f. Formas comunes de recolectar los datos. 
                    </strong>
                    <p>
                    La recolección de los datos personales de los sujetos que se identificaron en los apartes antes anteriores también pueden implicar los siguientes medios: (a) almacenamiento automático de los datos de los usuarios que acceden a la plataforma virtual de Fluio S.A.S. por el uso de cookies; (b) transmisión o transferencia de datos por partes de aliados estratégicos; (c) intercambio de correos electrónicos; (d) eventos realizados por Fluio; (e) con el acceso a las plataformas virtuales de Fluio, bien sea la página o portal Web, bien a través de la aplicación móvil; (f) llamadas telefónicas.
                    </p>
                    <strong>
                    Autorización para Recolección y Tratamiento de Datos Personales y Otra Información
                    </strong>
                    <p>
                    Mediante el suministro voluntario de alguno de los datos personales en la forma señalada en el numeral anterior y/o la autorización expresa verbal o por escrito, el Titular autoriza expresa e inequívocamente a la Compañía para (i) recolectar datos personales y cualesquiera otra información que suministre, y (ii) realizar el tratamiento sobre sus datos personales, de conformidad con esta Política y la ley.
                    </p>
                    <strong>
                    Tratamiento de los Datos Personales almacenados en las bases de datos de Fluio.
                    </strong>
                    <p>
                    La Compañía solo usará, procesará y circulará los datos personales y otra información de los Titulares para las finalidades descritas y para los tratamientos autorizados en esta Política de Tratamiento o en las leyes vigentes. En adición a lo mencionado en otras cláusulas, el Titular expresamente autoriza a la Compañía para la recolección, uso y circulación de sus datos personales y otra información para los propósitos que a continuación se describen y, exclusivamente, bajo las circunstancias que allí se enuncian: 
                    </p>
                    <p>
                        1. Establecer comunicación entre la Compañía y los Titulares para cualquier propósito relacionado con las finalidades que se establecen en la presente Política, ya sea mediante llamadas, mensajes de texto, correos electrónicos y/o físicos.
                    </p>
                    <p>
                        2. Auditar, estudiar y analizar la información de las Bases de Datos para diseñar y ejecutar estrategias administrativas, laborales, de seguridad y financieras relacionadas con el personal de la Compañía. 
                    </p>
                    <p>
                        3. Suministrar la información y datos personales de los Titulares a las sociedades subsidiarias, filiales o afiliadas a la compañía, aliados comerciales o a otras sociedades o personas que la compañía encargue para realizar el tratamiento de la información y cumplir con las finalidades descritas en la presente Política y el objeto de la relación laboral o civil con los Titulares. 
                    </p>
                    <p>
                        4. Con el fin de preservar la seguridad de la Compañía, analizar y verificar la información de los empleados y colaboradores de la compañía y de aquellos que participen en procesos de selección. 
                    </p>
                    <p>
                        5. Transferir, transmitir y suministrar, a título gratuito u oneroso, la información y datos personales de los Titulares a aliados comerciales nacionales y/o extranjeros para que estos contacten a los Titulares para ofrecerles sus productos, información o servicios que a juicio de la Compañía puedan ser de interés del Titular. 
                    </p>
                    <p>
                         6. Transferir, transmitir y suministrar la información y datos personales de los Titulares a terceros, en aquellos casos en que la Compañía participe en procesos de fusión, integración, escisión y/o liquidación. 
                    </p>
                    <p>
                        7. Verificar conflictos de intereses o posibles irregularidades en los nuevos contratistas y/o empleados de la Compañía. 
                    </p>
                    <p>
                        8. Realizar calificación de riesgo financiero, jurídico, comercial y de seguridad.
                    </p>
                    <p>
                        9. Consultar, almacenar y usar la información financiera obtenida de terceros administradores de bases de datos, previa autorización del Titular para dicha consulta.
                    </p>
                    <p>
                        10. Combinar los datos personales con la información que se obtenga de otros aliados o compañías o enviarla a los mismos para implementar estrategias comerciales conjuntas.
                    </p>
                    <p>
                        11. Cuando la información deba ser revelada para cumplir con leyes, regulaciones o procesos legales, en aras de asegurar el cumplimiento de los términos y condiciones, para detener o prevenir fraudes, ataques a la seguridad de la Compañía o de otros, prevenir problemas técnicos o proteger los derechos de otros. 
                    </p>
                    <p>
                        12. Auditar, estudiar y analizar la información de las Bases de Datos para diseñar estrategias comerciales y aumentar y/o mejorar los Productos y Servicios que ofrece la Compañía. 
                    </p>
                    <p>
                        13. Auditar, estudiar, analizar y utilizar la información de la Base de Datos para diseñar, implementar y desarrollar programas, proyectos y eventos.
                    </p>
                    <p>
                        14. Auditar, estudiar, analizar y utilizar la información de la Base de Datos para la socialización de políticas, proyectos, programas, resultados y cambios organizacionales.
                    </p>
                    <p>
                        15. Transmitir, transferir y suministrar la información y datos personales de los Titulares a aliados estratégicos nacionales y/o extranjeros para que estos contacten a los Titulares para ofrecerles bienes y servicios de su interés, recibir ofertas de los titulares, invitar a la participación en programas, proyectos, eventos, socializar políticas, proyectos, programas, resultados y cambios organizacionales.
                    </p>
                    <p>
                        16. Vender o ceder los datos a terceros nacionales y/o extranjeros, previo cumplimiento de la regulación correspondiente. 
                    </p>
                    <p>
                        17. Realizar actividades de mercadeo de los Productos ofrecidos. 
                    </p>
                    <p>
                        18. Informar sobre el alcance y características de los Productos de la Compañía.
                    </p>
                    <p>
                        19. Cualquier otra finalidad que se enmarque en las finalidades ya expuestas y que se relacione con el objeto social de la Compañía y su actividad.
                    </p>
                    <strong>
                    Solicitud de Autorización para Nuevos Usos.
                    </strong>
                    <p>
                    La Compañía podrá solicitar autorización de los Titulares para el uso o circulación de sus datos o información para propósitos diferentes a los expresados en la presente Política de Privacidad y en los Términos y Condiciones, para lo cual publicará los cambios en la presente Política de Tratamiento en su página web [•] o [•] o en cualquier medio que estime conveniente según el caso.
                    </p>
                    <strong>
                    Almacenamiento de Datos Personales.
                    </strong>
                    <p>
                    El Titular autoriza expresamente a la compañía para que almacene los datos de la forma que considere más oportuna y cumpla con la seguridad requerida para la protección de los datos de los Titulares.
                    </p>
                    <strong>
                    Medidas de Seguridad para la Protección de los Datos Personales y Otra Información.
                    </strong>
                    <p>
                    Las medidas de seguridad con las que cuenta la compañía buscan proteger los datos de los Titulares en aras de impedir su adulteración, pérdida, usos y accesos no autorizados. Para ello, la compañía de forma diligente implementa medidas de protección humanas, administrativas y técnicas que razonablemente están a su alcance. El Titular acepta expresamente esta forma de protección y declara que la considera conveniente y suficiente para todos los propósitos.
                    </p>
                    <strong>
                    Derechos de los Titulares.
                    </strong>
                    <p>
                    La Compañía informa a sus titulares que, conforme a la legislación vigente, estos tienen el derecho de conocer, actualizar, rectificar su información, y/o revocar la autorización para su tratamiento. En particular, son derechos de los titulares según se establece en el artículo 8 de la Ley 1581 de 2012: 
                    </p>
                    <p>
                        a. Conocer, actualizar y rectificar sus datos personales. 
                    </p>
                    <p>
                        b. Solicitar prueba de la autorización otorgada. 
                    </p>
                    <p>
                        c. Ser informado, previa solicitud, respecto del uso que les ha dado a sus datos personales.
                    </p>
                    <p>
                        d. Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la ley.
                    </p>
                    <p>
                        e. Revocar la autorización y/o solicitar la supresión del dato.
                    </p>
                    <p>
                        f. Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.
                    </p>
                    <strong>
                        i. Área encargada de peticiones, consultas y reclamos.
                    </strong>
                    <p>
                    El área encargada de atender las peticiones, consultas y reclamos de los Titulares para ejercer sus derechos a conocer, actualizar, rectificar y suprimir sus datos y revocar su autorización es [•]. 
                    </p>
                    <strong>
                        ii. Procedimiento para ejercer sus derechos.
                    </strong>
                    <p>
                    En caso de que desee ejercer sus derechos, el Titular deberá enviar un correo electrónico o una petición en físico a las direcciones de contacto establecidas en la presente Política de Tratamiento y Protección de Datos Personales. El procedimiento que se seguirá para la atención de dichas comunicaciones, serán los que se indican a continuación:
                    </p>
                    <p>
                    Peticiones y Consultas sobre Datos Personales. Cuando el Titular de los datos o sus causahabientes deseen consultar la información que reposa en la base de datos de Fluio, la Compañía responderá la solicitud en plazo de máximo diez (10) días. En cumplimiento a lo dispuesto en la Ley 1581 de 2012, cuando no fuere posible atender la consulta dentro de dicho término, se informará a los Titulares, se le expresará los motivos de la demora y se le señalará la fecha en que se atenderá su consulta, la cual no podrá, para todos los efectos, superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
                    </p>
                    <p>
                    Revocación de autorización, retiro o supresión de la Base de Datos y reclamos sobre Datos Personales. Cuando el Titular de los datos o sus causahabientes consideren que la información contenida en las bases de datos debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley 1581 de 2012 con respecto a las obligaciones de la Compañía; podrán presentar un reclamo ante la Compañía, el cual será tramitado bajo las siguientes reglas: (a) El reclamo se formulará mediante solicitud dirigida a la Compañía con la identificación de los Titulares, la descripción de los hechos que dan lugar al reclamo, la dirección y se anexarán los documentos que se quieran hacer valer. Si el reclamo resulta incompleto, la Compañía podrá requerir al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo. 
                    </p>
                    <p>
                    En caso de que la Compañía no sea competente para resolver el reclamo, dará traslado, a quien corresponda, en un término máximo de dos (2) días hábiles e informará de la situación al Titular, con lo cual quedará relevada de cualesquiera reclamaciones o responsabilidades por el uso, rectificación o supresión de los datos. 
                    </p>
                    <p>
                    Así, una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.
                    </p>
                    <p>
                    Ahora, el término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al Titular los motivos de la demora y la fecha en que se atenderá su reclamo, la cual, en ningún caso, podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término. El retiro o supresión no procederá cuando exista un deber contractual de permanecer en la base de datos de Fluio.
                    </p>
                    <strong>
                    Transmisión y/o Transferencia Nacional o Internacional
                    </strong>
                    <p>
                    Fluio podrá compartir la información de los datos personales con aquellos terceros que sea necesario para el desarrollo de sus actividades y objeto social, siempre protegiendo los derechos e información del titular del dato.
La Transmisión o Transferencia de Datos Personales que se realice observará las reglas que para tal efecto disponga la normatividad aplicable y la autoridad de control, especialmente las siguientes:
                    </p>
                    <p>
                        *Cuando se trate de transmisiones o transferencias nacionales de datos personales, Fluio S.A.S. se asegurará del cumplimiento de las exigencias de la legislación de protección de datos vigente y las medidas de protección por parte del encargado o nuevo responsable, según sea el caso.
                    </p>
                    <p>
                        * De tratarse de una transferencia internacional se deberá asegurar que el país receptor de los datos personales proporcione niveles adecuados de protección, de la forma que establezca la Autoridad de Control en Colombia, para que la misma profiera la declaración de conformidad a la que se refiere el parágrafo primero del artículo 26 de la Ley 1581 de 2012. Cuando el país receptor no cumpla con los estándares adecuados de protección de datos, la transmisión o transferencia quedará prohibida a menos que se configure alguna de las siguientes excepciones legales:
                    </p>
                    <p>
                        (i) Que el Titular haya dado autorización expresa e inequívoca para la transferencia o transmisión de datos.
                    </p>
                    <p>
                        (ii) Intercambio de datos de carácter médico cuando así lo exija el tratamiento del Titular por razones de salud e higiene pública. 
                    </p>
                    <p>
                        (iii) Transferencias bancarias o bursátiles, conforme a la legislación que les resulte aplicable.
                    </p>
                    <p>
                        (iv) Transferencias acordadas en el marco de tratados internacionales en los cuales Colombia sea parte, con fundamento en el principio de reciprocidad
                    </p>
                    <p>
                        (v) Transferencias necesarias para la ejecución de un contrato entre el Titular y el responsable del tratamiento, o la ejecución de medidas precontractuales siempre y cuando se cuente con autorización del Titular.
                    </p>
                    <strong>
                    Contacto
                    </strong>
                    <p>
                    Cualquier duda o información adicional, será recibida y tramitada mediante su envío a las direcciones de contacto establecidas en la presente Política de Tratamiento y Protección de Datos Personales.
                    </p>
                    <strong>
                    Período de Vigencia de la Base de Datos.
                    </strong>
                    <p>
                    Los datos personales incorporados en las Bases de Datos estarán vigentes durante el plazo necesario para cumplir sus finalidades.
                    </p>
                    <strong>
                    Cambios en la Política de Tratamiento y Protección de datos Personales.
                    </strong>
                    <p>
                    Cualquier cambio sustancial en las Políticas de Tratamiento, será comunicado oportunamente a los Titulares mediante la publicación en nuestros portales web.
                    </p>
                    <strong>
                    Legislación Vigente.
                    </strong>
                    <p>
                    La legislación nacional vigente en materia de protección de datos personales está contenida en la Ley 1581 de 2012, el Decreto 1377 de 2013 y la Ley 1266 de 2008, así como en las normas que lo modifiquen o complementen.
                    </p>

                </div>
            </div>
        )
    }
}

export default PdT
