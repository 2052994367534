import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { dataLogout, clearOrden } from "../../../../actions/index";
import cartIcon from "../.././../../assets/images/cart.png";
import { getCartLength } from "../../../../servicios";
import { getLocationText, isLocation } from "../../../../funciones/cities";
import Modal from "react-responsive-modal";
import LocationForm from "../../../Modals/LocationForm";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLocation: false,
    };
  }

  closeLocation = () => {
    this.setState({
      openLocation: false,
    });
  };

  openLocation = (e) => {
    e.preventDefault();
    this.setState({
      openLocation: true,
    });
  };

  goHome = (e) => {
    e.preventDefault();
    window.location.replace(process.env.PUBLIC_URL + "/");
  };

  logout = (e) => {
    e.preventDefault();

    const { dataLogout, clearOrden } = this.props;
    clearOrden();
    dataLogout();
    this.props.history.push(process.env.PUBLIC_URL + "/");
  };

  linkHome = (e) => {
    e.preventDefault();
    this.props.history.push(process.env.PUBLIC_URL + "/");
  };

  linkProfile = (e) => {
    e.preventDefault();
    this.props.history.push(process.env.PUBLIC_URL + "/profile");
  };

  linkOrders = (e) => {
    e.preventDefault();
    this.props.history.push(process.env.PUBLIC_URL + "/orders");
  };

  linkLogin = (e) => {
    e.preventDefault();
    this.props.history.push(process.env.PUBLIC_URL + "/login");
  };

  linkRegister = (e) => {
    e.preventDefault();
    this.props.history.push(process.env.PUBLIC_URL + "/register");
  };

  linkCart = (e) => {
    e.preventDefault();
    this.props.history.push(process.env.PUBLIC_URL + "/cart");
  };

  render() {
    const { customer, dataLogout, tamaño } = this.props;

    return (
      <>
        <div className="top-header mb-display">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="header-contact">
                  <ul>
                    <li>Bienvenido a Fluio</li>
                    {/*
                                    <li><i className="fa fa-phone" aria-hidden="true"></i>Contáctanos al:  123 - 456 - 7890</li>
                                    */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 text-right">
                <ul className="header-dropdown">
                  <li className="mobile-wishlist nav-style mb-none">
                    <Link className="ttn" to={`${process.env.PUBLIC_URL}/`}>
                      Home <i className=" fa fa-home" />
                    </Link>
                  </li>
                  <li className="onhover-dropdown mobile-account nav-style mb-none">
                    <i className="fa fa-user" aria-hidden="true" />{" "}
                    {customer ? customer.name : "Mi Cuenta"}
                    <ul className="onhover-show-div">
                      {customer ? (
                        <Fragment>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/profile`}
                              data-lng="en"
                            >
                              Perfil
                            </Link>
                          </li>
                          <li className="mb-none">
                            <Link
                              to={`${process.env.PUBLIC_URL}/orders`}
                              data-lng="en"
                            >
                              Mis pedidos
                            </Link>
                          </li>
                          <li>
                            <Link onClick={this.logout} to="#" data-lng="en">
                              Cerrar sesión
                            </Link>
                          </li>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/login`}
                              data-lng="en"
                            >
                              Login
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`${process.env.PUBLIC_URL}/register`}
                              data-lng="en"
                            >
                              Registro
                            </Link>
                          </li>
                        </Fragment>
                      )}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-none mt-5">
          <div className="row">
            <div className="col-4" />

            <div className="col-4">
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/assets/images/icon/fluio-logo.JPG`}
                alt="logo"
                className="img-fluid i-footer mb-fix-logo top-logo"
              />
            </div>

            <div className="col-4  mt-4">
              <div className="top-location-pos">
                <a href="#" onClick={this.openLocation}>
                  <i className="fa fa-map-marker location-icon" />
                  {this.props.locationText}
                </a>
              </div>
              <div className="d-flex top-button-pos">
                <div>
                  <div>
                    <i
                      className="fa fa-home top-home-icon"
                      onClick={this.linkHome}
                    />
                  </div>
                </div>

                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle top-drop"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Mi cuenta
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {customer ? (
                      <>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={this.linkProfile}
                        >
                          Perfil
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={this.linkOrders}
                        >
                          Mis pedidos
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={this.logout}
                        >
                          Cerrar sesión
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={this.linkLogin}
                        >
                          Login
                        </a>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={this.linkRegister}
                        >
                          Registro
                        </a>
                      </>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-secondary btn-top"
                    onClick={this.linkCart}
                  >
                    <img src={cartIcon} alt="cart" />
                    <span>{tamaño}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.openLocation}
          onClose={this.closeLocation}
          center
          classNames={{
            modal: "modal-mobile-style",
            closeButton: "close-forgotten",
          }}
          showCloseIcon={false}
          closeOnOverlayClick={false}
        >
          <LocationForm close={this.closeLocation} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customer.data,
  tamaño: getCartLength(),
  locationText: getLocationText(),
});

export default connect(
  mapStateToProps,
  { dataLogout, clearOrden }
)(withRouter(TopBar));
