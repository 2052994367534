import React, { Component } from 'react';
import { formatNumber, priceNumber } from '../../../funciones/general';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastSuccess } from '../../../alertas';
import ShareButton from '../../hybrid/ShareButton';

export class PriceListingModal extends Component {
    onCopy = () => {
        toastSuccess('Enlace copiado');
    };

    render() {
        const { product } = this.props;

        return (
            <>
                {product.if_offer ? (
                    <>
                        <h3 className="mb-none">
                            ${formatNumber(product.price_offer)}
                            <del>
                                <span className="money old-price-space">${product.price}</span>
                            </del>
                            <ShareButton product={product} />
                        </h3>

                        <div className="modal-prod-price mb-display">
                            $ {formatNumber(product.price_offer)}
                            <del className="old-price-space mr-1">${formatNumber(product.price)}</del>
                            cop
                            <ShareButton product={product} />
                        </div>
                    </>
                ) : (
                    <>
                        <h3 className="mb-none">
                            ${formatNumber(product.price)}
                            <ShareButton product={product} />
                        </h3>

                        <div className="modal-prod-price mb-display">
                            $ {formatNumber(product.price)}
                            cop
                            <ShareButton product={product} />
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default PriceListingModal;
