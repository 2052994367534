import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { filterCategory, updateHeaders, dataLogout, updateLoadHome } from '../../actions';
import store from '../../store';
import { LazyImage } from 'react-lazy-images';
import Modal from 'react-responsive-modal';
import Sugerencias from '../Sugerencias';
import ProductosSugeridos from '../ProductosSugeridos';
import ContactIcons from '../common/ContactIcons';
import SocialIcons from '../common/SocialIcons';
import TagsSlider from '../common/TagsSlider';
import HomeCategories from '../common/HomeCategories';
import { cleverSearch } from '../../funciones/clevertap';

class HomeBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorias: [],
            text: '',
            open: false,
            openProductos: true,
        };
        this.goBycategory = this.goBycategory.bind(this);
        this.cargaHome = this.cargaHome.bind(this);
    }

    componentDidMount() {
        this.cargarCategorias();
    }

    async cargarCategorias() {
        this.setState({
            categorias: this.props.categories,
        });
    }

    openModal = (e) => {
        e.preventDefault();
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    openProductos = (e) => {
        e.preventDefault();
        this.setState({ openProductos: true });
    };

    closeProductos = () => {
        this.setState({ openProductos: false });
    };

    goBycategory(category) {
        if (category.tipo == 2 || category.tipo == 6) {
            this.props.history.push('/aliados/' + category.id);
        } else if (category.jerarquia == 1) {
            this.props.history.push('/menu-huerta');
        } else {
            this.props.history.push('/pasillos/' + category.id);
        }
    }

    goByTag = (e, tag) => {
        e.preventDefault();
        this.props.history.push('/pasillos-tag/' + tag.id);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.filterCategory([]);
        cleverSearch(this.state.text);
        this.props.history.push('/productos/' + this.state.text);
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    cargaHome() {
        const { categories } = this.props;

        if (categories.length == 0) {
            setTimeout(() => this.cargaHome(), 2000);
        }
    }

    componentDidMount() {
        //refreshToken()
        //cargarDatos()
        this.cargaHome();

        setTimeout(() => {
            if (store.getState().data.products.length == 0) {
                window.location.reload();
            }
        }, 240000);
    }

    /**
    async componentDidMount(){

        if(this.props.user){

            try{

                const config={
                    headers: store.getState().customer.headers
                }

                console.log(config)

                const response= await getData("customer_auth/validate_token/",config)
                console.log(response)
                this.props.updateHeaders(response.config.headers)

                
                
            }catch(err){
                console.log(err.response)
                //this.props.dataLogout()
            }

        }
        
    }
     */

    searchFocus = () => {
        this.setState({
            openProductos: false,
        });
    };

    deleteText = () => {
        this.setState(
            {
                text: '',
            },
            () => {
                document.getElementById('search2').focus();
            }
        );
    };

    render() {
        const { text } = this.state;
        const { load, products, user, ordenes, categories } = this.props;

        return (
            <div className="bg-white">
                <div className="slide-1 home-slider mb-none">
                    <div className="" style={{ backgroundImage: `url(${'/assets/images/home.jpg'})` }}>
                        <div className="">
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <div className="w-100">
                                            <form onSubmit={this.handleSubmit}>
                                                <span className="fa fa-search home-sicon" />
                                                <div className="form-group margin-zero">
                                                    <input
                                                        type="search"
                                                        name="text"
                                                        className="form-control center-home-s tal home-isearch"
                                                        placeholder="¿Qué quieres hoy?"
                                                        onChange={this.handleChange}
                                                        autoComplete="off"
                                                        required
                                                    />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="somos-con tac mb-none">
                    <div className="title">¿Quiénes somos?</div>
                    <div>
                        <p className="ttn text">
                            Creemos que un mundo mejor es posible y que todos tenemos algo que aportar. Nuestra misión
                            es facilitarte ese aporte a la ecología, a tu salud y a la sociedad.
                        </p>
                    </div>
                </div>

                <form onSubmit={this.handleSubmit} className="search-style mb-display">
                    <div className=" has-search mb-search search-h">
                        <input
                            id="search2"
                            onFocus={this.searchFocus}
                            type="search"
                            name="text"
                            className="pl-3 form-control br-r-25 se-input input-seach-style"
                            placeholder="¿Qué quieres hoy?"
                            onChange={this.handleChange}
                            autoComplete="off"
                            required
                            value={text}
                        />
                        {text ? (
                            <span className="fa fa-times form-control-feedback se-span" onClick={this.deleteText} />
                        ) : (
                            <span className="fa fa-search form-control-feedback se-span" />
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    filters: state.filters,
    categories: state.categories.categories,
    user: state.customer.data,
    headers: state.customer.headers,
    load: state.customer.load_home,
    products: state.data.products,
    ordenes: state.orden.pendientes.length,
});

export default connect(
    mapStateToProps,
    { filterCategory, dataLogout, updateHeaders, updateLoadHome }
)(withRouter(HomeBanner));
