import { Cities, Countries, primaryCites } from '../constants/data';
import store from '../store';

export const isLocation = () => {
    const city = store.getState().data.city;
    const country = store.getState().data.country;

    return city && country;
};

export const getInitialCity = () => {
    const city = store.getState().data.city;

    if (city) {
        return city;
    } else {
        return Cities[0];
    }
};

export const getInitialCountry = () => {
    const country = store.getState().data.country;

    if (country) {
        return country;
    } else {
        return Countries[0];
    }
};

export const getLocationText = (params) => {
    const city = store.getState().data.city;
    const country = store.getState().data.country;

    if (city && country) {
        return `${country} - ${city}`;
    } else {
        return '';
    }
};

export const isActualDirectionLocal = () => {
    const city = store.getState().data.city;
    return primaryCites.includes(city);
};

export const isDirectionLocal = (city) => {
    return primaryCites.includes(city);
};

export const getLocationCategories = () => {
    const data = store.getState().categories.categories;

    if (isLocation()) {
        if (isActualDirectionLocal()) {
            return data;
        } else {
            return data.filter((val) => val.ext === 0);
        }
    } else {
        return data;
    }
};

export const getLocationHalls = () => {
    const data = store.getState().halls.halls;

    if (isLocation()) {
        if (isActualDirectionLocal()) {
            return data;
        } else {
            //need filter
            return data.filter((val) => val.ext === 0);
        }
    } else {
        return data;
    }
};

export const getLocationProducts = () => {
    const data = store.getState().data.products;

    if (isLocation()) {
        if (isActualDirectionLocal()) {
            return data;
        } else {
            //need filter
            return data.filter((val) => val.ext === 0);
        }
    } else {
        return data;
    }
};
