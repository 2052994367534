import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { filterCategoryupdateLoadHome } from '../../actions';
import FacebookLogin from 'react-facebook-login';
import Facebook from './Buttons/Facebook';

class FacebookLoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //id:"350880502883464" //web test
            id:"675724609741983" //web prod
        };
    }

    componentDidMount(){
    }

    facebookSuccess = (e) => {

        console.log(e)
        
    }

    facebookFail = (e) => {

        console.log(e)
        
    }
    
    

    render() {

        
        return (
            <div >
                <FacebookLogin
                    appId={this.state.id}
                    autoLoad={false}
                    fields="name,email,picture"
                    onClick={this.facebookSuccess}
                    callback={this.facebookFail}
                    cssClass="facebook-button"
                    textButton="Login con facebook"
                    icon={<i className="fa fa-facebook face-login-icon"/>}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({

})

export default connect(
    mapStateToProps,
    {  }
)(withRouter(FacebookLoginComponent));