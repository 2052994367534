import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getArrayCategoriesSlider } from '../../funciones/pasillo'
import { LazyImage } from "react-lazy-images";

class TagsHome extends Component {

    constructor(props){
        super()
        this.state={
            shuffle_array:getArrayCategoriesSlider(props.categories,props.categoria_id),
            id:props.categoria_id
        }
    }

    goBycategory(category){

        if(category.tipo==2 || category.tipo==6){
            this.props.history.push("/aliados/"+category.id)
        }else if(category.jerarquia==1){
            this.props.history.push("/menu-huerta")
        }else{
            this.props.history.push("/pasillos/"+category.id)
        }

    }

    goByTag = (e,tag) => {

        e.preventDefault()
        this.props.history.push("/tag/"+tag.seo)
        
    }

    static getDerivedStateFromProps(nextProps, prevState){

        if(prevState.shuffle_array.find(value=> value.id==nextProps.categoria_id)){
            return{
                shuffle_array:getArrayCategoriesSlider(nextProps.categories,nextProps.categoria_id),
                id:nextProps.id
            }
        }else{
            return null
        }
    }


    render() {

        const {categories}=this.props

        let slider_catgories=categories.filter((value)=>value.pri==77).map((value,i)=>{
            return(
                <div key={i} className="col-3 home-t-label px-0" onClick={(e)=>{this.goByTag(e,value)}}>

                    {'#'+value.name}

                </div>
            )
        })

        const list_especial=categories.filter((value)=>value.pri===77).map((value,index)=>{

            return(

                <div className="cate-tag-home tac col-3"  key={index}>
                    <div href={"#"} onClick={(e)=>{this.goByTag(e,value)}} >
                        {value.name}
                    </div>
                </div>

            )
            
        })

        return (
            <>

            <div className="mx-2 tags-area mb-display">

                <div className="row margin-zero">

                    {slider_catgories}

                </div>

            </div>

            <div className="cate-sand special-h mb-none cate-tag-home-pos">
                <section className="special-p">
                    <div className="">
                        <div className="row">

                            {list_especial}       

                        </div>

                    </div>
                    
                </section>
            </div>

            </>
        )
    }
}

const mapStateToProps = state => ({
    categories: state.categories.categories,
})

export default connect(
    mapStateToProps,
    { }
)(withRouter(TagsHome));