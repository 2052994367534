import * as types from '../constants/ActionTypes'

const userReducerDefaultState = {
    data: null,
    envio: null,
    headers:{},
    load_home:true
};

const userReducer = (state = userReducerDefaultState, action) => {
    // console.log('Action Result');
    // console.log(action);
    switch (action.type) {
        case types.LOG_IN:
            return {
                ...state,
                data: action.user,
                headers:action.headers
            };
        case types.UPDATE_ENVIO:
            return {
                ...state,
                envio: action.envio
            };
        case types.LOG_OUT:
            return {
                ...state,
                data: null,
                envio:null,
                headers:{}
            };
        case types.UPDATE_HEADERS:
            return {
                ...state,
                headers:action.headers
            };
        case types.UPDATE_USER:
            return {
                ...state,
                data:action.user
            };
        case types.UPDATE_LOAD_HOME:
            return{
                ...state,
                load_home: action.bool
            }
        default:
            return state;
    }
}

export default userReducer;