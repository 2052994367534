import store from "../store";
import { removeFromCore, removeFromRestaurante,removeFromSnack,removeFromCart, removeFromHuerta, removeFromCongelado, removeFromCena } from "../actions";

const activeSnack = () => {

    const cart=store.getState().snack.cart

    cart.forEach((value)=>{
        if(value.activo==0 || value.activo_hall==0){
            store.dispatch(removeFromSnack(value))
        }
    })

    
}

const activeCore = () => {

    const cart=store.getState().core.cart

    cart.forEach((value)=>{
        if(value.activo==0 || value.activo_hall==0){
            store.dispatch(removeFromCore(value))
        }
    })

    
}

const activePlan = () => {

    const cart=store.getState().cartList.cart

    cart.forEach((value)=>{
        if(value.activo==0 || value.activo_hall==0){
            store.dispatch(removeFromCart(value))
        }
    })

    
}


const activeRestaurante = () => {

    const cart=store.getState().restaurante.cart

    cart.forEach((value)=>{
        if(value.activo==0 || value.activo_hall==0){
            store.dispatch(removeFromRestaurante(value))
        }
    })

    
}

const activeHuerta = () => {

    const cart=store.getState().huerta.cart

    cart.forEach((value)=>{
        if(value.activo==0 || value.activo_hall==0){
            store.dispatch(removeFromHuerta(value))
        }
    })

    
}

const activeCongelado = () => {

    const cart=store.getState().congelado.cart

    cart.forEach((value)=>{
        if(value.activo==0 || value.activo_hall==0){
            store.dispatch(removeFromCongelado(value))
        }
    })

    
}

const activeCena = () => {

    const cart=store.getState().cena.cart

    cart.forEach((value)=>{
        if(value.activo==0 || value.activo_hall==0){
            store.dispatch(removeFromCena(value))
        }
    })

    
}


export const validateActiveInCart=()=>{

    activeRestaurante()
    activeSnack()
    activeCore()
    activePlan()
    activeHuerta()
    activeCongelado()
    activeCena()

}
