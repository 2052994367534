import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToCart, addToWishlist, addToCompare, filterCategory } from '../../../actions';
import ProductListItem from './product-list-item';
import { withRouter } from 'react-router-dom';
import { getLocationProducts } from '../../../funciones/cities';

class ProductListingInPasilloAllAliado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productos: [],
        };
    }

    goToPasillo = (pasillo) => {
        this.props.history.push('/pasillo/' + pasillo);
    };

    componentDidMount() {
        this.props.filterCategory([]);
    }

    render() {
        const { addToCart, symbol, addToWishlist, addToCompare, products, pasillo, aliado, hallValue } = this.props;

        const busqueda = products.filter(
            (value) => value.hallsId.includes(parseInt(pasillo)) && value.ally == parseInt(aliado)
        );

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        <div className="row">
                            {busqueda.map((product, index) => (
                                <div
                                    className={`${
                                        this.props.colSize === 3
                                            ? 'col-xl-3 col-md-6 col-6'
                                            : 'col-lg-' + this.props.colSize
                                    }`}
                                    key={index}
                                >
                                    <ProductListItem
                                        product={product}
                                        symbol={symbol}
                                        onAddToCompareClicked={() => addToCompare(product)}
                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                        onAddToCartClicked={addToCart}
                                        key={index}
                                        hallValue={hallValue}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    symbol: state.data.symbol,
    products: getLocationProducts(),
});

export default connect(
    mapStateToProps,
    { addToCart, addToWishlist, addToCompare, filterCategory }
)(withRouter(ProductListingInPasilloAllAliado));
