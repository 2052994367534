import React, { Component } from 'react'
import { connect } from 'react-redux'
import { postData, getData } from '../servicios'
import { toastError } from '../alertas'
import ProductListItem3 from './collection/common/product-list-item3'
import { addToCart, addToWishlist, addToCompare } from '../actions/index'
import ProductListItemSugerido from './collection/common/ProductListItemSugerido'

class ProductosSugeridos extends Component {

    constructor(props){
        super(props)
        this.state={
            data:[],
            error:false,
            load:true
        }
    }

    async componentDidMount(){

        try {

            const response=await getData("reco-products")
            const data=response['data']

            this.setState({
                data:data,
                load:false
            })
            
        } catch (error) {

            console.log(error)

            this.setState({
                load:false,
                error:true
            })
            
        }

    }


    render() {
        const {load,error,data}=this.state
        const {products,symbol}=this.props

        const list_products=products.filter((producto)=>
            data.some((value)=>value.id==producto.id)
        )

        return (
            <div className="float-box mb-display">
                <div className="tac" className="sugeridos-header mb-1">

                    <div className="d-flex justify-content-between bd-highlight">
                        <div className="bd-highlight"></div>
                        <div className="bd-highlight">Productos sugeridos </div>
                        <div className="bd-highlight cerrar-sugerido" onClick={this.props.close}>
                           cerrar <i className="fa fa-close close-sugerido-icon" />
                        </div>
                    </div>
                    
                </div>

                {
                    load?
                    <div className="row margin-zero cargando-sugerido">
                        <div className="col-sm-12 text-center no-found" >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mt-1 mb-1" />
                        </div>
                    </div>
                    :

                        error?
                            
                            <div className="tac error-sugerido">
                                No se pudo cargar productos
                            </div>

                        :

                            <div >
                                
                                <div className="slider-box slider-sugeridos">

                                    <div className="w-max-content">

                                        { 
                                            list_products.map((product, index) =>
                                                /**
                                                <div key={index} className={"mr-2 inline"}>
                                                    <ProductListItem3 product={product} symbol={symbol}
                                                    onAddToCompareClicked={() => addToCompare(product)}
                                                    onAddToWishlistClicked={() => addToWishlist(product)}
                                                    onAddToCartClicked={addToCart} key={index}
                                                    products_length={list_products.length}
                                                    index={index}/>
                                                </div>
                                                */

                                                <div key={index} className={"margin-sugerido inline"}>
                                                    <ProductListItemSugerido product={product} symbol={symbol}
                                                    onAddToCompareClicked={() => addToCompare(product)}
                                                    onAddToWishlistClicked={() => addToWishlist(product)}
                                                    onAddToCartClicked={addToCart} key={index}
                                                    products_length={list_products.length}
                                                    index={index}/>
                                                </div>
                                            
                                            )
                                        }

                                    </div>

                                </div>
                            </div>
                }
                    
                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user:state.customer.data,
    products: state.data.products,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps,{addToCart, addToWishlist, addToCompare}
)(ProductosSugeridos);