import React, { Component } from 'react'
import { isProgramCenaValid, isProgramCoreValid, isProgramSnackValid } from '../../funciones/cart'
import Modal from 'react-responsive-modal';
import ProgramarCena from '../cart/programacion/ProgramarCena';
import { sweetPop } from '../../funciones/general';
import ProgramarCore from '../cart/programacion/ProgramarCore';
import ProgramableSnacks from '../cart/programacion/ProgramableSnacks';

export class ProgramingValidation extends Component {

    constructor(props){
        super(props)
        this.state={
            openCena:false,
            openCore:false,
            openSnack:false,
        }
    }

    closeCena = () => {

        this.setState({
            openCena:false
        })
        
    }

    closeCore = () => {

        this.setState({
            openCore:false
        })
        
    }

    closeSnack = () => {

        this.setState({
            openSnack:false
        })
        
    }


    async componentDidMount(){

        const cena=!isProgramCenaValid()
        //const core=!isProgramCoreValid()
        //const snack=!isProgramSnackValid()

        if(cena || false || false){

            await sweetPop(
                "Aviso",
                "Algunos de tus productos estan programados para horas de entrega que ya no son válidas, por favor escoge una nueva programción",
                "warning"
            )

            this.setState({
                openCena:cena,
                //openCore:core,
                //openSnack:snack,
            })

        }
    }

    render() {
        return (
            <>
                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openCena} onClose={this.closeCena}  showCloseIcon={false} closeOnOverlayClick={false}>
                    <ProgramarCena close={this.closeCena} />
                </Modal>
                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openCore} onClose={this.closeCore}  showCloseIcon={false} closeOnOverlayClick={false}>
                    <ProgramarCore close={this.closeCore} />
                </Modal>
                <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openSnack} onClose={this.closeSnack}  showCloseIcon={false} closeOnOverlayClick={false}>
                    <ProgramableSnacks close={this.closeSnack} />
                </Modal>
            </>
        )
    }
}

export default ProgramingValidation
