import store from '../store';

export const getNavGreetText = () => {
    const user = store.getState().customer.data;

    if (user) {
        return `Hola ${user.name}`;
    } else {
        return `Bienvenido a fluio`;
    }
};
