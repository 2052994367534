import React, { Component } from 'react'

export class SocialIcons extends Component {
    render() {
        return (
            <div className="tac mb-display padding-0">
                <a href="instagram://user?username=fluio.co">
                    <i className="fa fa-instagram soporte-icon-footer"/>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/FLUIO-101854164543495/">
                    <i className="fa fa-facebook-square soporte-icon-footer"/>
                </a>
            </div>
        )
    }
}

export default SocialIcons
