import React, { Component } from 'react'
import { connect } from 'react-redux'
import Breadcrumb from "../common/breadcrumb";
import Banner from '../common/Banner';
import { getData } from '../../servicios';
import ProductListingSugeridos from './common/product-listing-sugeridos';


class CollectionSugeridos extends Component {

    constructor(props){
        super(props)
        this.state={
            data:[],
            loading:true,
            error:false
        }
    }

    async componentDidMount(){

        try {

            const response=await getData("reco-products")
            const data=response['data']

            this.setState({
                data:data,
                loading:false
            })
            
        } catch (error) {

            console.log(error)

            this.setState({
                loading:false,
                error:true
            })
            
        }

    }

    render (){
        const {data,loading,error} = this.state


        return (
            <div>

                <Banner/>

                <Breadcrumb title={"Productos Sugeridos"}/>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">

                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">


                                                            </div>
                                                        </div>

                                                        {
                                                            loading?
                                                            <div className="row">
                                                                <div className="col-md-12 text-center section-b-space mt-5 no-found" >
                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                                                    <h3>Cargando</h3>
                                                                </div>
                                                            </div>
                                                            :
                                                                error?
                                                                <div className="tac">
                                                                    No se pudo cargar sugeridos
                                                                </div>
                                                                :
                                                                <ProductListingSugeridos products={data} colSize={3} />
                                                        }
                                                        

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    products: state.data.products,
})

export default connect(
    mapStateToProps,
    {}
)(CollectionSugeridos)