import React, {Component} from 'react';
import FormError from '../common/FormError';
import Breadcrumb from "../common/breadcrumb";
import { postData } from '../../servicios';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { dataLogin } from '../../actions';
import { cargarOrders } from '../../services';
import ForgottenForm from './ForgottenForm';
import Modal from 'react-responsive-modal';
import { cleverOnLogin, cleverWebPush } from '../../funciones/clevertap';
import GoogleLoginComponent from '../common/GoogleLoginComponent';
import FacebookLoginComponent from '../common/FacebookLoginComponent';

class Login extends Component {

    constructor (props) {
        super (props)
        this.state={
            loginbool:false,
            open:false,
        }

    }

    login = async (e) => {

        e.preventDefault()

        this.setState({
            loginbool:false
        })

        const json={
            email:this.state.email,
            password:this.state.password,
        }


        try{
            const response= await postData("customer_auth/sign_in",json)
            this.props.dataLogin(response['data'].data,response.headers)
            cleverOnLogin(response['data'].data) 
            cleverWebPush()
            const url=this.props.match.params.url

            if(url){
                cargarOrders()
                window.location.href="/"+url
            }else{
                window.location.href="/"
            }

        }catch(err){
            this.setState({
                loginbool:true
            })
        }

    }

    openModal = (e) => {
        e.preventDefault()
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };


    handleChange= (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }


    render (){

        const {loginbool,open} = this.state

        return (

            <div>
                <Breadcrumb title={'Login'}/>

                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3 className="ttn">Inicio de sesión</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.login} >
                                        <div className="form-group">
                                            <label htmlFor="email">Correo</label>
                                            <input type="email" className="form-control" name="email" placeholder="Correo" onChange={this.handleChange} required />
                                            <FormError text="El correo o contraseña no coinciden" show={loginbool}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Contraseña</label>
                                            <input type="password" className="form-control" name="password" placeholder="Ingresa tu contraseña" onChange={this.handleChange} required />
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-6">
                                                <button type="submit" className="btn btn-solid ttn">Login</button>
                                            </div>
                                            <div class="form-group col-6">
                                                <a href="/#" className="forgot-label" onClick={this.openModal}>Olvide mi contraseña</a>
                                            </div>
                                        </div>

                                        {/*
                                        <div class="form-row">

                                            <div class="form-group col-6">
                                                <GoogleLoginComponent/>
                                            </div>

                                            <div class="form-group col-6">
                                                <FacebookLoginComponent/>
                                            </div>

                                        </div>
                                        */}
                                        
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3 className="ttn">Eres un usuario nuevo?</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-fon ttn">Crea tu cuenta</h6>
                                    <p>
                                        Registrarse en fluio es rápido y sencillo,
                                        además de que te permite tener acceso a todos
                                        nuestros productos
                                    </p>
                                    <a href="/register" className="btn btn-solid ttn">Crear cuenta</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal classNames={{'modal': 'mb-modal'}} open={open} onClose={this.closeModal} classNames={{closeButton: "close-forgotten"}} center>
                        
                    <ForgottenForm close={this.closeModal} />

                </Modal>

            </div>

        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(
    mapStateToProps, { dataLogin }
)(withRouter(Login))
