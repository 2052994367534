import * as types from '../constants/ActionTypes'

const ordenReducerDefaultState = {
    pendientes: [],
};

const ordenReducer = (state = ordenReducerDefaultState, action) => {

    switch (action.type) {
        case types.UPDATE_ORDEN:
            return {
                ...state,
                pendientes: action.ordenes
            };
        case types.CLEAR_ORDEN:
            return {
                ...state,
                pendientes: []
            };
        default:
            return state;
    }
}

export default ordenReducer;