import moment from "moment";

export const HuertaCoreMatchOrden = (huerta,core) => {

    if(huerta.length>0 && core.length>0){

        const fecha_huerta=huerta[0].fecha_i
        const fecha_core=moment(core[0].fecha_i,'YYYY-MM-DD').format('DD/MM/YYYY')
        const hora_huerta=huerta[0].hora
        const hora_core=core[0].hora

        if(fecha_huerta==fecha_core && hora_huerta==hora_core){
            return true
        }else{
            return false
        }

    }else{
        return false
    }

}

export const OrdenesAscByDate = (array) => {

    return array.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(moment(b.fecha,'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD')) - new Date(moment(a.fecha,'DD/MM/YYYY hh:mm:ss').format('YYYY-MM-DD'));
    });
    
}

