import React, {Component} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { filterCategory, updateHeaders, dataLogout,updateLoadHome } from '../actions';
import store from '../store';
import { LazyImage } from "react-lazy-images";
import Modal from 'react-responsive-modal';
import Sugerencias from './Sugerencias';
import ProductosSugeridos from './ProductosSugeridos';
import ContactIcons from './common/ContactIcons'
import SocialIcons from './common/SocialIcons';
import TagsSlider from './common/TagsSlider';
import HomeCategories from './common/HomeCategories';
import Banner from './common/Banner';
import HomeSugeridosSlider from './common/HomeSugeridosSlider';
import HomeAliados from './common/HomeAliados';
import HomeIcons from './common/HomeIcons';
import { isMobileSize, isSurveyComplete } from '../funciones/general';
import LaunchPage from './LaunchPage';
import { cleverHomeVisit } from '../funciones/clevertap';
import HomeBanner from './common/HomeBanner';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categorias:[],
            text:'',
            open:false,
            openProductos:true,
            showLaunch:false,
        };
    }

    componentDidMount(){
        this.cargarCategorias()
    }



    async cargarCategorias(){

        this.setState({
            categorias:this.props.categories
        })

    }

    openModal = (e) => {
        e.preventDefault()
        this.setState({ open: true });
    };

    closeModal = () => {
        this.setState({ open: false });
    };

    openProductos = (e) => {
        e.preventDefault()
        this.setState({ openProductos: true });
    };

    closeProductos = () => {
        this.setState({ openProductos: false });
    };

    handleSubmit = (e) => {
		e.preventDefault()
		this.props.filterCategory([]);
		this.props.history.push("/productos/"+this.state.text)
    }
    
    handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }
    

    componentDidMount(){
        //refreshToken()
        //cargarDatos()
        cleverHomeVisit()

        if(!isSurveyComplete() && isMobileSize()  && false){

            this.props.history.push("/launch")

        }

        setTimeout(()=>{

            if(store.getState().data.products.length==0){
                window.location.reload()
            }

        },240000)


        
    }

    /**
    async componentDidMount(){

        if(this.props.user){

            try{

                const config={
                    headers: store.getState().customer.headers
                }

                console.log(config)

                const response= await getData("customer_auth/validate_token/",config)
                console.log(response)
                this.props.updateHeaders(response.config.headers)

                
                
            }catch(err){
                console.log(err.response)
                //this.props.dataLogout()
            }

        }
        
    }
     */

     searchFocus = () => {
         this.setState({
             openProductos:false
         })
     }

    render() {

        const {load,products,user,ordenes,categories} = this.props;
        const {showLaunch}= this.state
        
        return (
            <div className="bg-white">

                <Banner/>

                {
                    showLaunch?
                        <LaunchPage/>
                    :<></>
                }

                {
                    false?
                    <div className="container">
                        <div className="row">

                            <div className="col-6">
                                <Link to="/orders?tab=2" className="btn repeat-home-btn btn-center">
                                        Pídelo de nuevo
                                </Link> 
                            </div>

                            <div className="col-6">
                                <Link to="/orders" className="btn repeat-home-btn btn-center">
                                        Pedido en curso <span>{ordenes}</span>
                                </Link> 
                            </div>
                                   
                        </div>
                    </div>
                    :''
                }     

                {
                    products.length==0 && categories.length==0 ?
                    <div className="row">
                        <div className="col-md-12 text-center section-b-space mt-5 no-found" >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                            <h3>Cargando</h3>
                        </div>
                    </div>
                    :
                    <>              

                        <TagsSlider/>

                        <HomeCategories/>

                        <div className="container mb-display home-title-sugerido-margin">
                            <div className="normal-title-sugerido tac">
                                Productos Sugeridos
                            </div>
                        </div>

                        <HomeSugeridosSlider/>

                        <div className="vm-sugeridos-container mb-display">
                            <Link to="productos-sugeridos" className="vm-sugeridos">
                                Ver más sugeridos
                            </Link>
                        </div>

                        <div className="home-separator mb-display">
                            <hr/>
                        </div>

                        <div className="container mb-display home-title-aliado-margin mb-display">
                            <div className="normal-title-aliado tac">
                                Aliados
                            </div>
                        </div>

                        <HomeAliados/>

                        <div className="vm-sugeridos-container mb-display">
                            <Link to="nuestros-aliados" className="vm-sugeridos">
                                Ver más aliados
                            </Link>
                        </div>

                        <div className="home-separator mb-display">
                            <hr/>
                        </div>

                        <div className="space4" />

                        {/*
                        <div className="container-sugeridos">
                            <ProductosSugeridos close={this.closeProductos}/>
                        </div>
                        */}

                        

                    </>

                }

                <div className="home-comment-container">
                    <button type="submit" className="btn ttn btn-com-home" onClick={this.openModal}>¿Hay algo que no encuentras?</button>
                </div>

                <Modal open={this.state.open} onClose={this.closeModal} center classNames={{'modal': 'mb-modal',closeButton: "close-forgotten"}} >
                        <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                            <div className="modal-content quick-view-modal">
                                <div className="modal-body">
                                    <Sugerencias close={this.closeModal}/>
                                </div>
                            </div>
                        </div>
                </Modal>



                {/*

                <Modal open={this.state.openProductos} onClose={this.closeProductos} center
                 classNames={{'modal': 'productos-sugeridos-modal2',closeButton: "close-forgotten",overlay: 'productos-sugeridos-overlay'}} >

                    <ProductosSugeridos close={this.closeProductos}/>

                </Modal>

                */}

                <HomeIcons/>

                {/*
                <ContactIcons/>
                <SocialIcons/>
                */}

            </div>
        )
    }
}

const mapStateToProps = state => ({
    filters: state.filters,
    categories: state.categories.categories,
    user:state.customer.data,
    headers: state.customer.headers,
    load: state.customer.load_home,
    products: state.data.products,
    ordenes: state.orden.pendientes.length,
})

export default connect(
    mapStateToProps,
    { filterCategory,dataLogout,updateHeaders,updateLoadHome }
)(withRouter(Home));