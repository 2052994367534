import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux'
import {toastSuccess, toastError} from  '../../../alertas'
import ItemTipoRestaurant from './modales/ItemTipoRestaurant';
import ItemTipoSemanal from './modales/ItemTipoSemanal';
import ItemTipoQuincenal from './modales/ItemTipoQuincenal';
import ItemTipoMensual from './modales/ItemTipoMensual';
import ItemTipoHuerta from './modales/ItemTipoHuerta';
import ItemTipoCongelado from './modales/ItemTipoCongelado';
import ItemTipoCore from './modales/ItemTipoCore';
import ItemTipoSnack from './modales/ItemTipoSnack';
import ItemTipoCena from './modales/ItemTipoCena';
import LazyLoad from 'react-lazy-load';
import { checkDisponible, isProductoInactivo, getProductButtonText } from '../../../funciones/general';
import { LazyImage } from "react-lazy-images";

class ProductListItem3 extends Component {

    constructor(props){
        super(props)

        this.state = {
            openRestaurant: false,
            openSemanal: false,
            openQuincenal:false,
            openMensual:false,
            openHuerta:false,
            openCongelado:false,
            openCore:false,
            openSnack:false,
            openCena:false,
            stock: 'InStock',
            quantity: 1,
            image: ''
        }
    }

    onOpenRestaurant = (e) => {
        e.preventDefault()
        this.setState({ openRestaurant: true });
    };

    onCloseRestaurant = () => {
        this.setState({ openRestaurant: false });
    };

    onOpenSemanal = (e) => {
        e.preventDefault()
        this.setState({ openSemanal: true });
    };

    onCloseSemanal = () => {
        this.setState({ openSemanal: false });
    };

    onOpenQuincenal = (e) => {
        e.preventDefault()
        this.setState({ openQuincenal: true });
    };

    onCloseQuincenal = () => {
        this.setState({ openQuincenal: false });
    };

    onOpenMensual = (e) => {
        e.preventDefault()
        this.setState({ openMensual: true });
    };

    onCloseMensual = () => {
        this.setState({ openMensual: false });
    };

    onOpenHuerta = (e) => {
        e.preventDefault()
        this.setState({ openHuerta: true });
    };

    onCloseHuerta = () => {
        this.setState({ openHuerta: false });
    };

    onOpenCongelado = (e) => {
        e.preventDefault()
        this.setState({ openCongelado: true });
    };

    onCloseCongelado = () => {
        this.setState({ openCongelado: false });
    };

    onOpenCore = (e) => {
        e.preventDefault()
        this.setState({ openCore: true });
    };

    onCloseCore = () => {
        this.setState({ openCore: false });
    };

    onOpenSnack = (e) => {
        e.preventDefault()
        this.setState({ openSnack: true });
    };

    onCloseSnack = () => {
        this.setState({ openSnack: false });
    };

    onOpenCena = (e) => {
        e.preventDefault()
        this.setState({ openCena: true });
    };

    onCloseCena = () => {
        this.setState({ openCena: false });
    };

    onClickHandle(img) {
        this.setState({ image : img} );
    }

    agregarCarrito = (product) => {

        const {carrito,onAddToCartClicked}= this.props

        //determina si el item esta en el carrito para saber si agregarlo o no
        const bool=carrito.some((item) => {
            return item.id==product.id
        })

        if(bool){
            toastError("El producto ya esta en el carrito")
        }else{
            onAddToCartClicked(product, 1)
            toastSuccess("Se agrego al carrito")
        }
        this.onCloseRestaurant()
    }

    handleOpen = (e,product) => {
        e.preventDefault()


        if(product.activo_hall==0 || product.activo==0){
            toastError("Producto no disponible")
            return
        }

        //caso plan alimenticio
        if(product.tipo_cate==3){


            if(product.tipo_hall==1){
                this.onOpenSemanal(e)
            }
            if(product.tipo_hall==2){
                this.onOpenQuincenal(e)
            }
            if(product.tipo_hall==4){
                this.onOpenMensual(e)
            }

        
        // resto de casos
        }else{

            if(product.tipo_cate==0){
                this.onOpenSnack(e)
            }

            if(product.tipo_cate==1){
                this.onOpenCore(e)
            }

            if(product.tipo_cate==2){
                this.onOpenRestaurant(e)
            }

            if(product.tipo_cate==4){
                this.onOpenHuerta(e)
            }

            if(product.tipo_cate==5){
                this.onOpenCongelado(e)
            }

            if(product.tipo_cate==6){
                this.onOpenCena(e)
            }

        }
        
    }


    render() {
        const {product, symbol, products_length, index} = this.props;
        const {openRestaurant,openSemanal,openQuincenal,openMensual,openHuerta,openCongelado,openCore,openSnack,openCena} = this.state;
        

            let RatingStars = []
            for(var i = 0; i < product.rating; i++) {
                RatingStars.push(<i className="fa fa-star" key={i}></i>)
            }

        return (

                    <div className={"product-box pb-style " + (products_length-1==index ? "end-pasillo " : "")}>
                        <div className="mb-img-max-h">
                            <div className="front h-170">
                                <Link to="/#" onClick={(e)=>this.handleOpen(e,product)} >

                                {/*
                                <LazyLoad
                                    offset={200}
                                    once={true}
                                    placeholder={<ImgPlaceholder/>}
                                >
                                    <img  src={product.pictures[0]}
                                    className="img-fluid max-h mb-center90 mb-pas-img"
                                    alt="" />
                                </LazyLoad>
                                */}

                                <LazyImage
                                    src={product.pictures[0]}
                                    alt={product.name}
                                    placeholder={({ imageProps, ref }) => (
                                        <img
                                            className="max-h mb-center90 green-loader"
                                            ref={ref} src={`${process.env.PUBLIC_URL}/assets/images/loading4.gif`}
                                            alt={imageProps.alt}
                                        />
                                    )}
                                    actual={({ imageProps }) => 
                                        <img {...imageProps} className="img-fluid max-h mb-center90 mb-pas-img"/>
                                    }
                                    observerProps={{
                                        rootMargin: "200px 500px 200px 500px",
                                        threshold: 0
                                    }}
                                />
                                    
                                </Link>                             
                            </div>
                            {product.variants?
                            <ul className="product-thumb-list">
                                {product.variants.map((vari, i) =>
                                    <li className={`grid_thumb_img ${(vari.images === this.state.image)?'active':''}`} key={i}>
                                        <a href="javascript:void(0)" title="Add to Wishlist">
                                            <img src={`${vari.images}`} onClick={() => this.onClickHandle(vari.images)} />
                                        </a>
                                    </li>)
                                }
                            </ul>:''}

                        </div>
                        <div className="product-detail">
                            <div>
                                <Link to={"/#"} onClick={(e)=>this.handleOpen(e,product)}>
                                    <h6 className="mb-font13 name-h">{product.name}</h6>
                                    <h6 className="lbl-a2">{product.aliado}</h6>
                                </Link>
                                <div className="mb-sepa-h mb-display">
                                    
                                </div>
                                <h4 className="mb-font13">
                                    {symbol}{product.price}
                                </h4>
                                {product.variants?
                                <ul className="color-variant">
                                    {product.variants.map((vari, i) => {
                                        return (
                                            <li className={vari.color} key={i} title={vari.color} onClick={() => this.onClickHandle(vari.images)}></li>)
                                    })}
                                </ul>:''}
                            </div>
                        </div>
                        <div className="mb-sepa-h">

                        </div>
                        <button className={"btn no-padding btn-cart mb-font-6p8 desk-font-10 ttn "+checkDisponible(product)} onClick={(e)=>this.handleOpen(e,product)}>
                            {getProductButtonText(product)}
                        </button>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openRestaurant} onClose={this.onCloseRestaurant} center >
                        
                        <ItemTipoRestaurant product={product} close={this.onCloseRestaurant} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openSemanal} onClose={this.onCloseSemanal} center>
                        
                        <ItemTipoSemanal product={product} close={this.onCloseSemanal} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openQuincenal} onClose={this.onCloseQuincenal} center>
                        
                        <ItemTipoQuincenal product={product} close={this.onCloseQuincenal} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openMensual} onClose={this.onCloseMensual} center>
                        
                        <ItemTipoMensual product={product} close={this.onCloseMensual} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} classNames={{'modal': 'mb-modal'}} classNames={{'modal': 'mb-modal'}} open={openHuerta} onClose={this.onCloseHuerta} center>
                        
                        <ItemTipoHuerta product={product} close={this.onCloseHuerta} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openCongelado} onClose={this.onCloseCongelado} center>
                        
                        <ItemTipoCongelado product={product} close={this.onCloseCongelado} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openCore} onClose={this.onCloseCore} center>
                        
                        <ItemTipoCore product={product} close={this.onCloseCore} />

                    </Modal>
                    
                    <Modal classNames={{'modal': 'mb-modal'}} open={openSnack} onClose={this.onCloseSnack} center>
                        
                        <ItemTipoSnack product={product} close={this.onCloseSnack} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openCena} onClose={this.onCloseCena} center>
                        
                        <ItemTipoCena product={product} close={this.onCloseCena} />

                    </Modal>

                </div>
        )
    }
}

const mapStateToProps = (state) => ({
    carrito: state.cartList.cart
})

export default connect(mapStateToProps)(ProductListItem3)