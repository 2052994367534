import React, { Component } from 'react'

export class ContactIcons extends Component {
    render() {
        return (

                <div className="tac mb-display padding-0">
                    <a href="mailto:info@fluio.com.co" data-toggle="tooltip" title="info@fluio.com.co">
                        <i className="fa fa-envelope soporte-icon-footer"/>
                    </a>
                    <a href="tel:+573107139466" data-toggle="tooltip" title="(+57)3107139466">
                        <i className="fa fa-phone soporte-icon-footer"/>
                    </a>
                    <a href="https://wa.me/573107139466">
                        <i className="fa fa-whatsapp soporte-icon-footer"/>
                    </a>
                </div>
                
        )
    }
}

export default ContactIcons
