import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getTotalNormal } from '../../../services'
import { getFechaPatronHuertaYCongelado, getNextSabado,getNextMartes } from '../../../funciones/patrones'
import { getDiaMesEspanolHuertaCongelado } from '../../../funciones/momentEspañol'

class CheckCongelado extends Component {

    render() {
        const{cartItems,total,symbol,delivery,programacion}=this.props

        if(cartItems.length>0){
            const fecha=getFechaPatronHuertaYCongelado(programacion.dia)
            return (
                <>                   
                    <strong className="center70 font-25">Congelados</strong>
                    <br/>
                    <div className="title-box">
                        <div className="row">
                            <div className="col-7">
                                Producto
                            </div>
                            <div className="col-5">
                                Total
                            </div>
                        </div>
                    </div>
                    <ul className="qty">
                        {
                            cartItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div className="row">
                                            <div className="col-7">
                                                {item.name} (×{item.qty})  
                                            </div>
                                            <div className="col-5">
                                                {symbol}{(item.price*item.qty)}
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>

                    <ul className="sub-total">
                        <li>Entrega
                            <span className="count">
                                {programacion.dia=="mar"?
                                getDiaMesEspanolHuertaCongelado(getNextMartes())+" ":
                                getDiaMesEspanolHuertaCongelado(getNextSabado())+" "} 
                                a las {programacion.hora}
                            </span>
                        </li>
                        <li>Delivery <span className="count">{symbol}{delivery}</span></li>
                        <li>Subtotal <span className="count">{symbol}{total}</span></li>
                    </ul>


                </>
            )
        }else{
            return ('')
        }
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.congelado.cart,
    symbol: state.data.symbol,
    total: getTotalNormal(state.congelado.cart,5),
    delivery:state.delivery.congelado,
    programacion:state.congelado.programacion
})

export default connect(
    mapStateToProps,
    {}
)(CheckCongelado)