import React, { Component } from 'react';
import { Link, NavLink, withRouter} from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import NavBar from "./common/navbar";
import SideBar from "./common/sidebar";
import CartContainer from "./../../../containers/CartContainer";
import TopBar from "./common/topbar";
import LogoImage from "./common/logo";
import {changeCurrency, filterCategory} from '../../../actions'
import {connect} from "react-redux";
import HomeNav from './common/HomeNav';

class HomeHeader extends Component {

    constructor(props) {
        super(props);

		this.state = {
			isLoading:false,
			text:''
		}
    }
    /*=====================
         Pre loader
         ==========================*/
    componentDidMount() {
		
        setTimeout(function() {
            document.querySelector(".fluio-wrapper").style = "display: none";
		}, 2000);
		

		this.setState({ open: true });
    }

    componentWillMount(){
        //window.addEventListener('scroll', this.handleScroll);
	}
    componentWillUnmount() {
        //window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky-home").classList.remove('fixed');
            }else
            	document.getElementById("sticky-home").classList.add('fixed');
        } else {
            document.getElementById("sticky-home").classList.remove('fixed');
        }
    }

    changeLanguage(lang) {
        store.dispatch(IntlActions.setLocale(lang))
	}

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if(openmyslide){
            openmyslide.classList.add('open-side')
		}
    }
    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

	load = ()=>{
		this.setState({isLoading: true});
		fetch().then(()=>{
			// deal with data fetched
			this.setState({isLoading: false})
		})
	};

	handleSubmit = (e) => {
		e.preventDefault()
		this.props.filterCategory([]);
		this.props.history.push("/productos/"+this.state.text)
		window.location.reload()
		
	}

	handleChange = (e) => {
        const { name,value}= e.target

        this.setState({
            [name]: value
        })
    }

	
	
	render() {

		const {ordenes} = this.props

		return (
			<div className='mb-none' >

				<header id="sticky-home" className="sticky mb-header">
					{this.state.isLoading ? <Pace color="#27ae60"/> : null}
					<div className="mobile-fix-option mb-none"></div>
					{/*Top Header Component*/}
					<TopBar/>

					<div className="container home-nav-size">
						<div className="row">
							<div className="col-sm-12">

								<div className="main-menu fixed-main">
									
									<div className="center-nav-home">
										{/*Top Navigation Bar Component*/}
										<HomeNav/>

									</div>
								</div>
							</div>
						</div>
					</div>
				</header>

			</div>
			)
	}
}

const mapStateToProps = state => ({
	estado: state,
	ordenes: state.orden.pendientes.length
})

export default connect(mapStateToProps,
    { changeCurrency,filterCategory }
)(withRouter(HomeHeader));