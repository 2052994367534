import React, { Component } from 'react'
import { connect } from 'react-redux'
import horas from '../../../api/horarios/tipoRestaurante.json'
import { actualizarRestaurante } from '../../../actions/index.js'
import * as moment from 'moment';
import { getDiffHoraria } from '../../../servicios.js'
import { postreByHall, bebidaByHall } from '../../../services';

class Adicionales extends Component {

    constructor(props){
        super(props)
        this.state={
            postre: props.product.postre,
            bebida: props.product.bebida,
            num_postre: props.product.num_postre,
            num_bebida: props.product.num_bebida,
            comment: props.product.comment,
        }
    }

    submit = (e,product) => {
        e.preventDefault()
        const {actualizarRestaurante}=this.props
        const {bebida,postre,num_bebida,num_postre,comment}= this.state

        product.bebida=bebida
        product.postre=postre

        product.num_bebida=num_bebida
        product.num_postre=num_postre
        product.comment=comment

        actualizarRestaurante(product)

        this.props.close()

        
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    getPostre(id_add){
        const {add_postre}=this.props

        return add_postre.find((value)=>
            value.id==id_add
        )

    }

    getBebida(id_add){
        const {add_bebida}=this.props

        return add_bebida.find((value)=>
            value.id==id_add
        )

    }

    restar = (e,key) => {

        e.preventDefault()
        this.setState({
            [key]: this.state[key]-1
        })
        
    }


    sumar = (e,key) => {

        e.preventDefault()
        this.setState({
            [key]: this.state[key]+1
        })
        
    }

    render() {

        const {product,symbol,add_postre,add_bebida}=this.props
        const {postre,bebida,num_postre,num_bebida}=this.state

        const list_postre=postreByHall(product.hall).map((value)=>{
            return(
            <option value={value.id} key={value.id}>{value.name}</option>
            )
        })

        const list_bebida=bebidaByHall(product.hall).map((value)=>{
            return(
            <option value={value.id} key={value.id}>{value.name}</option>
            )
        })
        
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title tac" >Adicionales</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">

                            <div className="col-12">
                                <form onSubmit={(e)=>{this.submit(e,product)}}>

                                    <div className="form-group">
                                        <label htmlFor="postre" className="col-form-label" >Postre:</label>
                                        <select name="postre" onChange={this.handleChange} value={this.state.postre || ''} className="form-control">
                                            <option  value="">N/A</option>  
                                            {list_postre}                                                                                     
                                        </select>
                                    </div>


                                    {
                                        postre ?
                                            
                                            <>

                                                <p className="tac">${this.getPostre(this.state.postre).price+" c/u"}</p>
                                                <label htmlFor="cantidad" className="col-form-label" >Cantidad de postres:</label>
                                                <div className="input-group">
                                        
                                                    <span className="input-group-prepend">
                                                        <button type="button" className="btn quantity-left-minus" onClick={(e) => this.restar(e,"num_postre")} data-type="minus" data-field="" disabled={(num_postre <= 1)? true : false}>
                                                            <i className="fa fa-angle-left"></i>
                                                        </button>
                                                    </span>
                                                    <input type="text" name="num_postres" value={num_postre} readOnly={true} className="form-control input-number tac" />
                                                    <span className="input-group-prepend">
                                                        <button className="btn quantity-right-plus" onClick={(e) => this.sumar(e,"num_postre")}  data-type="plus" disabled={(num_postre >= 5)? true : false}>
                                                            <i className="fa fa-angle-right"></i>
                                                        </button>
                                                    </span>
                                                </div>

                                                {/*                                            
                                                <img src={this.getPostre(this.state.postre).image} alt="Imagen del postre" className="add-img mb-display"/>
                                                
                                                */}

                                            </>
                                            
                                        :''
                                    }


                                    <div className="form-group">
                                        <label htmlFor="bebida" className="col-form-label" >Bebida:</label>
                                        <select name="bebida" onChange={this.handleChange} value={this.state.bebida || ''} className="form-control">
                                            <option  value="">N/A</option>  
                                            {list_bebida}                                                                                     
                                        </select>
                                    </div>


                                    {
                                        bebida ?
                                            
                                            <>
                                                <p className="tac">${this.getBebida(this.state.bebida).price+" c/u"}</p>
                                                <label htmlFor="cantidad" className="col-form-label" >Cantidad de bebidas:</label>
                                                <div className="input-group">
                                        
                                                    <span className="input-group-prepend">
                                                        <button type="button" className="btn quantity-left-minus" onClick={(e) => this.restar(e,"num_bebida")} data-type="minus" data-field="" disabled={(num_bebida <= 1)? true : false}>
                                                            <i className="fa fa-angle-left"></i>
                                                        </button>
                                                    </span>
                                                    <input type="text" name="num_bebida" value={num_bebida} readOnly={true} className="form-control input-number tac" />
                                                    <span className="input-group-prepend">
                                                        <button className="btn quantity-right-plus" onClick={(e) => this.sumar(e,"num_bebida")}  data-type="plus" disabled={(num_bebida >= 5)? true : false}>
                                                            <i className="fa fa-angle-right"></i>
                                                        </button>
                                                    </span>
                                                </div>

                                                {/*  
                                                <img src={this.getBebida(this.state.bebida).image} alt="Imagen de la bebida" className="add-img mb-display"/>
                                                */}
                                                
                                            </>
                                            
                                        :''
                                    }

                                    <div className="form-group">
                                        <label htmlFor="comment" className="col-form-label" >Comentarios:</label>
                                        <textarea name="comment" className="form-control" rows="2" onChange={this.handleChange} value={this.state.comment || ''} placeholder="Sin lechuga, sin cebolla, etc"/>
                                    </div>

                                    <br/>

                                    <button className="btn btn-solid"> Guardar</button>

                                </form>

                            </div>


                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    hora: state.restaurante.hora_entrega,
    add_postre: state.data.postres,
    add_bebida: state.data.bebidas,
})

export default connect(
    mapStateToProps,{actualizarRestaurante}
)(Adicionales)