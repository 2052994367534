import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addToCart, addToWishlist, addToCompare, filterCategory } from '../../../actions'
import ProductListItem from './product-list-item';
import { withRouter } from 'react-router-dom';


class ProductListingSugeridos extends Component {


    constructor(props){
        super(props)
        this.state={
        }
    }

    
    
    render() {
        const {addToWishlist, addToCompare,productos,products,symbol} = this.props;
        console.log(products)


        const list_products=productos.filter((producto)=>
            products.some((value)=>value.id==producto.id)
        )

        return (
            <div>
                
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
 
                            <div className="row">
                                { list_products.map((product, index) =>
                                    
                                    <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-6':'col-lg-'+this.props.colSize}`} key={index}>
                                    <ProductListItem product={product} symbol={symbol}
                                                        onAddToCompareClicked={() => addToCompare(product)}
                                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                                        onAddToCartClicked={addToCart} key={index}/>
                                    </div>)
                                }
                            </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    productos: state.data.products,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare,filterCategory}
)(withRouter(ProductListingSugeridos))