import React, { Component } from 'react'
import { connect } from 'react-redux'
import horas from '../../../api/horarios/tipoHuerta.json'
import { updateCongelado } from '../../../actions/index.js'
import { getFechaPatronHuertaYCongelado,getNextMartes,getNextSabado, getDateOrdenadoHuertaCongelado } from '../../../funciones/patrones'
import { getDiaMesEspanolHuertaCongelado } from '../../../funciones/momentEspañol'

class ProgramarCongelado extends Component {

    constructor(props){
        super(props)
        this.state={
            hora: props.programacion.hora,
            radio:props.programacion.dia
        }
    }

    submit = (e) => {
        e.preventDefault()
        const programacion={
            hora: this.state.hora,
            dia: this.state.radio
        }
        this.props.updateCongelado(programacion)
        this.props.close()
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }
    
    

    render() {

        const {radio}=this.state

        const list_horas=horas.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_radio=getDateOrdenadoHuertaCongelado().map((value,i)=>{
            return(

                <label className="radio-inline" key={i}>
                    <input 
                        type="radio" 
                        name="radio"  
                        value={value.day=="martes"?'mar':"sab"} 
                        onChange={this.handleChange} 
                        checked={value.day=="martes"? radio == 'mar': radio=="sab"} />

                    {value.day=="martes"?
                        getDiaMesEspanolHuertaCongelado(getNextMartes())
                        :
                        getDiaMesEspanolHuertaCongelado(getNextSabado())}
                </label>


            )
        })
        
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title" >Editar envío de congelados</h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submit}>

                            {list_radio}

                            <div className="form-group">
                                <label htmlFor="hora" className="col-form-label" >Hora :</label>
                                <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                    {list_horas}
                                </select>
                            </div>

                            <button className="btn btn-solid"> Guardar</button>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    programacion: state.congelado.programacion,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps,{updateCongelado}
)(ProgramarCongelado)