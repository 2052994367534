import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Breadcrumb from '../common/breadcrumb'

class SubHuerta extends Component {

    constructor(props){
        super(props)
    }

    goBycategory(category){

        this.props.history.push("/categoria/"+category.seo)

    }

    render() {

        const {categories}=this.props

        const list_categories=categories.filter((value)=>value.jerarquia==1 || value.jerarquia==2).map((value,i)=>

            <div className="col-xl-4 mt-3 pointer" onClick={()=>this.goBycategory(value)} key={i}>
                <div className="subcategory-card">
                    <div className="container">
                        <div className="row">

                            <div className="col-8 p-0">
                                <div className="container" style={{width:'100%'}}>
                                    <h3 className="tac bold text-dark" style={{paddingTop: '20px'}}>{value.name}</h3>
                                    <p className="tac mt-2">
                                        {
                                            value.tipo==1?
                                                "Entrega en menos de 90 minutos"
                                            :
                                                'Entregas los martes, jueves y sábados'
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className="col-4 p-0">

                                <div className="my-3 subcategory-image-box" style={{backgroundColor: value.color}}>
                                    <img src={value.image} alt={value.name} className="pt-2"/>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        )

        return (

            <div className="mb-5">
                <Breadcrumb title={'Escoge una opción'}/>
                <div className="container">

                    <div className="row">

                       {list_categories}

                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    categories: state.categories.categories,
})

export default connect(
    mapStateToProps, { }
)(withRouter(SubHuerta))
