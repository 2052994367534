import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getArrayCategoriesSlider } from '../../funciones/pasillo'
import { LazyImage } from "react-lazy-images";
import { isAppColor, stringPreview, productInCart } from '../../funciones/general';
import { getData } from '../../servicios';
import ItemTipoCena from '../collection/common/modales/ItemTipoCena';
import ItemTipoSnack from '../collection/common/modales/ItemTipoSnack';
import ItemTipoCore from '../collection/common/modales/ItemTipoCore';
import ItemTipoHuerta from '../collection/common/modales/ItemTipoHuerta';
import ItemTipoMensual from '../collection/common/modales/ItemTipoMensual';
import ItemTipoQuincenal from '../collection/common/modales/ItemTipoQuincenal';
import ItemTipoSemanal from '../collection/common/modales/ItemTipoSemanal';
import ItemTipoRestaurant from '../collection/common/modales/ItemTipoRestaurant';
import Modal from 'react-responsive-modal';
import { toastError } from '../../alertas';

class CategoriesHomeSlider1 extends Component {

    constructor(props){
        super()
        this.state={
            data:[],
            error:false,
            load:true,
            openRestaurant: false,
            openSemanal: false,
            openQuincenal:false,
            openMensual:false,
            openHuerta:false,
            openCongelado:false,
            openCore:false,
            openSnack:false,
            openCena:false,
            product:'',
            inCart:'',
        }
    }

    goBycategory(category){

        if(category.tipo==2 || category.tipo==6){
            this.props.history.push("/aliados/"+category.id)
        }else if(category.jerarquia==1){
            this.props.history.push("/menu-huerta")
        }else{
            this.props.history.push("/pasillos/"+category.id)
        }

    }

    async componentDidMount(){

        try {

            const response=await getData("reco-products")
            const data=response['data']

            this.setState({
                data:data,
                load:false
            })
            
        } catch (error) {

            console.log(error)

            this.setState({
                load:false,
                error:true
            })
            
        }

    }

    onOpenRestaurant = (e) => {
        e.preventDefault()
        this.setState({ openRestaurant: true });
    };

    onCloseRestaurant = () => {
        this.setState({ openRestaurant: false });
    };

    onOpenSemanal = (e) => {
        e.preventDefault()
        this.setState({ openSemanal: true });
    };

    onCloseSemanal = () => {
        this.setState({ openSemanal: false });
    };

    onOpenQuincenal = (e) => {
        e.preventDefault()
        this.setState({ openQuincenal: true });
    };

    onCloseQuincenal = () => {
        this.setState({ openQuincenal: false });
    };

    onOpenMensual = (e) => {
        e.preventDefault()
        this.setState({ openMensual: true });
    };

    onCloseMensual = () => {
        this.setState({ openMensual: false });
    };

    onOpenHuerta = (e) => {
        e.preventDefault()
        this.setState({ openHuerta: true });
    };

    onCloseHuerta = () => {
        this.setState({ openHuerta: false });
    };

    onOpenCongelado = (e) => {
        e.preventDefault()
        this.setState({ openCongelado: true });
    };

    onCloseCongelado = () => {
        this.setState({ openCongelado: false });
    };

    onOpenCore = (e) => {
        e.preventDefault()
        this.setState({ openCore: true });
    };

    onCloseCore = () => {
        this.setState({ openCore: false });
    };

    onOpenSnack = (e) => {
        e.preventDefault()
        this.setState({ openSnack: true });
    };

    onCloseSnack = () => {
        this.setState({ openSnack: false });
    };

    onOpenCena = (e) => {
        e.preventDefault()
        this.setState({ openCena: true });
    };

    onCloseCena = () => {
        this.setState({ openCena: false });
    };

    handleOpen = (e,product) => {
        e.preventDefault()


        if(product.activo_hall==0 || product.activo==0){
            toastError("Producto no disponible")
            return
        }

        this.setState({
            product:product,
            inCart:productInCart(product)
        })

        //caso plan alimenticio
        if(product.patron_default==3){


            if(product.tipo_hall==1){
                this.onOpenSemanal(e)
            }
            if(product.tipo_hall==2){
                this.onOpenQuincenal(e)
            }
            if(product.tipo_hall==4){
                this.onOpenMensual(e)
            }

        
        // resto de casos
        }else{

            if(product.patron_default==0){
                this.onOpenSnack(e)
            }

            if(product.patron_default==1){
                this.onOpenCore(e)
            }

            if(product.patron_default==2){
                this.onOpenRestaurant(e)
            }

            if(product.patron_default==4){
                this.onOpenHuerta(e)
            }

            if(product.patron_default==5){
                this.onOpenCongelado(e)
            }

            if(product.patron_default==6){
                this.onOpenCena(e)
            }

        }
        
    }

    render() {

        const {load,error,data,product,openRestaurant,openSemanal,openQuincenal,openMensual,openHuerta,openCongelado,openCore,openSnack,openCena,inCart}= this.state
        const {products,categories}=this.props

        const list_products=products.filter((producto)=>
            data.some((value)=>value.id==producto.id)
        )

        const list_sugeridos=list_products.map((value,i)=>{
            return(
                <div key={i} className="sugerido-item" onClick={(e)=>{this.handleOpen(e,value)}}>

                    <div>
                        <div className={"sugerido-card-home "}>
                            <div className="container">
                                <div className="row">
                                    
                                    <div className="sugerido-home-label ">
                                        {stringPreview(value.name,15)}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
        })

        return (
            <>

            <div className="sugeridos-home-slider-box mb-display">

                {
                    load?
                        <div className="row margin-zero cargando-sugerido">
                            <div className="col-sm-12 text-center no-found" >
                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mt-1 mb-1" />
                            </div>
                        </div>
                    :
                        error?
                            <div className="tac error-sugerido">
                                    No se pudo cargar sugeridos
                            </div>
                        :
                            <div className="w-max-content ml-3">

                            {list_sugeridos}
            
                            </div>
                }

                    <Modal classNames={{'modal': 'mb-modal'}} open={openRestaurant} onClose={this.onCloseRestaurant} center >
                        
                        <ItemTipoRestaurant product={product} close={this.onCloseRestaurant} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openSemanal} onClose={this.onCloseSemanal} center>
                        
                        <ItemTipoSemanal product={product} close={this.onCloseSemanal} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openQuincenal} onClose={this.onCloseQuincenal} center>
                        
                        <ItemTipoQuincenal product={product} close={this.onCloseQuincenal} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openMensual} onClose={this.onCloseMensual} center>
                        
                        <ItemTipoMensual product={product} close={this.onCloseMensual} />

                    </Modal>

                    <Modal  classNames={{'modal': 'modal-product-style','closeIcon':'hidex'}} open={openHuerta} onClose={this.onCloseHuerta} center>
                        
                        <ItemTipoHuerta product={product} close={this.onCloseHuerta} inCart={inCart} />

                    </Modal>


                    <Modal  classNames={{'modal': 'modal-product-style','closeIcon':'hidex'}} open={openCore} onClose={this.onCloseCore} center>
                        
                        <ItemTipoCore product={product} close={this.onCloseCore} inCart={inCart} />

                    </Modal>
                    
                    <Modal  classNames={{'modal': 'modal-product-style','closeIcon':'hidex'}} open={openSnack} onClose={this.onCloseSnack} center>
                        
                        <ItemTipoSnack product={product} close={this.onCloseSnack} inCart={inCart} />

                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={openCena} onClose={this.onCloseCena} center>
                        
                        <ItemTipoCena product={product} close={this.onCloseCena} />

                    </Modal>

            </div>

            </>
        )
    }
}

const mapStateToProps = state => ({
    products: state.data.products,
    categories: state.categories.categories,
})

export default connect(
    mapStateToProps,
    { }
)(withRouter(CategoriesHomeSlider1));