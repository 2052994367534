import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addToCart, addToWishlist, addToCompare, filterCategory } from '../../../actions'
import ProductListItem from './product-list-item';
import { withRouter } from 'react-router-dom';
import { getProductosVisibles } from '../../../services';


class ProductListingInPasillos extends Component {


    constructor(props){
        super(props)
        this.state={
            productos:[]
        }
    }


    goToPasillo = (pasillo) => {
        this.props.history.push("/pasillo/"+pasillo)
    }

    componentDidMount(){
        this.props.filterCategory([])
    }

    
    
    render() {
        const {addToCart, symbol, addToWishlist, addToCompare,products,pasillo,hallValue} = this.props;

        
        const busqueda=products.filter((item)=>{

                return item.hallsId.includes(pasillo)

        })

        console.log(busqueda)

        return (
            <div>
                
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
 
                            <div className="row">
                                { busqueda.map((product, index) =>
                                    
                                    <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-6':'col-lg-'+this.props.colSize}`} key={index}>
                                    <ProductListItem 
                                        product={product} symbol={symbol}
                                        onAddToCompareClicked={() => addToCompare(product)}
                                        onAddToWishlistClicked={() => addToWishlist(product)}
                                        onAddToCartClicked={addToCart} 
                                        key={index}
                                        hallValue={hallValue}
                                    />
                                    </div>)
                                }
                            </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    symbol: state.data.symbol,
    products: getProductosVisibles(state.data, state.filters)
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare,filterCategory}
)(withRouter(ProductListingInPasillos))
