import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getArrayCategoriesSlider } from '../../funciones/pasillo'
import { LazyImage } from "react-lazy-images";
import CategoriesHomeSlider1 from './CategoriesHomeSlider1';
import CategoriesHomeSection1 from './CategoriesHomeSection1';
import CategoriesHomeSlider2 from './CategoriesHomeSlider2';

class HomeAliados extends Component {

    constructor(props){
        super()
        this.state={
        }
    }

    goAliado = (seo) => {

        this.props.history.push("/"+seo)
        
    }
    

    render() {

        const {aliados}=this.props

        return (
            <>
            {
                aliados.length>0?
                <div className="d-flex justify-content-center mb-display">
                
                    <div onClick={()=>this.goAliado(aliados[0].seo)}>
                        <img className="home-aliado-img" src={aliados[0].image} alt="aliado 1"/>
                    </div>

                    <div className="mid-aliado-img-container" onClick={()=>this.goAliado(aliados[1].seo)}>
                        <img className="home-aliado-img" src={aliados[1].image} alt="aliado 2"/>
                    </div>

                    <div onClick={()=>this.goAliado(aliados[2].seo)}>
                        <img className="home-aliado-img" src={aliados[2].image} alt="aliado 3"/>
                    </div>

                </div>
                :''
            }
            </>
        )
    }
}

const mapStateToProps = state => ({
    aliados: state.aliado.data,
})

export default connect(
    mapStateToProps,
    { }
)(withRouter(HomeAliados));