import React, { Component } from 'react'
import jsPDF from 'jspdf'
import logo from '../../assets/images/fluio-logo.JPG'
import { getData } from '../../servicios';
import { getStatusText } from '../../funciones/general';
import BotonPSE from '../../assets/images/BotonPSE.png'
import ContactIcons from '../common/ContactIcons';
const queryString = require('query-string');
const nit='901323943-5'
const nombre='FLUIO S.A.S.'


export class PseSuccess extends Component {

    constructor(props){
        super(props)
        this.state={
            loading:true,
            error:false,
            data:null,
        }
    }

    printDocument = () => {

        const {data}=this.state

        const pdf = new jsPDF();


        pdf.text(75, 15, 'Gracias Por Tu Pedido')
        pdf.text(50, 22, 'El Estado De Tu Transacción PSE Es El Siguiente:')

        pdf.setFontSize(12)

        pdf.text(90, 35, 'NIT:')
        pdf.text(82, 41, 'Nombre:')
        pdf.text(70, 47, 'Valor de pago:')
        pdf.text(62, 53, 'Fecha de creación:')
        pdf.text(51, 59, 'Estado de la transacción:')
        pdf.text(85, 65, 'Banco:')
        pdf.text(43, 71, 'Código único de seguimiento:')
        pdf.text(76, 77, 'Referencia:')
        pdf.text(75, 83, 'Descripción:')
        
        pdf.text(105,35, nit)
        pdf.text(105,41, nombre)
        pdf.text(105,47, '$'+data.amount)
        pdf.text(105,53, data.fecha)
        pdf.text(105,59, getStatusText(data.mensaje))
        pdf.text(105,65, data.bankName)
        pdf.text(105,71, data.trazabilityCode)
        pdf.text(105,77, data.cod_f?data.cod_f:'N/A')
        pdf.text(105,83, 'Compra de domicilio saludable en fluio')

        if(data.mensaje=="PENDING"){

            pdf.text(62,100, 'Por favor verificar si el débito fue realizado en el Banco')
            pdf.text(57,106, 'La información de la transaccion se actualiza cada 3 minutos')

        }

        const img = new Image;
        img.src=logo
        img.onload=function() {
            pdf.addImage(this,'JPEG',70,120,80,40)
            pdf.save("comprobante.pdf");
        };
    
    }

    async componentDidMount(){

        const parsed = queryString.parse(window.location.search)

        try {

            const req=await getData("check-order/?token="+parsed.token,{})

            this.setState({
                data:req.data,
                loading:false
            })
            
        } catch (error) {
            console.log(error)
            this.setState({
                error:true
            })
            
        }

    }

    render() {

        const {data}= this.state

        return (
            <section className="section-b-space light-layout">
                <div className="container"  >
                    <div className="row" id="divToPrint">
                        <div className="col-md-12">
                            {
                                this.state.loading ?
                                    <div className="row h-196 margin-zero">
                                        <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                            <h3>{"Cargando"}</h3>
                                        </div>
                                    </div>
                                :

                                <div className="success-text">
                                    <h3 className="bold-text-black">Gracias Por Tu Pedido</h3>
                                    <p>El estado de tu transacción PSE es el siguiente:</p>

                                    <div className="row">

                                        <div className="col-6 pse-title">
                                            Nit:
                                        </div>
                                        <div className="col-6 pse-info">
                                            {nit}
                                        </div>

                                        <div className="col-6 pse-title">
                                            Nombre:
                                        </div>
                                        <div className="col-6 pse-info">
                                            {nombre}
                                        </div>

                                        <div className="col-6 pse-title">
                                            Valor de pago:
                                        </div>
                                        <div className="col-6 pse-info">
                                            ${data.amount}
                                        </div>

                                        <div className="col-6 pse-title">
                                            Fecha de creación:
                                        </div>
                                        <div className="col-6 pse-info">
                                            {data.fecha}
                                        </div>

                                        <div className="col-6 pse-title">
                                            Estado de la transacción
                                        </div>
                                        <div className="col-6 pse-info">
                                            {getStatusText(data.mensaje)}                                      
                                        </div>

                                        <div className="col-6 pse-title">
                                            Banco:
                                        </div>
                                        <div className="col-6 pse-info">
                                        {data.bankName}
                                        </div>

                                        <div className="col-6 pse-title">
                                            Código único de seguimiento:
                                        </div>
                                        <div className="col-6 pse-info">
                                        {data.trazabilityCode}
                                        </div>

                                        <div className="col-6 pse-title">
                                            Referencia:
                                        </div>
                                        <div className="col-6 pse-info">
                                        {data.cod_f?data.cod_f:'N/A'}
                                        </div>

                                        <div className="col-6 pse-title">
                                            Descripcíon:
                                        </div>
                                        <div className="col-6 pse-info">
                                        Compra de domicilio saludable en fluio
                                        </div>
                                        {
                                            data.mensaje=="PENDING"?
                                            <>
                                                <div className="col-12 tac mt-4">
                                                    Por favor verificar si el débito fue realizado en el Banco
                                                    <br/>
                                                    La información de la transacción se actualiza cada 3 minutos
                                                </div>

                                                <div className="col-12 tac mt-4">
                                                    Ir al Banco
                                                    <a href={data.url_x} >
                                                        <img src={BotonPSE} className="pse-button-order" alt={"boton pse"}/>
                                                    </a>
                                                </div>
                                            </>
                                            :''
                                        }                                    
                                        <div className="tac col-12 mt-3 mb-5">

                                            <button onClick={this.printDocument}>Descargar pdf</button>

                                        </div>

                                        

                                    </div>

                                    
                                </div>
                            }
                            
                            
                        </div>
                    </div>
                </div>
                <ContactIcons/>
            </section>
        )
    }
}

export default PseSuccess

