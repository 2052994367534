import React, {Component} from 'react';
import { connect } from 'react-redux'
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { SlideToggle } from 'react-slide-toggle';


import {getBrands, getColors, getMinMaxPrice} from '../../../services';
import {filterBrand, filterColor, filterPrice, filterCategory} from '../../../actions'
import { getData } from '../../../servicios';


class FilterPasillo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openFilter: false,
            categorias:[]
        }

        const value={
            'min': this.props.prices.min,
            'max': this.props.prices.max
        }
        //coloca al inicio el rango maximo en el filtrado de precios
        this.props.filterPrice({ value })
        
    }

    closeFilter = () => {
        document.querySelector(".collection-filter").style = "left: -365px";
    }

    clickBrandHendle(event, brands) {

        var index = brands.indexOf(event.target.value);
        if (event.target.checked)
            brands.push(event.target.value); // push in array checked value
        else
            brands.splice(index, 1); // removed in array unchecked value

        this.props.filterBrand(brands);
    }


    //metodo para manejar el click de una categoria de los checkbox
    clickCategoryHandle(event, categories) {

        var index = categories.indexOf(parseInt(event.target.value));
        if (event.target.checked){
            categories.push(parseInt(event.target.value)); // push in array checked value
        }else{
            categories.splice(index, 1); // removed in array unchecked value
        }

        this.props.filterCategory(categories);
    }

    colorHandle(event, color){
        var elems = document.querySelectorAll(".color-selector ul li");
        [].forEach.call(elems, function(el) {
            el.classList.remove("active");
        });
        event.target.classList.add('active');
        this.props.filterColor(color)
    }

    componentDidMount(){
        this.cargarCategorias()
    }

    async cargarCategorias(){

        const response=await getData("categories")
        const json=response['data']

        this.setState({
            categorias:json
        })

    }

    handlePrice = (value) => {

        //console.log(value)

        this.props.filterPrice({ value })
        
    }
    

    render (){

        const { categorias } = this.state;
        const filteredCategories = this.props.filters.category;

        const cate_checkbox=categorias.filter((value)=>value.pri<7).map((value)=>{

            return(
                <div className="custom-control custom-checkbox collection-filter-checkbox" key={value.id}>
                    <input type="checkbox" onClick={(e) => this.clickCategoryHandle(e,filteredCategories)}  defaultChecked={filteredCategories.includes(value.id)? true : false} value={value.id}  className="custom-control-input" id={value.name} />
                    <label className="custom-control-label"
                            htmlFor={value.name}>{value.name}</label>
                </div>
            )
            
        })

        const especial_checkbox=categorias.filter((value)=>value.pri===7).map((value)=>{

            return(
                <div className="custom-control custom-checkbox collection-filter-checkbox" key={value.id}>
                    <input type="checkbox" onClick={(e) => this.clickCategoryHandle(e,filteredCategories)}  defaultChecked={filteredCategories.includes(value.id)? true : false} value={value.id}  className="custom-control-input" id={value.name} />
                    <label className="custom-control-label"
                            htmlFor={value.name}>{value.name}</label>
                </div>
            )
            
        })


        return (
                <div className="collection-filter-block">
                    {/*brand filter start*/}
                    <div className="collection-mobile-back">
                        <span className="filter-back ttn" onClick={(e) => this.closeFilter(e)} >
                            <i className="fa fa-angle-left" aria-hidden="true"></i> Cerrar
                        </span>
                    </div>
        

                    {/*color filter start here
                    <SlideToggle>
                        {({onToggle, setCollapsibleElement}) => (
                            <div className="collection-collapse-block">
                                <h3 className="collapse-block-title" onClick={onToggle}>colors</h3>
                                <div className="collection-collapse-block-content" ref={setCollapsibleElement}>
                                    <div className="color-selector">
                                        <ul>
                                            {this.props.colors.map((color, index) => {
                                                return (
                                                    <li className={color} title={color} onClick={(e) => this.colorHandle(e, color)} key={index}></li> )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>
                    */}
                    {/*price filter start here */}
                    <SlideToggle>
                        {({onToggle, setCollapsibleElement}) => (
                            <div className="collection-collapse-block open">
                                <h3 className="collapse-block-title ttn" onClick={onToggle}>Precio</h3>
                                <div className="collection-collapse-block-content block-price-content" ref={setCollapsibleElement}>
                                    <div className="collection-brand-filter">
                                        <div className="custom-control custom-checkbox collection-filter-checkbox">
                                            <InputRange
                                                maxValue={this.props.prices.max}
                                                minValue={this.props.prices.min}
                                                value={this.props.filters.value}
                                                onChange={value => this.handlePrice( value )} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </SlideToggle>
                </div>
        )
    }
}


const mapStateToProps = state => ({
    brands: getBrands(state.data.products),
    colors: getColors(state.data.products),
    prices: getMinMaxPrice(state.data.products),
    filters: state.filters
})

export default connect(
    mapStateToProps,
    { filterBrand, filterColor, filterPrice, filterCategory }
)(FilterPasillo);