import React, { Component } from 'react';
import { connect } from 'react-redux';
import { postData, getData } from '../servicios';
import { toastError } from '../alertas';
import ProductListItem3 from './collection/common/product-list-item3';
import { addToCart, addToWishlist, addToCompare } from '../actions/index';
import ProductListItemSugerido from './collection/common/ProductListItemSugerido';
import { getLocationProducts } from '../funciones/cities';

class CheckoutSugeridos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            error: false,
            load: true,
        };
    }

    async componentDidMount() {
        try {
            const response = await getData('reco-products');
            const data = response['data'];

            this.setState({
                data: data,
                load: false,
            });
        } catch (error) {
            console.log(error);

            this.setState({
                load: false,
                error: true,
            });
        }
    }

    goChekout = () => {
        window.location.href = '/checkout';
    };

    render() {
        const { load, error, data } = this.state;
        const { products, symbol } = this.props;

        const list_products = products.filter((producto) => data.some((value) => value.id == producto.id));

        return (
            <div className="float-box mb-display">
                <div className="tac" className="sugeridos-header2 mb-1">
                    <div className="d-flex justify-content-between bd-highlight">
                        <div className="bd-highlight" />
                        <div className="bd-highlight mt-2">No olvides llevar</div>
                        <div className="bd-highlight cerrar-sugerido2" onClick={this.goChekout}>
                            cerrar <i className="fa fa-close close-sugerido-icon" />
                        </div>
                    </div>
                </div>

                {load ? (
                    <div className="row margin-zero cargando-sugerido">
                        <div className="col-sm-12 text-center no-found">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`}
                                className="img-fluid mt-1 mb-1"
                            />
                        </div>
                    </div>
                ) : error ? (
                    <div className="tac error-sugerido">No se pudo cargar productos</div>
                ) : (
                    <div>
                        <div className="check-sugerido-box slider-sugeridos">
                            <div className="row">
                                {list_products.map((product, index) => (
                                    /**
                                                <div key={index} className={"mr-2 inline"}>
                                                    <ProductListItem3 product={product} symbol={symbol}
                                                    onAddToCompareClicked={() => addToCompare(product)}
                                                    onAddToWishlistClicked={() => addToWishlist(product)}
                                                    onAddToCartClicked={addToCart} key={index}
                                                    products_length={list_products.length}
                                                    index={index}/>
                                                </div>
                                                */

                                    <div key={index} className={'col-4 margin-check-sugerido'}>
                                        <ProductListItemSugerido
                                            product={product}
                                            symbol={symbol}
                                            onAddToCompareClicked={() => addToCompare(product)}
                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                            onAddToCartClicked={addToCart}
                                            key={index}
                                            products_length={list_products.length}
                                            index={index}
                                        />
                                    </div>
                                ))}

                                <div className="col-12" style={{ height: '20px' }} />
                            </div>
                        </div>
                    </div>
                )}

                <a href={`${process.env.PUBLIC_URL}/checkout`} className="btn btn-suge-cont">
                    Continuar
                </a>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    products: getLocationProducts(),
    symbol: state.data.symbol,
});

export default connect(
    mapStateToProps,
    { addToCart, addToWishlist, addToCompare }
)(CheckoutSugeridos);
