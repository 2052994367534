import React, { Component } from 'react'
import FormError from '../common/FormError'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateHeaders, updateUser } from '../../actions'
import { getData, postData, putData, putDataNoId } from '../../servicios'
import { toastSuccess } from '../../alertas'
import store from '../../store'
import { cleverUsedCode } from '../../funciones/clevertap'


class BonoForm extends Component {

    constructor(props){
        super(props)
        this.state={
            code:'',
            error:'',
            loading:false,
        }
    }

    handleChange= (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        const{user}= this.props


        this.setState({
            loading:true,
            error:'',
        })

        let json={
            email:user.email,
            promo:this.state.code,
        }

        console.log(json)

        try{

            const req=await postData("apply-promo",json,{})
            const req_saldo= await getData("saldo/?id="+user.id)
            toastSuccess("Canje exitoso")

            let cleverData={
                email:user.email,
                codigo:this.state.code,
            }

            cleverUsedCode(cleverData)

            let newData=user
            newData.saldo=parseFloat(req_saldo.data.saldo)
            store.dispatch(updateUser(newData))

            setTimeout(() => {
                window.location.reload()
            }, 1000);

            
        }catch (error) {

            console.log(error)
        
            if(error.response){

                console.log(error.response)

                this.setState({
                    error:error.response.data.errors,
                    loading:false
                })

            }else{
                this.setState({
                    error:"Error inesperado",
                    loading:false
                })
            }
            
        }
        
    }
    


    render() {

        const {error,loading}=this.state

        return (
<           div>
                {
                    loading ?

                        <div className="row h-196">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                <h3 >Verificando código</h3>
                            </div>
                        </div>

                    :

                        <form className="theme-form" onSubmit={this.handleSubmit} >
                            <strong>Canjea un código</strong>
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="code" placeholder="Código" onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <FormError text={error} show={error}/>
                                <button type="submit" style={{width: '100%'}} className="btn btn-solid ttn">Canjear código</button>                  
                            </div>
                            
                        </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    headers: state.customer.headers
})

export default connect(
    mapStateToProps, { updateHeaders }
)(withRouter(BonoForm))