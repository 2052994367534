import store from '../store';
import {
    getTotalGeneral,
    calcularCosto,
    calcularRestaurante,
    getArrayAdicional,
    getNumberBebida,
    getNumberPostre,
    calcularPlan,
    getDeliveryCost,
    getMatchDelivery,
} from '../services';
import { isLogin, notWeekend } from './general';
import Swal from 'sweetalert2';
import moment from 'moment';
import horaRestaurante from '../api/horarios/tipoRestaurante.json';
import horaCenaSenama from '../api/horarios/cenaSemana.json';
import horaCenaFin from '../api/horarios/cenaFin.json';
import { getDiffMinutes, getNextMonday, isHoraCenaDisponible } from '../servicios';
import { getFechaPatronHuertaYCongelado } from './patrones';
import { dataLogout } from '../actions';
import { isActualDirectionLocal, isLocation } from './cities';

export const getDescuento = () => {
    if (isLogin()) {
        const total = getTotalGeneral();
        const descuento = parseFloat(store.getState().customer.data.saldo);

        const resultado = total - descuento;

        if (resultado > 0) {
            return descuento;
        } else {
            return total;
        }
    } else {
        return 0;
    }
};

export const tokenRequetError = (error, code, text) => {
    console.log('alert');

    Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: true,
        footer: code ? 'Code:' + code : '',
        text: text ? text : '',
    }).then(() => {
        if (error == 'Estatus:401') {
            store.dispatch(dataLogout());
        }
        window.location.reload();
    });
};

export const getArrayProductosOrder = () => {
    const snack = store.getState().snack;
    const core = store.getState().core;
    const restaurante = store.getState().restaurante;
    const plan = store.getState().cartList;
    const huerta = store.getState().huerta;
    const congelado = store.getState().congelado;
    const cena = store.getState().cena;
    const external = store.getState().external;

    let array_productos = [];

    if (snack.cart.length > 0) {
        snack.cart.forEach((value) => {
            array_productos.push(armarItemSnack(value));
        });
    }

    if (core.cart.length > 0) {
        core.cart.forEach((value) => {
            array_productos.push(armarItemCore(value));
        });
    }

    if (external.cart.length > 0) {
        external.cart.forEach((value) => {
            array_productos.push(armarItemExternal(value));
        });
    }

    if (restaurante.cart.length > 0) {
        restaurante.cart.forEach((value) => {
            array_productos.push(armarItemRestaurante(value));
        });
    }

    if (plan.cart.length > 0) {
        plan.cart.forEach((value) => {
            array_productos.push(armarItemPlan(value));
        });
    }

    if (huerta.cart.length > 0) {
        huerta.cart.forEach((value) => {
            array_productos.push(armarItemHuerta(value));
        });
    }

    if (congelado.cart.length > 0) {
        congelado.cart.forEach((value) => {
            array_productos.push(armarItemCongelado(value));
        });
    }

    if (cena.cart.length > 0) {
        cena.cart.forEach((value) => {
            array_productos.push(armarItemCena(value));
        });
    }

    return array_productos;
};

const armarItemSnack = (value) => {
    const snack = store.getState().snack;
    const delivery = getDeliveryCost(0);

    let fecha;
    let hora;

    if (snack.programacion == 1) {
        hora = '1';
        fecha = moment().format('DD/MM/YYYY');
    } else {
        hora = snack.programacion.hora;
        fecha = snack.programacion.fecha;
    }

    let product = {
        product_id: value.id,
        tipo: 0,
        amount: value.qty,
        hora: hora,
        fecha_i: fecha,
        fecha_f: fecha,
        delivery: delivery,
        total: calcularCosto(value),
    };

    return product;
};

const armarItemCore = (value) => {
    const core = store.getState().core;
    const delivery = CoreHuertaMatch() ? getMatchDelivery() : getDeliveryCost(1);

    let fecha;
    let hora;

    if (core.programacion == 1) {
        hora = '1';
        fecha = moment().format('DD/MM/YYYY');
    } else {
        hora = core.programacion.hora;
        fecha = core.programacion.fecha;
    }

    let product = {
        product_id: value.id,
        tipo: 1,
        amount: value.qty,
        hora: hora,
        fecha_i: fecha,
        fecha_f: fecha,
        delivery: delivery,
        total: calcularCosto(value),
        com: value.comment,
    };

    return product;
};

const armarItemExternal = (value) => {
    const external = store.getState().external;
    const delivery = 0;

    let fecha;
    let hora;

    hora = 'N/A';
    fecha = external.programacion.fecha;

    let product = {
        product_id: value.id,
        tipo: value.tipo_cate,
        amount: value.qty,
        hora: hora,
        fecha_i: fecha,
        fecha_f: fecha,
        delivery: delivery,
        total: calcularCosto(value),
        com: value.comment,
    };

    return product;
};

const mananaDisponibe = () => {
    let dia = moment().isoWeekday();

    if (dia == 6 || dia == 7 || dia == 5) {
        return false;
    } else {
        return true;
    }
};

const armarItemRestaurante = (value) => {
    const restaurante = store.getState().restaurante;
    const delivery = store.getState().delivery;
    let fecha;

    const val_horas = horaRestaurante.filter((value) => {
        if (getDiffMinutes(value.hora) >= 50) {
            return true;
        }
    });

    if (val_horas.length > 0 && notWeekend()) {
        fecha = moment().format('DD/MM/YYYY');
    } else {
        if (mananaDisponibe()) {
            fecha = moment()
                .add(1, 'days')
                .format('DD/MM/YYYY');
        } else {
            const today = moment().isoWeekday();

            if (today == 5) {
                // then just give me this week's instance of that day
                fecha = moment()
                    .add(3, 'days')
                    .format('DD/MM/YYYY');
            }
            if (today == 6) {
                // then just give me this week's instance of that day
                fecha = moment()
                    .add(2, 'days')
                    .format('DD/MM/YYYY');
            }
            if (today == 7) {
                // then just give me this week's instance of that day
                fecha = moment()
                    .add(1, 'days')
                    .format('DD/MM/YYYY');
            }
        }
    }

    let product = {
        product_id: value.id,
        tipo: 2,
        amount: value.qty,
        hora: restaurante.hora_entrega,
        fecha_i: fecha,
        fecha_f: fecha,
        delivery: delivery.restaurante,
        total: calcularRestaurante(value),
        adicional: getArrayAdicional(value.postre, value.bebida),
        bebidas_n: getNumberBebida(value),
        postres_n: getNumberPostre(value),
        com: value.comment,
    };

    return product;
};

const getDias = (value) => {
    let dias = '[';

    if (value.tipo_hall == 1) {
        value.programacion.semana1.forEach((dia, index) => {
            dias += dia;
            if (index !== value.programacion.semana1.length - 1) {
                dias += ',';
            }
        });

        dias += ']';
    }
    if (value.tipo_hall == 2) {
        value.programacion.semana1.forEach((dia, index) => {
            dias += dia;
            if (index !== value.programacion.semana1.length - 1) {
                dias += ',';
            }
        });

        dias += '],[';

        value.programacion.semana2.forEach((dia, index) => {
            dias += dia;
            if (index !== value.programacion.semana2.length - 1) {
                dias += ',';
            }
        });

        dias += ']';
    }
    if (value.tipo_hall == 4) {
        value.programacion.semana1.forEach((dia, index) => {
            dias += dia;
            if (index !== value.programacion.semana1.length - 1) {
                dias += ',';
            }
        });

        dias += '],[';

        value.programacion.semana2.forEach((dia, index) => {
            dias += dia;
            if (index !== value.programacion.semana2.length - 1) {
                dias += ',';
            }
        });

        dias += '],[';

        value.programacion.semana3.forEach((dia, index) => {
            dias += dia;
            if (index !== value.programacion.semana3.length - 1) {
                dias += ',';
            }
        });

        dias += '],[';

        value.programacion.semana4.forEach((dia, index) => {
            dias += dia;
            if (index !== value.programacion.semana4.length - 1) {
                dias += ',';
            }
        });

        dias += ']';
    }

    return dias;
};

const getFechaFinalPlan = (tipo) => {
    const primer_lunes = getNextMonday();

    if (tipo == 1) {
        return moment(primer_lunes, 'DD/MM/YYYY')
            .add(4, 'days')
            .format('DD/MM/YYYY');
    }
    if (tipo == 2) {
        return moment(primer_lunes, 'DD/MM/YYYY')
            .add(11, 'days')
            .format('DD/MM/YYYY');
    }
    if (tipo == 4) {
        return moment(primer_lunes, 'DD/MM/YYYY')
            .add(25, 'days')
            .format('DD/MM/YYYY');
    }
};

const armarItemPlan = (value) => {
    const delivery = store.getState().delivery;

    let product = {
        product_id: value.id,
        tipo: 3,
        amount: value.qty,
        hora: value.programacion.hora,
        fecha_i: getNextMonday(),
        fecha_f: getFechaFinalPlan(value.tipo_hall),
        delivery: delivery.plan,
        total: calcularPlan(value),
        dias: getDias(value),
    };

    return product;
};

const armarItemHuerta = (value) => {
    const huerta = store.getState().huerta;
    const delivery = CoreHuertaMatch() ? getMatchDelivery() : getDeliveryCost(4);

    let product = {
        product_id: value.id,
        tipo: 4,
        amount: value.qty,
        hora: huerta.programacion.hora,
        fecha_i: getFechaPatronHuertaYCongelado(huerta.programacion.dia),
        fecha_f: getFechaPatronHuertaYCongelado(huerta.programacion.dia),
        delivery: delivery,
        total: calcularCosto(value),
    };

    return product;
};

const armarItemCongelado = (value) => {
    const congelado = store.getState().congelado;
    const delivery = store.getState().delivery;

    let product = {
        product_id: value.id,
        tipo: 5,
        amount: value.qty,
        hora: congelado.programacion.hora,
        fecha_i: getFechaPatronHuertaYCongelado(congelado.programacion.dia),
        fecha_f: getFechaPatronHuertaYCongelado(congelado.programacion.dia),
        delivery: delivery.congelado,
        total: calcularCosto(value),
    };

    return product;
};

const armarItemCena = (value) => {
    const cena = store.getState().cena;
    const delivery = getDeliveryCost(6);

    let fecha;

    if (isHoraCenaDisponible(horaCenaSenama, horaCenaFin)) {
        fecha = moment().format('DD/MM/YYYY');
    } else {
        fecha = moment()
            .add(1, 'days')
            .format('DD/MM/YYYY');
    }

    let product = {
        product_id: value.id,
        tipo: 6,
        amount: value.qty,
        hora: cena.hora_entrega,
        fecha_i: fecha,
        fecha_f: fecha,
        delivery: delivery,
        total: calcularRestaurante(value),
        adicional: getArrayAdicional(value.postre, value.bebida),
        bebidas_n: getNumberBebida(value),
        postres_n: getNumberPostre(value),
        com: value.comment,
    };

    return product;
};

export const getPaybuttonText = (radio, amount) => {
    if (amount == 0) {
        return 'Realizar Pedido';
    }

    if (radio == 'tdc' || radio == 'pse') {
        return 'Realizar Pedido';
    } else {
        return 'Realizar Pedido';
    }
};

export const CoreHuertaMatch = () => {
    const huerta = store.getState().huerta;
    const core = store.getState().core;

    if (huerta.cart.length > 0 && core.cart.length > 0) {
        const fecha_huerta = getFechaPatronHuertaYCongelado(huerta.programacion.dia);
        const fecha_core = moment(core.programacion.fecha, 'YYYY-MM-DD').format('DD/MM/YYYY');
        const hora_huerta = huerta.programacion.hora;
        const hora_core = core.programacion.hora;

        if (fecha_huerta == fecha_core && hora_huerta == hora_core) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const inHollyDayRange = (date = new Date()) => {
    const begin = new Date('2020-12-24T00:00:00');
    const end = new Date('2021-01-02T23:59:59');

    if (date >= begin && date <= end) {
        return true;
    } else {
        return false;
    }
};

export const coreDateFormat = (date) => {
    return new Date(date + 'T00:00:00');
};

export const isCoreHollyDayPrograming = () => {
    const core = store.getState().core;

    if (core.cart.length > 0) {
        const prograCore = core.programacion;

        if (prograCore == 1) {
            return false;
        } else {
            if (inHollyDayRange(coreDateFormat(prograCore.fecha))) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return false;
    }
};

export const isSnackHollyDayPrograming = () => {
    const snack = store.getState().snack;

    if (snack.cart.length > 0) {
        const prograSnack = snack.programacion;

        if (prograSnack == 1) {
            return false;
        } else {
            if (inHollyDayRange(coreDateFormat(prograSnack.fecha))) {
                return true;
            } else {
                return false;
            }
        }
    } else {
        return false;
    }
};

export const huertaDateFormat = (date) => {
    const fecha = getFechaPatronHuertaYCongelado(date);
    return new Date(moment(fecha, 'DD/MM/YYYY').format('YYYY-MM-DD') + 'T00:00:00');
};

export const isHuertaHollyDayPrograming = () => {
    const huerta = store.getState().huerta;

    if (huerta.cart.length > 0) {
        const prograHuerta = huerta.programacion;

        if (inHollyDayRange(huertaDateFormat(prograHuerta.dia))) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const isHollyDayPrograming = () => {
    if (isHuertaHollyDayPrograming() || isCoreHollyDayPrograming() || isSnackHollyDayPrograming()) {
        return true;
    } else {
        return false;
    }
};

export const getDeliveryAddress = (dir) => {
    return `${dir}`;
};

export const getOrderType = () => {
    //this is inverted values from category,product,hall
    if (isActualDirectionLocal()) {
        return 0;
    } else {
        return 1;
    }
};

export const isOrderCoreHuerta = () => {
    const huerta = store.getState().huerta;
    const core = store.getState().core;

    return huerta.cart.length > 0 && core.cart.length > 0;
};
