import {
    isDomingo,
    isSabado,
    isViernes,
    isHorasDisponibles1h,
    isHorainArray,
    isAfterHora,
    isLunes,
    isBeforeHora,
    isBeforeJueves,
    isMiercoles,
    isUnaHoraUntil,
    isToday,
    getToday,
    getAmericanToLatinDate,
    validAllies,
} from './general';
import * as moment from 'moment';
import { getDiffHoraria, getDiffMinutes, hayHorasDisponibles } from '../servicios';
import store from '../store';
import { getDiaMesEspanolHuertaCongelado } from './momentEspañol';
import { cleverSchedule } from './clevertap';

export const getNextMartes = () => {
    //caso lunes antes de las 10
    if (isLunes() && isBeforeHora('15:00')) {
        return moment()
            .weekday(1)
            .format('DD/MM/YYYY');
    } else {
        // retornta el martes de la semana siguiente
        return moment()
            .add(1, 'weeks')
            .weekday(1)
            .format('DD/MM/YYYY');
    }
};

export const getNextJueves = () => {
    //antes del jueves
    if (isBeforeJueves()) {
        //miercoles despues de las 10:00
        if (isMiercoles() && !isBeforeHora('15:00')) {
            return moment()
                .add(1, 'weeks')
                .weekday(3)
                .format('DD/MM/YYYY');
        } else {
            return moment()
                .weekday(3)
                .format('DD/MM/YYYY');
        }

        //jueves en adelante
    } else {
        return moment()
            .add(1, 'weeks')
            .weekday(3)
            .format('DD/MM/YYYY');
    }
};

export const getNextSabado = () => {
    /**
     * validacion ya que el dia previo no se puede pedir(caso entrega sabado)
     */
    if (isSabado() || isViernes()) {
        if (isViernes() && isBeforeHora('15:00')) {
            // then just give me this week's instance of that day
            return moment()
                .weekday(5)
                .format('DD/MM/YYYY');
        } else {
            // returnta el martes de la semana siguiente
            return moment()
                .add(1, 'weeks')
                .weekday(5)
                .format('DD/MM/YYYY');
        }
    } else {
        if (isDomingo()) {
            // returnta el martes de la semana siguiente
            return moment()
                .add(1, 'weeks')
                .weekday(5)
                .format('DD/MM/YYYY');
        } else {
            // then just give me this week's instance of that day
            return moment()
                .weekday(5)
                .format('DD/MM/YYYY');
        }
    }
};

export const getFechaPatronHuertaYCongelado = (value) => {
    switch (value) {
        case 'mar':
            return getNextMartes();
        case 'jue':
            return getNextJueves();
        case 'sab':
            return getNextSabado();
        default:
            return getNextSabado();
    }
};

export const detFechaPatronCore = (horas) => {
    const programacion = store.getState().core.programacion;
    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD');

    //existe programacion
    if (programacion && false) {
        //programado en menos de una hora
        if (programacion == 1) {
            //menos de una hora valido
            if (isUnaHoraUntil('16:00')) {
                return today;

                //menos de una hora no valido
            } else {
                return tomorrow;
            }

            //programado con fecha
        } else {
            //fecha programada es mayor a la del dia de hoy
            if (new Date(programacion.fecha) > new Date(today)) {
                return programacion.fecha;

                //fecha programado es <= a la del dia de hoy
            } else {
                //hay horas disponibles el dia de hoy
                if (isUnaHoraUntil('16:00')) {
                    return today;

                    //no hay horas disponibles para el dia de hoy
                } else {
                    return tomorrow;
                }
            }
        }

        //no existe programacion
    } else {
        //hay horas disponibles el dia de hoy
        if (isUnaHoraUntil('16:00')) {
            return today;

            //no hay horas disponibles el dia de hoy
        } else {
            return tomorrow;
        }
    }
};

export const detFechaPatronSnack = (horas) => {
    const programacion = store.getState().snack.programacion;
    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment()
        .add(1, 'days')
        .format('YYYY-MM-DD');

    //existe programacion
    if (programacion && false) {
        //programado en menos de una hora
        if (programacion == 1) {
            //menos de una hora valido
            if (isUnaHoraUntil('16:00')) {
                return today;

                //menos de una hora no valido
            } else {
                return tomorrow;
            }

            //programado con fecha
        } else {
            //fecha programada es mayor a la del dia de hoy
            if (new Date(programacion.fecha) > new Date(today)) {
                return programacion.fecha;

                //fecha programado es <= a la del dia de hoy
            } else {
                //hay horas disponibles el dia de hoy
                if (isUnaHoraUntil('16:00')) {
                    return today;

                    //no hay horas disponibles para el dia de hoy
                } else {
                    return tomorrow;
                }
            }
        }

        //no existe programacion
    } else {
        //hay horas disponibles el dia de hoy
        if (isUnaHoraUntil('16:00')) {
            return today;

            //no hay horas disponibles el dia de hoy
        } else {
            return tomorrow;
        }
    }
};

export const detRadioPatronCore = (horas) => {
    const programacion = store.getState().core.programacion;

    //existe programacion
    if (programacion && false) {
        //programado a menos de una hora
        if (programacion == 1) {
            //menos de 1 hra disponible
            if (isUnaHoraUntil('18:00') && isAfterHora('9:30') && validAllies()) {
                return 1;

                //menos de una hora no disponible
            } else {
                return 2;
            }

            //programado con fecha
        } else {
            return 2;
        }

        //no existe programacion
    } else {
        //hay horas disponibles
        if (isUnaHoraUntil('18:00') && isAfterHora('9:30') && validAllies()) {
            return 1;

            //ya no hay horas disponibles
        } else {
            return 2;
        }
    }
};

export const detRadioPatronSnack = (horas) => {
    const programacion = store.getState().snack.programacion;

    //existe programacion
    if (programacion && false) {
        //programado a menos de una hora
        if (programacion == 1) {
            //menos de 1 hra disponible
            if (isUnaHoraUntil('18:00') && isAfterHora('9:30') && validAllies()) {
                return 1;

                //menos de una hora no disponible
            } else {
                return 2;
            }

            //programado con fecha
        } else {
            return 2;
        }

        //no existe programacion
    } else {
        //hay horas disponibles
        if (isUnaHoraUntil('18:00') && isAfterHora('9:30') && validAllies()) {
            return 1;

            //ya no hay horas disponibles
        } else {
            return 2;
        }
    }
};

export const getArrayPatronCore = (horas) => {
    const programacion = store.getState().core.programacion;
    const today = moment().format('YYYY-MM-DD');

    //existe programacion
    if (programacion && false) {
        //programa a menos de una hora
        if (programacion == 1) {
            //hay horas disponibles para el dia de hoy
            if (isUnaHoraUntil('16:00')) {
                return horas.filter((value) => getDiffMinutes(value.hora) > 170);

                //no hay horas disponibles para el dia de hoy
            } else {
                return horas;
            }

            //programado a una fecha
        } else {
            //la fecha programada es mayor a la del dia de hoy
            if (new Date(programacion.fecha) > new Date(today)) {
                return horas;

                //la fecha programada es <= a la del dia de hoy
            } else {
                //hay horas disponibles para el dia de hoy
                if (isUnaHoraUntil('16:00')) {
                    return horas.filter((value) => getDiffMinutes(value.hora) > 170);

                    //no hay horas disponibles para el dia de hoy
                } else {
                    return horas;
                }
            }
        }

        // no existe programacion
    } else {
        //hay horas disponibles para el dia de hoy
        if (isUnaHoraUntil('16:00')) {
            return horas.filter((value, index) => getDiffMinutes(value.hora) > 170);

            //no hay horas disponibles para el dia de hoy
        } else {
            return horas;
        }
    }
};

export const getArrayPatronSnack = (horas) => {
    const programacion = store.getState().snack.programacion;
    const today = moment().format('YYYY-MM-DD');

    //existe programacion
    if (programacion && false) {
        //programa a menos de una hora
        if (programacion == 1) {
            //hay horas disponibles para el dia de hoy
            if (isUnaHoraUntil('16:00')) {
                return horas.filter((value) => getDiffMinutes(value.hora) > 170);

                //no hay horas disponibles para el dia de hoy
            } else {
                return horas;
            }

            //programado a una fecha
        } else {
            //la fecha programada es mayor a la del dia de hoy
            if (new Date(programacion.fecha) > new Date(today)) {
                return horas;

                //la fecha programada es <= a la del dia de hoy
            } else {
                //hay horas disponibles para el dia de hoy
                if (isUnaHoraUntil('16:00')) {
                    return horas.filter((value) => getDiffMinutes(value.hora) > 170);

                    //no hay horas disponibles para el dia de hoy
                } else {
                    return horas;
                }
            }
        }

        // no existe programacion
    } else {
        //hay horas disponibles para el dia de hoy
        if (isUnaHoraUntil('16:00')) {
            return horas.filter((value, index) => getDiffMinutes(value.hora) > 170);

            //no hay horas disponibles para el dia de hoy
        } else {
            return horas;
        }
    }
};

export const initialHourCore = (horas, fecha) => {
    const programacion = store.getState().core.programacion;
    const today = moment().format('YYYY-MM-DD');
    const array_horas = getArrayPatronCore(horas, fecha);

    //existe programacion
    if (programacion && false) {
        //programado a menos de una hora
        if (programacion == 1) {
            console.log(array_horas);

            return array_horas[0].hora;

            //programado a una fecha
        } else {
            //la fecha programada es mayor o igual a la del dia de hoy
            if (new Date(programacion.fecha) > new Date(today)) {
                return programacion.hora;

                //la fecha programada es menor igual a la del dia de hoy
            } else {
                //la fecha seleccionada en el input es mayor a la dia de hoy
                if (new Date(fecha) > new Date(programacion.fecha)) {
                    return array_horas[0].hora;

                    //la fecha seleccionada en el input es menor igual a la dia de hoy
                } else {
                    //la hora programada existe en el array
                    if (isHorainArray(programacion.hora, array_horas)) {
                        return programacion.hora;

                        //la hora programada no existe en el array
                    } else {
                        return array_horas[0].hora;
                    }
                }
            }
        }

        //no existe programacion
    } else {
        return array_horas[0].hora;
    }
};

export const initialHourSnack = (horas, fecha) => {
    const programacion = store.getState().snack.programacion;
    const today = moment().format('YYYY-MM-DD');
    const array_horas = getArrayPatronSnack(horas, fecha);

    //existe programacion
    if (programacion && false) {
        //programado a menos de una hora
        if (programacion == 1) {
            return array_horas[0].hora;

            //programado a una fecha
        } else {
            //la fecha programada es mayor o igual a la del dia de hoy
            if (new Date(programacion.fecha) > new Date(today)) {
                return programacion.hora;

                //la fecha programada es menor igual a la del dia de hoy
            } else {
                //la fecha seleccionada en el input es mayor a la dia de hoy
                if (new Date(fecha) > new Date(programacion.fecha)) {
                    return array_horas[0].hora;

                    //la fecha seleccionada en el input es menor igual a la dia de hoy
                } else {
                    //la hora programada existe en el array
                    if (isHorainArray(programacion.hora, array_horas)) {
                        return programacion.hora;

                        //la hora programada no existe en el array
                    } else {
                        return array_horas[0].hora;
                    }
                }
            }
        }

        //no existe programacion
    } else {
        return array_horas[0].hora;
    }
};

export const getMinDateCore = (horas) => {
    if (isUnaHoraUntil('16:00')) {
        return moment().format('YYYY-MM-DD');
    } else {
        return moment()
            .add(1, 'days')
            .format('YYYY-MM-DD');
    }
};

export const getMinDateExternal = () => {
    return moment()
        .add(3, 'days')
        .format('YYYY-MM-DD');
};

export const getArrayDateCore = (horas) => {
    const initialDate = getMinDateCore(horas);

    const array = [initialDate];

    for (let index = 1; index < 5; index++) {
        array.push(
            moment(initialDate, 'YYYY-MM-DD')
                .add(index, 'days')
                .format('YYYY-MM-DD')
        );
    }

    return array;
};

export const getArrayDateExternal = () => {
    const initialDate = getMinDateExternal();

    const array = [initialDate];

    for (let index = 1; index < 5; index++) {
        array.push(
            moment(initialDate, 'YYYY-MM-DD')
                .add(index, 'days')
                .format('YYYY-MM-DD')
        );
    }

    return array;
};

//recive el array ordenado de menor a mayor
export const getDateOrdenadoHuertaCongelado = () => {
    const array = [
        {
            date: moment(getNextMartes(), 'DD-MM-YYYY').format('YYYY-MM-DD'),
            day: moment(getNextMartes(), 'DD-MM-YYYY').format('dddd'),
        },
        {
            date: moment(getNextJueves(), 'DD-MM-YYYY').format('YYYY-MM-DD'),
            day: moment(getNextJueves(), 'DD-MM-YYYY').format('dddd'),
        },
        {
            date: moment(getNextSabado(), 'DD-MM-YYYY').format('YYYY-MM-DD'),
            day: moment(getNextSabado(), 'DD-MM-YYYY').format('dddd'),
        },
    ];

    return array.sort(function(a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.date) - new Date(b.date);
    });
};

export const getNumberDeliveryPlan = (product) => {
    if (product.tipo_hall == 1) {
        return product.programacion.semana1.length;
    }
    if (product.tipo_hall == 2) {
        return product.programacion.semana1.length + product.programacion.semana2.length;
    }

    if (product.tipo_hall == 4) {
        const dias_semanas =
            product.programacion.semana1.length +
            product.programacion.semana2.length +
            product.programacion.semana3.length +
            product.programacion.semana4.length;

        return dias_semanas;
    }
};

export const getCostoPlan = (product) => {
    if (product.tipo_hall == 1) {
        return product.price * product.programacion.semana1.length;
    }
    if (product.tipo_hall == 2) {
        return product.price * (product.programacion.semana1.length + product.programacion.semana2.length);
    }

    if (product.tipo_hall == 4) {
        const dias_semanas =
            product.programacion.semana1.length +
            product.programacion.semana2.length +
            product.programacion.semana3.length +
            product.programacion.semana4.length;

        return product.price * dias_semanas;
    }
};

export const getDiaEntregaHuerta = (day) => {
    switch (day) {
        case 'mar':
            return getDiaMesEspanolHuertaCongelado(getNextMartes());
        case 'jue':
            return getDiaMesEspanolHuertaCongelado(getNextJueves());
        case 'sab':
            return getDiaMesEspanolHuertaCongelado(getNextSabado());
        default:
            return getDiaMesEspanolHuertaCongelado(getNextSabado());
    }
};

export const getListaHorasRepeticionCore = (horas) => {
    if (isUnaHoraUntil('18:00')) {
        return horas.filter((value, index) => getDiffMinutes(value.hora) > 170);

        //no hay horas disponibles para el dia de hoy
    } else {
        return horas;
    }
};

export const getTimeRange = (value) => {
    return (
        value +
        ' a ' +
        moment(value, 'HH:mm')
            .add(30, 'minutes')
            .format('HH:mm')
    );
};

export const checkProgram = () => {
    const snack = store.getState().snack;
    const core = store.getState().core;
    const huerta = store.getState().huerta;

    return getAmericanToLatinDate(snack.programacion.fecha);
};

export const sendSchedule = (programing) => {
    const snack = store.getState().snack;
    const core = store.getState().core;
    const huerta = store.getState().huerta;

    if (snack.cart.length > 0) {
        let snackJSON = {
            tipo: 'Snack',
        };

        if (snack.programacion == 1) {
            snackJSON.fecha = getToday();
            snackJSON.hora = 'Menos de 90 minutos';
        } else {
            snackJSON.fecha = getAmericanToLatinDate(snack.programacion.fecha);
            snackJSON.hora = snack.programacion.hora;
        }

        cleverSchedule(snackJSON);
    }

    if (core.cart.length > 0) {
        let coreJSON = {
            tipo: 'Mercado',
        };

        if (core.programacion == 1) {
            coreJSON.fecha = getToday();
            coreJSON.hora = 'Menos de 90 minutos';
        } else {
            coreJSON.fecha = getAmericanToLatinDate(core.programacion.fecha);
            coreJSON.hora = core.programacion.hora;
        }

        cleverSchedule(coreJSON);
    }

    if (huerta.cart.length > 0) {
        let huertaJSON = {
            tipo: 'Huerta',
            fecha: getFechaPatronHuertaYCongelado(huerta.programacion.dia),
            hora: huerta.programacion.hora,
        };

        cleverSchedule(huertaJSON);
    }
};

export const transformDateHuertaToCore = (date) => {
    return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
};
