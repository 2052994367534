import React, { Component } from 'react'
import FormError from '../common/FormError'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateHeaders } from '../../actions'
import { putData, putDataNoId } from '../../servicios'
import { toastSuccess } from '../../alertas'


class PasswordForm extends Component {

    constructor(props){
        super(props)
        this.state={
            newpassword:'',
            confirmPassword:'',
            error:false,
            loading:false,
            noMatch:false
        }
    }

    handleChange= (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        if(this.state.newpassword!=this.state.confirmPassword){
            this.setState({
                noMatch:true,
            })
            return
        }

        this.setState({
            loading:true,
            error:false,
        })


        let json={
            password:this.state.newpassword,
            password_confirmation:this.state.confirmPassword,
        }

        
        

        try{

            const config={
                headers: this.props.headers
            }

            const req=await putDataNoId("customer_auth/password",json,config)
            this.props.updateHeaders(req.headers)
            toastSuccess("Contraseña actualizada")
            this.props.close()

            
        }catch (error) {

            console.log(error)
        
            if(error.response){

                console.log(error.response)

                this.setState({
                    error:true,
                    loading:false
                })

            }else{
                this.setState({
                    error:true,
                    loading:false
                })
            }
            
        }
        
    }
    


    render() {

        const {error,loading,noMatch}=this.state

        return (
<           div>
                {
                    loading ?

                        <div className="row h-196">
                            <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} className="img-fluid mb-4" />
                                <h3 >Actualizando contraseña</h3>
                            </div>
                        </div>

                    :

                        <form className="theme-form" onSubmit={this.handleSubmit} >
                            <strong>Actualiza tu contraseña</strong>
                            <div className="form-group">
                                <label htmlFor="newpassword" className="ttn">Nueva contraseña</label>
                                <input type="password" className="form-control" name="newpassword" placeholder="Nueva contraseña" onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword" className="ttn">Confirmar nueva contraseña</label>
                                <input type="password" className="form-control" name="confirmPassword" placeholder="Confirmar contraseña" onChange={this.handleChange} required />
                            </div>
                            <div className="form-group">
                                <FormError text="Ocurrio un problema intenta de nuevo" show={error}/>
                                <FormError text="Las contraseñas no coinciden " show={noMatch}/>
                                <button type="submit" className="btn btn-solid ttn">Solicitar nueva contraseña</button>                           
                            </div>
                            
                        </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.customer.data,
    headers: state.customer.headers
})

export default connect(
    mapStateToProps, { updateHeaders }
)(withRouter(PasswordForm))
