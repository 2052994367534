import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCartIndividual, getNumberDeliveryPlan, getCostoPlan } from '../../../services'
import Modal from 'react-responsive-modal';
import DetalleSemanal from '../modales/DetalleSemanal';
import DetalleQuincenal from '../modales/DetalleQuincenal';
import DetalleMensual from '../modales/DetalleMensual';

class CheckPlan extends Component {


    constructor(props){
        super(props)
        this.state={
            openSemanal: false,
            openQuincenal: false,
            openMensual: false,
            product:null,
        }
    }


    onOpenSemanal = () => {
        this.setState({ openSemanal: true });
    };

    onCloseSemanal = () => {
        this.setState({ openSemanal: false });
    };

    onOpenQuincenal = () => {
        this.setState({ openQuincenal: true });
    };

    onCloseQuincenal = () => {
        this.setState({ openQuincenal: false });
    };

    onOpenMensual = () => {
        this.setState({ openMensual: true });
    };

    onCloseMensual = () => {
        this.setState({ openMensual: false });
    };




    handleModal = (e,product) => {
        e.preventDefault()
        this.setState({
            product:product
        },()=>{

                if(product.tipo_hall==1){
                    this.onOpenSemanal()
                }

                if(product.tipo_hall==2){
                    this.onOpenQuincenal()
                }

                if(product.tipo_hall==4){
                    this.onOpenMensual()
                }

        })

    }


    render() {

        const{cartItems,total,symbol,delivery}=this.props

        if(cartItems.length>0){

            return(

                <div>

                    <strong className="center70 font-25">Planes alimenticios</strong>
                    <br/>
                    <div className="title-box">
                        <div className="row">
                            <div className="col-6">
                                Producto
                            </div>
                            <div className="col-6">
                                Total/Entrega
                            </div>
                        </div>
                    </div>
                    <ul className="qty">
                        {
                            cartItems.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <div className="row">
                                            <div className="col-7">
                                                {item.name} (×{item.qty})
                                            </div>
                                            <div className="col-5">
                                            {symbol}
                                                {
                                                    item.tipo_cate!==3 ? (item.price*item.qty)+(delivery*(getNumberDeliveryPlan(item))) :
                                                    getCostoPlan(item)*item.qty+(delivery*getNumberDeliveryPlan(item))
                                                }
                                                {" / "}
                                                <button className="btn btn-outline-check" onClick={(e)=>{this.handleModal(e,item)}}>
                                                    <i className="fa fa-truck"></i>
                                                </button>
                                            </div>
                                        </div>

                                    </li>
                                )
                            })
                        }
                    </ul>

                    <ul className="sub-total">
                        <li>Subtotal <span className="count">{symbol}{total}</span></li>
                    </ul>


                    <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openSemanal} onClose={this.onCloseSemanal} >
                        <DetalleSemanal close={this.onCloseSemanal} product={this.state.product} />
                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openQuincenal} onClose={this.onCloseQuincenal} >
                        <DetalleQuincenal close={this.onCloseQuincenal} product={this.state.product} />
                    </Modal>

                    <Modal classNames={{'modal': 'mb-modal'}} open={this.state.openMensual} onClose={this.onCloseMensual} >
                        <DetalleMensual close={this.onCloseMensual} product={this.state.product} />
                    </Modal>

                </div>

            )

        }else{
            return ''
        }
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartIndividual(state.cartList.cart),
    delivery:state.delivery.plan,
    programacion:state.huerta.programacion
})

export default connect(
    mapStateToProps,
    {}
)(CheckPlan)
