import store from '../store';
import axios from 'axios';
import { getData, postData } from '../servicios';
import {
    updateOrden,
    updateUser,
    updateCategories,
    updateHalls,
    updateBebida,
    updatePostre,
    removeFromCart,
    addToCart,
    removeFromHuerta,
    addToHuerta,
    removeFromCongelado,
    addToCongelado,
    removeFromCore,
    addToCore,
    removeFromSnack,
    addToSnack,
    removeFromRestaurante,
    addToRestaurante,
    removeFromCena,
    addToCena,
    receiveProducts,
    updateAliado,
    updateDelivery,
    updateHeaders,
} from '../actions';
import { validateActiveInCart } from './validateCart';
import { reduceObjectArray } from './general';

export const cargarStorage = () => {
    const user = store.getState().customer.data;

    let array_productos = [];
    let array_bebidas = [];
    let array_postres = [];
    let array_pasillos = [];
    let array_categorias = [];

    axios
        .all([getData('categories'), getData('allies')])
        .then(
            axios.spread((res1, res2) => {
                res1.data.map((categoria) => {
                    categoria.pasillos.map((pasillo) => {
                        pasillo.bebidas.map((bebida) => {
                            array_bebidas.push(bebida);
                        });

                        pasillo.postres.map((postre) => {
                            array_postres.push(postre);
                        });

                        pasillo.productos.map((producto) => {
                            array_productos.push(producto);
                        });

                        delete pasillo['productos'];

                        array_pasillos.push(pasillo);
                    });

                    delete categoria['pasillos'];
                    array_categorias.push(categoria);
                });

                console.log('data actualizada');
                store.dispatch(updateCategories(orderByPriority(array_categorias)));
                store.dispatch(updateHalls(orderByPriority(array_pasillos)));
                store.dispatch(updateBebida(orderByPriority(array_bebidas)));
                store.dispatch(updatePostre(orderByPriority(array_postres)));
                store.dispatch(updateAliado(orderByPriority(res2.data)));
                store.dispatch(receiveProducts(orderByPriority(buildProductos(array_productos))));

                /** 
        store.dispatch(getAllProducts());
        store.dispatch(updateCategories(res1.data))
        store.dispatch(updatePostre(res2.data))
        store.dispatch(updateBebida(res3.data))
        store.dispatch(updateHalls(res4.data))
        store.dispatch(updateAliado(res5.data))
        console.log("paso data")
        */
            })
        )
        .catch(() => {
            if (store.getState().categories.categories.length == 0) {
                setTimeout(() => {
                    window.location.reload();
                }, 1900);
            }
        });

    if (user) {
        let newData = user;

        getData('usuario/ordenes/?id=' + user.id).then((req) => {
            store.dispatch(updateOrden(req.data));
        });

        axios
            .all([
                getData('usuario/ordenes/?id=' + user.id),
                getData('saldo/?id=' + user.id),
                getData('new-dir?id=' + user.id),
            ])
            .then(
                axios.spread((res1, res2, res3) => {
                    store.dispatch(updateOrden(res1.data));

                    newData.saldo = parseFloat(res2.data.saldo);
                    newData.directions = res3.data.directions;
                    store.dispatch(updateUser(newData));
                })
            );
    }
};

const orderByPriority = (array) => {
    return array.sort((a, b) => a.pri - b.pri);
};

const buildProductos = (array) => {
    const json = reduceObjectArray(array);
    const carrito = store.getState().cartList.cart;
    const carrito_huerta = store.getState().huerta.cart;
    const carrito_congelado = store.getState().congelado.cart;
    const carrito_core = store.getState().core.cart;
    const carrito_snack = store.getState().snack.cart;
    const carrito_restaurante = store.getState().restaurante.cart;
    const carrito_cena = store.getState().cena.cart;

    let Productos = [];
    for (let i = 0; i < json.length; i++) {
        let datos = {
            id: json[i].id,
            name: json[i].name,
            price: parseInt(json[i].price),
            salePrice: 200.0,
            discount: 10,
            pictures: [json[i].image],
            description: json[i].desc,
            shortDetails: 'lorem',
            stock: parseInt(json[i].stock),
            new: false,
            sale: false,
            category: json[i].category_id,
            tipo_cate: json[i].category_type,
            tipo_hall: json[i].hall_type,
            hall: json[i].hall_id,
            aliado: json[i].aliado,
            activo: json[i].activo,
            activo_hall: json[i].activo_hall,
            ally: json[i].ally_id,
            pri: json[i].pri,
            tags: json[i].tags_id,
            if_coment: json[i].if_coment,
            category_name: json[i].category_name,
            hall_name: json[i].hall_name,
            if_offer: json[i].if_offer,
            price_offer: parseInt(json[i].price_offer),
            hallsId: json[i].halls,
            patrones: json[i].patrones,
            patron_default: json[i].patron_default,
            seo: json[i].seo,
            ext: json[i].ext,
        };
        Productos.push(datos);
    }

    const productos_carrito = Productos.filter((producto) => {
        return carrito.some((item) => {
            return item.id == producto.id;
        });
    });

    carrito.forEach((value) => {
        /**
         * variable para saber si
         * el producto realmente esta en el carrito
         */
        let bool = true;

        //algoritmo par actualizar datos del carrito
        productos_carrito.forEach((item) => {
            if (value.id == item.id) {
                bool = false;

                //actualizacion de programacion segun tipo de articulo

                //caso tipo restaurante
                if (item.tipo_cate == 2 || item.tipo_cate == 3) {
                    var programacion = value.programacion;
                }

                if (item.stock == 0) {
                    store.dispatch(removeFromCart(value));
                } else if (value.qty > item.stock) {
                    store.dispatch(removeFromCart(value));
                    store.dispatch(addToCart(item, programacion, item.stock));
                } else {
                    store.dispatch(removeFromCart(value));
                    store.dispatch(addToCart(item, programacion, value.qty));
                }
            }
        });

        if (bool) {
            /**
             * algoritmo para elminar del carrito ya que
             * es posible que algun producto que estaba en el carrito
             * se quedo sin stock
             */
            store.dispatch(removeFromCart(value));
        }
    });

    //mismo algoritmo para caso carrito de huerta

    const productos_carrito_huerta = Productos.filter((producto) => {
        return carrito_huerta.some((item) => {
            return item.id == producto.id;
        });
    });

    carrito_huerta.forEach((value) => {
        /**
         * variable para saber si
         * el producto realmente esta en el carrito
         */
        let bool_huerta = true;

        //algoritmo par actualizar datos del carrito
        productos_carrito_huerta.forEach((item) => {
            if (value.id == item.id) {
                bool_huerta = false;

                if (item.stock == 0) {
                    store.dispatch(removeFromHuerta(value));
                } else if (value.qty > item.stock) {
                    store.dispatch(removeFromHuerta(value));
                    store.dispatch(addToHuerta(item, store.getState().huerta.programacion, item.stock));
                } else {
                    store.dispatch(removeFromHuerta(value));
                    store.dispatch(addToHuerta(item, store.getState().huerta.programacion, value.qty));
                }
            }
        });

        if (bool_huerta) {
            /**
             * algoritmo para elminar del carrito ya que
             * es posible que algun producto que estaba en el carrito
             * se quedo sin stock
             */
            store.dispatch(removeFromHuerta(value));
        }
    });

    //mismo algoritmo para caso carrito de congelados

    const productos_carrito_congelado = Productos.filter((producto) => {
        return carrito_congelado.some((item) => {
            return item.id == producto.id;
        });
    });

    carrito_congelado.forEach((value) => {
        /**
         * variable para saber si
         * el producto realmente esta en el carrito
         */
        let bool_congelado = true;

        //algoritmo par actualizar datos del carrito
        productos_carrito_congelado.forEach((item) => {
            if (value.id == item.id) {
                bool_congelado = false;

                if (item.stock == 0) {
                    store.dispatch(removeFromCongelado(value));
                } else if (value.qty > item.stock) {
                    store.dispatch(removeFromCongelado(value));
                    store.dispatch(addToCongelado(item, store.getState().congelado.programacion, item.stock));
                } else {
                    store.dispatch(removeFromCongelado(value));
                    store.dispatch(addToCongelado(item, store.getState().congelado.programacion, value.qty));
                }
            }
        });

        if (bool_congelado) {
            /**
             * algoritmo para elminar del carrito ya que
             * es posible que algun producto que estaba en el carrito
             * se quedo sin stock
             */
            store.dispatch(removeFromCongelado(value));
        }
    });

    //mismo algoritmo para caso carrito de core

    const productos_carrito_core = Productos.filter((producto) => {
        return carrito_core.some((item) => {
            return item.id == producto.id;
        });
    });

    carrito_core.forEach((value) => {
        /**
         * variable para saber si
         * el producto realmente esta en el carrito
         */
        let bool_core = true;

        //algoritmo par actualizar datos del carrito
        productos_carrito_core.forEach((item) => {
            if (value.id == item.id) {
                bool_core = false;
                item.comment = value.comment;

                if (item.stock == 0) {
                    store.dispatch(removeFromCore(value));
                } else if (value.qty > item.stock) {
                    store.dispatch(removeFromCore(value));
                    store.dispatch(addToCore(item, store.getState().core.programacion, item.stock));
                } else {
                    store.dispatch(removeFromCore(value));
                    store.dispatch(addToCore(item, store.getState().core.programacion, value.qty));
                }
            }
        });

        if (bool_core) {
            /**
             * algoritmo para elminar del carrito ya que
             * es posible que algun producto que estaba en el carrito
             * se quedo sin stock
             */
            store.dispatch(removeFromCore(value));
        }
    });

    //mismo algoritmo para caso carrito de snack

    const productos_carrito_snack = Productos.filter((producto) => {
        return carrito_snack.some((item) => {
            return item.id == producto.id;
        });
    });

    carrito_snack.forEach((value) => {
        /**
         * variable para saber si
         * el producto realmente esta en el carrito
         */
        let bool_snack = true;

        //algoritmo par actualizar datos del carrito
        productos_carrito_snack.forEach((item) => {
            if (value.id == item.id) {
                bool_snack = false;

                if (item.stock == 0) {
                    store.dispatch(removeFromSnack(value));
                } else if (value.qty > item.stock) {
                    store.dispatch(removeFromSnack(value));
                    store.dispatch(addToSnack(item, store.getState().snack.programacion, item.stock));
                } else {
                    store.dispatch(removeFromSnack(value));
                    store.dispatch(addToSnack(item, store.getState().snack.programacion, value.qty));
                }
            }
        });

        if (bool_snack) {
            /**
             * algoritmo para elminar del carrito ya que
             * es posible que algun producto que estaba en el carrito
             * se quedo sin stock
             */
            store.dispatch(removeFromSnack(value));
        }
    });

    //mismo algoritmo para caso carrito de restaurante

    const productos_carrito_restaurante = Productos.filter((producto) => {
        return carrito_restaurante.some((item) => {
            return item.id == producto.id;
        });
    });

    carrito_restaurante.forEach((value) => {
        /**
         * variable para saber si
         * el producto realmente esta en el carrito
         */
        let bool_restaurante = true;

        //algoritmo par actualizar datos del carrito
        productos_carrito_restaurante.forEach((item) => {
            if (value.id == item.id) {
                bool_restaurante = false;

                item.bebida = value.bebida;
                item.postre = value.postre;

                item.num_bebida = value.num_bebida;
                item.num_postre = value.num_postre;
                item.comment = value.comment;

                item.hall = value.hall;

                if (item.stock == 0) {
                    store.dispatch(removeFromRestaurante(value));
                } else if (value.qty > item.stock) {
                    store.dispatch(removeFromRestaurante(value));
                    store.dispatch(addToRestaurante(item, store.getState().restaurante.hora_entrega, item.stock));
                } else {
                    store.dispatch(removeFromRestaurante(value));
                    store.dispatch(addToRestaurante(item, store.getState().restaurante.hora_entrega, value.qty));
                }
            }
        });

        if (bool_restaurante) {
            /**
             * algoritmo para elminar del carrito ya que
             * es posible que algun producto que estaba en el carrito
             * se quedo sin stock
             */
            store.dispatch(removeFromRestaurante(value));
        }
    });

    //mismo algoritmo para caso carrito de cenas

    const productos_carrito_cena = Productos.filter((producto) => {
        return carrito_cena.some((item) => {
            return item.id == producto.id;
        });
    });

    carrito_cena.forEach((value) => {
        /**
         * variable para saber si
         * el producto realmente esta en el carrito
         */
        let bool_cena = true;

        //algoritmo par actualizar datos del carrito
        productos_carrito_cena.forEach((item) => {
            if (value.id == item.id) {
                bool_cena = false;

                item.bebida = value.bebida;
                item.postre = value.postre;

                item.num_bebida = value.num_bebida;
                item.num_postre = value.num_postre;
                item.comment = value.comment;

                item.hall = value.hall;

                if (item.stock == 0) {
                    store.dispatch(removeFromCena(value));
                } else if (value.qty > item.stock) {
                    store.dispatch(removeFromCena(value));
                    store.dispatch(addToCena(item, store.getState().cena.hora_entrega, item.stock));
                } else {
                    store.dispatch(removeFromCena(value));
                    store.dispatch(addToCena(item, store.getState().cena.hora_entrega, value.qty));
                }
            }
        });

        if (bool_cena) {
            /**
             * algoritmo para elminar del carrito ya que
             * es posible que algun producto que estaba en el carrito
             * se quedo sin stock
             */
            store.dispatch(removeFromCena(value));
        }
    });

    validateActiveInCart();

    return Productos;
};

export const setDeliverys = () => {
    let deliverys = {
        core: 6500,
        snack: 6500,
        huerta: 6500,
        congelado: 6500,
        restaurante: 6500,
        plan: 1500,
        core_huerta: 6500,
        chia: 13000,
    };
    store.dispatch(updateDelivery(deliverys));
};

export const loadUserCoor = async () => {
    const user = store.getState().customer.data;

    if (user) {
        if (!user.addresses[0].lat) {
            console.log('sin coordenadas');

            let newData = user;
            newData.addresses[0].lat = null;
            newData.addresses[0].lon = null;
            store.dispatch(updateUser(newData));

            //delete newData.addresses[0]['lon']
            //delete newData.addresses[0]['lat']
        }
    }
};
