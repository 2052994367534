import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getArrayCategoriesSlider } from '../../funciones/pasillo';
import { LazyImage } from 'react-lazy-images';
import { isPair, isAppColor } from '../../funciones/general';
import { getLocationCategories } from '../../funciones/cities';

class CategoriesHomeSlider2 extends Component {
    constructor(props) {
        super();
        this.state = {
            shuffle_array: getArrayCategoriesSlider(props.categories, props.categoria_id),
            id: props.categoria_id,
        };
    }

    goBycategory(category) {
        if (category.tipo == 2 || category.tipo == 6) {
            this.props.history.push('/aliados/' + category.seo);
        } else if (category.jerarquia == 1) {
            this.props.history.push('/menu-huerta');
        } else {
            this.props.history.push('/categoria/' + category.seo);
        }
    }

    render() {
        const { shuffle_array } = this.state;
        const { categories } = this.props;

        const cate_items = categories.filter((value) => value.pri < 77 && value.jerarquia != 2);

        let slider_categories = cate_items.slice(cate_items.length - 2, cate_items.length).map((value, i) => {
            return (
                <div
                    key={i}
                    className="col-12"
                    onClick={() => {
                        this.goBycategory(value);
                    }}
                >
                    <div>
                        <div
                            className={
                                'category-home-b3 ' +
                                (isAppColor(value.back_on) ? 'no-border ' : ' ') +
                                (i > 0 ? 'mt-4' : '')
                            }
                            style={{ backgroundColor: isAppColor(value.back_on) ? value.color : 'white' }}
                        >
                            <div className="container">
                                <div className="row">
                                    {isPair(i) ? (
                                        <>
                                            <div className="col-12 p-0">
                                                <div
                                                    className="item-vertical-center container"
                                                    style={{ width: '100%' }}
                                                >
                                                    <p className="subcategory-home-card-label">{value.name}</p>
                                                    <div className="my-3 subcategory-home-image-box2">
                                                        <img src={value.image} alt={value.name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="col-12 p-0">
                                                <div
                                                    className="item-vertical-center container"
                                                    style={{ width: '100%' }}
                                                >
                                                    <p className="subcategory-home-card-label">{value.name}</p>
                                                    <div className="my-3 subcategory-home-image-box2">
                                                        <img src={value.image} alt={value.name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <>
                <div className="container mb-display home-title-margin">
                    <div className="normal-title tac">Tendencias saludables</div>
                </div>

                <div className="content-home-m-1 mb-display">
                    <div className="row m-0">{slider_categories}</div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    categories: getLocationCategories(),
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(CategoriesHomeSlider2));
