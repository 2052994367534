import React, { Component } from 'react'
import Breadcrumb from '../../common/breadcrumb'
import { getData } from '../../../servicios'
import Modal from 'react-responsive-modal';
import ModalOrder from './Modales/ModalOrder';
import json from '../../../api/ordenes.json'
import { connect } from 'react-redux';
import { cargarOrders } from '../../../services';
import ContactIcons from '../../common/ContactIcons'
import BotonPse from '../../../assets/images/BotonPSE.png'
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class OrdenCancelada extends Component {

    constructor(props){
        super(props)
        this.state={
            ordenes:[],
            open: false,
            orden: null
        }

    }

    openModal = (e,value) => {
        e.preventDefault()
        this.setState({
            open:true,
            orden:value
        })

    }

    closeModal = () => {
        this.setState({
            open:false
        })
    }



    async componentDidMount(){

        const {user}=this.props

        try {
            const req=await getData("usuario/ordenes-canceladas/?id="+user.id)
            this.setState({
                ordenes:req.data
            })

        } catch (error) {
            console.log(error)
        }

        cargarOrders()

    }



    render() {
        const {ordenes,orden,open}=this.state

        const list=ordenes.map((value)=>{

            return(
                <tr key={value.id}>
                    <td>
                        {value.fecha}
                    </td>
                    <td>
                        {value.amount}
                    </td>
                    <td>
                        <span onClick={(e)=> this.openModal(e,value)}>
                            <i className="fa fa-eye pointer"  style={{ width: 35, fontSize: 20, padding: 11,color:'#138f9b' }}></i>
                        </span>
                        {
                            value.pay_method==3?
                            <Link to={"/pse-confirmation?token="+value.pay_cod} >
                                <img src={BotonPse} className="pse-button-order" alt={"boton pse"}/>
                            </Link>
                            :''
                        }
                    </td>

                </tr>
            )

        })

      

        return (

            <>
                <table className="table table-cart table-responsive-xs">

                    <thead>
                        <tr>
                            <th scope="col">
                                Fecha
                            </th>
                            <th scope="col">
                                Monto
                            </th>
                            <th scope="col">
                                Detalles
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {list}
                    </tbody>

                </table>

                <Modal classNames={{'modal': 'mb-modal'}} open={open} onClose={this.closeModal}>
                    <ModalOrder orden={orden} />
                </Modal>
            </>

        )
    }

}

const mapStateToProps = (state) => ({
    user: state.customer.data
})

export default connect(
    mapStateToProps, { }
)(OrdenCancelada)