import store from "../store";
import { findCategory } from "./general";

const clevertap=window.clevertap

export const cleverAddToCart = (product) => {

    clevertap.event.push("Al carrito", {
        "Producto":product.name,
        "Categoria":product.category,
        "Pasillo":product.hall_name,
        "Precio":product.price,
    });
    
}

export const cleverViewProduct = (product) => {

    clevertap.event.push("Producto Visto", {
        "Producto":product.name,
        "Categoria":product.category,
        "Pasillo":product.hall_name,
        "Precio":product.price,
    });
    
}

export const cleverSearch = (value) => {

    clevertap.event.push("Busquedas", {
        "Texto":value,
    });
    
}


export const cleverFailSearch = (value) => {

    clevertap.event.push("Busquedas Sin Resultados", {
        "Texto":value,
    });
    
}


export const cleverVisitedCategory = (value) => {

    clevertap.event.push("Categoria Visitada", {
        "Categoria":value,
    });
    
}

export const cleverVisitedHall = (value) => {

    clevertap.event.push("Pasillo Visitado", {
        "Pasillo":value,
    });
    
}

export const cleverSetUser = () => {

    const data=store.getState().customer.data

    if(data){

        clevertap.onUserLogin.push({
            "Site": {
                "Identity":data.id,
                "Name": data.name,
                "Usuario": data.nickname,
                "Cedula": data.ci,
                "Email": data.email,
                "Telefono": data.tel,
                "MSG-push": true,
                "MSG-sms": true                    
            }
        });
        
    }
    
}

export const cleverCheckoutFail = (text) => {

    clevertap.event.push("Checkout no completado", {
        "Motivo":text,
    });

}

export const cleverOnLogin = (data) => {

    clevertap.onUserLogin.push({
        "Site": {
            "Identity":data.id,
            "Name": data.name,
            "Usuario": data.nickname,
            "Cedula": data.ci,
            "Email": data.email,
            "Telefono": data.tel,
            "MSG-push": true,
            "MSG-sms": true                     
        }
    });

}

export const cleverRegistered = (data) => {

    clevertap.event.push("Registered", {
        "id":data.id,
        "Nombre": data.name,
        "Usuario": data.nickname,
        "Cedula": data.ci,
        "Email": data.email,
        "Telefono": data.tel,
    });
    
}

export const cleverItemNotFound = (data) => {

    clevertap.event.push("ItemNotFound",data);
    
}


export const cleverSchedule = (data) => {

    clevertap.event.push("Schedule",data);

}

export const cleverSurvey = (data) => {

    clevertap.event.push("Survey Preferences",data)

}

export const cleverChargued = (amount) => {

    clevertap.event.push("Charged",{
        revenue: parseFloat(amount)
    })
    
}

export const cleverUsedCode = (data) => {

    clevertap.event.push("Bono Canjeado",data)
    
}

export const cleverWebPush = () => {
    clevertap.notifications.push(
        {
            "titleText":"Fluio desea enviarte notificaciones",
            "bodyText":"permitir notificaciones?",
            "okButtonText":"Si",
            "rejectButtonText":"No",
            "okButtonColor":"#168577"
        }
    );
}

export const cleverHomeVisit = () => {

    clevertap.event.push("Home visitado")
    
}







