import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import Modal from 'react-responsive-modal';
import { removeFromCongelado,incrementCongelado,decrementCongelado } from '../../../actions';
import { getTotalNormal, getTipoCategoria, validateCongelado } from '../../../services';
import ProgramarCongelado from '../programacion/ProgramarCongelado';
import { toastError } from '../../../alertas';


class CartCongelado extends Component {

    constructor(props){
        super(props)
        this.state={
            open:false
        }

    }

    openCongelado = () => {
        this.setState({
            open: true
        })
    }

    closeCongelado = () => {
        this.setState({
            open: false
        })
    }
    

    handleModal = (e) => {
        e.preventDefault()
        this.openCongelado()
    }

    agregar = (item) => {

        if(validateCongelado(1)){
            this.props.incrementCongelado(item, 1)
        }else{
            toastError("Limite de articulos congelados alcanzado")
        }

    }


    render() {

        const {cartItems, symbol, total, delivery,tipo_categoria} = this.props;

        const img_categorias=tipo_categoria.map((value,index)=>{
            return(
                <img src={value.image} alt="" key={index} className="mb-cate-cart" />
            )
        })

        return (
            cartItems.length>0?
            <>
            <hr className="hr-cart"/>
                <section className="cart-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12" id="accordion">


                                {
                                    cartItems.length>=3 ? 
                                    <div className="card-header" id="headingOne">
                                        <div className="row">

                                            <div className="col-md-6 col-sm-12">
                                                <h5 className="mb-0">
                                                    <button className="btn btn-link theme-color wrap-buttom mt-1" 
                                                    data-toggle="collapse" data-target="#collapseCongelado" aria-expanded="true" aria-controls="collapseOne">
                                                        {img_categorias}
                                                    </button>
                                                </h5>
                                            </div>


                                        </div>
                                                                        
                                    </div>

                                    : 
                                    <div className="row">

                                        <div className="col-sm-6 col-8">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link theme-color wrap-buttom mt-1">
                                                    {img_categorias}
                                                </button>
                                            </h5>
                                        </div>


                                        <div className="col-4 col-sm-6 center flr">
                                            <div className="row flr envio-box2">

                                                <div className="d-flex flex-row center">
                                                    <strong className="envio-label mb-font-15">Editar Envío</strong>
                                                    <i className="fa fa-clock-o pointer clock-icon" onClick={(e)=>{this.handleModal(e)}} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                }
            

                                <div id="collapseCongelado" className={cartItems.length>=3 ? "collapse show" : ''} aria-labelledby="headingOne" data-parent="#accordion">
                                    
                                    <br/>

                                    {
                                        cartItems.length>=3 ? 

                                        <div className="row flr envio-box">

                                            <div className="d-flex flex-row center">
                                                <strong className="envio-label mb-font-15">Editar Envío</strong>
                                                <i className="fa fa-clock-o pointer clock-icon" onClick={(e)=>{this.handleModal(e)}} />
                                            </div>

                                        </div>
                                        :

                                        ''
                                    }                   

                                    <table className="table cart-table table-responsive-xs fix-table-cart">
                                        <thead className="thead-group">
                                        <tr className="table-head">
                                            <th scope="col">
                                                <strong className="mb-none">Imagen</strong>
                                                <strong className="mb-display">Producto</strong>
                                            </th>
                                            <th scope="col">
                                                <strong className="mb-none">Nombre</strong>
                                                <strong className="mb-display">Acciones</strong>
                                            </th>
                                            <th scope="col">
                                                <strong className="mb-none">Precio</strong>
                                                <strong className="mb-display">Precios</strong>
                                            </th>
                                            <th scope="col">Cantidad</th>
                                            <th scope="col">Borrar</th>
                                            <th scope="col">Total</th>
                                        </tr>
                                        </thead>
                                        {cartItems.map((item, index) => {


                                            return (
                                                <tbody key={index} className="tbody-group">
                                                    <tr>
                                                        <td>
                                                            <Link to="#" onClick={(e)=>e.preventDefault()}>
                                                                <img src={item.variants?
                                                                        item.variants[0].images
                                                                        :item.pictures[0]} alt=""
                                                                        className="fix-img-cart" />
                                                            </Link>
                                                            <Link to="#" className="mb-display" onClick={(e)=>e.preventDefault()}>{item.name}</Link>  
                                                        </td>
                                                        <td className="mb-p0"><Link to="#" className="mb-none" onClick={(e)=>e.preventDefault()}>{item.name}</Link>

                                                            {/*este bloque se utiliza para el responsive cuando la pantlla es pequeña*/}
                                                            <div className="mobile-cart-content row">
                                                                <div className="col-xs-3">
                                                                    <div className="qty-box">
                                                                        <div className="input-group">

                                                                            <span className="input-group-prepend">
                                                                                <button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementCongelado(item.id)} data-type="minus" data-field="">
                                                                                    <i className="fa fa-angle-left"></i>
                                                                                </button>
                                                                            </span>
                                                                            <input type="text" name="quantity" value={item.qty} readOnly={true} className="form-control input-number" />

                                                                            <span className="input-group-prepend">
                                                                                <button className="btn quantity-right-plus" onClick={() => this.agregar(item)}  data-type="plus" disabled={(item.qty >= item.stock)? true : false}>
                                                                                    <i className="fa fa-angle-right"></i>
                                                                                </button>
                                                                            </span>

                                                                        </div>
                                                                        
                                                                    </div>
                                                
                                                                </div>
                                                                <div className="col-xs-3 mt-4">
                                                                    <h2 className="td-color">
                                                                        <span className="icon pointer" onClick={() => this.props.removeFromCongelado(item)}>
                                                                            <i className="icon-close mobile-x"></i>
                                                                        </span>
                                                                    </h2>
                                                                </div>
                                                            </div>

                                                        </td>

                                                        <td className="mb-display">
                                                            <div className="col-xs-3 mt-5">
                                                                <h2 className="td-color font-15">{symbol}{(item.price)}</h2>
                                                            </div>
                                                            <h2 className="td-color font-15">
                                                                Total:{symbol+(item.price*item.qty)}
                                                            </h2>
                                                        </td>

                                                        {/*este bloque se utiliza para el responsive cuando la pantlla es "normal" */}
                                                        <td>
                                                            <h2>{symbol}{(item.price)}</h2>
                                                        </td>

                                                        <td>
                                                            <div className="qty-box">

                                                                <div className="input-group">

                                                                    <span className="input-group-prepend">
                                                                        <button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementCongelado(item.id)} data-type="minus" data-field="">
                                                                            <i className="fa fa-angle-left"></i>
                                                                        </button>
                                                                    </span>
                                                                    <input type="text" name="quantity" value={item.qty} readOnly={true} className="form-control input-number" />

                                                                    <span className="input-group-prepend">
                                                                        <button className="btn quantity-right-plus" onClick={() => this.agregar(item)}  data-type="plus" disabled={(item.qty >= item.stock)? true : false}>
                                                                            <i className="fa fa-angle-right"></i>
                                                                        </button>
                                                                    </span>

                                                                </div>
                                                            </div>
                                                            {(item.qty >= item.stock)? 'Se alcanzo el límite' : ''}

                                                        </td>
                                                        {/*
                                                        <td>
                                                            <Link to="/#" onClick={(e)=>{this.handleModal(e,item)}}>
                                                                <img src="/assets/images/clock.png" className="image45" alt="" />
                                                            </Link>
                                                        </td>
                                                        */}
                                                        <td>
                                                            <span className="icon pointer" onClick={() => this.props.removeFromCongelado(item)}>
                                                                <i className="fa fa-times"></i>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <h2 className="td-color">
                                                                {symbol} {(item.price*item.qty)}
                                                            </h2>
                                                        </td>
                                                    </tr>

                                                    {/*
                                                    <tr >
                                                        <td>
                                                            Hora de entrega: 14:30
                                                            Del 24/02/2020 al 29/02/2020
                                                            Dias: Lunes,Martes

                                                        </td>
                                                    </tr>
                                                    */}

                                                    


                                                </tbody>                                

                                            )
                                            
                                        })}
                                    </table>
                                    
                                    <table className="table cart-table table-responsive-md sub-fix">
                                        <tfoot>
                                        <tr>
                                            <td>Delivery :</td>
                                            <td>
                                                <h2 className="mb-mt-1">
                                                    {cartItems.length>0 ? symbol+delivery : symbol+0} 
                                                </h2>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Sub total :</td>
                                            <td><h2 className="mb-mt-1">{symbol+total} </h2></td>
                                        </tr>
                                        </tfoot>
                                    </table>




                                </div>

                                
                            </div>
                        </div>
                    </div>

                    <Modal classNames={{'modal': 'mb-modal'}} open={this.state.open} onClose={this.closeCongelado} >
                        <ProgramarCongelado close={this.closeCongelado} />
                    </Modal>

                </section>
            </>
            :
            null
        )

        
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.congelado.cart,
    symbol: state.data.symbol,
    total: getTotalNormal(state.congelado.cart,5),
    delivery: state.delivery.congelado,
    tipo_categoria: getTipoCategoria(5)
})

export default connect(
    mapStateToProps,
    {removeFromCongelado, incrementCongelado, decrementCongelado}
)(CartCongelado)