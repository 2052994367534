import React, { Component } from 'react'
import { connect } from 'react-redux'
import horas from '../../../api/horarios/tipoHuerta.json'
import { updateHuerta } from '../../../actions/index.js'
import {  } from '../../../servicios.js'
import { getFechaPatronHuertaYCongelado,getNextMartes,getNextSabado, getDateOrdenadoHuertaCongelado, getNextJueves } from '../../../funciones/patrones'
import { getDiaMesEspanolHuertaCongelado } from '../../../funciones/momentEspañol'


class ProgramarHuerta extends Component {

    constructor(props){
        super(props)
        this.state={
            hora: props.programacion.hora,
            radio:props.programacion.dia
        }
    }

    submit = (e) => {
        e.preventDefault()
        const programacion={
            hora: this.state.hora,
            dia: this.state.radio
        }
        this.props.updateHuerta(programacion)
        this.props.close()
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }


    getRadioCheck = (day) => {

        const {radio}=this.state

        switch (day) {
            case "martes":
                return radio=="mar"
            case "jueves":
                return radio=="jue"
            case "sabado":
                return radio=="sab"
            default:
                return radio=="sab"
        }

    }

    getRadioDate = (day) => {

        switch (day) {
            case "martes":
                return getDiaMesEspanolHuertaCongelado(getNextMartes())
            case "jueves":
                return getDiaMesEspanolHuertaCongelado(getNextJueves())
            case "sabado":
                return getDiaMesEspanolHuertaCongelado(getNextSabado())
            default:
                return getDiaMesEspanolHuertaCongelado(getNextSabado())
        }
        
    }

    getRadioValue = (day) => {

        switch (day) {
            case "martes":
                return "mar"
            case "jueves":
                return "jue"
            case "sabado":
                return "sab"
            default:
                return "sab"
        }
        
    }
    
    

    render() {

        const {radio}=this.state

        const list_horas=horas.map((value,index)=>{

            return(
                <option  value={value.hora} key={index}>{value.hora}</option>
            )

        })

        const list_radio=getDateOrdenadoHuertaCongelado().map((value,i)=>{
            return(

                <label className="radio-inline" key={i}>
                    <input 
                        type="radio" 
                        name="radio"  
                        value={this.getRadioValue(value.day)} 
                        onChange={this.handleChange} 
                        checked={this.getRadioCheck(value.day)}
                    />

                    {this.getRadioDate(value.day)}

                </label>
                
            )
        })
        
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title" >Editar envío de huerta orgánica</h5>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={this.submit}>

                            {list_radio}

                            <div className="form-group">
                                <label htmlFor="hora" className="col-form-label" >Hora :</label>
                                <select name="hora" onChange={this.handleChange} value={this.state.hora || ''} className="form-control" required>
                                    {list_horas}
                                </select>
                            </div>

                            <button className="btn btn-solid"> Guardar</button>

                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    programacion: state.huerta.programacion,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps,{updateHuerta}
)(ProgramarHuerta)