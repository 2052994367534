import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getArrayCategoriesSlider } from '../../funciones/pasillo';
import { LazyImage } from 'react-lazy-images';
import { getLocationHalls } from '../../funciones/cities';

class HallSlider extends Component {
    constructor(props) {
        super();
        this.state = {
            id: props.categoria_id,
        };
    }

    scrollToHall(hall) {
        var element = document.getElementById('hall' + hall.id);

        element.scrollIntoView({ block: 'center' });
        //window.scrollBy(0, 300)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.id != nextProps.categoria_id) {
            return {
                id: nextProps.categoria_id,
            };
        } else {
            return null;
        }
    }

    render() {
        const { id } = this.state;
        const { halls } = this.props;

        const newHall = [...halls];

        let slider_catgories = newHall
            .filter((h) => h.category_id == id)
            .map((value, i) => {
                return (
                    <div
                        key={i}
                        className="mr-2 inline-halls"
                        onClick={() => {
                            this.scrollToHall(value);
                        }}
                    >
                        {value.name}
                    </div>
                );
            });

        return (
            <div className="container-hall-s mb-display">
                <div className="categories-slider-box my-2">
                    <div className="w-max-content">{slider_catgories}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    halls: getLocationHalls(),
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(HallSlider));
