import React from 'react'
import { Route,Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

export const AuthRoute = ({component:Component,...rest}) => {


    const customer=JSON.parse(localStorage.getItem("state")).customer.data
     
    return (
        <Route {...rest} render={
            (props)=>{
                if(customer){
                    return <Component {...props} />
                }else{

                    if(window.location.href.includes("checkout")){
                        return <Redirect to="/login/checkout" />
                    }
                    if(window.location.href.includes("orders")){
                        return <Redirect to="/login/orders" />
                    }

                    return <Redirect to="/login" />
                }
            }
        }/>
    )
    
}

const mapStateToProps = (state) => ({
    customer: state.customer.data
})

export default connect(mapStateToProps)(AuthRoute)
