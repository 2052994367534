import React, { Component } from "react";
import { connect } from "react-redux";
import { getDeliveryCost, getTotalNormal } from "../../../services";
import * as moment from "moment";
import { getDiaMesEspanol } from "../../../funciones/momentEspañol";
import { getTimeRange } from "../../../funciones/patrones";
import { formatNumber, getPrice } from "../../../funciones/general";

class CheckSnack extends Component {
  render() {
    const { cartItems, total, symbol, delivery, entrega } = this.props;

    if (cartItems.length > 0) {
      return (
        <>
          <strong className="center70 font-25">Snacks</strong>
          <br />
          <div className="title-box">
            <div className="row">
              <div className="col-7">Producto</div>
              <div className="col-5">Total</div>
            </div>
          </div>
          <ul className="qty">
            {cartItems.map((item, index) => {
              return (
                <li key={index}>
                  <div className="row">
                    <div className="col-7">
                      {item.name} (×{item.qty})
                    </div>
                    <div className="col-5">
                      {symbol}
                      {formatNumber(getPrice(item) * item.qty)}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>

          <ul className="sub-total">
            <li>
              Entrega
              <span className="count">
                {entrega == 1
                  ? "Entrega en menos de 90 minutos"
                  : getDiaMesEspanol(entrega.fecha) +
                    " de " +
                    getTimeRange(entrega.hora)}
              </span>
            </li>
            <li>
              Delivery{" "}
              <span className="count">
                {symbol}
                {formatNumber(delivery)}
              </span>
            </li>
            <li>
              Subtotal{" "}
              <span className="count">
                {symbol}
                {formatNumber(total)}
              </span>
            </li>
          </ul>
        </>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.snack.cart,
  symbol: state.data.symbol,
  total: getTotalNormal(state.snack.cart, 0),
  delivery: getDeliveryCost(0),
  entrega: state.snack.programacion,
});

export default connect(
  mapStateToProps,
  {}
)(CheckSnack);
