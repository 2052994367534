import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../common/breadcrumb';
import ProductListingInPasillo from './common/product-listing-in-pasillo';
import { filterCategory } from '../../actions/index';
import Banner from '../common/Banner';
import { cleverVisitedHall } from '../../funciones/clevertap';

class CollectionInPasillo extends Component {
    state = {
        layoutColumns: 3,
    };

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums,
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            hall: this.props.match.params.pasillo,
            productos: [],
            loading: true,
        };
    }

    openFilter = () => {
        document.querySelector('.collection-filter').style = 'left: -15px';
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.filterCategory([]);
        this.props.history.push('/productos/' + this.state.text);
    };

    componentDidMount() {
        this.setState({
            loading: false,
        });
    }

    render() {
        const { halls, loading_products, products } = this.props;
        const { loading } = this.state;

        let pasillo = halls.find((val) => val.seo == this.props.match.params.pasillo);

        return (
            <div>
                <Banner />

                <Breadcrumb title={pasillo ? pasillo.name : ''} />

                {pasillo ? cleverVisitedHall(pasillo.name) : ''}

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-xl-12" />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {loading_products ? (
                                                            <div className="row">
                                                                <div className="col-md-12 text-center section-b-space mt-5 no-found">
                                                                    <img
                                                                        src={`${
                                                                            process.env.PUBLIC_URL
                                                                        }/assets/images/loading.gif`}
                                                                        className="img-fluid mb-4"
                                                                    />
                                                                    <h3>Cargando</h3>
                                                                </div>
                                                            </div>
                                                        ) : products.length > 0 && halls.length > 0 ? (
                                                            <ProductListingInPasillo
                                                                colSize={3}
                                                                pasillo={pasillo.id}
                                                                hallValue={pasillo}
                                                            />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    products: state.data.products,
    halls: state.halls.halls,
    loading_products: state.data.loading_products,
});

export default connect(
    mapStateToProps,
    { filterCategory }
)(CollectionInPasillo);
