import React, { Component } from 'react'
import { connect } from 'react-redux'
import horas from '../../../api/horarios/tipoRestaurante.json'
import { actualizarRestaurante, actualizarCore } from '../../../actions/index.js'
import * as moment from 'moment';
import { getDiffHoraria } from '../../../servicios.js'
import { postreByHall, bebidaByHall } from '../../../services';

class Comentarios extends Component {

    constructor(props){
        super(props)
        this.state={
            comment: props.product.comment,
        }
    }

    submit = (e,product) => {
        e.preventDefault()
        const {actualizarCore}=this.props
        const {comment}= this.state

        product.comment=comment

        actualizarCore(product)

        this.props.close()

        
    }

    handleChange = (e) => {
        const { name,value}= e.target
        this.setState({
            [name]: value
        })
    }

    restar = (e,key) => {

        e.preventDefault()
        this.setState({
            [key]: this.state[key]-1
        })
        
    }


    sumar = (e,key) => {

        e.preventDefault()
        this.setState({
            [key]: this.state[key]+1
        })
        
    }

    render() {

        const {product,symbol,add_postre,add_bebida}=this.props
        const { }=this.state

        
        return (
            <div className="modal-dialog modal-dialog-centered w-vista-r" role="document">
                <div className="modal-content quick-view-modal">
                    <div>
                        <h5 className="modal-title tac" >Comentarios</h5>
                    </div>
                    <div className="modal-body">
                        <div className="row">

                            <div className="col-12">
                                <form onSubmit={(e)=>{this.submit(e,product)}}>

                                    <div className="form-group">
                                        <label htmlFor="comment" className="col-form-label" >Comentarios:</label>
                                        <textarea name="comment" className="form-control" rows="2" onChange={this.handleChange} value={this.state.comment || ''} placeholder="Añade comentarios al pedido de este producto"/>
                                    </div>

                                    <br/>

                                    <button className="btn btn-solid"> Guardar</button>

                                </form>

                            </div>


                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    hora: state.restaurante.hora_entrega,
    add_postre: state.data.postres,
    add_bebida: state.data.bebidas,
})

export default connect(
    mapStateToProps,{actualizarCore}
)(Comentarios)