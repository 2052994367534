import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LazyImage } from 'react-lazy-images';
import {
    isPair,
    getSection1Color,
    getSection1ColorLabel,
    getSection1Border,
    isAppColor,
} from '../../funciones/general';
import moreImg from '../../assets/images/more.png';
import allyImg from '../../assets/images/aliados.png';
import { getLocationCategories } from '../../funciones/cities';

class HomeCategoriesSection1 extends Component {
    constructor(props) {
        super();
        this.state = {
            more: false,
        };
    }

    goBycategory(category) {
        if (category.tipo == 2 || category.tipo == 6) {
            this.props.history.push('/aliados/' + category.seo);
        } else if (category.jerarquia == 1) {
            this.props.history.push('/menu-huerta');
        } else {
            this.props.history.push('/categoria/' + category.seo);
        }
    }

    goAliado() {
        this.props.history.push('/nuestros-aliados');
    }

    handleMore = () => {
        this.setState({
            more: true,
        });
    };

    closeMore = () => {
        this.setState({
            more: false,
        });
    };

    render() {
        const { categories } = this.props;
        const { more } = this.state;

        const cate_items = categories.filter((value) => value.pri < 77 && value.jerarquia != 2);

        const aliadoItem = () => {
            return (
                <div
                    className={'mb-padding col-sm-6 col-lg-3 col-xl-3 col-4 big-cate-img center-cate-styles'}
                    onClick={() => this.goAliado()}
                >
                    <div
                        className={
                            'cate-banner box400 bg-cate mb-5 mb-cate-box border-category-home ' +
                            (true ? 'no-border' : '')
                        }
                        style={{ backgroundColor: isAppColor(true) ? '#e3b0c7' : 'white' }}
                    >
                        <a>
                            <LazyImage
                                src={allyImg}
                                alt={'ally'}
                                placeholder={({ imageProps, ref }) => (
                                    <img
                                        className={'home-loader img-fluid bg-img up-image mb-home-loader'}
                                        ref={ref}
                                        src={`${process.env.PUBLIC_URL}/assets/images/loading5.gif`}
                                        alt={imageProps.alt}
                                    />
                                )}
                                actual={({ imageProps }) => (
                                    <img {...imageProps} className={'img-fluid bg-img up-image more-img'} />
                                )}
                            />
                            {/*
                            <img src={value.image} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')} alt="" />
                            */}
                        </a>
                        {/*                      
                        <div className={"category-box"}>                       
                            <a className="pointer" >
                                <p style={{color: getSection1ColorLabel(index,value.color)}}
                                    className={"mb-category-section-label second-cate-label"}>{value.name}</p>
                            </a>
                        </div>
                        */}
                    </div>
                    <p className="center-cate-label mt-1">{'Aliados'}</p>
                </div>
            );
        };

        const moreItem = () => {
            return (
                <div
                    className={'mb-padding col-sm-6 col-lg-3 col-xl-3 col-4 big-cate-img center-cate-styles'}
                    onClick={() => this.handleMore()}
                >
                    <div
                        className={
                            'cate-banner box400 bg-cate mb-5 mb-cate-box border-category-home ' +
                            (false ? 'no-border' : '')
                        }
                        style={{ backgroundColor: isAppColor(true) ? 'white' : 'white' }}
                    >
                        <a>
                            <LazyImage
                                src={moreImg}
                                alt={'more'}
                                placeholder={({ imageProps, ref }) => (
                                    <img
                                        className={'home-loader img-fluid bg-img up-image mb-home-loader'}
                                        ref={ref}
                                        src={`${process.env.PUBLIC_URL}/assets/images/loading5.gif`}
                                        alt={imageProps.alt}
                                    />
                                )}
                                actual={({ imageProps }) => (
                                    <img {...imageProps} className={'img-fluid bg-img up-image more-img'} />
                                )}
                            />
                            {/*
                            <img src={value.image} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')} alt="" />
                            */}
                        </a>
                        {/*                      
                        <div className={"category-box"}>                       
                            <a className="pointer" >
                                <p style={{color: getSection1ColorLabel(index,value.color)}}
                                    className={"mb-category-section-label second-cate-label"}>{value.name}</p>
                            </a>
                        </div>
                        */}
                    </div>
                    <p className="center-cate-label mt-1">{'Ver más'}</p>
                </div>
            );
        };

        const list_categories_desk = cate_items.slice(2, 6).map((value, index) => {
            return (
                <div
                    key={index}
                    className={'mb-padding col-sm-6 col-lg-3 col-xl-3 col-4 big-cate-img center-cate-styles'}
                    key={index}
                    onClick={() => this.goBycategory(value)}
                >
                    <div
                        className={
                            'cate-banner box400 bg-cate mb-5 mb-cate-box border-category-home ' +
                            (isAppColor(value.back_on) ? 'no-border' : '')
                        }
                        style={{ backgroundColor: isAppColor(value.back_on) ? value.color : 'white' }}
                    >
                        <a>
                            <LazyImage
                                src={value.image}
                                alt={value.name}
                                placeholder={({ imageProps, ref }) => (
                                    <img
                                        className={'home-loader img-fluid bg-img up-image mb-home-loader'}
                                        ref={ref}
                                        src={`${process.env.PUBLIC_URL}/assets/images/loading5.gif`}
                                        alt={imageProps.alt}
                                    />
                                )}
                                actual={({ imageProps }) => (
                                    <img
                                        {...imageProps}
                                        className={'center-mt-30 img-fluid bg-img up-image big-cate-img'}
                                    />
                                )}
                            />
                            {/*
                            <img src={value.image} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')} alt="" />
                            */}
                        </a>
                        {/*                      
                        <div className={"category-box"}>                       
                            <a className="pointer" >
                                <p style={{color: getSection1ColorLabel(index,value.color)}}
                                 className={"mb-category-section-label second-cate-label"}>{value.name}</p>
                            </a>
                        </div>
                        */}
                    </div>
                    <p className="center-cate-label mt-1">{value.name}</p>
                </div>
            );
        });

        const list_categories_pop = cate_items.slice(6, cate_items.length - 2).map((value, index) => {
            return (
                <div
                    key={index}
                    className={'mb-padding col-sm-6 col-lg-3 col-xl-3 col-4 big-cate-img center-cate-styles'}
                    key={index}
                    onClick={() => this.goBycategory(value)}
                >
                    <div
                        className={
                            'cate-banner box400 bg-cate mb-5 mb-cate-box border-category-home ' +
                            (isAppColor(value.back_on) ? 'no-border' : '')
                        }
                        style={{ backgroundColor: isAppColor(value.back_on) ? value.color : 'white' }}
                    >
                        <a>
                            <LazyImage
                                src={value.image}
                                alt={value.name}
                                placeholder={({ imageProps, ref }) => (
                                    <img
                                        className={'home-loader img-fluid bg-img up-image mb-home-loader'}
                                        ref={ref}
                                        src={`${process.env.PUBLIC_URL}/assets/images/loading5.gif`}
                                        alt={imageProps.alt}
                                    />
                                )}
                                actual={({ imageProps }) => (
                                    <img
                                        {...imageProps}
                                        className={'center-mt-30 img-fluid bg-img up-image big-cate-img'}
                                    />
                                )}
                            />
                            {/*
                            <img src={value.image} className={"center-mt-30 img-fluid bg-img up-image "+ (index>3 ? '' : 'big-cate-img')} alt="" />
                            */}
                        </a>
                        {/*                      
                        <div className={"category-box"}>                       
                            <a className="pointer" >
                                <p style={{color: getSection1ColorLabel(index,value.color)}}
                                 className={"mb-category-section-label second-cate-label"}>{value.name}</p>
                            </a>
                        </div>
                        */}
                    </div>
                    <p className="center-cate-label mt-1">{value.name}</p>
                </div>
            );
        });

        const morePop = () => {
            return (
                <div className="float-box2 mb-display">
                    <div className="tac" className="sugeridos-header2 mb-1">
                        <div className="d-flex justify-content-between bd-highlight">
                            <div className="bd-highlight" />
                            <div className="bd-highlight mt-2">Más categorías</div>
                            <div className="bd-highlight cerrar-sugerido2" onClick={this.closeMore}>
                                cerrar <i className="fa fa-close close-sugerido-icon" />
                            </div>
                        </div>
                    </div>

                    <div className="sandwitch4">
                        <div>
                            <div className="content-more">
                                <div style={{ height: '20px' }} />

                                <div className="row margin-zero">{list_categories_pop}</div>

                                <div style={{ height: '30px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <>
                <div className="bg-white mb-display">
                    <section className="p-0 ratio2_1 bg-white sandwitch8">
                        <div className="">
                            <div className="row">
                                {list_categories_desk}

                                {aliadoItem()}

                                {moreItem()}
                            </div>
                        </div>
                    </section>
                </div>

                {more ? morePop() : <></>}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    categories: getLocationCategories(),
});

export default connect(
    mapStateToProps,
    {}
)(withRouter(HomeCategoriesSection1));
