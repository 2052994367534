import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { checkDisponible, decrementInSlider, getMaxProd, incrementInSlider, productInCart } from '../../funciones/general'
import PriceListingPage from './PriceListingPage'
import { addFromSlider } from '../../funciones/general'
import { cleverAddToCart } from '../../funciones/clevertap'
import { toastError } from '../../alertas'
import NotAvailableProd from '../collection/common/NotAvailableProd'
import PriceListingModal from '../collection/common/PriceListingModal'
import TipoCenaPage from '../collection/common/modales/TipoCenaPage'
import Modal from 'react-responsive-modal';
import TipoSemanalPage from '../collection/common/modales/TipoSemanalPage'
import TipoQuincenalPage from '../collection/common/modales/TipoQuincenalPage'
import TipoMensualPage from '../collection/common/modales/TipoMensualPage'

class ProductPageView extends Component {

    constructor (props) {
        super (props)
        this.state={
            openSemanal: false,
            openQuincenal:false,
            openMensual:false,
            openCena:false
        }
    }


    initialAdd = (e,prod) => {

        if(prod.patron_default==6){
            this.onOpenCena(e)
            return
        }

        if(prod.patron_default==3){

            if(prod.tipo_hall==1){
                this.onOpenSemanal(e)
            }
            if(prod.tipo_hall==2){
                this.onOpenQuincenal(e)
            }
            if(prod.tipo_hall==4){
                this.onOpenMensual(e)
            }

            return

        }

        if(prod.activo_hall==0 || prod.activo==0 || prod.stock==0){

            toastError("Producto no disponible")

        }else{

            cleverAddToCart(prod)
            addFromSlider(prod,prod.patron_default)

        }
        
    }

    onOpenCena = (e) => {
        e.preventDefault()
        this.setState({ openCena: true });
    };

    onCloseCena = () => {
        this.setState({ openCena: false });
    };

    onOpenSemanal = (e) => {
        e.preventDefault()
        this.setState({ openSemanal: true });
    };

    onCloseSemanal = () => {
        this.setState({ openSemanal: false });
    };

    onOpenQuincenal = (e) => {
        e.preventDefault()
        this.setState({ openQuincenal: true });
    };

    onCloseQuincenal = () => {
        this.setState({ openQuincenal: false });
    };

    onOpenMensual = (e) => {
        e.preventDefault()
        this.setState({ openMensual: true });
    };

    onCloseMensual = () => {
        this.setState({ openMensual: false });
    };
    

    render() {

        const {prod, inCart} = this.props
        const {openCena,openSemanal,openQuincenal,openMensual} = this.state

        return (
            <>
            <div className="row mb-none">

                <div className="col-sm-5">
                    <img src={prod.pictures[0]} alt={prod.name} className="product-page-img"/>
                </div>

                <div className="col-sm-7">

                    <div className="wrap1">
                        <div className="inner1">

                            <h4 className="prod-p-name">
                                {prod.name}
                            </h4>

                            <div className="prod-p-aliado">
                                {prod.aliado}
                            </div>

                            <div className="mt-4">
                                <PriceListingPage product={prod}/>
                            </div>

                            <div className="mt-4 prod-p-desc">
                                {prod.description}
                            </div>

                            {
                                inCart?

                                <div className="row">
                                    <div className="col-4">

                                        <label htmlFor="cantidad" className="col-form-label cantidad-modal-label" >Cantidad:</label>
                                        <div className="input-group">
                                
                                            <span className="input-group-prepend">
                                                <button type="button" className="btn quantity-left-minus" 
                                                    onClick={(e) => decrementInSlider(e,inCart,inCart.qty,inCart.patron_default)} 
                                                    data-type="minus" 
                                                    data-field="" >
                                                    <i className="fa fa-angle-left"></i>
                                                </button>
                                            </span>

                                            <input type="text" name="cantidad" value={inCart.qty} 
                                            readOnly={true} className="form-control input-number tac" 
                                            />
                                            <span className="input-group-prepend">
                                                <button className="btn quantity-right-plus" 
                                                onClick={(e) => incrementInSlider(e,inCart,inCart.qty,inCart.patron_default)} 
                                                data-type="plus" disabled={(inCart.qty >= getMaxProd(prod))? true : false}>
                                                    <i className="fa fa-angle-right"></i>
                                                </button>
                                            </span>

                                        </div>

                                    </div>                                 
                                </div>

                                :
                                <button className={"btn btn-solid btn-round "+checkDisponible(prod)} 
                                onClick={(e)=>this.initialAdd(e,prod)}>
                                    Añadir al carrito
                                </button>
                            }

                            

                        </div>
                    </div>



                </div>

            </div>


            <div className="mb-display fluio-font">

                <div className="modal-prod-title">
                    {prod.name}
                </div>

                <div className="modal-prod-name">
                    {prod.aliado}
                </div>

                <div className="quick-view-img">
                    <img src={prod.variants?
                        this.state.image?this.state.image:prod.variants[0].images
                        :prod.pictures[0]} alt="" className="modal-image-style" />
                </div>

                <PriceListingModal product={prod}/>

                <div className="modal-prod-desc">
                    {prod.description}
                </div>

                {
                    inCart?
                    <>
                    <div className="modal-container-qty">
                        <div>

                            <span className="modal-cantidad-text">
                                Cantidad
                            </span>

                            <span className="modal-min" onClick={
                                (e) =>{ 
    
                                    decrementInSlider(e,inCart,inCart.qty,inCart.patron_default)
                                                                         
                                }
                            }>
                                -
                            </span>

                            <span className="modal-plus" onClick={
                                (e) => {
                                    if(inCart.qty<getMaxProd(prod)){
                                        incrementInSlider(e,inCart,inCart.qty,inCart.patron_default)
                                    }                                           
                                }
                            }>
                                +
                            </span>

                            <span className="modal-cantidad-number">
                                {inCart.qty}
                            </span>

                        </div>
                    
                    </div>

                    </>
                    :
                    <div className="container-button-prod">
                        <button onClick={(e)=>this.initialAdd(e,prod)}
                        className="btn modal-btn-add ttn" style={{width: '100%'}} >
                            Añadir al carrito
                        </button>                                                  
                    </div>    
                }
                
                
            </div>

            <Modal classNames={{'modal': 'mb-modal'}} open={openCena} onClose={this.onCloseCena} center>
                        
                <TipoCenaPage product={prod} close={this.onCloseCena} />

            </Modal>

            <Modal classNames={{'modal': 'mb-modal'}} open={openSemanal} onClose={this.onCloseSemanal} center>
                        
                <TipoSemanalPage product={prod} close={this.onCloseSemanal} />

            </Modal>

            <Modal classNames={{'modal': 'mb-modal'}} open={openQuincenal} onClose={this.onCloseQuincenal} center>
                        
                <TipoQuincenalPage product={prod} close={this.onCloseQuincenal} />

            </Modal>

            <Modal classNames={{'modal': 'mb-modal'}} open={openMensual} onClose={this.onCloseMensual} center>
                        
                <TipoMensualPage product={prod} close={this.onCloseMensual} />

            </Modal>

            </>
        )
    }
}

const mapStateToProps = (state,ownProps) => ({
    products: state.data.products,
    loading_products:state.data.loading_products,
    inCart:productInCart(ownProps.prod,ownProps.prod.patron_default),
})

export default connect(
    mapStateToProps, { }
)(withRouter(ProductPageView))