import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToCart, addToWishlist, addToCompare } from '../../../actions';
import ProductListItem2 from './product-list-item2';
import { withRouter } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import Slider from 'react-slick';
import { getLocationProducts } from '../../../funciones/cities';

class ProductListingPasillos2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productos: [],
        };
    }

    goToPasillo = (e, pasillo) => {
        e.preventDefault();
        window.location.href = process.env.PUBLIC_URL + '/pasillo/' + pasillo;
    };

    render() {
        const {
            pasillo,
            pasillo_seo,
            addToCart,
            symbol,
            addToWishlist,
            addToCompare,
            categoria,
            products,
            hallValue,
        } = this.props;
        const { productos } = this.state;

        if (categoria == 2) {
            productos.sort((product1, product2) => {
                return product2.price > product1.price ? -1 : 1;
            });
        }

        let list_products = products.filter((value) => value.hallsId.includes(pasillo));

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1424 },
                items: 4,
                slidesToSlide: 1, // optional, default to 1.
                partialVisibilityGutter: 40,
            },
            tablet: {
                breakpoint: { max: 1424, min: 764 },
                items: 2,
                slidesToSlide: 2, // optional, default to 1.
            },
            mobile: {
                breakpoint: { max: 764, min: 0 },
                items: 2.5,
                slidesToSlide: 2,
            },
        };

        var settings = {
            dots: true,
            infinite: false,
            speed: 200,
            lazyLoad: 'progressive',
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 1500,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        arrows: true,
                    },
                },
            ],
        };

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid mb-no-padding">
                        {/*
                            <a href="#" className="mb-display text-deco" onClick={(e)=>{this.goToPasillo(e,pasillo)}}>Ver más</a>
                            */}
                        <br />
                        {/*validaciones con ternarios para que se muestre de forma correcta en movil(cambios en css)
                            {productos.length>2?
                                
                                
                                <Slider {...settings}>
                                    { 
                                        productos.map((product, index) =>


                                            <div key={index} className={"padding-10 mb-no-padding "}>
                                                <ProductListItem2 product={product} symbol={symbol}
                                                onAddToCompareClicked={() => addToCompare(product)}
                                                onAddToWishlistClicked={() => addToWishlist(product)}
                                                onAddToCartClicked={addToCart} key={index}
                                                products_length={productos.length}
                                                index={index}/>
                                            </div>
                                        
                                        )
                                    }
                                </Slider>
                            */}

                        <div className="slider-box mb-display">
                            <div className="w-max-content">
                                {list_products.map((product, index) => (
                                    <div key={index} className={'mr-2 inline'}>
                                        <ProductListItem2
                                            product={product}
                                            symbol={symbol}
                                            onAddToCompareClicked={() => addToCompare(product)}
                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                            onAddToCartClicked={addToCart}
                                            key={index}
                                            products_length={list_products.length}
                                            index={index}
                                            hallValue={hallValue}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="mb-none">
                            <Slider {...settings}>
                                {list_products.map((product, index) => (
                                    <div key={index} className={'padding-10 mb-no-padding '}>
                                        <ProductListItem2
                                            product={product}
                                            symbol={symbol}
                                            onAddToCompareClicked={() => addToCompare(product)}
                                            onAddToWishlistClicked={() => addToWishlist(product)}
                                            onAddToCartClicked={addToCart}
                                            key={index}
                                            products_length={list_products.length}
                                            index={index}
                                            hallValue={hallValue}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>

                        <button
                            className="btn btn-outline btn-center mt-5 mb-none"
                            onClick={(e) => {
                                this.goToPasillo(e, pasillo_seo);
                            }}
                        >
                            Ver más
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    symbol: state.data.symbol,
    products: getLocationProducts(),
});

export default connect(
    mapStateToProps,
    { addToCart, addToWishlist, addToCompare }
)(withRouter(ProductListingPasillos2));
