import { combineReducers } from "redux";
import { IntlReducer as Intl } from "react-redux-multilingual";

// Import custom components
import productReducer from "./products";
import cartReducer from "./cart";
import filtersReducer from "./filters";
import wishlistReducer from "./wishlist";
import compareReducer from "./compare";
import userReducer from "./user";
import huertaReducer from "./huerta";
import congeladoReducer from "./congelado";
import coreReducer from "./core";
import snackReducer from "./snack";
import deliveryReducer from "./delivery";
import restauranteReducer from "./restaurante";
import categoriesReducer from "./categories";
import cenaReducer from "./cena";
import hallsReducer from "./halls";
import ordenReducer from "./orden";
import aliadoReducer from "./aliado";
import externalReducer from "./external";

const rootReducer = combineReducers({
  data: productReducer,
  cartList: cartReducer,
  filters: filtersReducer,
  wishlist: wishlistReducer,
  compare: compareReducer,
  customer: userReducer,
  huerta: huertaReducer,
  congelado: congeladoReducer,
  core: coreReducer,
  snack: snackReducer,
  delivery: deliveryReducer,
  restaurante: restauranteReducer,
  categories: categoriesReducer,
  cena: cenaReducer,
  halls: hallsReducer,
  orden: ordenReducer,
  aliado: aliadoReducer,
  external: externalReducer,
  Intl,
});

export default rootReducer;
