import * as types from '../constants/ActionTypes'

const aliadoReducerDefaultState = {
    data:[]
};

const aliadoReducer = (state = aliadoReducerDefaultState, action) => {
    switch (action.type) {
        case types.UPDATE_ALIADO:
            return {
                ...state,
                data: action.aliado
            };
        default:
            return state;
    }
}

export default aliadoReducer;