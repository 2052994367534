import axios from 'axios';
import * as moment from 'moment';
import { parse } from 'query-string';
import store from './store';

const url = 'https://api.fluio.com.co/';
//const url = 'http://96.126.120.138:45208/';

const config = {
    headers: store.getState().customer.headers,
};

export function postData(endpoint, data, headers) {
    return axios.post(url + endpoint, data, headers);
}

export function getData(endpoint, headers) {
    return axios.get(url + endpoint, headers);
}

export function deleteData(endpoint, id) {
    return axios.delete(url + endpoint + '/' + id, config);
}
export function putData(endpoint, id, data) {
    return axios.put(url + endpoint + '/' + id, data, config);
}

export function putDataNoId(endpoint, data, config) {
    return axios.put(url + endpoint, data, config);
}

export function getUser() {
    if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
    } else {
        return {};
    }
}

export function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function getNextMonday() {
    const today = moment().isoWeekday();

    /**
     * validacion para saber si es  sabado ya que hata el viernes sepueden pedir platos para el
     * lunes inmediato, a partir de un sabado el plato llega el lunes de arriba
     */
    if (today == 6) {
        // then just give me this week's instance of that day
        return moment()
            .add(2, 'weeks')
            .weekday(0)
            .format('DD/MM/YYYY');
    } else {
        // otherwise, give me *next week's* instance of that same day
        return moment()
            .add(1, 'weeks')
            .weekday(0)
            .format('DD/MM/YYYY');
    }
}

export function getNextMartes() {
    const today = moment().isoWeekday();

    /**
     * validacion para saber si es  sabado ya que hata el viernes sepueden pedir platos para el
     * lunes inmediato, a partir de un sabado el plato llega el lunes de arriba
     */
    if (today == 7 || today == 1) {
        // then just give me this week's instance of that day
        return moment()
            .weekday(1)
            .format('DD/MM/YYYY');
    } else {
        // otherwise, give me *next week's* instance of that same day
        return moment()
            .add(1, 'weeks')
            .weekday(2)
            .format('DD/MM/YYYY');
    }
}

export function getCartLength() {
    const individual = store.getState().cartList.cart.length;
    const huerta = store.getState().huerta.cart.length;
    const congelado = store.getState().congelado.cart.length;
    const core = store.getState().core.cart.length;
    const snack = store.getState().snack.cart.length;
    const restaurante = store.getState().restaurante.cart.length;
    const cena = store.getState().cena.cart.length;
    const external = store.getState().external.cart.length;

    return individual + huerta + congelado + core + snack + restaurante + cena + external;
}

export function getDiffHoraria(hora) {
    return moment(hora, 'HH:mm').diff(moment(), 'hours');
}

export function getDiffMinutes(hora) {
    /**
     * 
    const a = moment(hora,'HH:mm')
    const b = moment(getHoraBogota(),'HH:mm')

    let total1=a.minutes() + a.hours() * 60;

    let total2=b.minutes() + b.hours() * 60;
    console.log(total1-total2)
    

    return total1-total2
     */

    return moment(hora, 'HH:mm').diff(moment(), 'minutes');
}

export function detectCardType(number) {
    var re = {
        electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        dankort: /^(5019)\d+$/,
        interpayment: /^(636)\d+$/,
        unionpay: /^(62|88)\d+$/,
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        amex: /^3[47][0-9]{13}$/,
        dinersclub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
        amex: /^3[47][0-9]{5,}$/,
    };

    for (var key in re) {
        if (re[key].test(number)) {
            return key;
        }
    }

    return '';
}

export function getArrayByTipo(array, tipo) {
    const datos = array.filter((value, index) => {
        return value.tipo == tipo;
    });

    return datos;
}

export const isOrdenLocal = (val) => {
    if (val === parseInt(0)) {
        return true;
    } else {
        return false;
    }
};

export const getTotalOrden = (cartItems, tipo, match, ordenType = 0) => {
    var total = 0;
    const delivery = store.getState().delivery;

    for (var i = 0; i < cartItems.length; i++) {
        total += parseInt(cartItems[i].total, 10);
    }

    if (isOrdenLocal(ordenType)) {
        switch (tipo) {
            case 0:
                total += parseFloat(cartItems[0].delivery);
                break;
            case 1:
                if (!match) {
                    total += parseFloat(cartItems[0].delivery);
                }
                break;
            case 2:
                total += parseFloat(cartItems[0].delivery);
                break;
            case 3:
                total += 0;
                break;
            case 4:
                if (!match) {
                    total += parseFloat(cartItems[0].delivery);
                }
                break;
            case 5:
                total += parseFloat(cartItems[0].delivery);
                break;
            default:
                break;
        }
    }

    return total;
};

export const hayHorasDisponibles = (horas) => {
    const list_horas = horas.filter((value, index) => {
        if (getDiffMinutes(value.hora) >= 50) {
            return true;
        }
    });

    if (list_horas.length > 0) {
        return true;
    } else {
        return false;
    }
};

export const getDia = (horaSemana, horaFinSemana) => {
    //caso fin de semana
    if (isWeekend()) {
        if (hayHorasDisponibles(horaFinSemana)) {
            return 'HOY';

            //caso hora para el dia de mañana
        } else {
            return 'MAÑANA';
        }

        //caso semana
    } else {
        if (hayHorasDisponibles(horaSemana)) {
            return 'HOY';

            //caso hora para el dia sabado
        } else {
            return 'MAÑANA';
        }
    }
};

export const isWeekend = () => {
    let dia = moment().isoWeekday();

    if (dia == 6 || dia == 7) {
        return true;
    } else {
        return false;
    }
};

export const isViernes = () => {
    let dia = moment().isoWeekday();

    if (dia == 5) {
        return true;
    } else {
        return false;
    }
};

export const isDomingo = () => {
    let dia = moment().isoWeekday();

    if (dia == 7) {
        return true;
    } else {
        return false;
    }
};

//metodo para saber si la hora almacenada esta disponible
export const isHoraCenaDisponible = (horas, horas_fin) => {
    if (isWeekend()) {
        if (hayHorasDisponibles(horas_fin)) {
            return true;
        } else {
            return false;
        }

        //caso semana
    } else {
        //caso horas disponible para el viernes
        if (hayHorasDisponibles(horas)) {
            return true;

            //caso hora para el dia sabado
        } else {
            return false;
        }
    }
};

// obtiene un array de horas disponibles para un patron que en la semana tiene
//un grupo de horas y el fin de semana tiene otro grupo de horas
export const getArrayPatronSemanaYFin = (horas, horas_fin) => {
    if (isWeekend()) {
        if (hayHorasDisponibles(horas_fin)) {
            return horas_fin.filter((value, index) => getDiffMinutes(value.hora) >= 50);
        } else {
            if (isDomingo()) {
                return horas;
            } else {
                return horas_fin;
            }
        }
        //caso semana
    } else {
        if (hayHorasDisponibles(horas)) {
            return horas.filter((value, index) => getDiffMinutes(value.hora) >= 50);
            //caso hora para el dia sabado
        } else {
            if (isViernes()) {
                return horas_fin;
            } else {
                return horas;
            }
        }
    }
};

//retorna array de horas para patron restaurante
export const getArrayPatronRestaurante = (horas) => {
    if (hayHorasDisponibles(horas)) {
        return horas.filter((value, index) => getDiffMinutes(value.hora) >= 50);
    } else {
        return horas;
    }
};

export const mananaDisponibe = () => {
    let dia = moment().isoWeekday();

    if (dia == 5 || dia == 6 || dia == 7) {
        return false;
    } else {
        return true;
    }
};

export const getDiaRestaurante = (horas) => {
    if (isWeekend()) {
        return 'EL LUNES';
    } else {
        if (hayHorasDisponibles(horas)) {
            return 'HOY';
        } else {
            if (isViernes()) {
                return 'EL LUNES';
            } else {
                return 'MAÑANA';
            }
        }
    }
};
