import {
  FETCH_SINGLE_PRODUCT,
  CHANGE_CURRENCY,
  RECEIVE_PRODUCTS,
  UPDATE_BEBIDA,
  UPDATE_POSTRE,
  COMPLETE_SURVEY,
  UPDATE_LOCATION,
} from "../constants/ActionTypes";

const initialState = {
  products: [],
  symbol: "$",
  product_details: [],
  bebidas: [],
  postres: [],
  loading_products: true,
  survey_complete: false,
  city: "",
  country: "",
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      return { ...state, products: action.products, loading_products: false };
    case FETCH_SINGLE_PRODUCT:
      if (
        state.products.findIndex(
          (product) => product.id === action.productId
        ) !== -1
      ) {
        const singleItem = state.products.reduce((itemAcc, product) => {
          return product;
        }, []);
        return { ...state, product_details: singleItem };
      }
    case CHANGE_CURRENCY:
      return { ...state, symbol: action.symbol };
    case UPDATE_POSTRE:
      return { ...state, postres: action.postres };
    case UPDATE_BEBIDA:
      return { ...state, bebidas: action.bebidas };
    case COMPLETE_SURVEY:
      return { ...state, survey_complete: true };
    case UPDATE_LOCATION:
      return { ...state, city: action.city, country: action.country };
    default:
      return state;
  }
};
export default productReducer;
